import React, {useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom"
import axios from "axios";
import { ColorRing } from 'react-loader-spinner';
import { getToken } from "../../services/TokenService";
import cn from 'classnames'

// css
import Style from './index.module.css'
import Cmm from '@/assets/image/ico/ico-cmm.svg'
import PagePrev from '@/assets/image/ico/ico-page-prev.svg'
import PageNext from '@/assets/image/ico/ico-page-next.svg'
import SearchImg from '@/assets/image/ico/ico-search.svg'
import Pen from '@/assets/image/ico/ico-pen.svg'

const Index = () => {
	const [boardList, setBoardList] = useState([]);
	const [pageList, setPageList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [listloading, setListLoading] = useState(false);
	const [search, setSearch] = useState({
		page: 1,
		sck: '',
		scv: '',
	  });

	const [pageNum, setPageNum] = useState("1");
	const [totalPageNum, setTotalPageNum] = useState("1");
	const [admChk, setAdmChk] = useState("N");
	const [searchWd, setSearchWd] = useState("");
	const [searchKey, setSearchKey] = useState("title");

	const navigate = useNavigate();
	
	const getBoardList = async (e) => {

		const token = getToken();

		if(token !== null){
			const local_token = JSON.parse(token);

			if (pageNum === e) return; //현재 페이지와 누른 페이지가 같으면 return

			//const queryString = Object.entries(search)
			//.map((e) => e.join('='))
			//.join('&');
			
			//const queryString = "/"+search.page;
			//const queryString = "/"+pageNum;
			if(e == undefined){
				e = "";	
			}
			const queryString = "/"+e;

			//console.log(queryString)
			
			axios({
				method: 'post',
				url: process.env.REACT_APP_API_COMMUNICATION_LIST,
				mode: "cors",
				credentials: "same-origin",
				headers: {
					Authorization: "Bearer " + local_token.access_token
				},
				data:{
					page: e,
					searchword: searchWd,
					searchkey: searchKey,
					company: local_token.company,
				},
			}).then((res) => {
				setBoardList(res.data.list); 

				const pngn = res.data.paging;
				const curPage = pngn.page;
				const curPage2 = pngn.cur_page;
				const pageRow = pngn.page_rows;
				const totalCnt = pngn.total_cnt;
				const totalPageCnt = pngn.num_page_cnt;
				const startPage = pngn.startpage;
				const endPage = pngn.endpage;
				const base_page = 1;

				const tmpPages = [];
				for (let i = startPage - 1; i <= endPage; i++) {
					if (i >= base_page){
						tmpPages.push(i);
					}
				}
				setPageList(tmpPages);
				setTotalPageNum(totalPageCnt);
				setLoading(false);
				setListLoading(true);

			}).catch((err) => {	
				console.log("에러", err);
			})
		}
	}

	const getSearch = () =>{

		if(searchWd === ""){
			alert("검색어를 입력해주세요.");
			return;
		}else{
			const token = getToken();
			setPageNum("1");

			if(token !== null){
				const local_token = JSON.parse(token);
				
				axios({
					method: 'post',
					url: process.env.REACT_APP_API_COMMUNICATION_LIST,
					mode: "cors",
					credentials: "same-origin",
					headers: {
						Authorization: "Bearer " + local_token.access_token
					},
					data:{
						page: pageNum,
						searchword: searchWd,
						searchkey: searchKey,
						company: local_token.company,
					},
				}).then((res) => {
					setBoardList(res.data.list); 

					const pngn = res.data.paging;
					const curPage = pngn.page;
					const curPage2 = pngn.cur_page;
					const pageRow = pngn.page_rows;
					const totalCnt = pngn.total_cnt;
					const totalPageCnt = pngn.num_page_cnt;
					const startPage = pngn.startpage;
					const endPage = pngn.endpage;
					const base_page = 1;

					const tmpPages = [];
					for (let i = startPage - 1; i <= endPage; i++) {
						if (i >= base_page){
							tmpPages.push(i);
						}
					}
					setPageList(tmpPages);
					setTotalPageNum(totalPageCnt);
					setLoading(false);
					setListLoading(true);
				}).catch((err) => {	
					console.log("에러", err);
				})
			}
		}
	}

	const noticeRegister =() => {
		if(admChk === "Y"){
			navigate("/admin/communicationRegister");
		}else{
			navigate("/communicationRegister");
		}
	}

	const onBtnClick = (event) => {
		let value = event.target.getAttribute('data');
		//console.log(value);
		/*setSearch({
		  ...search,
		  page: value,
		});*/

		setPageNum(value);

		//console.log(pageNum);
	
		getBoardList(value);
	};

	const onBtnClick2 = (e) => {
		let val = e.totalPageNum;
		console.log(val);
		/*setSearch({
		  ...search,
		  page: value,
		});*/

		setPageNum(val);

		//console.log(pageNum);
	
		getBoardList(val);
	};
	
	const tokencall = () => {
		const token = getToken();

		if(token !== null){
			const local_token = JSON.parse(token);
			//console.log(local_token.adchk);
			if(local_token.adchk === "Y"){
				setAdmChk("Y");
			}else{
				setAdmChk("N");
			}
		}
	}

	useEffect(() => {
		setLoading(true);
		tokencall();
		getBoardList();
	  }, []);

	return (
		<>
		{ loading ?  <div style={{zIndex:'500', position: 'absolute', width: '80px', height: '80px', top: 'calc( 50% - 100px )', left: 'calc( 50% )'}}>
				<ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}/></div> : "" }

		<div className={Style.wrapper}>

            <div className={Style.subTitle}>
				<img src={Cmm} />
				<span>1:1 커뮤니케이션 리스트</span>
			</div>

			<div className={Style.cmmList}>
				<ul>

				{listloading ?
				boardList.map((board) => (
					<li key={board.seq}>
					{admChk === "Y" ? 
						<a href={`/admin/communicationView/${board.seq}`}>
							<div className={Style.cate}>[{board.gubun}]</div>
							<div className={Style.cate} style={{width:'92px'}}>{board.housename}</div>
							<div className={Style.cate} style={{width:'80px'}}>{board.hosu ? board.hosu+"호":""}</div>
							<div className={Style.cate} style={{width:'80px', display:'block', overflow:'hidden', textOverflow:'ellipsis'}}>{board.memname}</div>
							<div className={Style.tit} style={{width:'130px', flex:'none', display:'flex'}}>{board.status}</div>
							<div className={Style.tit}>{board.subject}</div>
							<div className={Style.date}>{board.regdate}</div>
						</a>
						:
						<a href={`/communicationView/${board.seq}`}>
							<div className={Style.cate}>[{board.gubun}]</div>
							<div className={Style.tit}>{board.status}</div>
							<div className={Style.tit}>{board.subject}</div>
							<div className={Style.date}>{board.regdate}</div>
						</a>
					}
					</li>
				))
				:""
				}	

				{/*}
					<li>
						<a href="/CommunicationView">
							<div className={Style.cate}>[주거시설]</div>
							<div className={Style.status}>접수</div>
							<div className={Style.tit}>클럽내 공지사항 제목 클럽내 공지사항 제목 클럽내 공지사항 제목 클럽내 공지사항 제목 공지사항 제목 클</div>
							<div className={Style.date}>23.01.12</div>
						</a>
					</li>
					<li>
						<a href="#">
							<div className={Style.cate}>[주거시설]</div>
							<div className={Style.status}>접수</div>
							<div className={Style.tit}>클럽내 공지사항 제목 클럽내 공지사항 제목 클럽내 공지사항 제목 클럽내 공지사항 제목 공지사항 제목 클</div>
							<div className={Style.date}>23.01.12</div>
						</a>
					</li>
				{*/}
				</ul>
			</div>

			<div className={Style.boardBtn}>
				<button type="button" onClick={noticeRegister}>
					<img src={Pen} />
					<span>글쓰기</span>
				</button>
			</div>

			<div className={Style.paging}>
			<button type="button" onClick={() => onBtnClick2('1')} value="1"><img src={PagePrev} /></button>
				<ul>
				
				{pageList.map((page, index) => (
					{...pageNum == page ? 
					<li key={index}><a href="#" className={Style.on} onClick={onBtnClick} data={page}>{page}</a></li>
					:
					<li key={index}><a href="#" onClick={onBtnClick} data={page}>{page}</a></li>
					}
				))}
				
				</ul>
				<button type="button" onClick={() => onBtnClick2({totalPageNum})} value={totalPageNum}><img src={PageNext} /></button>
			</div>

			<div className={Style.boardSearch}>
				<div className={Style.searchForm}>
					<select className={Style.select} name="search_key" onChange={(e) => setSearchKey(e.target.value)}>
						<option value="title">제목검색</option>
						<option value="content">내용검색</option>
					</select>
					<input type="text" className={Style.inp} name="search_word" onChange={(e) => setSearchWd(e.target.value)} />
					<button type="button"><img src={SearchImg} onClick={getSearch} /></button>
				</div>
			</div>

		</div>
		</>
	)
}

export default Index
