const option = {
    maximumFractionDigits: 1
  };

export const getChartOptions = (xAxisArray) => {
    return {
        chart: {
            height: 350,
            type: 'line',
            stacked: false
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 2
        },
        xaxis: {
            categories: xAxisArray,
            labels: {
                style: {
                    colors: "#DEDEDE"
                },
            },
        },
        yaxis: [
            {
                seriesName: '보증금',
                opposite: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: false,
                    color: '#008FFB'
                },
                labels: {
                    style: {
                        colors: '#008FFB',
                    },
                    formatter: (value) => {
                        return value.toLocaleString('ko-KR', option) + "만"
                    },
                },
                tooltip: {
                    enabled: false
                }
            },
            {
                seriesName: '월세',
                opposite: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: false,
                    color: '#00E396'
                },
                labels: {
                    style: {
                        colors: '#00E396',
                    },
                    formatter: (value) => {
                        return value.toLocaleString('ko-KR', option) + "만"
                    },
                },
            },
            {
                seriesName: '관리비',
                opposite: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: false,
                    color: '#FEB019'
                },
                labels: {
                    style: {
                        colors: '#FEB019',
                    },
                    formatter: (value) => {
                        return value.toLocaleString('ko-KR', option) + "만"
                    },
                },
            },
        ],
        tooltip: {
            fixed: {
                enabled: true,
                position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
                offsetY: 0,
                offsetX: 0,
                style: {
                    colors: "#DEDEDE"
                },
            },
        },
        legend: {
            horizontalAlign: 'center',
            offsetX: 0,
            labels: {
                colors: '#FFFFFF',
            },
        }
    }
}