import React from "react";

const cleanPercentage = (percentage) => {
  const tooLow = !Number.isFinite(+percentage) || percentage < 0;
  const tooHigh = percentage > 100;
  return tooLow ? 0 : tooHigh ? 100 : +percentage;
};

const Circle = ({ colour, pct }) => {
  const r = 20;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - pct) * circ) / 100;
  return (
    <circle
      r={r}
      cx={100}
      cy={100}
      fill="transparent"
      stroke={strokePct !== circ ? colour : ""} // remove colour as 0% sets full circumference
      strokeWidth={"0.8rem"}
      strokeDasharray={circ}
      strokeDashoffset={pct ? strokePct : 0}
      strokeLinecap=""
    ></circle>
  );
};

const Text = ({ percentage }) => {
  return (
    <text
      x="100%"
      y="100%"
      dominantBaseline="central"
      textAnchor=""
      fontSize={"1rem"}
    >
      <span style={{display: "inline-block", width: "100%", textAlign: "center", color: "#fff"}}>
        {percentage.toFixed(0)}%
      </span>
    </text>
  );
};

const Pie = ({ percentage, colour }) => {
  const pct = cleanPercentage(percentage);
  return (
    <>
    <svg width={70} height={70}>
      <g transform={`rotate(-90 ${"30 100"})`}>
        <Circle colour="lightgrey" />
        <Circle colour={colour} pct={pct} />
      </g>
    </svg>
    <Text percentage={pct} />
    </>
  );
};

export default Pie;