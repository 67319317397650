import React, {useState, useRef, useEffect} from 'react'
import axios from 'axios';
import cn from 'classnames'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import koLocale from '@fullcalendar/core/locales/ko';
import styled from "@emotion/styled";
// css
import Style from './index.module.css'
import SchAdd from '@/assets/image/ico/ico-sch-add.svg'
import SchRemove from '@/assets/image/ico/ico-sch-remove.svg'
import popClose from '@/assets/image/ico/ico-pop-close.svg'

import { getToken } from "@/services/TokenService";
import Axios from "@/components/lib/axios";
import { GreenLabel, Label } from "@/components/ui/label"

export const StyleWrapper = styled.div`
  .fc td {
    
  }
  .fc-prev-button + button{display:none;}
  .fc-toolbar-title + button{display:none;}
  .fc-toolbar-title{color:#40EFC1;}
  .fc-toolbar-chunk{display:flex;justify-content:center;align-items:center;}
  .fc .fc-button-primary{}
  .fc-today-button{background:#40EFC1 !important;border-color:#40EFC1 !important;color:#000 !important;}
  .fc .fc-daygrid-day.fc-day-today{background: rgba(64, 239, 193, 0.22) !important;}
  .fc-theme-standard tr>td:first-child,
  .fc-theme-standard tr>td:nth-child(7){background: rgba(217, 217, 217, 0.16) !important;}
  .fc .fc-daygrid-day-number{color:#fff}
  .fc .fc-col-header-cell-cushion{color:#fff;}
  .fc-event-title { color: #fff; }
  @media screen and (max-width: 1024px) {
  	.fc-header-toolbar{display:block;}
  	.fc-toolbar-chunk{margin:20px 0 0 0;}
  }
`

const Index = ({calendarData, openUserInfoDialog, shIdx}) => {
	const [boardList, setBoardList] = useState(calendarData.board_list);
	const [eventList, setEventList] = useState(calendarData.list)

	const handleEventClick = (selected) => {
		selected.jsEvent.preventDefault();
		openUserInfoDialog(selected.event.title, selected.event.url);
	};

	const handleMonthChange = async (selected) => {
		const {data} = await Axios.get(`/api_house/main/calendar/monthly`, {
			params: {start: selected.startStr, end: selected.endStr, sh_idx: shIdx}})
		if(data !== undefined){
			console.log(data);
			setBoardList(data.board_list);
			setEventList(data.list);
		}
	  };

	return (
		<>
			<div className={Style.wrapper}>
				<div className={Style.titlebar}>
					<strong>Calendar</strong>
				</div>
				<div className={Style.descbar}>Events</div>
				<div className={Style.calendarRela}>
					<div className={Style.side}>
						{
							boardList.length > 0
							?
							<ul>
								{boardList.map((board) => (
									<li className={Style.status01}>
										<div className={Style.mdesc}>{board.title}</div>
										<div className={Style.mdate}>{board.date}</div>
										<button type={'button'} className={Style.btnRemove}>
											<img src={SchRemove} />
										</button>
									</li>
								))}
							</ul>
							:
							<GreenLabel className="flex justify-center items-center h-full">데이터 없음</GreenLabel>
						}
					</div>
					
					<div className={Style.acticle} id="not_all">
						<StyleWrapper>
							<FullCalendar 
								plugins={[dayGridPlugin,timeGridPlugin,interactionPlugin, listPlugin ]}
								initialView={'dayGridMonth'}
								headerToolbar={
									{
										left: "today",
										center: 'prev, title, next',
										right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek"
									}
								}
								height={"85vh"}
								locale={koLocale}
								events={eventList}
								eventClick={handleEventClick}
								datesSet= {handleMonthChange}
							/>
						</StyleWrapper>
					</div>
				</div>
			</div>
		</>
	)
}

export default Index
