import React, {useState, useEffect, useRef} from 'react'
import axios from "axios";
import cn from 'classnames'
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { getToken } from "../../services/TokenService";

// css
import Style from './index.module.css'
import HistoryIcon from '@/assets/image/ico/ico-time.svg'
import SpaceHome from '@/assets/image/ico/ico-space-pin.svg'
import HomeIcon from '@/assets/image/ico/ico-home-green.svg'
import HistoryGreenIcon from '@/assets/image/ico/ico-history01.svg'
import HistoryAddIcon from '@/assets/image/ico/ico-history-add.svg'


const Main = () => {
	const [houseList, setHouseList] = useState([]);
	const [isPopup, setIsPopup] = useState(false);
	const [gonggan, setGongGan] = useState("");
	const [housetxt, setHouseTxt] = useState("");
	const [houseid, setHouseId] = useState("");
	const [house, setHouse] = useState("");
	const [floor, setFloor] = useState("");
	const [hosu, setHosu] = useState("");
	const [etctxt, setEtcTxt] = useState("");
	//const [pyungsu, setPyungSu] = useState("");
	const [juyoinfo, setJuyoInfo] = useState("");
	const [gayo, setGayo] = useState("");
	const [haphosu, setHapHosu] = useState(0);
	const [housebaro, setHouseBaro] = useState("");
	const [jutype, setJuType] = useState("");
	const [junm1, setJunM1] = useState("");
	const [junm2, setJunM2] = useState("");
	const [junm3, setJunM3] = useState("");
	const [pyungsuInt, setPyungSuInt] = useState("1");
	const [pyungsu, setPyungSu] = useState("m²");

	const etctxtfocusRef = useRef(null);
	const pyungsufocusRef = useRef(null);
	const juyoinfofocusRef = useRef(null);
	const gayofocusRef = useRef(null);

	const handleOpen = () => {
		setIsPopup(true);
	}
	const handleClose = () => {
		setIsPopup(false);
	}

	const _ = require('lodash');
	const floors = _.range(1, 51, 1);

    const gethouseList = async (e) => {
		//const resp = await (await axios.get(process.env.REACT_APP_API_MANAGERHOUSELIST)).data; 
		//setHouseList(resp.list); 
		//setHouseBaro(resp.baro);

		const token = getToken();
		
		if(token !== null){
			const local_token = JSON.parse(token);

			axios({
			method: 'post',
			url: process.env.REACT_APP_API_MANAGERHOUSELIST,
			mode: "cors",
			credentials: "same-origin",
			data:{
				company: local_token.company,
			},
			}).then((res) => {
				setHouseList(res.data.list); 
				setHouseBaro(res.data.baro);
			}).catch((err) => {	
				console.log("에러", err);
			})
		}
    }

	const [pinhtml, setPinHtml] = useState([]);
	const [options, setOption] = useState([]);
	const nextId = useRef(0);

	const onAdd = () => {
		if(house === ""){
			alert("공유주택을 선택해주세요.");
			return;
		}

    	if(floor === ""){
			alert("층수를 선택해주세요.");
			return;
		}

    	if(hosu === ""){
			alert("호수를 선택해주세요.");
			return;
		}
		
		let idd1 = '';
		let idd2 = '';
		if(house !== ''){
			let idsp = house.split('|');
			//console.log(idsp[1]);
			setHouseTxt(idsp[1]);
			idd2 = idsp[1];
			setHouseId(idsp[0]);
			idd1 = idsp[0];
		}

		//중복체크
		let chkbit = 'Y';
		/*if(options.length > 0){
			options.map((option) => {
				if(option.opthouse_idx === idd1 && option.optfloor === floor){
					alert("이미 추가된 공간입니다.");
					chkbit = 'N';
					document.getElementById("sharehouse").value = "";
					document.getElementById("sharefloor").value = "";
					document.getElementById("sharehosu").value = "";
					return;
				}
			})
		}*/
		
		if(chkbit === 'Y'){
			setHapHosu(parseInt(haphosu) + parseInt(hosu));

			//let addhtml = <li><div className={Style.cell01}><button type={'button'}>삭제</button></div><div className={Style.cell02}>3층</div>
			//<div className={Style.cell03}>7호</div><div className={Style.cell04}><input type={'text'} className={Style.inp} placeholder={'공간 평수'} />
			//</div><div className={Style.cell05}><input type={'text'} className={Style.inp} placeholder={'공간 개요'} /></div></li>;

			//const rootElement = document.getElementById("pinadd");
			
			//const element = React.createElement("li", {children : "<div className={Style.cell01}><button type={'button'}>삭제</button></div>"});
			//ReactDOM.render(element, rootElement);
			//setPinHtml(addhtml);
			//usersDiv.push(addhtml);

			//console.log(addhtml.length);
			//rootElement.append(addhtml);
			//ReactDOM.render(addhtml, rootElement);
			
			//const container = document.getElementById('app');
			//const root = createRoot(rootElement);
			//root.render(addhtml);
			
			let arr = [];
			for ( let i = 1; i <= hosu; i++) 
            {
				/*setOption(
				[...options, { idx: nextId.current, optfloor: floor, opthosu: i, opthouse_idx: idd1, opthouse_txt: idd2, optpyungsu: '', optgayo: '' }]
				);
				console.log(nextId.current+"/"+i+"/"+floor+"/"+idd1+"/"+idd2);*/
				let optfloortxt = floor;
				if(floor.indexOf('-') !== -1){
					optfloortxt = floor.replace('-','B');
				}
				arr.push({ idx: nextId.current, optfloor: floor, optfloor_txt: optfloortxt, opthosu: i, opthouse_idx: idd1, opthouse_txt: idd2, optjutype: '', optjunm1: '', optjunm1_p: '', optjunm2: '', optjunm2_p: '', optjunm3: '', optjunm3_p: '' });
				nextId.current += 1;
            }

			setOption(options.concat(arr));

			/*const [state, setState] = useState([]);

			let arr = [];
			for(let i = 0; i < 3; i++) {
				arr.push(i);
			}
			setState(arr);

			console.log(state);*/

			//setOption(
			//[{ idx: nextId.current, optfloor: floor, opthosu: hosu, opthouse_idx: idd1, opthouse_txt: idd2, optpyungsu: '', optgayo: '' },
			//...options]
			//);
			document.getElementById("sharehouse").value = "";
			document.getElementById("sharefloor").value = "";
			document.getElementById("sharehosu").value = "";
			//nextId.current += 1;
			//console.log(options);

		}
	}
	
	const onJutype = (idx, e) => {
		//console.log(e);
		setOption(
			options.map((option) =>
			  option.idx === idx ? { ...option, optjutype: e } : option
			)
		);
	}

	const onJunm1 = (idx, e) => {
		//console.log(e);
		setOption(
			options.map((option) =>
			  option.idx === idx ? { ...option, optjunm1: e } : option
			)
		);
	}

	const onJunm2 = (idx, e) => {
		//console.log(e);
		setOption(
			options.map((option) =>
			  option.idx === idx ? { ...option, optjunm2: e } : option
			)
		);
	}

	const onJunm3 = (idx, e) => {
		//console.log(e);
		setOption(
			options.map((option) =>
			  option.idx === idx ? { ...option, optjunm3: e } : option
			)
		);
	}

	const onRemove = (houseid, floor, hosu) => {
		//console.log(houseid+"/"+floor+"/"+hosu);
		setOption(options.filter(option => (option.opthouse_idx !== houseid || option.optfloor !== floor || option.opthosu !== hosu)));
		setHapHosu(parseInt(haphosu) - 1);
	}

    const onReg = () => {
		
		let cnt = options.length;
		
		if(cnt === 0){
			alert("생성할 공간을 추가해주세요.");
			return false;
		}

		const formData = new FormData();
		//formData.append('manager',usersid)
		formData.append('option_list',JSON.stringify(options))
			
		axios({
			method:'post',
			url:process.env.REACT_APP_API_SPACEALLCREATE,
			data: formData,
		})
			.then((result)=>{
				if(result.data.code == 200){
					alert("등록 되었습니다.");
					window.location.href="/admin/space/home";
        }else{
					console.log(result);
				}
		})
			.catch((error)=>{console.log('요청실패')
			console.log(error)  
		})
	}

	const onChange_junm1 = (idx, e) => {
		if(e !== ""){
			let findIndex = options.findIndex(option => option.idx === idx);
			let copiedItems = [...options];
			let vm = parseFloat(parseFloat(e)*0.3025).toFixed(2);
			if(vm !== "NaN"){
				copiedItems[findIndex].optjunm1_p = vm;
				setOption(copiedItems);
			}
			//console.log(vm);
			//console.log(e);
		}
	}

	const onChange_junm2 = (idx, e) => {
		if(e !== ""){
			let findIndex = options.findIndex(option => option.idx === idx);
			let copiedItems = [...options];
			let vm = parseFloat(parseFloat(e)*0.3025).toFixed(2);
			if(vm !== "NaN"){
				copiedItems[findIndex].optjunm2_p = vm;
				setOption(copiedItems);
			}
			//console.log(vm);
			//console.log(e);
		}
	}

	const onChange_junm3 = (idx, e) => {
		if(e !== ""){
			let findIndex = options.findIndex(option => option.idx === idx);
			let copiedItems = [...options];
			let vm = parseFloat(parseFloat(e)*0.3025).toFixed(2);
			if(vm !== "NaN"){
				copiedItems[findIndex].optjunm3_p = vm;
				setOption(copiedItems);
			}
			//console.log(vm);
			//console.log(e);
		}
	}

	const pyungChange = () => {
		if(pyungsu === "평"){
		  setPyungSu("m²");
		  setPyungSuInt("1");
		}else{
		  setPyungSu("평");
		  setPyungSuInt("2");
		}
	}

  	useEffect(() => {
		gethouseList();
	}, []);

	/*useEffect(()=>{
		setTimeout(function(){
			handleOpen();
		},1000)
	},[]);*/

	return (
		<div className={Style.wrapper}>

			<section className={Style.spacedHeader}>

				<div className={cn(Style.pageTitle, Style.type01)}>
					<img src={SpaceHome} />
					<span><strong>공간핀생성 -공간 </strong></span>
				</div>
			</section>

			<section className={Style.spaceView}>
				<div className={Style.optList}>
					<ul>
            <li>
              <select className={Style.select} id="sharehouse" onChange={(e) => setHouse(e.target.value)}>
			  	<option value="">공유주택 선택</option>
                {houseList.map((list) => (
                  <option key={list.seq} value={list.house_id_name}>{list.house_name}</option>
                ))}
              </select>
            </li>
            <li>
              <select className={Style.select} id="sharefloor" onChange={(e) => setFloor(e.target.value)}>
			  	<option value="">층수선택</option>
                <option value="-5">B5</option>
                <option value="-4">B4</option>
                <option value="-3">B3</option>
                <option value="-2">B2</option>
                <option value="-1">B1</option>
                {
					floors.map((option) => (
						<option key={option} value={option}>{option}층</option>
					))
				}
              </select>
            </li>
            <li>
            	<select className={Style.select} id="sharehosu" name="hosu" onChange={(e) => setHosu(e.target.value)}>
					<option value="">호수선택</option>
					{
						floors.map((option) => (
							<option key={option} value={option}>{option}호</option>
						))
					}
				</select>
			</li>
						<li>
							<button type={'button'} onClick={() => onAdd()}>추가</button>
						</li>
					</ul>
				</div>
				
				{/*}
				<div className={Style.formList}>
					<ul>
						<li>
							<div className={Style.label}>공간평수</div>
							<div className={Style.formItem}>
								<input type="text" className={Style.inp} />
							</div>
						</li>
						<li>
							<div className={Style.label}>공간주요정보</div>
							<div className={Style.formItem}>
								<input type="text" className={Style.inp} />
							</div>
						</li>
						<li>
							<div className={Style.label}>공간주요정보</div>
							<div className={Style.formItem}>
								<input type="text" className={Style.inp} />
							</div>
						</li>
					</ul>
				</div>
				{*/}

				<ul className={cn(Style.pinTh, Style.type01)}>
          <li>&nbsp;</li>
					<li>총 구분</li>
					<li>거주실 호수</li>
					<li style={{width: '160px'}}>주택타입</li>
					<li style={{width: '215px'}}>전용면적</li>
					<li style={{width: '215px'}}>공급면적</li>
					<li style={{width: '215px'}}>실거주면적</li>
        </ul>
				<ul className={Style.pinTd} id="pinadd">
					{/*}
					<li>
						<div className={Style.cell01}>
						<button type={'button'}>삭제</button>
						</div>
									<div className={Style.cell02}>3층</div>
									<div className={Style.cell03}>7호</div>
									<div className={Style.cell04}>
						<input type={'text'} className={Style.inp} placeholder={'공간 평수'} />
						</div>
									<div className={Style.cell05}>
										<input type={'text'} className={Style.inp} placeholder={'공간 개요'} />
						</div>
					</li>
					<li>
						<div className={Style.cell01}>
							<button type={'button'}>삭제</button>
						</div>
						<div className={Style.cell02}>3층</div>
						<div className={Style.cell03}>7호</div>
						<div className={Style.cell04}>
							<input type={'text'} className={Style.inp} placeholder={'공간 평수'} />
						</div>
						<div className={Style.cell05}>
							<input type={'text'} className={Style.inp} placeholder={'공간 개요'} />
						</div>
					</li>
					{*/}

					{options && 
                		options.map((option, idx) => {
							//console.log(option);
							return (
							<li key={idx}>
								<div className={Style.cell01}>
									<button type={'button'} onClick={() => onRemove(option.opthouse_idx, option.optfloor, option.opthosu)}>삭제</button>
								</div>
								<div className={Style.cell02}>{option.opthouse_txt} {option.optfloor_txt}층</div>
								<div className={Style.cell03}>{option.opthosu}호</div>
								<div className={Style.cell04} style={{width: '155px'}}>
									<input type={'text'} id={'jutype'+option.idx} value={option.optjutype} className={Style.inp} placeholder={'주택타입'} onChange={(e) => onJutype(option.idx, e.target.value)} />
								</div>
								<div className={Style.cell05} style={{position:'relative'}}>
									{pyungsuInt === "1" ? 
									<input type={'text'} id={'junm1'+option.idx} className={Style.inp} value={option.optjunm1} placeholder={'공간 전용면적'} onBlur={(e) => onChange_junm1(option.idx, e.target.value)} onChange={(e) => onJunm1(option.idx, e.target.value)} />
									:
									<input type={'text'} id={'junm1'+option.idx} className={Style.inp} value={option.optjunm1_p} placeholder={'공간 전용면적'} />
									}
									<span className={Style.unit}>{pyungsu} </span>
								</div>
								<div className={Style.cell05} style={{position:'relative'}}>
									{pyungsuInt === "1" ? 
									<input type={'text'} id={'junm2'+option.idx} className={Style.inp} value={option.optjunm2} placeholder={'공간 공급면적'} onBlur={(e) => onChange_junm2(option.idx, e.target.value)} onChange={(e) => onJunm2(option.idx, e.target.value)} />
									:
									<input type={'text'} id={'junm2'+option.idx} className={Style.inp} value={option.optjunm2_p} placeholder={'공간 공급면적'} />
									}
									<span className={Style.unit}>{pyungsu} </span>
								</div>
								<div className={Style.cell05} style={{position:'relative'}}>
									{pyungsuInt === "1" ? 
									<input type={'text'} id={'junm3'+option.idx} className={Style.inp} value={option.optjunm3} placeholder={'공간 실거주면적'} onBlur={(e) => onChange_junm3(option.idx, e.target.value)} onChange={(e) => onJunm3(option.idx, e.target.value)} />
									:
									<input type={'text'} id={'junm3'+option.idx} className={Style.inp} value={option.optjunm3_p} placeholder={'공간 실거주면적'} />
									}
									<span className={Style.unit}>{pyungsu} </span>
								</div>
							</li>
							);
						})
					}
					
				</ul>

				<ul className={Style.pinTh}>
          <li>&nbsp;</li>
					<li>소계</li>
					<li>{haphosu}호</li>
					<li>&nbsp;</li>
					<li><a href="#;" onClick={pyungChange}>m² ↔ 평 전환</a></li>
				</ul>

				<div className={Style.mbtn}>
					<button type="button" onClick={() => onReg()}>공간생성</button>
				</div>

			</section>


		</div>
	)
}

export default Main
