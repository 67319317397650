import {React, useState, useEffect, useRef} from 'react'
import axios from "axios";
import cn from 'classnames'
import { getToken } from "../../services/TokenService";

// css
import Style from './index.module.css'
import ProductImg from '@/assets/image/etc/etc-product01.png'
import MainImg from '@/assets/image/etc/etc-main-list01.png'
import SpaceHome from '@/assets/image/ico/ico-adm.svg'
import MnList01 from '@/assets/image/ico/ico-mn-list01.svg'
import MnList02 from '@/assets/image/ico/ico-mn-list02.svg'
import MnList03 from '@/assets/image/ico/ico-mn-list03.svg'
import MnList04 from '@/assets/image/ico/ico-mn-list04.svg'
import MnList05 from '@/assets/image/ico/ico-mn-list05.svg'
import MnList06 from '@/assets/image/ico/ico-mn-list06.svg'
import Cmm from '@/assets/image/ico/ico-cmm.svg'
import Notice from '@/assets/image/ico/ico-noti.svg'
import More from '@/assets/image/ico/ico-arr-more.svg'
import Profile from "@/assets/image/etc/etc-product01.png";
import SearchBtn from "@/assets/image/ico/ico-search.svg";
import RemoveIco from "@/assets/image/ico/ico-remove-white.svg";
import popClose from '@/assets/image/ico/ico-pop-close.svg'



const Index = () => {
	const [isPopup, setIsPopup] = useState(true);
	const [list, setList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [houseList, setHouseList] = useState([]);
	const [managername, setManagerName] = useState("");
	const [manageremail1, setManagerEmail1] = useState("");
	const [manageremail2, setManagerEmail2] = useState("");
	const [managerpasswd, setManagerPasswd] = useState("");
	const [managerhouse, setManagerHouse] = useState("");
	const [managerclass, setManagerClass] = useState("JM");
	const [managersex, setManagerSex] = useState("");
	const [managerbirthy, setManagerBirthy] = useState("");
	const [managerbirthm, setManagerBirthm] = useState("");
	const [managerbirthd, setManagerBirthd] = useState("");
	const [managerhptel1, setManagerHptel1] = useState("010");
	const [managerhptel2, setManagerHptel2] = useState("");
	const [managerhptel3, setManagerHptel3] = useState("");

	const now = new Date();
	const namefocusRef = useRef(null);
	const email1focusRef = useRef(null);
	const email2focusRef = useRef(null);
	const passwdfocusRef = useRef(null);
	const housefocusRef = useRef(null);

	const _ = require('lodash');
	const years = _.range(now.getFullYear() - 64, now.getFullYear() + 1, 1);

	const handleClose = () => {
		setIsPopup(false);
	}

	const gethouseList = async (e) => {
		//const resp = await (await axios.get(process.env.REACT_APP_API_MANAGERHOUSELIST)).data; 
		//setHouseList(resp.list); 

		const token = getToken();
		
		if(token !== null){
			const local_token = JSON.parse(token);
			
			axios({
				method: 'post',
				url: process.env.REACT_APP_API_MANAGERHOUSELIST,
				mode: "cors",
				credentials: "same-origin",
				data:{
					company: local_token.company,
				},
			}).then((res) => {
				setHouseList(res.data.list); 
			}).catch((err) => {	
				console.log("에러", err);
			})
		}
	}

	const onReg = () => {
		
		if(managername === ""){
			alert("이름을 입력해주세요.");
			namefocusRef.current.focus();
			return;
		}

		if(manageremail1 === ""){
			alert("이메일을 입력해주세요.");
			email1focusRef.current.focus();
			return;
		}
		if(manageremail2 === ""){
			alert("이메일을 입력해주세요.");
			email2focusRef.current.focus();
			return;
		}

		if(managerpasswd === ""){
			alert("비밀번호를 입력해주세요.");
			passwdfocusRef.current.focus();
			return;
		}

		if(managerhouse === ""){
			alert("관리주택을 선택해주세요.");
			housefocusRef.current.focus();
			return;
		}
		
		const token = getToken();
		
		if(token !== null){
			const local_token = JSON.parse(token);

			const formData = new FormData();
			//formData.append('manager',usersid)
			formData.append('managerclass',managerclass)
			formData.append('managername',managername)
			formData.append('sex',managersex)
			formData.append('birth_y',managerbirthy)
			formData.append('birth_m',managerbirthm)
			formData.append('birth_d',managerbirthd)
			formData.append('hptel1',managerhptel1)
			formData.append('hptel2',managerhptel2)
			formData.append('hptel3',managerhptel3)
			formData.append('email1',manageremail1)
			formData.append('email2',manageremail2)
			formData.append('passwd',managerpasswd)
			formData.append('house',managerhouse)
			formData.append('company',local_token.company)

			axios({
				method:'post',
				url:process.env.REACT_APP_API_MANAGERREG,
				data: formData,
			})
				.then((result)=>{
					if(result.data.code == 200){
						alert("등록 되었습니다.");
						window.location.href="/admin/teamList";
					}else if(result.data.code == 300){
						alert("이미 등록된 이메일 주소입니다.");
					}else{
						console.log(result);
					}
			})
				.catch((error)=>{console.log('요청실패')
				console.log(error)  
			})
		}
	}

	useEffect(() => {
		gethouseList();
	}, []);

	return (
		<div className={Style.wrapper}>

			<section className={Style.spacedHeader}>

				<div className={Style.pageTitle}>
					<img src={SpaceHome} alt="매니저등록" />
					<span>매니저 등록</span>
				</div>

			</section>

			<section className={Style.registerWrap}>

				<div className={Style.photoList}>
					<div className={Style.photoUpload}>
						<label>
							<input type="file" />
						</label>
					</div>
					{/*}
					<div className={Style.msg}>사진을 움직여서 정사각형 대표이미지를  지정해주세요</div>
					{*/}
				</div>

				<div className={Style.formGroup}>
					
					<div className={Style.formRows}>
						<div className={Style.label}>매니저 클래스</div>
						<div className={Style.formItem}>
							<label htmlFor={'id'}><input type='radio' checked='checked' id={'id'} name="class_gubun" value="JM" onChange={(e) => setManagerClass(e.target.value)} /><span>주택매니저</span></label> 	
							<label htmlFor={'id2'}><input type='radio' name="class_gubun" id={'id2'} value="SM" onChange={(e) => setManagerClass(e.target.value)} /><span>시설매니저</span></label>
						</div>
					</div>

					<div className={Style.formRows}>
						<div className={Style.label}>이름</div>
						<div className={Style.formItem}>
							<input type={'text'} className={cn(Style.inp, Style.email)} ref={namefocusRef} value={managername} onChange={(e) => setManagerName(e.target.value)} />	
						</div>
					</div>
					<div className={Style.formRows}>
						<div className={Style.label}>성별/생년월일</div>
						<div className={Style.formItem}>
							<select className={Style.select} value={managersex} onChange={(e) => setManagerSex(e.target.value)}>
								<option value="">성별선택</option>
								<option value="M">남자</option>
								<option value="W">여자</option>
							</select>
							<select className={Style.select} value={managerbirthy} onChange={(e) => setManagerBirthy(e.target.value)}>
								<option value="">출생년도</option>
								{
									years.map((option) => (
										<option key={option} value={option}>{option}년</option>
									))
								}
							</select>
							<select className={cn(Style.select, Style.minSize)} value={managerbirthm} onChange={(e) => setManagerBirthm(e.target.value)}>
								<option value="">월</option>
								{
									Array(12).fill(0).map((_, i) => (
										<option key={i + 1} value={i + 1}>{i + 1}월</option>
									))
								}
							</select>
							<select className={cn(Style.select, Style.minSize)} value={managerbirthd} onChange={(e) => setManagerBirthd(e.target.value)}>
								<option value="">일</option>
								{
									Array(31).fill(0).map((_, i) => (
										<option key={i + 1} value={i + 1}>{i + 1}일</option>
									))
								}
							</select>
						</div>
					</div>
					<div className={Style.formRows}>
						<div className={Style.label}>연락처</div>
						<div className={Style.formItem}>
							{/*}
							<input type={'text'} className={cn(Style.inp, Style.tel)} />
							{*/}
							<select name="hptel1" className={Style.select} value={managerhptel1} onChange={(e) => setManagerHptel1(e.target.value)}>
								<option value="010" selected>010</option>
								<option value="011">011</option>
								<option value="016">016</option>
								<option value="017">017</option>
								<option value="018">018</option>
								<option value="019">019</option>
								<option value="02">02</option>
								<option value="031">031</option>
								<option value="032">032</option>
							</select>
							<span className={Style.e01}>-</span>
							<input type={'text'} className={cn(Style.inp, Style.tel)} value={managerhptel2} onChange={(e) => setManagerHptel2(e.target.value)} />
							<span className={Style.e01}>-</span>
							<input type={'text'} className={cn(Style.inp, Style.tel)} value={managerhptel3} onChange={(e) => setManagerHptel3(e.target.value)} />
						</div>
					</div>

          <div className={Style.formRows}>
            <div className={Style.label}>이메일</div>
            <div className={Style.formItem}>
              <input type={'text'} name="email1" className={cn(Style.inp, Style.email)} ref={email1focusRef} value={manageremail1} onChange={(e) => setManagerEmail1(e.target.value)} />
              <span className={Style.e01}>@</span>
              <input type={'text'} name="email2" className={cn(Style.inp, Style.email)} ref={email2focusRef} value={manageremail2} onChange={(e) => setManagerEmail2(e.target.value)} />
            </div>
          </div>

		  <div className={Style.formRows}>
			<div className={Style.label}>비밀번호</div>
			<div className={Style.formItem}>
				<input type={'password'} className={cn(Style.inp, Style.email)} ref={passwdfocusRef} value={managerpasswd} onChange={(e) => setManagerPasswd(e.target.value)} />	
			</div>
		  </div>

          <div className={Style.formRows}>
            <div className={Style.label}>관리주택</div>
            <div className={Style.formItem}>
              <select className={Style.select} ref={housefocusRef} value={managerhouse} onChange={(e) => setManagerHouse(e.target.value)}>
				 <option value="">관리주택 선택</option>
				 {houseList.map((list) => (
					<option key={list.seq} value={list.seq}>{list.house_name}</option>
				 ))}
			  </select>
            </div>
          </div>

				</div>


				<div className={Style.btnGroup}>
					<button type="button" className={Style.btnCancel}>취소</button>
					<button type="button" className={Style.btnConfirm} onClick={onReg}>등록</button>
				</div>
			</section>


		</div>
	)
}

export default Index
