import { useContext } from 'react'
import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"

import { Send, Plus, Trash, Circle, X } from "lucide-react"

export const getColumns = (type, clickAddButton) => {
  var columns;

  columns = [
    {
      accessorKey: "sh_name",
      header: () => <div className="text-left">건물명</div>,
      cell: ({ row }) => <div>{row.getValue("sh_name")}</div>,
    },
    {
      accessorKey: "hs_hosu",
      header: () => <div className="text-center">호수</div>,
      cell: ({ row }) => <div className="text-center">{row.getValue("hs_hosu")}</div>,
    },
    {
      accessorKey: "mem_name",
      header: () => <div className="text-center">이름</div>,
      cell: ({ row }) => <div className="text-center">{row.getValue("mem_name")}</div>,
    },
    {
      accessorKey: "mem_hptel",
      header: () => <div className="text-center">전화번호</div>,
      cell: ({ row }) => <div className="text-center">{row.getValue("mem_hptel")}</div>,
    },
    {
      accessorKey: "mem_email",
      header: () => <div className="text-center">이메일</div>,
      cell: ({ row }) => <div className="text-center">{row.getValue("mem_email")}</div>,
    },
    {
      id: "actions",
      header: () => <div className="flex justify-end align-middle">
                        <div className="w-16">추가</div>
                    </div>,
      enableHiding: false,
      cell: ({ row }) => {
        const user = row.original
  
        return (
          <div className="flex justify-end align-middle">
            <Button variant="icon" onClick={()=>clickAddButton(user)}>
              <Plus className="h-5 w-5"/>
            </Button>
         </div>
        )
      },
    },
  ]
  

  return columns;
}