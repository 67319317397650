import {useState, useEffect, useContext, createContext} from 'react'
import { TemplateTable } from './table/table'
import { Button } from '@/components/ui/button'
import { MySpinner } from '@/components/ui/spinner'
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import Axios from "@/components/lib/axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom"
import {
	AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle,
} from "@/components/ui/alert-dialog"

const ContentTable = ({type}) => {
	const navigate = useNavigate();
	const queryClient = useQueryClient()
	const [selectedTemplateId, setSelectedTemplateId] = useState(0);
	const [isOpenedDeleteDialog, setIsOpenedDeleteDialog] = useState(false);

	const { isLoading, error, data } = useQuery({queryKey:["message_template", type], queryFn: () =>
		Axios.get(`/api_message/template/${type}`).then((res) => {
			return res.data;
		})
	});

	const onSendButtonClicked = (templateId) => {
		//<Link to={`/admin/messages/sendMessages?type=${type}&id=${template.id}`}></Link>
		console.log("onSendButtonClicked");
		navigate(`/admin/messages/sendMessages?type=${type}&templateId=${templateId}`)
	}
	
	const onEditButtonClicked = (templateId) => {
		//<Link to={`/admin/messages/sendMessages?type=${type}&id=${template.id}`}></Link>
		console.log("onSendButtonClicked");
		navigate(`/admin/messages/templateSettings/${type}?mode=edit&id=${templateId}`)
	}

	const onDeleteButtonClicked = (templateId) => {
		setSelectedTemplateId(templateId)
		setIsOpenedDeleteDialog(true)
	}

	const onDeleteContinueButtonClicked = () => {
		setIsOpenedDeleteDialog(false)
		deleteTemplate.mutate({templateId: selectedTemplateId})
	}

	const deleteTemplate = useMutation({
		mutationFn: async ({templateId}) => {
			const result = await Axios.delete(`/api_message/template/${type}/${templateId}`)
		}, 
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["message_template", type] })
		},
	});

	return (
		<>
		{
			isLoading
            ? <div className='w-full h-[688px] flext justify-center items-center'><MySpinner/></div>
            :
			<div className="w-full mt-14">
				<div className="flex flex-row justify-end mr-4">
					<Link to={`/admin/messages/templateSettings/${type}?mode=create`}><Button onClick={()=>{}}>템플릿 생성</Button></Link>
				</div>
				<TemplateTable type={type} data={data} onSendButtonClicked={onSendButtonClicked} onEditButtonClicked={onEditButtonClicked} onDeleteButtonClicked={onDeleteButtonClicked}/>
			</div>
		}
		<AlertDialog open={isOpenedDeleteDialog} onOpenChange={setIsOpenedDeleteDialog}>
			<AlertDialogContent>
				<AlertDialogHeader>
					<AlertDialogTitle>정말 삭제하시겠습니까?</AlertDialogTitle>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel onClick={()=>setIsOpenedDeleteDialog(false)}>취소</AlertDialogCancel>
					<AlertDialogAction onClick={()=>onDeleteContinueButtonClicked()}>계속</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
		</>
	)
}

export default ContentTable
