import React from "react";

export default function CheckBox({id, checked, disabled, checkItemHandler}) {
  const checkHandled = (e) => {
    //console.log('checkHandled')
    checkItemHandler(e.target.id, e.target.checked);
  }

  return (
    <label>
      {disabled === "disabled" ? 
          <input id={id} type="checkbox" checked={checked} disabled onChange={checkHandled}/>
          :
          <input id={id} type="checkbox" checked={checked} onChange={checkHandled}/>
      }
    </label>
  )
}