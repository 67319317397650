import { useDropzone } from 'react-dropzone';

function ImageDropBox({ onDrop }) {
	const { getRootProps, getInputProps }
		= useDropzone({accept: {'image/jpeg': [], 'image/png': []}, onDrop: onDrop, noClick: false, noKeyboard: true,});

	return (
		<div className="dropbox" {...getRootProps()} style={{width: "100%", height: "100%"}}>
			<input {...getInputProps()} />
		</div>
	);
}

export default ImageDropBox;
