import {React, useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import HouseView from '@/pages/main/manager/house_view'

const Index = () => {
	const { idx } = useParams();
	const queryClient = new QueryClient()

	return (
			<QueryClientProvider client={queryClient}>
				<HouseView shIdx={idx}/>
			</QueryClientProvider>
	)
}

export default Index
