import React, {useState, useRef, useEffect} from 'react'
import axios from "axios";
import cn from 'classnames'
import { CKEditor } from '@ckeditor/ckeditor5-react';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

// css
import Style from './index.module.css'
import Notice from '@/assets/image/ico/ico-noti.svg'
import PagePrev from '@/assets/image/ico/ico-page-prev.svg'
import PageNext from '@/assets/image/ico/ico-page-next.svg'
import SearchImg from '@/assets/image/ico/ico-search.svg'
import Cmm from '@/assets/image/ico/ico-cmm.svg'
import styled from 'styled-components'

import { getToken, removeToken } from "../../services/TokenService";

const customUploadAdapter = (loader) => { // (2)
	return {
		upload(){
			return new Promise ((resolve, reject) => {
				const data = new FormData();
				 loader.file.then( (file) => {
						data.append("attach", file);

						axios.post(process.env.REACT_APP_API_COMMUNICATION_IMAGEUPLOAD, data)
							.then((res) => {
								resolve({
									default: res.data.imgUrl
								});
							})
							.catch((err)=>reject(err));
					})
			})
		}
	}
}

function uploadPlugin (editor){ // (3)
	editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
		return customUploadAdapter(loader);
	}
}

const Index = () => {
	const [content, setContent] = useState({content: ''})
	const [title, setTitle]	= useState("");
	const [gubun, setGubun]	= useState("");
	const [admChk, setAdmChk] = useState("N");

	const titlefocusRef = useRef(null);
	const gubunfocusRef = useRef(null);

	const tokencall = () => {
		const token = getToken();

		if(token !== null){
			const local_token = JSON.parse(token);
			//console.log(local_token.adchk);
			if(local_token.adchk === "Y"){
				setAdmChk("Y");
			}else{
				setAdmChk("N");
			}
		}
	}

	const onReg = () => {
		if(title === ""){
			alert("제목을 입력해주세요.");
			titlefocusRef.current.focus();
			return;
		}

		if(gubun === ""){
			alert("카테고리를 선택해주세요.");
			gubunfocusRef.current.focus();
			return;
		}

		if(content.content === ""){
			alert("내용을 입력해주세요.");
			return;
		}
		
		const token = getToken();

		if(token !== null){
			const local_token = JSON.parse(token);

			const formData = new FormData();
			formData.append('title',title)
			formData.append('gubun',gubun)
			formData.append('content',content.content)
			formData.append('company', local_token.company)
			
			axios({
				method:'post',
				url:process.env.REACT_APP_API_COMMUNICATION_REG,
				mode: "cors",
				credentials: "same-origin",
				headers: {
					"Content-Type": "multipart/form-data", 
					Authorization: "Bearer " + local_token.access_token,
				},
				data: formData,
			})
				.then((result)=>{
					if(result.data.code == 200){
						alert("등록 되었습니다.");
						if(admChk === "Y"){
							window.location.href="/admin/communication";
						}else{
							window.location.href="/communication";
						}
					}else{
						console.log(result)
					}
			})
				.catch((error)=>{console.log('요청실패')
				console.log(error)  
			})
		}
	}

	useEffect(() => {
		tokencall();
	  }, []);

	return (
		<div className={Style.wrapper}>

			<div className={Style.subTitle}>
				<img src={Cmm} />
				<span>1:1 커뮤니케이션 등록</span>
			</div>

			<div className={Style.regForm}>
				<div className={Style.formRow}>
					<div className={Style.formItem}>
						<input type="text" className={Style.inp} placeholder="제목을 입력하세요" ref={titlefocusRef} value={title} onChange={(e) => setTitle(e.target.value)} />
					</div>
				</div>

				<div className={Style.formRow}>
					<div className={Style.formItem}>
						<select className={Style.select} ref={gubunfocusRef} onChange={(e) => setGubun(e.target.value)}>
							<option value="">선택</option>
							<option value="S1">임대료 /관리비</option>
							<option value="S2">주거시설</option>
							<option value="S3">공유공간</option>
							<option value="S4">생활커뮤니티</option>
							<option value="S5">기타문의</option>
						</select>
					</div>
				</div>
				
				{/*}
				<div className={Style.formRow}>
					<div className={Style.formItem}>
						<input type="text" className={Style.inp} placeholder="태그를 설정하세요(최대10개)" />
					</div>
				</div>
				{*/}

				<div className={Style.formRow}>
					<div className={Style.editor} style={{height:'100%'}}>
					<CKEditor
						editor={ DecoupledEditor }
						data=""
						config={{
							placeholder:"1:1문의 작성시 문의사항 관련하여 [사진, 이미지] 첨부 요청드립니다.",
							extraPlugins: [uploadPlugin],
							mediaEmbed: {
								previewsInData: true
							},
							toolbar: ['heading', '|', 'bold', 'italic', '|', 'imageUpload', , 'mediaEmbed', '|', 'blockQuote', 'link', '|', 'numberedList', 'bulletedList', '|', 'undo', 'redo']
						}}
						onReady={editor => {
							if( editor.ui.getEditableElement() ){
								editor.ui.getEditableElement().parentElement.insertBefore(
									editor.ui.view.toolbar.element,
									editor.ui.getEditableElement()
								);
							}
							// You can store the "editor" and use when it is needed.
							//console.log('Editor is ready to use!', editor);
						}}
						onChange={ ( event, editor ) => {
							const data = editor.getData();
							setContent({
								content: data
							})
							//console.log( { event, editor, data } );
						} }
						onBlur={ ( event, editor ) => {
							//console.log( 'Blur.', editor );
						} }
						onFocus={ ( event, editor ) => {
							//console.log( 'Focus.', editor );
						} }
					/>
					</div>
				</div>

				<div className={Style.btnGroup}>
					<button type="button" className={Style.btnCancel}>취소</button>
					<button type="button" className={Style.btnConfirm} onClick={onReg}>등록</button>
				</div>
			</div>

		</div>
	)
}


export default Index
