import {useState, useEffect, useContext, createContext} from 'react'
import { AutoSendTable } from './table/table'
import { Button } from '@/components/ui/button'
import { MySpinner } from '@/components/ui/spinner'
import { Link, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import Axios from "@/components/lib/axios";
import {
	AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle,
} from "@/components/ui/alert-dialog"

const ContentTable = ({}) => {
	const navigate = useNavigate();
	const queryClient = useQueryClient()
	const [selectedAutoSendId, setSelectedAutoSendId] = useState(0);
	const [isOpenedDeleteDialog, setIsOpenedDeleteDialog] = useState(false);

	const { isLoading, error, data } = useQuery({queryKey:["auto_send_list"], queryFn: () =>
		Axios.get(`/api_message/auto_send`).then((res) => {
			return res.data;
		})
	});

	const activeToggle = useMutation({
		mutationFn: async ({autoSendId, isAutoSendActive}) => {
			const result = await Axios.put(`/api_message/auto_send/active/${autoSendId}`, { is_auto_send_active: isAutoSendActive})
		}, 
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["auto_send_list"] })
		},
	});
	
	const onEditButtonClicked = (autoSendId) => {
		navigate(`/admin/messages/autoSendSettings?mode=edit&id=${autoSendId}`)
	}

	const onDeleteButtonClicked = (autoSendId) => {
		setSelectedAutoSendId(autoSendId)
		setIsOpenedDeleteDialog(true)
	}

	const onDeleteContinueButtonClicked = () => {
		setIsOpenedDeleteDialog(false)
		deleteAutoSend.mutate({autoSendId: selectedAutoSendId})
	}

	// 삭제 버튼 누르면 해당 row를 삭제하고 리스트 업데이트
	const deleteAutoSend = useMutation({
		mutationFn: async ({autoSendId}) => {
			console.log("delete mutation")
			const result = await Axios.delete(`/api_message/auto_send/${autoSendId}`)
			console.log(result)
		}, 
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["auto_send_list"] })
		},
	});

	return (
		<>
		{
			isLoading
            ? <MySpinner/>
            :
			<div className="w-full mt-8">
				<div className="flex flex-row justify-end mr-4">
					<Link to={`/admin/messages/autoSendSettings?mode=create`}><Button>자동 전송 생성</Button></Link>
				</div>
				<AutoSendTable data={data} activeToggle={activeToggle} onEditButtonClicked={onEditButtonClicked} onDeleteButtonClicked={onDeleteButtonClicked}/>
			</div>
		}
		<AlertDialog open={isOpenedDeleteDialog} onOpenChange={setIsOpenedDeleteDialog}>
			<AlertDialogContent>
				<AlertDialogHeader>
					<AlertDialogTitle>정말 삭제하시겠습니까?</AlertDialogTitle>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel onClick={()=>setIsOpenedDeleteDialog(false)}>취소</AlertDialogCancel>
					<AlertDialogAction onClick={()=>onDeleteContinueButtonClicked()}>계속</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
		</>
	)
}

export default ContentTable
