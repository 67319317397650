import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from "lucide-react"
 
// import { Button } from "@/registry/new-york/ui/button"
import { Button } from "./button"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./select"
// } from "@/registry/new-york/ui/select"

export function DataTablePagination({table}) {

  var pageNumberArray = [];

  const index = table.getState().pagination.pageIndex;
  const count = table.getPageCount();
  var pageNumberStarter = index >= 10 ? Math.floor(index / 10) * 10 : 0 ;
  for(let i = pageNumberStarter; i < (pageNumberStarter + 10); i++){
    if(i >= count){
      break;
    }
    pageNumberArray.push(
      {
        "number" : i,
        "isCurrent" : i == index
      }
    )
  }

  return (
      <div className="flex justify-center space-x-6 lg:space-x-8">
        <div className="flex items-center space-x-2">
          <Button
            variant="ghost"
            className="h-8 w-8 p-0 lg:flex"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            <span className="sr-only">Go to first page</span>
            <ChevronsLeft className="h-4 w-4" />
          </Button>
          <Button
            variant="ghost"
            className="h-8 w-8 p-0"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <span className="sr-only">Go to previous page</span>
            <ChevronLeft className="h-4 w-4" />
          </Button>
          <div className="flex items-center space-x-2">
            {
              pageNumberArray.map(pageItem => {
                return <Button
                variant="ghost"
                className="h-8 w-8 p-0"
                onClick={() => table.setPageIndex(pageItem.number)}
              >
                <label class={pageItem.isCurrent ? "text-base text-accent-foreground" : "text-base"}>{pageItem.number + 1}</label>
              </Button>
              })
            }
          </div>
          <Button
            variant="ghost"
            className="h-8 w-8 p-0"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <span className="sr-only">Go to next page</span>
            <ChevronRight className="h-4 w-4" />
          </Button>
          <Button
            variant="ghost"
            className="h-8 w-8 p-0 lg:flex"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            <span className="sr-only">Go to last page</span>
            <ChevronsRight className="h-4 w-4" />
          </Button>
        </div>
      </div>
  )
}