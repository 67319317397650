import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"

import { ArrowUpDown, Send, Pencil, Trash, Circle, X } from "lucide-react"

export const getColumns = (targetType) => {
  var columns;

  if(targetType === "shared_building"){
    columns = [
      {
        id: "select",
        header: ({ table }) => (
          <Checkbox
            checked={
              table.getIsAllPageRowsSelected() ||
              (table.getIsSomePageRowsSelected() && "indeterminate")
            }
            onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
            aria-label="Select all"
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label="Select row"
          />
        ),
        enableSorting: false,
        enableHiding: false,
      },
      {
        accessorKey: "sh_name",
        header: () => <div className="text-left">건물명</div>,
        cell: ({ row }) => <div>{row.getValue("sh_name")}</div>,
      },
      {
        accessorKey: "hs_hosu",
        header: ({ column }) => {
          return (
            <Button
              className="text-center self-center"
              variant="header"
              onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            >
              호수
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          )
        },
        cell: ({ row }) => <div className="ml-8">{row.getValue("hs_hosu")}</div>,
      },
      {
        accessorKey: "mem_name",
        header: () => <div className="text-center">이름</div>,
        cell: ({ row }) => <div className="text-center">{row.getValue("mem_name")}</div>,
      },
      {
        accessorKey: "mem_hptel",
        header: () => <div className="text-center">전화번호</div>,
        cell: ({ row }) => <div className="text-center">{row.getValue("mem_hptel")}</div>,
      },
      {
        accessorKey: "mem_email",
        header: () => <div className="text-center">이메일</div>,
        cell: ({ row }) => <div className="text-center">{row.getValue("mem_email")}</div>,
      }
    ]
  }else if(targetType === "group"){
    columns = [
      {
        id: "select",
        header: ({ table }) => (
          <Checkbox
            checked={
              table.getIsAllPageRowsSelected() ||
              (table.getIsSomePageRowsSelected() && "indeterminate")
            }
            onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
            aria-label="Select all"
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label="Select row"
          />
        ),
        enableSorting: false,
        enableHiding: false,
      },
      {
        accessorKey: "building_name",
        header: () => <div className="text-left">건물명</div>,
        cell: ({ row }) => <div>{row.getValue("building_name")}</div>,
      },
      {
        accessorKey: "room_number",
        header: ({ column }) => {
          return (
            <Button
              className="text-center self-center"
              variant="header"
              onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            >
              호수
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          )
        },
        cell: ({ row }) => <div className="ml-8">{row.getValue("room_number")}</div>,
      },
      {
        accessorKey: "name",
        header: () => <div className="text-center">이름</div>,
        cell: ({ row }) => <div className="text-center">{row.getValue("name")}</div>,
      },
      {
        accessorKey: "phone_number",
        header: () => <div className="text-center">전화번호</div>,
        cell: ({ row }) => <div className="text-center">{row.getValue("phone_number")}</div>,
      },
      {
        accessorKey: "email",
        header: () => <div className="text-center">이메일</div>,
        cell: ({ row }) => <div className="text-center">{row.getValue("email")}</div>,
      }
    ]
  }
  
  

  return columns;
}