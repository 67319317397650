import React, {useState, useEffect} from 'react'
import axios from "axios";
import cn from 'classnames'
import { useNavigate } from "react-router-dom"

// css
import Style from './index.module.css'
import MainImg from '@/assets/image/etc/etc-main-list02.png'
import ContactImg from '@/assets/image/etc/etc-contact.png'

import Cmm from '@/assets/image/ico/ico-cmm.svg'
import Notice from '@/assets/image/ico/ico-noti.svg'
import More from '@/assets/image/ico/ico-arr-more.svg'
import Slogo from '@/assets/image/etc/etc-welcom-logo.svg'

import { getToken, removeToken } from "../../services/TokenService";


const Residence = () => {
    const [isLoggin, SetIsLogin] = useState(false);
	const [lcToken, SetLcToken] = useState("");

	const [memberType, SetMemberType] = useState("");
	const [memberName, SetMemberName] = useState("");
	const [memberHouse, SetMemberHouse] = useState("");
	const [memberIndate, SetMemberIndate] = useState("");
	const [memberOutdate, SetMemberOutdate] = useState("");
	const [memberHosu, SetMemberHosu] = useState("");
	const [memberFloor, SetMemberFloor] = useState("");
	const [memberManagerName, SetMemberManagerName] = useState("");
	const [memberHouseAddr, SetMemberHouseAddr] = useState("");
	const [boardList, setBoardList] = useState([]);
	const [cmboardList, setCmBoardList] = useState([]);
	const [comcnt1, setComCnt1] = useState("0");
	const [comcnt2, setComCnt2] = useState("0");
	const [comcnt3, setComCnt3] = useState("0");
	const navigate = useNavigate();
	
    const comm_go =() =>{
        window.location.href = "/communication";
    }

	const getBoardList = async (e) => {

		const token = getToken();
		
		if(token !== null){
			const local_token = JSON.parse(token);

			axios({
				method: 'post',
				url: process.env.REACT_APP_API_NOTICE_LIST_MAIN,
				mode: "cors",
				credentials: "same-origin",
				data:{
					mode: 'Y',
					company: local_token.company
				},
				headers: {
					Authorization: "Bearer " + local_token.access_token
				},
			}).then((res) => {
				setBoardList(res.data.list); 
				const totalCnt = res.data.cnt;
			}).catch((err) => {	
				console.log("에러", err);
			})
		}

		//const resp = await (await axios.get(process.env.REACT_APP_API_NOTICE_LIST_MAIN)).data; 
		//setBoardList(resp.list); 
		//const totalCnt = resp.cnt;
	}

	const getCmBoardList = async (e) => {

		const token = getToken();
		
		if(token !== null){
			const local_token = JSON.parse(token);

			axios({
				method: 'post',
				url: process.env.REACT_APP_API_COMMUNICATION_LIST_MAIN,
				mode: "cors",
				credentials: "same-origin",
				data:{
					company: local_token.company
				},
				headers: {
					Authorization: "Bearer " + local_token.access_token
				},
			}).then((res) => {
				setCmBoardList(res.data.list); 
				setComCnt1(res.data.pcnt1);
				setComCnt2(res.data.pcnt2);
				setComCnt3(res.data.pcnt3);
			}).catch((err) => {	
				console.log("에러", err);
			})
		}
	}

	const myinfo = () => {
		navigate("/myinfo");
	}

	useEffect(() => {
		const token = getToken();

		if(token !== null){
			const local_token = JSON.parse(token);
			//console.log(local_token.access_token);
			SetLcToken(local_token.access_token);
			
			axios({
				method: 'get',
				url: process.env.REACT_APP_API_MEMBERMYINFO,
				mode: "cors",
				credentials: "same-origin",
				headers: {
					Authorization: "Bearer " + local_token.access_token
				},
			}).then((res) => {
				//console.log(res.data.profile.mtype);
				//console.log(res.data.code);
				if(res.data.code === 200){
					SetMemberType(res.data.profile.mtype);
					SetMemberName(res.data.profile.nickname);
					SetMemberHouse(res.data.house.house_name);
					SetMemberIndate(res.data.house.indate);
					SetMemberOutdate(res.data.house.outdate);
					SetMemberHosu(res.data.house.hosu);
					SetMemberFloor(res.data.house.floor);
					SetMemberManagerName(res.data.house.manager_name);
					SetMemberHouseAddr(res.data.house.house_addr);
				}else if(res.data.code === 400){
					removeToken();
					window.location.href = "/";
				}

			}).catch((err) => {	
				console.log("에러", err);
			})
			
			if(local_token !== ""){
				SetIsLogin(true);
			}
		}

		getBoardList();
		getCmBoardList();
		
	}, []);

	return (
		<div className={cn(Style.wrapper, Style.whiteType)}>

			<section className={Style.residenceWrap}>
				<div className={Style.residenceHeader}>
					<div className={Style.profile} onClick={myinfo} style={{cursor:'pointer'}}>
						<div className={Style.upic} style={{background:`url(${MainImg})`}}>&nbsp;</div>
						<div className={Style.uname}>나의정보</div>
					</div>
				</div>
				<div className={Style.residenceCont} style={{background:`url(${MainImg})`}}>
					<div className={Style.scont}>
						<div className={Style.stit}>WELCOME</div>
						<div className={Style.sdesc}>공유주택에서의 새로운 라이프스타일</div>
					</div>
					<div className={Style.slogo}><img src={Slogo} /></div>
				</div>
			</section>

			<section className={Style.contactWrap}>
				<div className={Style.scont}>
					<dl>
						<dt>
							<div className={Style.upic} style={{background:`url(${MainImg})`}}>&nbsp;</div>
                            {memberHouse ?
                                <div className={Style.ucont}>
                                        {memberName} 님은 <br/><strong>{memberHouse} 거주 중</strong> 입니다.
                                </div>
                                :
                                <div className={Style.ucont}>
                                        {memberName} 님은 미거주 입니다.
                                </div>
                            }
						</dt>
                        {memberHouse && (
						<dd>
							<ul>
								<li>
									<div className={Style.key}>기간</div>
									<div className={Style.value}>{memberIndate} – 현재 (0일째)</div>
								</li>
								<li>
									<div className={Style.key}>주소</div>
									<div className={Style.value}>{memberHouseAddr}</div>
								</li>
								<li>
									<div className={Style.key}>거주실</div>
									<div className={Style.value}>{memberHosu}호</div>
								</li>
								<li>
									<div className={Style.key}>보증금 / 임대료 / 관리비</div>
									<div className={Style.value}>0만 / 0만 / 0만</div>
								</li>
								<li>
									<div className={Style.key}>담당매니저</div>
									<div className={Style.value}>{memberManagerName}</div>
								</li>
							</ul>
						</dd>
                        )}
					</dl>
                    {memberHouse && (
					<button type="button" onClick={comm_go}>1:1 문의하기</button>
                    )}
				</div>
			</section>

			<section className={Style.latestWrap}>
				<div  className={Style.headerWrap}>
					<div className={Style.lt}>
						<img src={Cmm} />
						<strong>1:1 커뮤니케이션</strong>
						<ul>
							<li>접수 <span>{comcnt1}</span></li>
							<li>확인 <span>{comcnt2}</span></li>
							<li>처리완료 <span>{comcnt3}</span></li>
						</ul>
					</div>
					<div className={Style.rt}>
						<a href="/communication">
							<span>더보기</span>
							<img src={More} />
						</a>
					</div>
				</div>
				<div  className={cn(Style.contWrap, Style.type01)}>
					<ul>
					{cmboardList.map((board) => (
						<li key={board.seq}>
							<a href={`/communicationView/${board.seq}`}>
								<div className={Style.cate}>[{board.gubun}]</div>
								<div className={Style.comm03}>{board.status}</div>
								<div className={Style.tit}>{board.subject}</div>
								<div className={Style.date}>{board.regdate}</div>
							</a>
						</li>
					))}
					</ul>
				</div>
			</section>

			<section className={Style.latestWrap}>
				<div  className={cn(Style.headerWrap, Style.t01)}>
					<div className={Style.lt}>
						<img src={Notice} />
						<strong>공지사항</strong>
					</div>
					<div className={Style.rt}>
						<a href="/notice">
							<span>더보기</span>
							<img src={More} />
						</a>
					</div>
				</div>
				<div className={Style.contWrap} style={{width:'100%'}}>
					<ul>
					{boardList.map((board) => (
						<li key={board.seq}>
							<a href={`/NoticeView/${board.seq}`}>
								<div className={Style.cate}>[{board.notype}]</div>
								<div className={Style.tit}>{board.subject}</div>
								<div className={Style.date}>{board.regdate}</div>
							</a>
						</li>
					))}		
					</ul>
				</div>
			</section>



		</div>
	)
}

export default Residence
