import React, {useEffect, useState} from 'react'
import cn from 'classnames'
import { useNavigate } from "react-router-dom"
import axios from "axios";
import { ColorRing } from 'react-loader-spinner';

// css
import Style from './index2.module.css'
import ProductImg from '@/assets/image/etc/etc-product01.png'
import SpaceHome from '@/assets/image/ico/ico-adm-req01.svg'
import AdminIcon from '@/assets/image/ico/ico-access-list01.svg'
import ManagerIcon from '@/assets/image/ico/ico-access-list02.svg'
import UserIcon from '@/assets/image/ico/ico-access-list03.svg'

import { getToken, removeToken } from "../../services/TokenService";

const Index = () => {
	
	const [loading, setLoading] = useState(true);
	const [mainData, setMainData] = useState({});

	const navigate = useNavigate();
	const getMainData = async () => {
		const access_token = (JSON.parse(getToken())).access_token;
		await axios({
			method: 'get',
			url: process.env.REACT_APP_API_BUILDING_ENGINEER_MAIN,
			mode: "cors",
			credentials: "same-origin",
			headers: {
				Authorization: "Bearer " + access_token
			},
		}).then((res) => {
			if(res.data.code === 200){
				setMainData(res.data);
				setLoading(false);
			}else if(res.data.code === 400){
				console.log(res.data);
			}
		}).catch((err) => {	
			console.log("에러", err);
		})
	}

	useEffect(() => {
		setLoading(true);
		getMainData();
	  }, []);

	const onListRowClicked = (params, e) => {
		const requestStatus = params[0];
		const requestId = params[1];

		if( requestStatus === "0" ){
			navigate(`/admin/space/reqView/${requestId}`)
		}else if( requestStatus === "1" || requestStatus === "2" ){
			navigate(`/admin/space/reportRegister/${requestId}`)
		}else if( requestStatus === "3" ){
			navigate(`/admin/space/reportView/${requestId}`)
		}else{
			console.log("Error");
		}
	}

	function getStatusName(status) {
		switch (status) {
			case '0':
				return "요청중";
			case '1':
				return "기사확인";
			case '2':
				return "방문예정";
			case '3':
				return "수리완료";
		  }
	  }

	return (
		<>
			{ loading ?  <div style={{zIndex:'500', position: 'absolute', width: '80px', height: '80px', top: 'calc( 50% - 100px )', left: 'calc( 50% )'}}>
					<ColorRing
				visible={true}
				height="80"
				width="80"
				ariaLabel="blocks-loading"
				wrapperStyle={{}}
				wrapperClass="blocks-wrapper"
				colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}/></div>  : (
				<div className={Style.wrapper}>

					<section className={Style.spacedHeader}>

						<div className={Style.pageTitle}>
							<img src={SpaceHome} />
							<span><strong>{mainData.engineername} 기사님의</strong> 시설관리 요청서</span>
						</div>

					</section>

					<section className={cn(Style.reqCnt)}>
						<ul>
							<li>&nbsp;</li>
							<li>&nbsp;</li>
							<li>&nbsp;</li>
							<li>&nbsp;</li>
						</ul>
					</section>


					<div className={cn(Style.scrolls, Style.type02)}>
						<table className={cn(Style.webList)}>
							<colgroup>
								<col width="70" />
								<col width="120" />
								<col width="220" />
								<col width="" />
								<col width="120" />
								<col width="120" />
							</colgroup>
							<tr>
								<th><input type="checkbox" /></th>
								<th>작성일</th>
								<th>위치</th>
								<th>제목</th>
								<th>시설기사</th>
								<th>상태</th>
							</tr>
							{mainData.requestlist.map((board) => 
								<tr style={{cursor : "pointer"}} onClick={(e)=>onListRowClicked([board.status, board.id], e)} key={ board.id }>
									<td><input type="checkbox" /></td>
									<td>{board.regdate}</td>
									<td>{`[${board.buildingname}/${board.floor}/${board.detaillocation}]`}</td>
									<td>{board.subject}</td>
									<td>{board.engineer}</td>
									<td>{getStatusName(board.status)}</td>
								</tr>
							)}
						</table>

						<ul className={cn(Style.mobList)}>
							{mainData.requestlist.map((board) => 
								<li key={ board.id }>
									<div className={Style.chk}>
										<input type="checkbox" />
									</div>
									<table style={{cursor : "pointer"}} onClick={(e)=>onListRowClicked([board.status, board.id], e)}>
										<colgroup>
											<col width="80" />
											<col width="" />
										</colgroup>
										<tr>
											<th className={Style.al}>작성일</th>
											<td className={Style.al}>{board.regdate}</td>
										</tr>
										<tr>
											<th className={Style.al}>위치</th>
											<td className={Style.al}>{`[${board.buildingname}/${board.floor}/${board.detaillocation}]`}</td>
										</tr>
										<tr>
											<th className={Style.al}>제목</th>
											<td className={Style.al}>{board.subject}</td>
										</tr>
										<tr>
											<th className={Style.al}>시설기사	</th>
											<td className={Style.al}>{board.engineer}</td>
										</tr>
										<tr>
											<th className={Style.al}>상태</th>
											<td className={Style.al}>{getStatusName(board.status)}</td>
										</tr>
									</table>
								</li>
							)}
						</ul>
					</div>
				</div>
				)
			}
			</>
	)
}

export default Index
