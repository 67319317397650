import React, {useEffect, useState} from 'react'
import cn from 'classnames'
import Axios from "@/components/lib/axios";
import { ColorRing } from 'react-loader-spinner';
import { GreenLabel, Label } from "@/components/ui/label"
import SpaceReq from '@/assets/image/ico/ico-req01.svg'
import { MySpinner } from '@/components/ui/spinner'
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Pencil, X, FolderCog, Circle, CircleDot } from 'lucide-react';
import { useParams, useNavigate } from 'react-router-dom';
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "@/components/ui/select"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { Textarea } from "@/components/ui/textarea"
import ImageDropBox from '@/components/ImageDropBox';
import ImageUploadPopup from '@/components/ImageUploadPopUp';
import Zoom from 'react-medium-image-zoom'
import '@/components/imageZoomStyles.css'
import Resizer from "react-image-file-resizer";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "@/components/imageSlider.css";
import { getToken } from "@/services/TokenService";
import axios from 'axios';
import image_dropbox_background from "@/assets/image/bg/bg-upload.png";
import RemoveIcon from '@/assets/image/ico/ico-remove01.svg'
// import FacilityStatusOne from '@/assets/image/ico/ico-facility-status-0.svg'
import {ReactComponent as FacilityStatusZero} from '@/assets/image/ico/ico-facility-status-0.svg';
import {ReactComponent as FacilityStatusOne} from '@/assets/image/ico/ico-facility-status-1.svg';
import {ReactComponent as FacilityStatusTwo} from '@/assets/image/ico/ico-facility-status-2.svg';
import {ReactComponent as FacilityStatusArrow} from '@/assets/image/ico/ico-facility-status-arrow.svg';

const FacilityView = () => {

	const { id } = useParams();
	const navigate = useNavigate();
	const queryClient = useQueryClient()

	const [facilityStatus, setFacilityStatus] = useState("0"); // facilityStatus
	
	const [radioPriority, setRadioPriority] = useState("0");
	const [inputSubject, setInputSubject] = useState("");
	const [inputFloor, setInputFloor] = useState("");
	const [inputDetailedLocation, setInputDetailedLocation] = useState("");
	const [inputContent, setInputContent] = useState("");
	const [selectedImgFiles, setSelectedImgFiles] = useState([]);
  	const [previewImgUrls, setPreviewImgUrls] = useState([]);
	const [selectEngineerSeq, setSelectEngineerSeq] = useState();
	const [selectHouseSeq, setSelectHouseSeq] = useState();
	const [engineerName, setEngineerName] = useState("");
	const [houseName, setHouseName] = useState("");
	const [regDate, setRegDate] = useState("");

	const [isEditMode, setIsEditMode] = useState(false);
	const [isImageEditPopUpShowing, setIsImageEditPopUpShowing] = useState(false)

	const [engineerList, setEngineerList] = useState();
	const [houseList, setHouseList] = useState();

	// isFromCommunicationBoard ? process.env.REACT_APP_API_REQUEST_REGISTER_START + `/${id}` : process.env.REACT_APP_API_REQUEST_REGISTER_START_BASIC
	
	// 요청 글 작성 시작 데이터 - 유저 요청 글 + 시설 기사 + 주택
	const { isLoading, data, error } = useQuery({queryKey:["facility_view", id],
		queryFn: () =>
			Axios.get(process.env.REACT_APP_API_REPORT_VIEW + `/${id}`)
			.then((res) => {
				setInputSubject(res.data.subject);
				setEngineerName(res.data.engineer);
				setRadioPriority(res.data.priority);
				setHouseName(res.data.buildingname);
				setInputFloor(res.data.floor);
				setInputDetailedLocation(res.data.detailedlocation);
				setInputContent(res.data.requestcontent);
				setPreviewImgUrls(res.data.request_imgurllist);
				setRegDate(res.data.regdate);

				setFacilityStatus(res.data.status);

				console.log(res.data);
				return res.data;
			})});

	const onImageDeleteButtonClicked = async (params, event) => {
		const index = params[0];
		const deletedImgUrls = previewImgUrls.splice(index, 1);
		const newPreviewImgUrls = [...previewImgUrls];
		setPreviewImgUrls(newPreviewImgUrls);

		const access_token = (JSON.parse(getToken())).access_token;
		try {
			axios({
				method: 'post',
				url: process.env.REACT_APP_API_REQUEST_SINGLE_IMAGE_DELETE,
				data: {seq: id, idx: deletedImgUrls[0].idx},
				mode: "cors",
            	credentials: "same-origin",
				headers: {
					Authorization: "Bearer " + access_token
				},
			}).then((res) => {
				if(res.data.code === 200){
					console.log(deletedImgUrls[0].imgurl + "deleted");
				}else if(res.data.code === 400){
					
				}

			}).catch((err) => {	
				console.log("에러", err);
			})

		} catch (err){
			console.error('Image Delete error:', err);
		}
	};

	const resizeFile = (file) =>
		new Promise((resolve) => {
				Resizer.imageFileResizer( file, 1920, 1920, "JPEG", 90, 0,
					(image) => {
						resolve(image);
					},
					"file"
			);
	});
	
	const onImagesDropped = async (acceptedFiles) => {
		await Promise.all(
			acceptedFiles.map((image) => {
				return resizeFile(image);
			})
		).then(async (uploadBranchImages) => {
			const formData = new FormData();
			uploadBranchImages.forEach((file) => {
				formData.append('attach[]', file);
			});
			formData.append('seq', id);
			for (var key of formData.entries()) {
				console.log(key[0] + ', ' + key[1]);
			}
			
			const access_token = (JSON.parse(getToken())).access_token;
			await axios({
				method: 'post',
				url: process.env.REACT_APP_API_REQUEST_IMAGES_UPLOAD,
				data: formData,
				mode: "cors",
				credentials: "same-origin",
				headers: {
					Authorization: "Bearer " + access_token
				},
			}).then((res) => {
				if(res.data.code === 200){
					console.log(res.data);
					setPreviewImgUrls((prevState) => [...res.data.imgurllist, ...prevState]);
					// setSelectedImgFiles([]);
					// images = [];
				}else if(res.data.code === 400){
					
				}
			}).catch((err) => {	
				console.log("에러", err);
			})
		});
	};

	const updateRequest = useMutation({
		mutationFn: async () => {
			var params;
			params = {
				fm_title: inputSubject,
				fm_gisa_member_idx: selectEngineerSeq,
				fm_house: selectHouseSeq,
				fm_floor: inputFloor,
				fm_detail_location: inputDetailedLocation,
				fm_gubun: radioPriority,
				fm_facility_gayo: inputContent
			}

			const { res } = await Axios.put(`/api_facility/article/${id}`, params)
			return res
		},
		onSuccess: (res) => {
			queryClient.invalidateQueries({ queryKey:["facility_view", id] })
			setIsEditMode(false);
		},
	});

	const onSubmitCliked = async (e) => {
		if(inputSubject === "") {alert("제목을 입력 해 주세요");return;}
		if(selectEngineerSeq === undefined) {alert("시설 기사를 선택 해 주세요");return;}
		if(selectHouseSeq === undefined) {alert("주택을 선택 해 주세요");return;}
		if(inputFloor === "") {alert("층수 정보를 입력 해 주세요");return;}
		if(inputDetailedLocation === "") {alert("세부 위치를 입력 해 주세요");return;}
		if(inputContent === "") {alert("요청 내용을 작성 해 주세요");return;}

		updateRequest.mutate();
	};

	const responsive = {
		superLargeDesktop: {
		  // the naming can be any, depends on you.
		  breakpoint: { max: 4000, min: 3000 },
		  items: 5
		},
		desktop: {
		  breakpoint: { max: 3000, min: 1024 },
		  items: 4
		},
		tablet: {
		  breakpoint: { max: 1024, min: 464 },
		  items: 2
		},
		mobile: {
		  breakpoint: { max: 464, min: 0 },
		  items: 1,
		}
	};

	const onEditButtonClicked = async () => {
		const result = await Axios.get(process.env.REACT_APP_API_REQUEST_REGISTER_START_BASIC);
		setEngineerList(result.data.engineerlist);
		setHouseList(result.data.houselist);

		const engineerFound = result.data.engineerlist.find((engineer) => engineer.eng_name === engineerName);
		const houseFound = result.data.houselist.find((house) => house.house_name === houseName);

		setSelectEngineerSeq(engineerFound.seq);
		setSelectHouseSeq(houseFound.seq);

		setIsEditMode(true);
	}
	
	return (
		<>
		{
		isLoading
		? <MySpinner/>
		:
		<div className="wrapper lg:pl-[110px] lg:w-[100%-110px] lg:mx-0 mx-3.5">
			<div className='px-2 lg:px-20 flex flex-col'>
				<div className="title w-full text-2xl"><img className='w-6 h-6 mr-2' src={SpaceReq} />시설 관리</div>
				<div className='mt-10 mb-6 lg:mt-18 lg:mb-10 w-full flex flex-row justify-center items-center gap-4 lg:gap-10 self-center'>
					<div className='flex flex-col items-center gap-2'>
						<FacilityStatusZero className='w-16 h-16 lg:w-32 lg:h-32 text-accent-foreground'/>
						<Label className='lg:text-xl text-accent-foreground'>요청 접수</Label>
					</div>
					<div className='flex flex-col items-center gap-2'>
						<FacilityStatusArrow className={`w-8 h-8 lg:w-20 lg:h-20 ${facilityStatus === '1' || facilityStatus === '3' ? "text-accent-foreground" : "text-gray-400"}`}/>
					</div>
					<div className='flex flex-col items-center gap-2'>
						<FacilityStatusOne className={`w-16 h-16 lg:w-32 lg:h-32 ${facilityStatus === '1' || facilityStatus === '3' ? "text-accent-foreground" : "text-gray-400"}`}/>
						<Label className={`lg:text-xl ${facilityStatus === '1' || facilityStatus === '3' ? "text-accent-foreground" : "text-gray-400"}`}>수리 시작</Label>
					</div>
					<div className='flex flex-col items-center gap-2'>
						<FacilityStatusArrow className={`w-8 h-8 lg:w-20 lg:h-20 ${facilityStatus === '3' ? "text-accent-foreground" : "text-gray-400"}`}/>
					</div>
					<div className='flex flex-col items-center gap-2'>
						<FacilityStatusTwo className={`w-16 h-16 lg:w-32 lg:h-32 ${facilityStatus === '3' ? "text-accent-foreground" : "text-gray-400"}`}/>
						<Label className={`lg:text-xl ${facilityStatus === '3' ? "text-accent-foreground" : "text-gray-400"}`}>수리 완료</Label>
					</div>
				</div>
				{
					data.communicationinfo &&
					<div className='p-4 mt-10 lg:p-10 lg:m-10 flex flex-col bg-section rounded-md'>
						<div className='flex flex-row gap-2 items-center'>
							<Pencil className='w-6 h-6 mr-1 text-accent-foreground'/>
							<GreenLabel className='text-xl'>거주자 - 불편 사항 및 수리 요청 글</GreenLabel>
						</div>
						<div className='p-2 mt-4 lg:m-10 flex flex-col gap-2'>
							<div className='flex flex-col lg:flex-row gap-1'>
								<Label className='text-lg lg:mr-22'>{`${data.buildingname} ${data.floor}층 ${data.communicationinfo.roominfo} ${data.communicationinfo.username}`}</Label>
								<Label className='text-lg'>{data.communicationinfo.subject}</Label>
							</div>
							<div className='p-2 mt-2 lg:p-10 lg:mt-10 bg-facility-contents-bg rounded-md text-lg' dangerouslySetInnerHTML={{__html: data.communicationinfo.content}}/>
						</div>
					</div>
				}
				{
					isEditMode
					?
					<div className='p-4 mt-10 lg:p-10 lg:m-10 flex flex-col bg-section rounded-md'>
						<div className='flex flex-row gap-2 items-center'>
							<Pencil className='w-6 h-6 mr-1 text-accent-foreground'/>
							<GreenLabel className='text-xl'>주택관리자 - 시설 관리 요청서 수정 중</GreenLabel>
						</div>
						<div className='p-2 mt-4 lg:m-10'>
							<div className='flex flex-wrap lg:w-full gap-2 items-end'>
								<div className='w-full lg:w-2/3 lg:w-fit flex flex-row items-center lg:mr-22'>
									<Label className='w-12 text-lg mr-4 lg:mr-10'>제목</Label>
									<Input className="w-full text-lg" value={inputSubject} onChange={(event)=>setInputSubject(event.target.value)}
										placeholder="요청 제목 입력"/>
								</div>
								<div className='w-full lg:w-fit flex flex-row justify-between items-end'>
									<Label className='w-18 text-lg mr-4 lg:mr-10 mb-2.5'>시설 기사</Label>
									<Select value={selectEngineerSeq} onValueChange={(value)=>setSelectEngineerSeq(value)}>
										<SelectTrigger className="w-52 mt-5 h-12">
											<SelectValue placeholder="기사님 배정"/>
										</SelectTrigger>
										<SelectContent>
											<SelectGroup>
												{engineerList.map((item, index)=>(
													<SelectItem value={item.seq}>{item.eng_name}</SelectItem>
												))}
											</SelectGroup>
										</SelectContent>
									</Select>
								</div>
								<div className='w-full lg:w-fit flex flex-row justify-between items-end lg:mr-10'>
									<Label className='w-12 text-lg mr-4 lg:mr-10 mb-2.5'>주택명</Label>
									<Select value={selectHouseSeq} onValueChange={(value)=>setSelectHouseSeq(value)}>
										<SelectTrigger className="w-52 mt-5 h-12">
											<SelectValue placeholder="주택 선택"/>
										</SelectTrigger>
										<SelectContent>
											<SelectGroup>
												{houseList.map((item, index)=>(
													<SelectItem value={item.seq}>{item.house_name}</SelectItem>
												))}
											</SelectGroup>
										</SelectContent>
									</Select>
								</div>
								<div className='w-full lg:w-fit flex flex-row justify-between items-center mt-4 lg:mr-10'>
									<Label className='w-11 text-lg mr-7'>층수</Label>
									<div className='relative'>
										<Input className="w-40 text-lg" value={inputFloor} onChange={(event)=>setInputFloor(event.target.value)}
											placeholder="층수만 입력"/>
										<Label className='absolute text-lg text-black right-4 bottom-2.5'>층</Label>
									</div>
								</div>
								<div className='w-full lg:w-fit flex flex-row justify-between items-center mt-4 lg:mr-10'>
									<Label className='w-25.5 text-lg mr-3'>세부 위치</Label>
									<Input className="w-40 text-lg" value={inputDetailedLocation} onChange={(event)=>setInputDetailedLocation(event.target.value)}
										placeholder="호수나 세부 위치 입력"/>
								</div>
								<div className='w-full lg:w-fit flex flex-row justify-between items-center mt-4'>
									<Label className='w-25.5 text-lg mr-3'>우선 순위</Label>
									<RadioGroup className="flex flex-row space-x-2 h-12" value={radioPriority}  onValueChange={(value)=>setRadioPriority(value)}>
										<div className="flex flex-row items-center space-x-2">
											<RadioGroupItem value="0" id="0" />
											<Label className="ml-2 text-lg">일반</Label>
										</div>
										<div className="flex flex-row items-center space-x-2">
											<RadioGroupItem value="1" id="1" />
											<Label>긴급</Label>
										</div>
									</RadioGroup>
								</div>
								<Textarea
									className="w-full resize-none mt-4 lg:mt-10"
									rows="6"
									value={inputContent}
									onChange={(event)=>setInputContent(event.target.value)}
									placeholder="요청 내용을 작성 해 주세요"
								/>
								
							</div>
							{
							isImageEditPopUpShowing && <ImageUploadPopup isPopUpShowing={isImageEditPopUpShowing} setIsPopUpShowing={setIsImageEditPopUpShowing} previewImgUrls={previewImgUrls} onImagesDropped={onImagesDropped} onDeleteButtonClicked={onImageDeleteButtonClicked} isObject={true} />}

							{
							previewImgUrls.length === 0 ?
							<div className='w-full h-72 bg-white mt-10 rounded-md' style={{ backgroundImage: `url(${image_dropbox_background})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
								<ImageDropBox onDrop={onImagesDropped}/>
							</div>
							:
							<div>
								<div className='mt-6 flex justify-end'>
									<Button variant='ghost' className='w-52 self-end mt-6' onClick={()=>setIsImageEditPopUpShowing(true)}><FolderCog className='w-4 h-4 mr-1'/>사진 삭제 및 변경</Button>
								</div>
								<Carousel className='w-full h-72 bg-white mt-4 rounded-md' responsive={responsive} swipeable={true} draggable={true}>
									{previewImgUrls.map((image, index) => (
										<div className='max-w-full max-h-56 self-center flex justify-center relative overflow-hidden'>
											<Zoom>
												<img className='max-w-full max-h-full object-cover' src={image.imgurl}/>
											</Zoom>
											<Button className='absolute right-2 top-1' variant='icon' size='icon' onClick={(e)=>onImageDeleteButtonClicked([index], e)}><X className='w-5.5 h-5.5'/></Button>
										</div>
									))}
								</Carousel>
							</div>
							}
							<div className='mt-14 flex justify-end gap-4'>
								<Button className='text-lg' variant="ghost" size="big" onClick={() => setIsEditMode(false)}>취소</Button>
								<Button className='text-lg' size="big" onClick={() => onSubmitCliked()}>저장</Button>
							</div>
						</div>
					</div>
					:
					<div className='p-4 mt-10 lg:p-10 lg:m-10 flex flex-col bg-section rounded-md'>
						<div className='flex flex-row gap-2 items-center'>
							<Pencil className='w-6 h-6 mr-1 text-accent-foreground'/>
							<GreenLabel className='text-xl'>주택관리자 - 시설 관리 요청서</GreenLabel>
						</div>
						<div className='p-2 lg:m-10 lg:mt-0 flex flex-col gap-2'>
							<Label className='self-end'>{regDate}</Label>
							<div className='flex flex-col lg:flex-row lg:justify-between mt-4 gap-2'>
								<Label className='text-lg'>{inputSubject}</Label>
								<Label className='text-lg'>{`담당 기사 : ${engineerName}`}</Label>
							</div>
							<div className='flex flex-col lg:flex-row lg:justify-between gap-2'>
								<Label className='text-lg lg:mr-22'>{`${houseName} ${inputFloor}층 ${inputDetailedLocation}`}</Label>
								<div className='flex flex-row items-center gap-2'>
									<CircleDot className='w-5.5 h-5.5 text-accent-foreground'/>
									<Label className='text-lg'>{radioPriority === '0' ? "일반" : "긴급"}</Label>
								</div>
							</div>
							<pre className='p-2 mt-2 lg:p-10 lg:mt-10 bg-facility-contents-bg rounded-md overflow-x-scroll no-scrollbar'>{inputContent}</pre>
							{
							previewImgUrls.length === 0 ?
							<div className='w-full h-72 bg-facility-contents-bg mt-6 lg:mt-10 rounded-md flex flex-row justify-center items-center'>
								<Label className='text-lg text-gray-900'>이미지가 없습니다</Label>
							</div>
							:
							<Carousel className='w-full h-72 bg-facility-contents-bg mt-4 rounded-md' responsive={responsive} swipeable={true} draggable={true}>
								{previewImgUrls.map((image, index) => (
									<div className='max-w-full max-h-56 self-center flex justify-center relative overflow-hidden'>
										<Zoom>
											<img className='max-w-full max-h-full object-cover' src={image.imgurl}/>
										</Zoom>
									</div>
								))}
							</Carousel>
							}
							<div className='mt-10 flex justify-end gap-4'>
								<Button onClick={() => onEditButtonClicked()}>요청서 수정</Button>
							</div>
						</div>
					</div>
				}
				{
					facilityStatus === '3'
					&&
					<div className='p-4 mt-10 lg:p-10 lg:m-10 flex flex-col bg-section rounded-md'>
						<div className='flex flex-row gap-2 items-center'>
							<Pencil className='w-6 h-6 mr-1 text-accent-foreground'/>
							<GreenLabel className='text-xl'>시설관리자 - 수리 완료 보고서</GreenLabel>
						</div>
						
						<div className='p-2 lg:m-10 flex flex-col gap-2'>
							<Label className='self-end'>{data.reportdate}</Label>
							<pre className='p-2 mt-6 lg:p-10 bg-facility-contents-bg rounded-md overflow-x-scroll no-scrollbar'>{data.reportcontent}</pre>
							{
							data.request_imgurllist.length === 0 ?
							<div className='w-full h-72 bg-facility-contents-bg mt-4 rounded-md flex flex-row justify-center items-center'>
								<Label className='text-lg text-gray-900'>이미지가 없습니다</Label>
							</div>
							:
							<div>
								<Carousel className='w-full h-72 bg-facility-contents-bg mt-4 rounded-md' responsive={responsive} swipeable={true} draggable={true}>
									{data.request_imgurllist.map((image, index) => (
										<div className='max-w-full max-h-56 self-center flex justify-center relative overflow-hidden'>
											<Zoom>
												<img className='max-w-full max-h-full object-cover' src={image.imgurl}/>
											</Zoom>
										</div>
									))}
								</Carousel>
							</div>
							}
						</div>
					</div>
				}
				
				{/* <Button variant="big" size="big" onClick={() => insertAutoSend.mutate()}>저장</Button>
				<Button className='w-64 self-end mt-10' variant="big" size="big" onClick={()=>{}}><Pencil className='w-5.5 h-5.5 mr-2'/>시설 관리 요청</Button> */}
			</div>
		</div>
		}
		</>
	)
}

export default FacilityView
