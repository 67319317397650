import {React, useState, useEffect, useRef} from 'react'
import { useLocation } from 'react-router-dom'
import axios from "axios";
import cn from 'classnames'
import { ColorRing } from 'react-loader-spinner';
import styled from 'styled-components'

// css
import Style from './index.module.css'
import popClose from '@/assets/image/ico/ico-pop-close.svg'
import Logo from '@/assets/video/logo.mp4'
//import { el } from '@fullcalendar/core/internal-common';
import Dialog from './dialog.module.css'

//daum post
import DaumPostcode from 'react-daum-postcode';
import Post from '../../components/post';

const Index = () => {
	const { state } = useLocation();

	const now = new Date();
	const _ = require('lodash');
	const years = _.range(now.getFullYear() - 70, now.getFullYear() + 1, 1);
	
	//console.log(state);
	//console.log(state.email);
	//console.log(state.password);

	const usernamefocusRef = useRef(null);
	const nicknamefocusRef = useRef(null);
	const hptelfocusRef = useRef(null);
	const certnumfocusRef = useRef(null);

	const [username, setUsername] = useState("");
	const [nickname, setNickname] = useState("");
	const [hptel, setHptel] = useState("");
	const [certnum, setCertnum] = useState("");
	const [isChecked, setIsChecked] = useState(false);
	const [addr, setAddr] = useState("");
	const [addrdetail, setAddrdetail] = useState("");

	const [managersex, setManagerSex] = useState("");
	const [managerbirthy, setManagerBirthy] = useState("");
	const [managerbirthm, setManagerBirthm] = useState("");
	const [managerbirthd, setManagerBirthd] = useState("");

	const [popChecked1, setPopChecked1] = useState(false);
	const [popChecked2, setPopChecked2] = useState(false);
	const [popChecked3, setPopChecked3] = useState(false);
	const [popChecked4, setPopChecked4] = useState(false);
	// checked 된 것들
    const [checkItems, setCheckItems] = useState([]);

	//SMS전송후 받은 인증키
	const [recv_certnum, setRecv_certnum] = useState("");
	//인증체크완료
	const [certchk_end, setCertchk_end] = useState(false);
	//닉네임체크완료
	const [nicknm_end, setNicknm_end] = useState(false);
	const [loading, setLoading] = useState(false);

	/** Daum post */
	const [enroll_company, setEnroll_company] = useState({
		address:'',
	});
	
	const [popup, setPopup] = useState(false);
	
	const handleInput = (e) => {
		setEnroll_company({
			...enroll_company,
			[e.target.name]:e.target.value,
		})
	}
	
	const handleComplete = (data) => {
		setPopup(!popup);
	}
	const handlepostClose = () => {
		setPopup(false);
	}
	/** Daum post */

	// 개별선택
    function checkHandler(checked, id) {
        if(checked) {
            setCheckItems([...checkItems, id])
        } else {
            // 체크해제
            setCheckItems(checkItems.filter(o=>o!==id))
        }
    }

	// 전체선택
    function checkAllHandler() {
        if(checkItems.length == 0){
            setCheckItems(["chk1","chk2","chk3","chk4"])
        }
    }

    const checkHandled = ({target}) => {
		setPopChecked1(!popChecked1);
	}
	const checkHandled2 = ({target}) => {
		setPopChecked2(!popChecked2);
	}
	const checkHandled3 = ({target}) => {
		setPopChecked3(!popChecked3);
	}
	const checkHandled4 = ({target}) => {
		setPopChecked4(!popChecked4);
	}

	const handlePress = (e) => {
		const regex = /^[0-9\b]{0,11}$/;
		if (regex.test(e.target.value)) {
			setHptel(e.target.value)
		}
	  }

	const [isPopup, setIsPopup] = useState(false);
	const handleOpen = () => {
		setIsPopup(true);
	}
	const handleClose = () => {
		setIsPopup(false);
	}
	/*useEffect(()=>{
        setTimeout(function(){
            handleOpen();
		},1000)
	},[]);*/
	const [member, setMember] = useState({
		nickname: '',
		hptel: '',
		hptel_auth_num: '',
	});

	const nickCheck = () => {
		if(nickname == ""){
			alert("원하는 활동명을 입력해주세요.");
			nicknamefocusRef.current.focus();
			return;
		}else{
			setLoading(true);
			axios({
				method: 'post',
				url: process.env.REACT_APP_API_NICKNAMECHK,
				data: {
				  nickname: nickname
				}
			  }).then((res) => {
				if(res.data.result == true){
					setNicknm_end(true);
					alert("사용가능한 활동명입니다.");
					setLoading(false);
				}else{
					alert("이미 등록된 활동명입니다. 다시 입력해주세요.");
					setLoading(false);
				}
			}).catch((err) => {	
				console.log("에러", err);
				setLoading(false);
			})
		}
	}

	const hptel_certnum_send = () => {
		if(hptel == ""){
			alert("휴대폰번호를 입력해주세요.");
			hptelfocusRef.current.focus();
			return;
		}else{
			axios({
				method: 'post',
				url: process.env.REACT_APP_API_SMSCERTNUMSEND,
				data: {
				  hptel: hptel
				}
			  }).then((res) => {
				if(res.data.result === 'success'){
					setRecv_certnum(res.data.certnum);
					alert("전송완료 되었습니다.");
				}else{
					if(res.data.code === '300'){
						alert("이미 회원가입이 완료된 휴대폰번호 입니다.\r\n확인후 다시 입력해주세요.");
					}else{
						console.log(res.data.result);
					}
				}
			}).catch((err) => {	
				console.log("에러", err);
			
			})
		}
	}

	const certnum_check = () => {
		if(certnum == ""){
			alert("인증번호를 입력해주세요.");
			certnumfocusRef.current.focus();
			return;
		}else{
			axios({
				method: 'post',
				url: process.env.REACT_APP_API_SMSCERTNUMCHECK,
				data: {
				  certnum: certnum,
				  rec_certnum: recv_certnum
				}
			  }).then((res) => {
				if(res.data.result == true){
					setCertchk_end(true);
					alert("인증되었습니다.");
				}else{
					alert("인증번호가 같지 않습니다. 확인후 다시 입력해주세요.");
					console.log(res.data.result);
				}
			}).catch((err) => {	
				console.log("에러", err);
			
			})
		}
	}

	const reg_go = () => {
		if(nickname == ""){
			alert("원하는 활동명을 입력해주세요.");
			nicknamefocusRef.current.focus();
			return;
		}
		if(hptel == ""){
			alert("휴대폰번호를 입력해주세요.");
			hptelfocusRef.current.focus();
			return;
		}
		if(certnum == ""){
			alert("인증번호를 입력해주세요.");
			certnumfocusRef.current.focus();
			return;
		}

		handleOpen();
	}

	const timerRef = useRef(null);
	const onClickmember = (e) => {
		e.preventDefault();
		
		if(managersex === ""){
			alert("성별을 선택해주세요.");
			handleClose();
			return false;
		}

		if(managerbirthy === ""){
			alert("출생년도를 선택해주세요.");
			handleClose();
			return false;
		}

		if(managerbirthm === ""){
			alert("월을 선택해주세요.");
			handleClose();
			return false;
		}

		if(managerbirthd === ""){
			alert("일을 선택해주세요.");
			handleClose();
			return false;
		}

		if(enroll_company.address === ""){
			alert("주소를 입력해주세요.");
			handleClose();
			return false;
		}

		if(popChecked1 == false){
			alert("이용약관은 필수입니다.");
			return false;
		}
		if(popChecked2 == false){
			alert("개인정보 취급방침은 필수입니다.");
			return false;
		}
		if(popChecked3 == false){
			alert("위치기반 서비스 이용약관은 필수입니다.");
			return false;
		}

		//if(popChecked1 == false && popChecked2 == false && popChecked3 == false && popChecked4 == false){
		//	setPopChecked1(!popChecked1);
		//	setPopChecked2(!popChecked2);
		//	setPopChecked3(!popChecked3);
		//	setPopChecked4(!popChecked4);
		//}

			//setTimeout(() => {
				const formData = new FormData();
				let birth = "";
				if(managerbirthy !== "" && managerbirthm !== "" && managerbirthd !== "" ){
					birth = managerbirthy+"-"+managerbirthm+"-"+managerbirthd;
				}

				formData.append('email',state.email)
				formData.append('password',state.password)
				formData.append('username',username)
				formData.append('nickname',nickname)
				formData.append('hptel',hptel)
				formData.append('certnum',certnum)
				formData.append('check1',popChecked1)
				formData.append('check2',popChecked2)
				formData.append('check3',popChecked3)
				formData.append('check4',popChecked4)
				formData.append('sex',managersex)
				formData.append('birth',birth)
				formData.append('addr',enroll_company.address)
				formData.append('addr_detail',addrdetail)
				axios({
					method:'post',
					url:process.env.REACT_APP_API_MEMBERREG,
					data: formData,
				})
					.then((result)=>{
						//console.log('요청성공')
						//console.log(result)
						if(result.data.code === 200){
							alert("회원가입이 완료 되었습니다.");
							window.location.href="/login";
						}else if(result.data.code === 300){
							alert("이미 등록된 이메일 주소 입니다. 확인후 다시 입력해주세요.");
							return;
						}else{
							console.log(result)
						}
				})
					.catch((error)=>{console.log('요청실패')
					console.log(error)  
				})

			//}, 2000);
	}
	

	return (
		<>
			{ loading ?  <div style={{position: 'absolute', width: '80px', height: '80px', top: 'calc( 50% - 100px )', left: 'calc( 50% )'}}>
				<ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}/></div> : "" }

			<div className={Style.wrapper}>

				<section className={cn(Style.signupWrap, Style.type01)}>
					<div className={Style.slogo}>
						<video width="100%" height="100%" id="v_player" playsinline="" allowfullscreen="true" autoPlay loop muted>
							<source src={Logo} type="video/mp4" />
						</video>
					</div>
					<div className={Style.stit}>회원가입</div>
					<ul>
						<li>
							<div className={Style.formItem}>
								<input type="text" id="username" name="username" value={username} ref={usernamefocusRef} className={Style.inp} placeholder="이름을 입력해주세요" onChange={(e) => setUsername(e.target.value)} />
							</div>
						</li>
						<li>
							<div className={Style.formItem}>
								<input type="text" id="nickname" name="nickname" value={nickname} ref={nicknamefocusRef} className={Style.inp} placeholder="원하는 활동명을 입력해주세요" onChange={(e) => setNickname(e.target.value)} />
								<button type="button" onClick={nickCheck}>중복확인</button>
							</div>
						</li>
						<li>
							<div className={Style.formItem}>
								<select className={Style.select} value={managersex} onChange={(e) => setManagerSex(e.target.value)}>
									<option value="">성별선택</option>
									<option value="M">남자</option>
									<option value="W">여자</option>
								</select>
								<select className={Style.select} value={managerbirthy} onChange={(e) => setManagerBirthy(e.target.value)}>
									<option value="">출생년도</option>
									{
										years.map((option) => (
											<option key={option} value={option}>{option}년</option>
										))
									}
								</select>
								<select className={cn(Style.select, Style.minSize)} value={managerbirthm} onChange={(e) => setManagerBirthm(e.target.value)}>
									<option value="">월</option>
									{
										Array(12).fill(0).map((_, i) => (
											<option key={i + 1} value={i + 1}>{i + 1}월</option>
										))
									}
								</select>
								<select className={cn(Style.select, Style.minSize)} value={managerbirthd} onChange={(e) => setManagerBirthd(e.target.value)}>
									<option value="">일</option>
									{
										Array(31).fill(0).map((_, i) => (
											<option key={i + 1} value={i + 1}>{i + 1}일</option>
										))
									}
								</select>
							</div>
						</li>
						<li>
							<div className={Style.formItem}>
								<input type="text" id="addr" name="addr" value={enroll_company.address} className={Style.inp} placeholder="주소검색" />
								<button type="button" onClick={handleComplete}>우편번호 찾기</button>
							</div>
						</li>
						<li>
							<div className={Style.formItem}>
								<input type="text" id="addr_detail" name="addr_detail" value={addrdetail} className={Style.inp} placeholder="주소상세 입력" onChange={(e) => setAddrdetail(e.target.value)} />
							</div>
						</li>
						<li>
							<div className={Style.formItem}>
								<input type="text" id="hptel" name="hptel" value={hptel} ref={hptelfocusRef} className={Style.inp} placeholder="휴대폰 번호(-없이 입력)" onChange={(e) => handlePress(e)} />
								<button type="button" onClick={hptel_certnum_send}>인증번호 전송</button>
							</div>
						</li>
						<li>
							<div className={Style.formItem}>
								<input type="text" id="certnum" name="certnum" value={certnum} ref={certnumfocusRef} className={Style.inp} placeholder="인증번호" onChange={(e) => setCertnum(e.target.value)} />
								<button type="button" onClick={certnum_check}>인증하기</button>
							</div>
						</li>
					</ul>
					{/*}
					<div className={Style.helpmsg}>대/소문자, 숫자, 특수문자 중 2가지 이상의 조합으로 10자 이상</div>
					{*/}
					<div className={Style.btnGroup}>
						{recv_certnum && certchk_end == true && nicknm_end == true ? <button type="button" onClick={reg_go}>다음</button> : <button type="button" disabled>다음</button>}
					</div>
				</section>

			</div>
			
			<div className={cn(Dialog.dialog, `${popup?Dialog.on:''}`)}>
			</div>

			{/*DAUM POST POPUP*/}
			{popup && 
			<div className={cn(Style.popup, `${popup?Style.on:''}`)}>
				<button type="button" className={Style.popClose} onClick={handlepostClose}><img src={popClose} /></button>
				<Post company={enroll_company} setcompany={setEnroll_company} popclose={handlepostClose}></Post>
			</div>}

			<div className={cn(Style.dialog, `${isPopup?Style.on:''}`)}>
				<div className={Style.popCont}>
					<button type="button" className={Style.popClose} onClick={handleClose}><img src={popClose} /></button>
					<div className={Style.ptit}>본인확인 약관</div>
					<ul className={Style.agreeList}>
						<li>
							<label>
								<input type="checkbox" id="chk1" checked={popChecked1} onChange={(e) => checkHandled(e)} />
								<span>이용약관 필수 동의</span>
							</label>
						</li>
						<li>
							<label>
								<input type="checkbox" id="chk2" checked={popChecked2} onChange={(e) => checkHandled2(e)} />
								<span>개인정보 취급방침 필수 동의</span>
							</label>
						</li>
						<li>
							<label>
								<input type="checkbox" id="chk3" checked={popChecked3} onChange={(e) => checkHandled3(e)} />
								<span>위치기반 서비스 이용약관 필수 동의</span>
							</label>
						</li>
						<li>
							<label>
								<input type="checkbox" id="chk4" checked={popChecked4} onChange={(e) => checkHandled4(e)} />
								<span>마케팅 수신 선택 동의</span>
							</label>
						</li>
					</ul>
					<div className={Style.btnGroup}>
						<button type="button" onClick={onClickmember}>모두 동의하고 가입하기</button>
					</div>
				</div>
			</div>
		</>
	)
}

export default Index
