import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { actionCreators as userActions } from "./redux/modules/user";
import axios from "axios";
import { useNavigate } from "react-router-dom"
import { setToken } from "./services/TokenService";
import Spinner from "./Spinner";


const Auth = (props) => {
    //const dispatch = useDispatch();
    const navigate = useNavigate();

    let code = new URL(window.location.href).searchParams.get("code");

    /*React.useEffect(async () => {
        await dispatch(userActions.kakaoLogin(code));
    }, []);*/
    //console.log("1-"+code);
    let num = 0;
    React.useEffect(() => {
        
        async function fetchData() {
            //console.log("2-"+code);
            await axios({
                method: "POST",
                url: process.env.REACT_APP_KAKAO_AUTHCHK,
                mode: "cors",
            	credentials: "same-origin",
                data: {
                    code: code,
                    mode: "join"
                }
            })
            .then((res) => {
                //console.log(res.data.accessToken);
                //const ACCESS_TOKEN = res.data.accessToken;
                //토큰저장
                //setToken(res.data.accessToken);
                if(res.data.code == "200"){
                    navigate("/joinsns", { state: res.data.snsdata });
                    //window.alert("회원가입이 완료 되었습니다.");  
                    //window.location.href="/login";
                }else if(res.data.code == "300"){
                    window.alert("이미 가입된 회원입니다.");  
                    window.location.href="/login";
                }else if(res.data.code == "400"){
                    console.log('400');
                }
    
            }).catch((err) => {
                
                console.log("에러", err);
                window.alert("로그인에 실패하였습니다.");
                //history.replace("/"); //로그인실패 로그인화면으로 이동
    
            })
        }
        fetchData();
    }, []);

    return (
        <div><Spinner /></div>
    );

    /*return (
        <div>
            { code }
        </div>
    );*/
};

export default Auth;