import React, { useState } from 'react'
import axios from "axios";
import { useGoogleLogin } from '@react-oauth/google'
import { setToken } from '../services/TokenService';
import Google from '@/assets/image/ico/ico-sns-list02.svg'

const GoogleSNSLogin = () => {

  const googleSocialLogin = useGoogleLogin({
    scope: "email profile",
    onSuccess: //(codeResponse) => console.log(codeResponse),
      async (res) => {
        console.log(res.access_token);
        await axios({
          method: "post",
          url: process.env.REACT_APP_GOOGLE_AUTHCHK,
          data: { code: res.access_token, mode: "login" },
        })
        .then((response) => {
            //console.log(res);
            if(response.data.result === true){
                const token_data = {
                    access_token: response.data.access_token, 
                    token_type: response.data.token_type,
                    exp: response.data.exp
                }; 
                if(response.data.adchk === 'Y'){
                    token_data["adchk"] = "Y";
                }  
                setToken(JSON.stringify(token_data));
                alert("로그인 했습니다.");
                
                if(response.data.adchk === 'Y'){
                    window.location.href="/admin/main";
                }else if(response.data.adchk === 'N'){
                    window.location.href="/";
                }
            }else{
                if(response.data.msg === 'no member'){
                    alert("등록된 회원이 없습니다. 확인후 다시 로그인해주세요.");
                    window.location.reload();
                }else{
                    alert("로그인이 실패하였습니다.");
                    window.location.reload();
                }
            }

        })
        .catch((e) => console.log(e));
      },
    onError: (errorResponse) => {
      console.error(errorResponse);
    },
    //flow: 'auth-code',
  })

  
  return (
    <a href="#" onClick={() => googleSocialLogin()}><img src={Google} /></a>
  )
}


export default GoogleSNSLogin