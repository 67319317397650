import React from 'react'
import cn from 'classnames'

// css
import Style from './index.module.css'
import PagePrev from '@/assets/image/ico/ico-page-prev.svg'
import PageNext from '@/assets/image/ico/ico-page-next.svg'
import AdminIcon from '@/assets/image/ico/ico-access-list01.svg'
import ManagerIcon from '@/assets/image/ico/ico-access-list02.svg'
import UserIcon from '@/assets/image/ico/ico-access-list03.svg'
import SpaceHome from '@/assets/image/ico/ico-space-home.svg'


const ShareList = () => {

	return (
		<div className={Style.wrapper}>

			<section className={Style.spacedHeader}>
				<div className={Style.pageLoacation}>
					<div className={Style.cate}>@장안생활</div>
					<ul>
						<li><a href="#" className={Style.on}>대시보드</a></li>
						<li><a href="#">게시판</a></li>
					</ul>
				</div>
				<div className={Style.pageTitle}>
					<img src={SpaceHome} />
					<span>공유주택 공간</span>
				</div>
			</section>

			<div className={cn(Style.scrolls, Style.type01)}>
				<table className={cn(Style.webList)}>
					<colgroup>
						<col width="70" />
						<col width="120" />
						<col width="150" />
						<col width="" />
						<col width="180" />
						<col width="180" />
						<col width="180" />
					</colgroup>
					<tr>
						<th><input type="checkbox" /></th>
						<th>작성일</th>
						<th>공간타입</th>
						<th>공간구분</th>
						<th>평수</th>
						<th>상태</th>
						<th>거주자</th>
					</tr>
					<tr>
						<td><input type="checkbox" /></td>
						<td>2023.7.13</td>
            <td>개별거주공간</td>
						<td>장안생활_7층_개별거주공간_703호</td>
						<td>4.5평</td>
						<td>미입실</td>
						<td>이원희</td>
					</tr>
					<tr>
						<td><input type="checkbox" /></td>
						<td>2023.7.13</td>
						<td>개별거주공간</td>
						<td>장안생활_7층_개별거주공간_703호</td>
						<td>4.5평</td>
						<td>미입실</td>
						<td>이원희</td>
					</tr>
					<tr>
						<td><input type="checkbox" /></td>
						<td>2023.7.13</td>
						<td>개별거주공간</td>
						<td>장안생활_7층_개별거주공간_703호</td>
						<td>4.5평</td>
						<td>미입실</td>
						<td>이원희</td>
					</tr>
				</table>

				<ul className={cn(Style.mobList)}>
					<li>
						<div className={Style.chk}>
							<input type="checkbox" />
						</div>
						<table>
							<colgroup>
								<col width="80" />
								<col width="" />
							</colgroup>
							<tr>
								<th className={Style.al}>작성일</th>
								<td className={Style.al}>2023.7.13</td>
							</tr>
							<tr>
								<th className={Style.al}>공간타입</th>
								<td className={Style.al}>개별거주공간</td>
							</tr>
							<tr>
								<th className={Style.al}>공간구분</th>
								<td className={Style.al}>장안생활_7층_개별거주공간_703호</td>
							</tr>
							<tr>
								<th className={Style.al}>평수	</th>
								<td className={Style.al}>4.5평</td>
							</tr>
							<tr>
								<th className={Style.al}>상태</th>
								<td className={Style.al}>	미입실</td>
							</tr>
							<tr>
								<th className={Style.al}>거주자</th>
								<td className={Style.al}>이원희</td>
							</tr>
						</table>
					</li>
					<li>
						<div className={Style.chk}>
							<input type="checkbox" />
						</div>
						<table>
							<colgroup>
								<col width="80" />
								<col width="" />
							</colgroup>
							<tr>
								<th className={Style.al}>작성일</th>
								<td className={Style.al}>2023.7.13</td>
							</tr>
							<tr>
								<th className={Style.al}>공간타입</th>
								<td className={Style.al}>개별거주공간</td>
							</tr>
							<tr>
								<th className={Style.al}>공간구분</th>
								<td className={Style.al}>장안생활_7층_개별거주공간_703호</td>
							</tr>
							<tr>
								<th className={Style.al}>평수	</th>
								<td className={Style.al}>4.5평</td>
							</tr>
							<tr>
								<th className={Style.al}>상태</th>
								<td className={Style.al}>	미입실</td>
							</tr>
							<tr>
								<th className={Style.al}>거주자</th>
								<td className={Style.al}>이원희</td>
							</tr>
						</table>
					</li>
				</ul>
			</div>

			<div className={Style.paging}>
				<button type="button"><img src={PagePrev} /></button>
				<ul>
					<li><a href="#" className={Style.on}>1</a></li>
					<li><a href="#">2</a></li>
					<li><a href="#">3</a></li>
					<li><a href="#">4</a></li>
					<li><a href="#">5</a></li>
					<li><a href="#">6</a></li>
				</ul>
				<button type="button"><img src={PageNext} /></button>
			</div>

		</div>
	)
}

export default ShareList
