import React, {useState, useEffect} from 'react'
import axios from "axios";
import cn from 'classnames'

// css
import Style from './index.module.css'
import User from '@/assets/image/ico/ico-user01.svg'

import { getToken, removeToken } from "../../services/TokenService";

const Index = () => {

  const [memberType, SetMemberType] = useState("");
	const [memberName, SetMemberName] = useState("");
	const [memberHouse, SetMemberHouse] = useState("");
	const [memberIndate, SetMemberIndate] = useState("");
	const [memberOutdate, SetMemberOutdate] = useState("");
	const [memberHosu, SetMemberHosu] = useState("");
  const [memberFloor, SetMemberFloor] = useState("");
	const [memberManagerName, SetMemberManagerName] = useState("");
	const [memberHouseAddr, SetMemberHouseAddr] = useState("");
  const [myinfo, SetMyinfo] = useState([]);
  const [myhouse, SetMyHouse] = useState([]);

  const getBoard = async () => {

    const token = getToken();

    if(token !== null){
			const local_token = JSON.parse(token);
      axios({
        method: 'post',
        url: process.env.REACT_APP_API_MEMBERMYINFO,
        mode: "cors",
				credentials: "same-origin",
        headers: {
					Authorization: "Bearer " + local_token.access_token
				},
      }).then((res) => {
        
        if(res.data.code === 200){
					SetMemberType(res.data.profile.mtype);
          SetMyinfo(res.data.profile);
          SetMyHouse(res.data.house);
					SetMemberHouse(res.data.house.house_name);
					SetMemberIndate(res.data.house.indate);
					SetMemberOutdate(res.data.house.outdate);
					SetMemberHosu(res.data.house.hosu);
          SetMemberFloor(res.data.house.floor);
					SetMemberManagerName(res.data.house.manager_name);
					SetMemberHouseAddr(res.data.house.house_addr);
				}else if(res.data.code === 400){
					removeToken();
					window.location.href = "/";
				}

      }).catch((err) => {	
        console.log("에러", err);
      })
    }
  }

  useEffect(() => {
    getBoard();
  }, []);

	return (
		<div className={Style.wrapper}>

			<div className={Style.subTitle}>
				<img src={User} />
				<span>나의정보</span>
				<button type={'button'}>정보수정</button>
			</div>

			<div className={Style.detailSection}>
				<dl>
					<dt>
          {memberHouse ?
              <div className={Style.stit}>{myinfo.name} 님은 {memberHouse} 거주 중 입니다.</div>
              :
              <div className={Style.stit}>{myinfo.name} 님은 미거주 입니다.</div>
          }

					</dt>
					<dd>
            <ul>
              <li>
                <div className={Style.key}>닉네임</div>
                <div className={Style.value}>{myinfo.nickname}</div>
							</li>
              <li>
                <div className={Style.key}>나이/성별</div>
                <div className={Style.value}>{myinfo.age} / {myinfo.sex}</div>
							</li>
              <li>
                <div className={Style.key}>연락처</div>
                <div className={Style.value}>{myinfo.hptel}</div>
              </li>
              <li>
                <div className={Style.key}>이메일</div>
                <div className={Style.value}>{myinfo.email}</div>
              </li>
              <li>
                <div className={Style.key}>주소</div>
                <div className={Style.value}>{memberHouseAddr}</div>
              </li>
						</ul>
					</dd>
				</dl>
        
        {memberHouse && (
        <dl>
					<dt className={Style.type01}>
						<div className={Style.stit}>거주정보</div>
						<label>
							<input type="checkbox" checked />
							<span>거주상태</span>
						</label>
					</dt>
          <dd>
            <ul>
              <li>
                <div className={Style.value}>
									<span>계약기간 : {memberIndate} ~ {memberOutdate}</span>
								</div>
              </li>
              <li>
                <div className={Style.value}>거주기간 : 0일</div>
              </li>
              <li>
                <div className={Style.value}>{memberHouseAddr}</div>
              </li>
            </ul>
						<div className={Style.sitem}>
							<span>{memberHouse}</span>
              <span>{memberFloor}층</span>
              <span>거주자 개별공간</span>
              <span>{memberHosu}호</span>
						</div>

            <ul>
              <li>
                <div className={Style.value}>
                  <span>보증금 / 임대료 / 관리비 : {myhouse.deposit} / {myhouse.rentfee} / 0</span>
                </div>
              </li>
              <li>
                <div className={Style.value}>월세납부일 | 매월 {myhouse.paymentdate}일</div>
              </li>
              <li>
                <div className={Style.value}>
									<span>계좌정보 : {myhouse.bankname} {myhouse.banknumber} 예금주 : {myhouse.bankyename}</span>
									<button type={'button'}>납부기록</button>
								</div>
              </li>
            </ul>
          </dd>
        </dl>
        )}  

        {/*}
				<div className={Style.btnGroup}>
					<button type={'button'}>공간비용 계약정보 수정</button>
				</div>
        {*/}
			</div>

		</div>
	)
}

export default Index
