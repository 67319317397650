import {React, useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import cn from 'classnames'

// css
import Style from './index.module.css'
import HistoryIcon from '@/assets/image/ico/ico-time.svg'
import SpaceHome from '@/assets/image/ico/ico-space-home.svg'
import HomeIcon from '@/assets/image/ico/ico-home-green.svg'
import HistoryGreenIcon from '@/assets/image/ico/ico-history01.svg'
import HistoryAddIcon from '@/assets/image/ico/ico-history-add.svg'
import MainImg from '@/assets/image/etc/etc-main-list01.png'
import popClose from '@/assets/image/ico/ico-pop-close.svg'
import PagePrev from '@/assets/image/ico/ico-page-prev.svg'
import PageNext from '@/assets/image/ico/ico-page-next.svg'

import { MinusCircle, XCircle } from "lucide-react"
import { Button } from "@/components/ui/button"

import { getToken, removeToken } from "../../services/TokenService";

const Main = () => {
  const { idx } = useParams();
  //const { state } = useLocation();
  const [isPopup, setIsPopup] = useState(false);
  const [pyungsuInt, setPyungSuInt] = useState("1");
  const [pyungsu, setPyungSu] = useState("m²");
  const [detaildata, setDetailData] = useState([]);
  const [inputCount, setInputCount] = useState(0);
	const [replyContent, setReplyContent] = useState("");
  const [boardList, setBoardList] = useState([]);
  const [pageList, setPageList] = useState([]);
	const [loading, setLoading] = useState(false);
  const [listloading, setListLoading] = useState(false);
  const [pageNum, setPageNum] = useState("1");
	const [totalPageNum, setTotalPageNum] = useState("1");

  const [startdatey, setStartDatey] = useState("");
  const [startdatem, setStartDatem] = useState("");
  const [startdated, setStartDated] = useState("");
  const [enddatey, setEndDatey] = useState("");
  const [enddatem, setEndDatem] = useState("");
  const [enddated, setEndDated] = useState("");

  const now = new Date();
	const _ = require('lodash');
	const years = _.range(now.getFullYear(), now.getFullYear() - 5, -1);

  const handleOpen = () => {
    setIsPopup(true);
  }
  const handleClose = () => {
    setIsPopup(false);
  }

  const getBoardList = async (e) => {
		if (pageNum === e) return;
		
		if(e == undefined){
			e = "";	
		}

		axios({
			method: 'post',
			url: process.env.REACT_APP_API_HOUSEHISTORY_LIST,
			mode: "cors",
			credentials: "same-origin",
			data:{
				page: e,
        idx: `${idx}`
			},
		}).then((res) => {
			setBoardList(res.data.list); 

			const pngn = res.data.paging;
			const curPage = pngn.page;
			const curPage2 = pngn.cur_page;
			const pageRow = pngn.page_rows;
			const totalCnt = pngn.total_cnt;
			const totalPageCnt = pngn.num_page_cnt;
			const startPage = pngn.startpage;
			const endPage = pngn.endpage;
			const base_page = 1;

			const tmpPages = [];
			for (let i = startPage - 1; i <= endPage; i++) {
				if (i >= base_page){
					tmpPages.push(i);
				}
			}
			setPageList(tmpPages);
			setTotalPageNum(totalPageCnt);
			setLoading(false);
      setListLoading(true);
		}).catch((err) => {	
			console.log("에러", err);
		})
	}

  const onBtnClick = (event) => {
		let value = event.target.getAttribute('data');
		//console.log(value);
		/*setSearch({
		  ...search,
		  page: value,
		});*/
		setPageNum(value);
		//console.log(pageNum);
		getBoardList(value);
	};

  const pyungChange = () => {
    if(pyungsu === "평"){
      setPyungSu("m²");
      setPyungSuInt("1");
    }else{
      setPyungSu("평");
      setPyungSuInt("2");
    }
  }

  const detailView = () => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_API_HOUSEDETAIL,
      data:{
        seq: `${idx}`
      },
    }).then((res) => {
      setDetailData(res.data.data);
    }).catch((err) => {	
      console.log("에러", err);
    })
  }

  const onTextareaHandler = (e) => {
		setInputCount(
			e.target.value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
		);
		
		setReplyContent(e.target.value);

		if (inputCount > 1000){
			alert("최대 1000자까지 입력 가능합니다.");
			return false;
			let chk = replyContent.substring(0, 1000);
			setReplyContent(chk);
			//document.getElementsByName("reply").value(replyContent.substring(0, 100));
		}
	};

  function inttozero(e){
    let ze = "";
    if(e){
      if(e.length == 1){
        ze = "0"+e;
      }else{
        ze = e;
      }
    }
    return ze;
  }

  const onReg = () => {

		if(startdatey === ""){
			alert("년도를 선택해주세요.");
			return;
		}

    if(startdatem === ""){
			alert("월을 선택해주세요.");
			return;
		}

    if(startdated === ""){
			alert("일을 선택해주세요.");
			return;
		}

    let startdate = startdatey + inttozero(startdatem) + inttozero(startdated);
    let enddate = enddatey + inttozero(enddatem) + inttozero(enddated);

    if(enddate){
      if(parseInt(startdate) > parseInt(enddate)){
        alert("종료일은 시작일보다 이전일수 없습니다.");
        return false;
      }
    }

		if(replyContent  === ""){
			alert("내용을 입력해주세요.");
			return;
		}

    
		
		const token = getToken();

		if(token !== null){
			const local_token = JSON.parse(token);

			const formData = new FormData();
			formData.append('idx',`${idx}`)
      formData.append('startdatey',startdatey)
      formData.append('startdatem',startdatem)
      formData.append('startdated',startdated)
      formData.append('enddatey',enddatey)
      formData.append('enddatem',enddatem)
      formData.append('enddated',enddated)
			formData.append('content',replyContent)
			
			axios({
				method:'post',
				url:process.env.REACT_APP_API_HOUSEHISTORYINPUT,
				mode: "cors",
				credentials: "same-origin",
				headers: {
					"Content-Type": "multipart/form-data", 
					Authorization: "Bearer " + local_token.access_token,
				},
				data: formData,
			})
				.then((result)=>{
					if(result.data.code == 200){
						alert("등록 되었습니다.");
						window.location.reload();
					}else{
						console.log(result)
					}
			})
				.catch((error)=>{console.log('요청실패')
				console.log(error)  
			})
		}

	}

  const housegongganDel = () => {
    if(window.confirm("정말로 삭제하시겠습니까?")){
    
      axios({
        method:'post',
        url:process.env.REACT_APP_API_HOUSEHOSUDEL,
        mode: "cors",
        credentials: "same-origin",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data:{
          seq: `${idx}`
        },
      })
        .then((result)=>{
          if(result.data.code == 200){
            alert("삭제 되었습니다.");
            window.location.href="/admin/space/home";
          }else{
            console.log(result)
          }
      })
        .catch((error)=>{console.log('요청실패')
        console.log(error)  
      })
    }
  }

  const housereplydel = (e) => {
    if(window.confirm("정말로 히스토리를 삭제하시겠습니까?")){
    
      axios({
        method:'post',
        url:process.env.REACT_APP_API_HOUSEHOSUREPLYDEL,
        mode: "cors",
        credentials: "same-origin",
        data:{
          seq: e
        },
      })
        .then((result)=>{
          if(result.data.code == 200){
            alert("삭제 되었습니다.");
            window.location.reload();
          }else{
            console.log(result)
          }
      })
        .catch((error)=>{console.log('요청실패')
        console.log(error)  
      })
    }
  }

  useEffect(()=>{
    /*setTimeout(function(){
      handleOpen();
    },1000)*/
    detailView();
    getBoardList();
  },[]);
	return (
		<div className={Style.wrapper}>

			<section className={Style.spacedHeader}>
        <div className={Style.pageLoacation}>
          <div className={Style.cate}>@{detaildata.name}</div>
          {/*}
          <ul>
            <li><a href="#" className={Style.on}>대시보드</a></li>
            <li><a href="#">게시판</a></li>
          </ul>
          {*/}
        </div>
        <div className={Style.pageTitle}>
          <img src={SpaceHome} />
          <span><strong>[{detaildata.name}]</strong> 공간상세정보</span>
        </div>
      </section>

      <section className={Style.spaceView}>
			  <div className={cn(Style.optList, Style.type01)}>
          <ul>
            <li>
              <div className={Style.value}>{detaildata.name}</div>
            </li>
						<li>
							<div className={Style.value}>{detaildata.floor}층</div>
						</li>
						<li>
							<div className={Style.value}>{detaildata.gonggan}</div>
						</li>
						<li>
              {detaildata.gong_code === 'S1' ?
							<div className={Style.value}>{detaildata.r_hosu}호</div>
              :
              <div className={Style.value}>{detaildata.etctxt}</div>
              }
						</li>
          </ul>
        </div>

        <div className={Style.formList}>
          <ul>
            {/*}
            <li>
              <div className={Style.label}>공간평수</div>
              <div className={Style.formItem}>
                <input type="text" className={Style.inp} />
              </div>
            </li>
            <li>
              <div className={Style.label}>공간주요정보</div>
              <div className={Style.formItem}>
                <input type="text" className={Style.inp} />
              </div>
            </li>
            <li>
              <div className={Style.label}>공간주요정보</div>
              <div className={Style.formItem}>
                <input type="text" className={Style.inp} />
              </div>
            </li>
            {*/}

            <li>
              <div className={Style.label}>주택타입</div>
              <div className={Style.form}>
                <div className={Style.inpWrap}>
                  <input type="text" className={Style.inp} value={detaildata.jtype} />
                </div>
              </div>
            </li>
            <li>
              <div className={Style.label}>전용면적</div>
              <div className={Style.form}>
                <div className={Style.inpWrap}>
                  {pyungsuInt === "1" ? 
                  <input type="text" className={Style.inp} value={detaildata.mj1} />
                  :
                  <input type="text" className={Style.inp} value={detaildata.mj1_p} />
                  }
                  <span className={Style.unit}>{pyungsu} </span>
                </div>
              </div>
            </li>
            <li>
              <div className={Style.label}>공급면적</div>
              <div className={Style.form}>
                <div className={Style.inpWrap}>
                  {pyungsuInt === "1" ? 
                  <input type="text" className={Style.inp} value={detaildata.mj2} />
                  :
                  <input type="text" className={Style.inp} value={detaildata.mj2_p} />
                  }
                  <span className={Style.unit}>{pyungsu} </span>
                </div>
              </div>
            </li>
            <li>
              <div className={Style.label}>실거주 면적</div>
              <div className={Style.form}>
                <div className={Style.inpWrap}>
                  {pyungsuInt === "1" ? 
                  <input type="text" className={Style.inp} value={detaildata.mj3} />
                  :
                  <input type="text" className={Style.inp} value={detaildata.mj3_p} />
                  }
								  <span className={Style.unit}>{pyungsu} </span>
                </div>
              </div>
            </li>
            <li>
              <div className={Style.form}>
                <div className={Style.inpWrap}>
                  <a href="#;" onClick={pyungChange}>m² ↔ 평 전환</a>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <dl>
          <dt>
            <img src={HomeIcon} />
            <span>공간개요</span>
          </dt>
          <dd>
            <div className={Style.formItem}>
              <textarea className={Style.textarea} value={detaildata.gayo}>{detaildata.gayo}</textarea>
            </div>
            
            {/*}
            <div className={Style.photoList01}>
              <ul>
                <li>
                  <div className={Style.mpic} style={{background:`url(${MainImg})`}}>&nbsp;</div>
                </li>
								<li>
									<div className={Style.mpic} style={{background:`url(${MainImg})`}}>&nbsp;</div>
								</li>
								<li>
									<div className={Style.mpic} style={{background:`url(${MainImg})`}}>&nbsp;</div>
								</li>
								<li>
									<div className={Style.mpic} style={{background:`url(${MainImg})`}}>&nbsp;</div>
								</li>
              </ul>
            </div>
            {*/}

          </dd>
           
          <div className={Style.mbtn}>
            <button type="button" onClick={housegongganDel}>공간삭제</button>
          </div>

          <dt>
            <img src={HistoryGreenIcon} />
            <span>공간히스토리</span>
          </dt>
          <dd>
            <div className={Style.formItem}>
              <button onClick={handleOpen}>
                <span>히스토리 추가</span>
                <img src={HistoryAddIcon} />
              </button>
            </div>
            <ul>
              {listloading ?
                boardList.map((board) => (
                  <li>
                    <div className={Style.sdate}>{board.startdate}</div>
                    
                    <div className={Style.stit}>


                      <div className='flex flex-row justify-between items-center w-full'>
                          <div className='text-xl font-bold line-clamp-2'>{board.content}</div>
                          <div className='flex flex-row ml-2 gap-2'>
                            {/*}
                            <Button variant="sheet_icon" size="icon" className="p-1" onClick={()=>{}}>
                              수정
                            </Button>
                            {*/}
                            <Button variant="sheet_icon" size="icon" className="p-1" onClick={()=>{housereplydel(board.seq)}}>
                              삭제
                            </Button>
                          </div>
                      </div>


                    </div>
                    

                    {/*}
                    <div className={Style.stit}>
                      <div className='flex flex-row justify-between items-center w-full'>
                        <div className='text-xl font-bold line-clamp-2'>{board.content}</div>
                        <div className='flex flex-row ml-2'>
                          <button variant="icon" size="icon" className="p-1" onClick={()=>{}}>
                          <MinusCircle className='w-8 h-8 text-red-700 hover:text-accent-foreground'/>
                          </button>
                          <button variant="icon" size="icon" className="p-1" onClick={()=>{}}>
                          <XCircle className='w-8 h-8 text-red-700 hover:text-accent-foreground'/>
                          </button>
                        </div>
                      </div>
                    </div>
                    {*/}

                  </li>
                ))
              :""
              }
                             
            </ul>
          </dd>
        </dl>

        <div className={Style.paging}>
          <button type="button"><img src={PagePrev} /></button>
          <ul>
            {pageList.map((page, index) => (
                {...pageNum == page ? 
                <li key={index}><a href="#" className={Style.on} onClick={onBtnClick} data={page}>{page}</a></li>
                :
                <li key={index}><a href="#" onClick={onBtnClick} data={page}>{page}</a></li>
                }
            ))}
          </ul>
          <button type="button"><img src={PageNext} /></button>
        </div>
			
			</section>


      <div className={cn(Style.dialog, `${isPopup?Style.on:''}`)}>
        <div className={Style.popCont}>
          <button type="button" className={Style.popClose} onClick={handleClose}><img src={popClose} /></button>
          <div className={Style.ptit}>
            <img src={HistoryIcon} />
            <strong>히스토리 작성</strong>
          </div>
          <div className={Style.optWrap}>
            <div className={Style.dateWrap}>
							<select className={Style.select} value={startdatey} onChange={(e) => setStartDatey(e.target.value)}>
                <option value="">년</option>
                {
                  years.map((option) => (
                    <option key={option} value={option}>{option}년</option>
                  ))
                }
							</select>
							<select className={Style.select} value={startdatem} onChange={(e) => setStartDatem(e.target.value)}>
                <option value="">월</option>
                {
                  Array(12).fill(0).map((_, i) => (
                    <option key={i + 1} value={i + 1}>{i + 1}월</option>
                  ))
                }
							</select>
							<select className={Style.select} value={startdated} onChange={(e) => setStartDated(e.target.value)}>
                <option value="">일</option>
                {
                  Array(31).fill(0).map((_, i) => (
                    <option key={i + 1} value={i + 1}>{i + 1}일</option>
                  ))
                }
							</select>
            </div>
            <div className={Style.e01}>~</div>
						<div className={Style.dateWrap}>
							<select className={Style.select} value={enddatey} onChange={(e) => setEndDatey(e.target.value)}>
                <option value="">년</option>
                {
                  years.map((option) => (
                    <option key={option} value={option}>{option}년</option>
                  ))
                }
							</select>
							<select className={Style.select} value={enddatem} onChange={(e) => setEndDatem(e.target.value)}>
                <option value="">월</option>
                {
                  Array(12).fill(0).map((_, i) => (
                    <option key={i + 1} value={i + 1}>{i + 1}월</option>
                  ))
                }
							</select>
							<select className={Style.select} value={enddated} onChange={(e) => setEndDated(e.target.value)}>
                <option value="">일</option>
                {
                  Array(31).fill(0).map((_, i) => (
                    <option key={i + 1} value={i + 1}>{i + 1}일</option>
                  ))
                }
							</select>
						</div>
          </div>
          <div className={Style.textareaWrap}>
            <div className={Style.cnt}>{inputCount}/1,000</div>
            <textarea className={Style.textarea} name="reply" onChange={onTextareaHandler} maxLength={1000}>&nbsp;</textarea>
          </div>
          <div className={Style.btnGroup}>
            <button type="button" onClick={onReg}>제출하기</button>
          </div>
        </div>
      </div>

		</div>
	)
}

export default Main
