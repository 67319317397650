import React from 'react'
import cn from 'classnames'

// css
import Style from './index.module.css'
import ProductImg from '@/assets/image/etc/etc-product01.png'

const Index = () => {
	return (
		<div className={Style.wrapper}>

            <div className={Style.formWrap}>

				<div className={Style.spic} style={{backgroundImage:`url(${ProductImg})`}}></div>
				<div className={Style.formRow}>
                    <div className={Style.formItem}>
                        <div className={Style.label}>주택명</div>
                        <input type="text" className={Style.inp} />
                    </div>
                </div>
				<div className={Style.formRow}>
					<div className={Style.formItem}>
						<div className={Style.label}>층수</div>
						<input type="text" className={Style.inp} />
					</div>
					<div className={Style.formItem}>
						<div className={Style.label}>거주호수</div>
						<input type="text" className={cn(Style.inp, Style.s01)} />
					</div>
				</div>
				<div className={Style.formRow}>
					<div className={Style.formItem}>
						<div className={Style.label}>주소(기본)</div>
						<input type="text" className={Style.inp} />
					</div>
				</div>
				<div className={cn(Style.formRow, Style.t01)}>
					<div className={Style.formItem}>
						<div className={Style.label}>상세</div>
						<input type="text" className={Style.inp} />
					</div>
				</div>
				<div className={Style.formRow}>
					<div className={Style.formItem}>
						<div className={Style.label}>매니저 이름</div>
						<input type="text" className={Style.inp} />
					</div>
				</div>
				<div className={Style.formRow}>
					<div className={Style.formItem}>
						<div className={Style.label}>주택정보</div>
						<input type="text" className={Style.inp} />
						<input type="text" className={Style.inp} />
					</div>
				</div>
				<div className={Style.formRow}>
					<div className={Style.formItem}>
						<div className={Style.label}>E - mail</div>
						<input type="text" className={Style.inp} />
						<input type="text" className={Style.inp} />
					</div>
				</div>

				<div className={Style.btnGroup}>
					<button type="button" className={Style.btnCancel}>취소</button>
					<button type="button" className={Style.btnEdit}>수정</button>
				</div>

            </div>

		</div>
	)
}

export default Index
