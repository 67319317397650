import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"

import { Send, Pencil, Trash, Circle, X } from "lucide-react"
import { getDateString } from "@/components/lib/date"
import { getNumberString } from "@/components/lib/number"

export const getColumns = (onUserInfoButtonClicked) => {
  var columns;

  columns = [
    {
      accessorKey: "sh_name",
      header: () => <div className="text-left">주택명</div>,
      cell: ({ row }) => <div>{row.getValue("sh_name")}</div>,
    },
    {
      accessorKey: "hs_hosu",
      header: () => <div className="text-left">호수</div>,
      cell: ({ row }) => <div>{row.getValue("hs_hosu")}</div>,
    },
    {
      accessorKey: "hs_rent_fee",
      header: () => <div className="text-center">월세</div>,
      cell: ({ row }) => <div className="text-center">{getNumberString(row.getValue("hs_rent_fee"))}</div>,
    },
    {
      accessorKey: "hs_paymentdate",
      header: () => <div className="text-center">납부일</div>,
      cell: ({ row }) => <div className="text-center">{row.getValue("hs_paymentdate")}</div>,
    },
    {
      accessorKey: "hs_payment",
      header: () => <div className="text-center">*오늘 납부액</div>,
      cell: ({ row }) => <div className="text-center">{row.getValue("hs_payment")}</div>,
    },
    {
      id: "go_to_user_info",
      enableHiding: false,
      cell: ({ row }) => {
        const rentee = row.original
  
        return (
          <div className="flex justify-end align-middle">
            <Button variant="ghost" onClick={()=>{onUserInfoButtonClicked(rentee.mem_seq)}}>
              유저 정보
            </Button>
         </div>
        )
      },
    },
  ]

  return columns;
}