import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ColorRing } from 'react-loader-spinner';
import cn from 'classnames'
import ImageDropBox from '../../components/ImageDropBox';
import ImageUploadPopup from '../../components/ImageUploadPopUp';
import Zoom from 'react-medium-image-zoom'
import '../../components/imageZoomStyles.css'
import Resizer from "react-image-file-resizer";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../components/imageSlider.css";
import { getToken } from "../../services/TokenService";

// css
import Style from './index.module.css'
import PagePrev from '@/assets/image/ico/ico-page-prev.svg'
import PageNext from '@/assets/image/ico/ico-page-next.svg'
import AdminIcon from '@/assets/image/ico/ico-access-list01.svg'
import PhotoImg01 from '@/assets/image/etc/etc-photo-list01.png'
import RemoveIcon from '@/assets/image/ico/ico-remove01.svg'
import SpaceReport from '@/assets/image/ico/ico-report.svg'
import SpaceReq from '@/assets/image/ico/ico-req02.svg'
import ReqImg from '@/assets/image/etc/etc-req01.jpg'


const ReportRegister = () => {

	const { id } = useParams();
	const navigate = useNavigate();

	const [requestData, setRequestData] = useState({});
	const [loading, setLoading] = useState(true);
	const [previewImgUrls, setPreviewImgUrls] = useState([]);
	const [inputContent, setInputContent] = useState("");
	const [isPopUpShowing, setIsPopUpShowing] = useState(false);
	const [requestImgUrlList, setRequestImgUrlList] = useState([]);

	const getRequestData = async () => {
		const resp = await (await axios.get(process.env.REACT_APP_API_REPORT_REGISTER_START + `/${id}`)).data;
		setRequestData(resp);
		const newPreviewImgUrls = [...resp.report_imgurllist, ...resp.request_imgurllist];
		setPreviewImgUrls(newPreviewImgUrls);
		setRequestImgUrlList([...resp.request_imgurllist]);
		setLoading(false);
	};

	useEffect(() => {
		setLoading(true);
		getRequestData();
	  }, []);

	const resizeFile = (file) =>
		new Promise((resolve) => {
			Resizer.imageFileResizer( file, 1920, 1920, "JPEG", 90, 0,
				(image) => {
					resolve(image);
				},
				"file"
			);
	});
  
	const onImagesDropped = async (acceptedFiles) => {
		await Promise.all(
			acceptedFiles.map((image) => {
				return resizeFile(image);
			})
		).then(async (uploadBranchImages) => {
			const formData = new FormData();
			uploadBranchImages.forEach((file) => {
				formData.append('attach[]', file);
			});
			formData.append('seq', id);
			for (var key of formData.entries()) {
				console.log(key[0] + ', ' + key[1]);
			}
			
			const access_token = (JSON.parse(getToken())).access_token;
			await axios({
				method: 'post',
				url: process.env.REACT_APP_API_IMAGES_UPLOAD,
				data: formData,
				mode: "cors",
				credentials: "same-origin",
				headers: {
					Authorization: "Bearer " + access_token
				},
			}).then((res) => {
				if(res.data.code === 200){
					console.log(res.data);
					setPreviewImgUrls((prevState) => [...res.data.imgurllist, ...prevState]);
				}else if(res.data.code === 400){
					
				}
			}).catch((err) => {	
				console.log("에러", err);
			})
		});
	};

	const responsive = {
		superLargeDesktop: {
		  breakpoint: { max: 4000, min: 3000 },
		  items: 5
		},
		desktop: {
		  breakpoint: { max: 3000, min: 1024 },
		  items: 4
		},
		tablet: {
		  breakpoint: { max: 1024, min: 464 },
		  items: 2
		},
		mobile: {
		  breakpoint: { max: 464, min: 0 },
		  items: 2,
		}
	  };

	const onDeleteButtonClicked = async (params, event) => {
		const index = params[0];
		var isItImageOfRequest = false;
		console.log(index);
		console.log(requestImgUrlList.length);
		if(index >= previewImgUrls.length - requestImgUrlList.length){
			console.log("index > previewImgUrls.length - requestImgUrlList.length");
			requestImgUrlList.splice(index, 1);
			setRequestImgUrlList([...requestImgUrlList]);
			isItImageOfRequest = true;
		}
		console.log(isItImageOfRequest);
		const deletedImgUrls = previewImgUrls.splice(index, 1);
		const newPreviewImgUrls = [...previewImgUrls];
		setPreviewImgUrls(newPreviewImgUrls);

		const access_token = (JSON.parse(getToken())).access_token;
		try {
			axios({
				method: 'post',
				url: isItImageOfRequest
					? process.env.REACT_APP_API_REQUEST_SINGLE_IMAGE_DELETE
					: process.env.REACT_APP_API_SINGLE_IMAGE_DELETE,
				data: {seq: id, idx: deletedImgUrls[0].idx},
				mode: "cors",
            	credentials: "same-origin",
				headers: {
					Authorization: "Bearer " + access_token
				},
			}).then((res) => {
				if(res.data.code === 200){
					console.log(deletedImgUrls[0].idx + ' ' + deletedImgUrls[0].imgurl + "deleted");
				}else if(res.data.code === 400){
					
				}

			}).catch((err) => {	
				console.log("에러", err);
			})
		} catch (err){
			console.error('Image Delete error:', err);
		}
		
	};

	const onSubmitCliked = async (e) => {
		e.preventDefault();
		setLoading(true);

		try {
			const res = await axios.post(process.env.REACT_APP_API_REPORT_REGISTER + `/${id}`, 
							{reportcontent: inputContent, seq: id});
			console.log('Report Submit successful:', res.data);
			navigate("/admin/space/reqList");
		} catch (err){
			console.error('Report Submit error:', err);
		}
	};

	return (
		<>
			{ loading ?  <div style={{zIndex:'500', position: 'absolute', width: '80px', height: '80px', top: 'calc( 50% - 100px )', left: 'calc( 50% )'}}>
				<ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}/></div> : (

				<div className={Style.wrapper}>

					<section className={Style.spacedHeader}>

						<div className={Style.pageTitle}>
							<img src={SpaceReport} />
							<span>수리 보고서</span>
						</div>
					</section>

					<section className={Style.reqView}>
						<div className={Style.reqHeader}>
							<div className={Style.mtit}>{requestData.subject}</div>
							<div className={Style.mdate}>{`담당 : ${requestData.engineer} ${requestData.regdate}`}</div>
						</div>
						{ 
							requestData.communicationInfo &&
							<div className={Style.reqInfo}>
								<ul>
									<li>{`${requestData.communicationInfo.roominfo} ${requestData.communicationInfo.username}`}</li>
									<li>{requestData.communicationInfo.type}</li>
									<li>{requestData.communicationInfo.subject}</li>
								</ul>
							</div>
						}
					
						<div className={Style.reqCont}>
							{ 
								requestData.communicationInfo &&
								<div className={Style.mcont}>
									<div className={Style.mdesc}>{requestData.communicationinfo.content}</div>
									
									{
									requestData.communicationInfo.imgurllist && 
										<div className={Style.mpic}>
											<ul>
												{requestData.communicationInfo.imgurllist.map((img) => 
													<li>
														<div className={Style.mpic} style={{background:`url(${img})`}}>&nbsp;</div>
													</li>
												)}
											</ul>
										</div>
									}
								</div>
							}

							<div className={Style.minfo}>
								<ul>
									<li>
										<div className={Style.key}>건물명</div>
										<div className={Style.value}>@{requestData.buildingname}</div>
									</li>
									<li>
										<div className={Style.key}>층수</div>
										<div className={Style.value}>{requestData.floor}</div>
									</li>
									<li>
										<div className={Style.key}>세부위치</div>
										<div className={Style.value}>{requestData.detailedlocation}</div>
									</li>
								</ul>
							</div>

							<div className={Style.reqBar}>
								<div className={Style.rtit}>
									<img src={SpaceReport} />
									<span>시설관리 요청개요</span>
								</div>
								<div className={Style.radio}>
									<label>
										<input type="radio" name="radio" checked={requestData.priority === "0"}/>
										<span>일반</span>
									</label>
									<label>
										<input type="radio" name="radio" checked={requestData.priority === "1"}/>
										<span>긴급</span>
									</label>
								</div>
							</div>
							<textarea className={Style.overview}>{requestData.requestcontent}
							</textarea>

							<div className={cn(Style.photoHeader, Style.sw)}>
								<div className={Style.status}>수리완료</div>
								<button type="button" onClick={ ()=>setIsPopUpShowing(true) }>
									<span>사진삭제 및 변경</span>
									<img src={RemoveIcon} />
								</button>
							</div>
							{isPopUpShowing && <ImageUploadPopup isPopUpShowing={isPopUpShowing} setIsPopUpShowing={setIsPopUpShowing} previewImgUrls={previewImgUrls} onImagesDropped={onImagesDropped} onDeleteButtonClicked={onDeleteButtonClicked} isObject={true}/>}

							{ previewImgUrls.length === 0 ?
								<div className={Style.photoList}>
									<div className={Style.photoUpload}>
										<ImageDropBox onDrop={onImagesDropped}/>
									</div>
								</div>
								:
								<Carousel responsive={responsive} swipeable={true} draggable={true}>
									{previewImgUrls.map((image, index) => (
											<div className="slider">
												<Zoom>
													<img src={image.imgurl}/>
												</Zoom>
												<button type="button" onClick={(e)=>onDeleteButtonClicked([index], e)}>X</button>
											</div>
										))}
								</Carousel>
							}

							<div className={Style.reqBar}>
								<div className={Style.rtit}>
									<img src={SpaceReq} />
									<span>시설보수 상세내용</span>
								</div>
							</div>
							<textarea className={Style.overview} onChange={(e)=>setInputContent(e.target.value)} value={inputContent}> </textarea>

							<div className={Style.mbtn}>
								<button type="button" onClick={onSubmitCliked}>보고서 등록</button>
							</div>
						</div>
					</section>


				</div>

		)
		}
		</>
	)
}

export default ReportRegister
