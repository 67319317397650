import {useState, useEffect, useContext, useRef} from 'react'
import { useNavigate } from 'react-router-dom';

import PushTemplateEditor from "@/components/message_template/push"
import InAppTemplateEditor from '@/components/message_template/inapp'
import EmailTemplateEditor from '@/components/message_template/email'
import SMSTemplateEditor from '@/components/message_template/sms'
import AlimtalkTemplateEditor from '@/components/message_template/alimtalk'

import { Button } from '@/components/ui/button'
import { useQuery, useMutation } from "@tanstack/react-query";
import Axios from "@/components/lib/axios";
import { SendMessageContext } from '../index'

const TemplateLoader = ({type, selectedTemplateId}) => {
	const navigate = useNavigate();

	const [inputSubject, setInputSubject] = useState("");
	const [inputContent, setInputContent] = useState("");
	const [inputLinkedPageUrl, setInputLinkedPageUrl] = useState(""); // InApp Only
	const [smsType, setSmsType] = useState("SMS"); // SMS, Alimtalk
	const [imageOne, setImageOne] = useState("");
	const [imageTwo, setImageTwo] = useState("");
	const [imageThree, setImageThree] = useState("");
	// ----------------------------------------- Alimtalk From Here
	const [alimtalkType, setAlimtalkType] = useState("BA");
	const [emphasisType, setEmphasisType] = useState("NONE");
	const [emphasisTextMainTitle, setEmphasisTextMainTitle] = useState("");
	const [emphasisTextSubTitle, setEmphasisTextSubTitle] = useState("");
	const [emphasisImageFileName, setEmphasisImageFileName] = useState("선택된 파일 없음");
	const [emphasisImageFileUrl, setEmphasisImageFileUrl] = useState("");
	const [alimtalkButtons, setAlimtalkButtons] = useState([]);
	const [sendSmsWhenFailed, setSendSmsWhenFailed] = useState("Y");
	const [inputSMSSubject, setInputSMSSubject] = useState("");
	const [inputSMSContent, setInputSMSContent] = useState("");
	const [emailHTMLContent, setEmailHTMLContent] = useState("");
	const { setAutoInputCustomVariableList } = useContext(SendMessageContext)

	const { isLoading: isLoadingAutoVariableNames, data: autoVariableNamesFromDB } = useQuery({queryKey:["auto_variable_name"], queryFn: () =>
		Axios.get('/api_message/common/auto_variables').then((res) => {
			return res.data;
		})});

	const { isLoading, error, data } = useQuery(
		{
		  queryKey: ["message_template", type, selectedTemplateId], 
		  queryFn: () =>
			Axios.get(`/api_message/template/${type}/${selectedTemplateId}`).then((res) => {
				console.log(res.data);
			  return res.data;
			}),
		  initialData: ""
		}
	  );
	
	// ----------------------------------------- EMAIL From Here
	const emailEditorRef = useRef(null);

	const onReady = () => {
		// editor is ready
		const editorRef = emailEditorRef.current;
		if (editorRef !== null) {
	
			editorRef.editor.registerCallback('image', function(file, done) {
				var data = new FormData()
				data.append('image', file.attachments[0])
				
				fetch('https://siso.live:5010/api_message/common/image', {
					method: 'POST',
					headers: {
					'Accept': 'application/json'
					},
					body: data
				}).then(response => {
					// Make sure the response was valid
					if (response.status >= 200 && response.status < 300) {
						return response
					} else {
						var error = new Error(response.statusText)
						error.response = response
						throw error
					}
				}).then(response => {
					return response.json()
				}).then(data => {
					// Pass the URL back to Unlayer to mark this upload as completed
					done({ progress: 100, url: data.imgUrl })
				})
			})

			if(inputContent !== "") editorRef.editor.loadDesign(inputContent);
		}
	}
	// ----------------------------------------- EMAIL

	useEffect(() => {
		if(data !== ""){
			if(type === "push"){
				setInputSubject(data.subject);
				setInputContent(data.content);
				data.img_url ? setImageOne(data.img_url) : setImageOne("");
			}else if(type === "inapp"){
				setInputSubject(data.subject);
				setInputContent(data.content);
				data.link_url ? setInputLinkedPageUrl(data.link_url) : setInputLinkedPageUrl("");
			}else if(type === "email"){
				setInputSubject(data.subject);
				setInputContent(JSON.parse(data.content))
				setEmailHTMLContent(data.html)
			}else if(type === "sms"){
				setSmsType(data.type)
				setInputSubject(data.subject);
				setInputContent(data.content);
				data.img_url1 ? setImageOne(data.img_url1) : setImageOne("");
				data.img_url2 ? setImageTwo(data.img_url2) : setImageTwo("");
				data.img_url3 ? setImageThree(data.img_url3) : setImageThree("");
			}else if(type === "alimtalk"){
				setInputSubject(data.alimtalk_subject);
				setInputContent(data.alimtalk_content);
				setAlimtalkType(data.alimtalk_template_type)
				setEmphasisType(data.alimtalk_template_emphasis_type)
				data.alimtalk_template_emphasis_text_main_title 
					? setEmphasisTextMainTitle(data.alimtalk_template_emphasis_text_main_title) : setEmphasisTextMainTitle("");
				data.alimtalk_template_emphasis_text_sub_title 
					? setEmphasisTextSubTitle(data.alimtalk_template_emphasis_text_sub_title) : setEmphasisTextSubTitle("");
				data.alimtalk_template_emphasis_image_file_name
					? setEmphasisImageFileName(data.alimtalk_template_emphasis_image_file_name) : setEmphasisImageFileName("선택된 파일 없음");
				data.alimtalk_template_emphasis_image_file_url 
					? setEmphasisImageFileUrl(data.alimtalk_template_emphasis_image_file_url) : setEmphasisImageFileUrl("");
				data.alimtalk_template_buttons ? setAlimtalkButtons(JSON.parse(data.alimtalk_template_buttons)) : setAlimtalkButtons([])
				setSendSmsWhenFailed(data.send_sms_when_failed);
				data.sms_type ? setSmsType(data.sms_type) : setSmsType("");
				data.sms_subject ? setInputSMSSubject(data.sms_subject) : setInputSMSSubject("");
				data.sms_content ? setInputSMSContent(data.sms_content) : setInputSMSContent("");
				data.sms_img_url1 ? setImageOne(data.sms_img_url1) : setImageOne("");
				data.sms_img_url2 ? setImageTwo(data.sms_img_url2) : setImageTwo("");
				data.sms_img_url3 ? setImageThree(data.sms_img_url3) : setImageThree("");
			}

			if(type !== "alimtalk"){
				const regexp = /#\{([^{}]+)\}/g;
				const wordsArray = data.content.match(regexp);
				if(wordsArray !== null && wordsArray.length > 0){
					const auto = [];
					wordsArray.forEach(
						(word, index) => {
							const found = autoVariableNamesFromDB.find((element) => element.variable_name === word )
							if(found === undefined){
								auto.push({
									id: index+1,
									variable_name: word,
									is_auto_variable: false,
									is_same_content: false,
									same_content: ""
								})
							}else{
								auto.push({
									id: index+1,
									variable_name: word,
									is_auto_variable: true,
								})
							}
						}
					);
					setAutoInputCustomVariableList(auto)
				}
			}else{
				const auto = [];
				const regexp = /#\{([^{}]+)\}/g;
				const wordsArrayForAlimtalk = data.alimtalk_content.match(regexp);
				console.log("wordsArrayForAlimtalk" + wordsArrayForAlimtalk)
				if(wordsArrayForAlimtalk !== null && wordsArrayForAlimtalk.length > 0){
					wordsArrayForAlimtalk.forEach(
						(word, index) => {
							const found = autoVariableNamesFromDB.find((element) => element.variable_name === word )
							if(found === undefined){
								auto.push({
									id: index+1,
									variable_name: word,
									is_auto_variable: false,
									is_same_content: false,
									same_content: ""
								})
							}else{
								auto.push({
									id: index+1,
									variable_name: word,
									is_auto_variable: true,
								})
							}
						}
					);
				}
				// if(data.send_sms_when_failed === 'Y'){
				// 	const wordsArrayForSMS = data.sms_content.match(regexp);
				// 	if(wordsArrayForSMS !== null && wordsArrayForSMS.length > 0){
				// 		wordsArrayForSMS.forEach(
				// 			(word, index) => {
				// 				const found = autoVariableNamesFromDB.find((element) => element.variable_name === word )
				// 				if(found === undefined){
				// 					auto.push({
				// 						id: index+1,
				// 						variable_name: word,
				// 						is_auto_variable: false,
				// 						is_same_content: false,
				// 						same_content: ""
				// 					})
				// 				}else{
				// 					auto.push({
				// 						id: index+1,
				// 						variable_name: word,
				// 						is_auto_variable: true,
				// 					})
				// 				}
				// 			}
				// 		);
				// 	}
				// }
				setAutoInputCustomVariableList(auto)
			}
		}
	}, [data]);

	useEffect(() => {
		if(inputContent !== ""){
			if(type === "email" && emailEditorRef.current !== null && emailEditorRef.current.editor !== null){
				emailEditorRef.current.editor.loadDesign(inputContent);
			}
		}
	}, [inputContent, emailEditorRef]);

	/*
	useEffect(() => {
		if(data !== ""){
			if(type === "email" && emailEditorRef.current !== null && emailEditorRef.current.editor !== null){
				setInputSubject(data.subject);
				const design = JSON.parse(data.content)
				emailEditorRef.current.editor.loadDesign(design);
				setEmailHTMLContent(data.html)
			}
		}
	}, [data, emailEditorRef]);
	*/
	
	const getEditor = (type) => {
		switch (type) {
			case 'push':
				return <PushTemplateEditor inputSubject={inputSubject} setInputSubject={setInputSubject} inputContent={inputContent}
				setInputContent={setInputContent} imageOne={imageOne} setImageOne={setImageOne}/>
			case 'inapp':
				return <InAppTemplateEditor inputSubject={inputSubject} setInputSubject={setInputSubject} inputContent={inputContent}
				setInputContent={setInputContent} inputLinkedPageUrl={inputLinkedPageUrl} setInputLinkedPageUrl={setInputLinkedPageUrl}/>
			case 'email':
				return <EmailTemplateEditor inputSubject={inputSubject} setInputSubject={setInputSubject} inputContent={inputContent}
				setInputContent={setInputContent} emailEditorRef={emailEditorRef} onReady={onReady}/>
			case 'sms':
				return <SMSTemplateEditor inputSubject={inputSubject} setInputSubject={setInputSubject} inputContent={inputContent}
				setInputContent={setInputContent} smsType={smsType} setSmsType={setSmsType} imageOne={imageOne} setImageOne={setImageOne}
				imageTwo={imageTwo} setImageTwo={setImageTwo} imageThree={imageThree} setImageThree={setImageThree}/>
			case 'alimtalk':
				return <AlimtalkTemplateEditor inputSubject={inputSubject} setInputSubject={setInputSubject} inputContent={inputContent}
				setInputContent={setInputContent} alimtalkType={alimtalkType} setAlimtalkType={setAlimtalkType} emphasisType={emphasisType}
				setEmphasisType={setEmphasisType} emphasisTextMainTitle={emphasisTextMainTitle} setEmphasisTextMainTitle={setEmphasisTextMainTitle}
				emphasisTextSubTitle={emphasisTextSubTitle} setEmphasisTextSubTitle={setEmphasisTextSubTitle} emphasisImageFileName={emphasisImageFileName}
				setEmphasisImageFileName={setEmphasisImageFileName} emphasisImageFileUrl={emphasisImageFileUrl} setEmphasisImageFileUrl={setEmphasisImageFileUrl}
				alimtalkButtons={alimtalkButtons} setAlimtalkButtons={setAlimtalkButtons} sendSmsWhenFailed={sendSmsWhenFailed}
				setSendSmsWhenFailed={setSendSmsWhenFailed} smsType={smsType} setSmsType={setSmsType} inputSMSSubject={inputSMSSubject}
				setInputSMSSubject={setInputSMSSubject} inputSMSContent={inputSMSContent} setInputSMSContent={setInputSMSContent} imageOne={imageOne}
				setImageOne={setImageOne} imageTwo={imageTwo} setImageTwo={setImageTwo} imageThree={imageThree} setImageThree={setImageThree}
				/>
		  }
	}


	const getEmailTemplateData = () => {
		return new Promise((resolve, reject) => {
			if (emailEditorRef.current !== null) {
				emailEditorRef.current.editor.exportHtml((data) => {
					resolve(data);
				});
			}
		});
	}
	  
	return (
		<>
			{getEditor(type)}
		</>
	)
}

export default TemplateLoader
