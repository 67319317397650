import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"

import { Send, Pencil, Trash, Circle, X } from "lucide-react"
import { Link, useParams, useNavigate } from "react-router-dom";

export const getColumns = (type) => {
  var columns;

  columns = [
    {
      accessorKey: "target",
      header: () => <div className="text-left">전송 대상</div>,
      cell: ({ row }) => <div>{row.getValue("target")}</div>,
    },
    {
      accessorKey: "subject",
      header: () => <div className="text-left">메시지 제목</div>,
      cell: ({ row }) => <Link to={`/admin/messages/sendHistory/${type}?mode=view&id=${row.original.id}`}><div>{row.getValue("subject")}</div></Link>,
    },
    {
      accessorKey: "sent_on",
      header: () => <div className="text-center">전송 시간</div>,
      cell: ({ row }) => <div className="text-center">{row.getValue("sent_on")}</div>,
    }
  ]

  return columns;
}