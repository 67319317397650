import React, { useState, useEffect } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import { GreenLabel } from '@/components/ui/label';
import { Button } from '@/components/ui/button';
import {
	AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle,
} from "@/components/ui/view-dialog"
import { ArrowBigLeft, ArrowBigRight } from 'lucide-react'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PdfViewerDialog = ({isOpenedDialog, setIsOpenedDialog, file}) => {
  const [totalNumPages, setTotalNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [renderNavButtons, setRenderNavButtons] = useState(false);

  const changePage = (offset) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }
  const previousPage = () => { changePage(-1); }
  const nextPage = () => { changePage(+1); }

  function onDocumentLoadSuccess({ numPages }){
    setTotalNumPages(numPages);
    if(numPages > 1) setRenderNavButtons(true);
    setPageNumber(1);
  }

  return (
	<>
	{
		file !== undefined &&
		<AlertDialog open={isOpenedDialog} onOpenChange={setIsOpenedDialog}>
			<AlertDialogContent>
				<AlertDialogHeader>
					<Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
						<Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false}/>
					</Document>
					
					{
						renderNavButtons &&
						<div className="flex flex-row items-center gap-4 self-center">
							<Button disabled={pageNumber <= 1} onClick={previousPage} variant='icon' size='icon'>
								<ArrowBigLeft className='w-8 h-8'/>
							</Button>
							<GreenLabel className="text-xl">{pageNumber} / {totalNumPages}</GreenLabel>
							<Button disabled={pageNumber === totalNumPages} onClick={nextPage} variant='icon' size='icon'>
								<ArrowBigRight className='w-8 h-8'/>
							</Button>
						</div>
					}
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel onClick={()=>{}}>닫기</AlertDialogCancel>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	}
    </>
  );
}

export default PdfViewerDialog;