
import { createAction, handleActions } from "redux-actions";
import { produce } from "immer";
import axios from "axios";
import { setToken } from "../../services/TokenService";

const initialState = {
    code: null,
    user: null,
    is_login: false
};

const kakaoLogin = (code) => {
    console.log(code);
    //dispatch, getState, { history }

    return function (dispatch, getState) {
        axios({
            method: "POST",
            url: `/ym/oauth/callback/kakao`,
            data: {
                code: {code}	
            }
        })
        .then((res) => {
            console.log(res.data.accessToken);

            const ACCESS_TOKEN = res.data.accessToken;

            //토큰저장
         //   setToken(res.data.accessToken);
         //   window.alert("로그인 성공");
            //window.location.href="/";
            //history.replace("/");

        }).catch((err) => {
            
            console.log("에러", err);
            window.alert("로그인에 실패하였습니다.");
            //history.replace("/"); //로그인실패 로그인화면으로 이동

        })
    }
}

const actionCreators = {
    kakaoLogin
};

export { actionCreators };
