import {React, useState, useEffect, useRef} from 'react'
import axios from "axios";
import cn from 'classnames'
import { getToken } from "../../services/TokenService";

// css
import Style from './index.module.css'
import HistoryIcon from '@/assets/image/ico/ico-time.svg'
import SpaceHome from '@/assets/image/ico/ico-space-pin.svg'
import HomeIcon from '@/assets/image/ico/ico-home-green.svg'
import HistoryGreenIcon from '@/assets/image/ico/ico-history01.svg'
import HistoryAddIcon from '@/assets/image/ico/ico-history-add.svg'


const Main = () => {
  const [houseList, setHouseList] = useState([]);
  const [isPopup, setIsPopup] = useState(false);
  const [gonggan, setGongGan] = useState("");
  const [house, setHouse] = useState("");
  const [floor, setFloor] = useState("");
  const [hosu, setHosu] = useState("");
  const [etctxt, setEtcTxt] = useState("");
  //const [pyungsu, setPyungSu] = useState("");
  const [juyoinfo, setJuyoInfo] = useState("");
  const [gayo, setGayo] = useState("");
  const [jutype, setJuType] = useState("");
  const [junm1, setJunM1] = useState("");
  const [junm2, setJunM2] = useState("");
  const [junm3, setJunM3] = useState("");
  const [junm1_p, setJunM1_p] = useState("");
  const [junm2_p, setJunM2_p] = useState("");
  const [junm3_p, setJunM3_p] = useState("");
  const [pyungsuInt, setPyungSuInt] = useState("1");
  const [pyungsu, setPyungSu] = useState("m²");
  
  const etctxtfocusRef = useRef(null);
	const pyungsufocusRef = useRef(null);
	const juyoinfofocusRef = useRef(null);
	const gayofocusRef = useRef(null);
	
  const handleOpen = () => {
    setIsPopup(true);
  }
  const handleClose = () => {
    setIsPopup(false);
  }

  const _ = require('lodash');
	const floors = _.range(1, 51, 1);

  const gethouseList = async (e) => {
		//const resp = await (await axios.get(process.env.REACT_APP_API_MANAGERHOUSELIST)).data; 
		//setHouseList(resp.list); 

    const token = getToken();
		
		if(token !== null){
			  const local_token = JSON.parse(token);

        axios({
          method: 'post',
          url: process.env.REACT_APP_API_MANAGERHOUSELIST,
          mode: "cors",
          credentials: "same-origin",
          data:{
            company: local_token.company,
          },
        }).then((res) => {
          setHouseList(res.data.list); 
        }).catch((err) => {	
          console.log("에러", err);
        })
    }
	}

  const pyungChange = () => {
    if(pyungsu === "평"){
      setPyungSu("m²");
      setPyungSuInt("1");
    }else{
      setPyungSu("평");
      setPyungSuInt("2");
    }
  }

  const onReg = () => {
		
		if(house === ""){
			alert("공유주택을 선택해주세요.");
			return;
		}

    if(floor === ""){
			alert("층수를 선택해주세요.");
			return;
		}

    if(gonggan === ""){
			alert("공간형태를 선택해주세요.");
			return;
		}

    if(pyungsu === ""){
			alert("공간평수를 입력해주세요.");
      pyungsufocusRef.current.focus();
			return;
		}	
		
    const token = getToken();
		
		if(token !== null){
			  const local_token = JSON.parse(token);

        const formData = new FormData();
        //formData.append('manager',usersid)
        formData.append('house',house)
        formData.append('floor',floor)
        formData.append('gonggan',gonggan)
        formData.append('hosu',hosu)
        formData.append('etctxt',etctxt)
        //formData.append('pyungsu',pyungsu)
        formData.append('juyoinfo',juyoinfo)
        formData.append('gayo',gayo)
        formData.append('jutype',jutype)
        formData.append('junm1',junm1)
        formData.append('junm2',junm2)
        formData.append('junm3',junm3)
        formData.append('junm1_p',junm1_p)
        formData.append('junm2_p',junm2_p)
        formData.append('junm3_p',junm3_p)
        formData.append('company',local_token.company);
        
        axios({
          method:'post',
          url:process.env.REACT_APP_API_SPACEONECREATE,
          data: formData,
        })
          .then((result)=>{
            if(result.data.code === '200'){
              alert("등록 되었습니다.");
              window.location.href="/admin/space/home";
            }else if(result.data.code === '300'){
              alert("이미 등록된 공간입니다.");
              return;
            }else{
              console.log(result);
            }
        })
          .catch((error)=>{console.log('요청실패')
          console.log(error)  
        })
    }
	}

  const onChange_junm1 = (e) => {
		if(e !== ""){
			let vm = parseFloat(parseFloat(e)*0.3025).toFixed(2);
      if(vm !== "NaN"){
			  setJunM1_p(vm);
      }
		}
	}

	const onChange_junm2 = (e) => {
		if(e !== ""){
			let vm = parseFloat(parseFloat(e)*0.3025).toFixed(2);
      if(vm !== "NaN"){
			  setJunM2_p(vm);
      }
		}
	}

	const onChange_junm3 = (e) => {
		if(e !== ""){
			let vm = parseFloat(parseFloat(e)*0.3025).toFixed(2);
      if(vm !== "NaN"){
        setJunM3_p(vm);
      }
		}
	}

  useEffect(() => {
		gethouseList();
	}, []);

  //useEffect(()=>{
   /* setTimeout(function(){
      handleOpen();
    },1000)*/
  //},[]);
	return (
		<div className={Style.wrapper}>

			<section className={Style.spacedHeader}>

        <div className={cn(Style.pageTitle, Style.type01)}>
          <img src={SpaceHome} />
          <span><strong>공간핀생성 -개별</strong></span>
        </div>
      </section>

      <section className={Style.spaceView}>
			  <div className={Style.optList}>
          <ul>
            <li>
              <select className={Style.select} onChange={(e) => setHouse(e.target.value)}>
                <option value="">공유주택 선택</option>
                {houseList.map((list) => (
                  <option key={list.seq} value={list.seq}>{list.house_name}</option>
                ))}
              </select>
            </li>
            <li>
              <select className={Style.select} onChange={(e) => setFloor(e.target.value)}>
                <option value="">층수선택</option>
                <option value="-5">B5</option>
                <option value="-4">B4</option>
                <option value="-3">B3</option>
                <option value="-2">B2</option>
                <option value="-1">B1</option>
                {
									floors.map((option) => (
										<option key={option} value={option}>{option}층</option>
									))
								}
              </select>
            </li>
            <li>
              <select className={Style.select} name="gonggan" onChange={(e) => setGongGan(e.target.value)}>
                <option value="">공간형태선택</option>
                <option value="S1">개별거주공간</option>
                <option value="S2">공유공간</option>
                {/*}
                <option value="S3">근린공간</option>
                {*/}
                <option value="S4">기타공간</option>
              </select>
            </li>
            {gonggan === 'S1' ? 
            <li>
            <select className={Style.select} name="hosu" onChange={(e) => setHosu(e.target.value)}>
              <option value="">호수선택</option>
              {
									floors.map((option) => (
										<option key={option} value={option}>{option}호</option>
									))
							}
            </select>
          </li>
            : 
              gonggan !== '' ?
            <li>
              <div className={Style.inpWrap}>
                <input type={'text'} className={Style.inp} name="etc_txt" ref={etctxtfocusRef} value={etctxt} onChange={(e) => setEtcTxt(e.target.value)} />
              </div>
            </li>
                : ""
              
            }
          </ul>
        </div>

        <div className={Style.formList}>
          <ul>
            {/*}
            <li>
              <div className={Style.label}>공간평수</div>
              <div className={Style.formItem}>
                <input type="text" className={Style.inp} name="pyungsu" ref={pyungsufocusRef} value={pyungsu} onChange={(e) => setPyungSu(e.target.value)} />
              </div>
            </li>
            <li>
              <div className={Style.label}>공간주요정보</div>
              <div className={Style.formItem}>
                <input type="text" className={Style.inp} name="juyoinfo" ref={juyoinfofocusRef} value={juyoinfo} onChange={(e) => setJuyoInfo(e.target.value)} />
              </div>
            </li>
            {*/}
            <li>
              <div className={Style.label}>주택타입</div>
              <div className={Style.form}>
                <div className={Style.inpWrap}>
                  <input type="text" className={Style.inp} name="jutype" value={jutype} onChange={(e) => setJuType(e.target.value)} />
                </div>
              </div>
            </li>
            <li>
              <div className={Style.label}>전용면적</div>
              <div className={Style.form}>
                <div className={Style.inpWrap}>
                {pyungsuInt === "1" ? 
                  <input type="text" className={Style.inp} name="junm1" value={junm1} onBlur={(e) => onChange_junm1(e.target.value)} onChange={(e) => setJunM1(e.target.value)} />
                  :
                  <input type="text" className={Style.inp} name="junm1" value={junm1_p} />
                }
                  <span className={Style.unit}>{pyungsu} </span>
                </div>
              </div>
            </li>
            <li>
              <div className={Style.label}>공급면적</div>
              <div className={Style.form}>
                <div className={Style.inpWrap}>
                {pyungsuInt === "1" ? 
                  <input type="text" className={Style.inp} name="junm2" value={junm2} onBlur={(e) => onChange_junm2(e.target.value)} onChange={(e) => setJunM2(e.target.value)} />
                  :
                  <input type="text" className={Style.inp} name="junm2" value={junm2_p} />
                }
                  <span className={Style.unit}>{pyungsu} </span>
                </div>
              </div>
            </li>
            <li>
              <div className={Style.label}>실거주 면적</div>
              <div className={Style.form}>
                <div className={Style.inpWrap}>
                {pyungsuInt === "1" ? 
                  <input type="text" className={Style.inp} name="junm3" value={junm3} onBlur={(e) => onChange_junm3(e.target.value)} onChange={(e) => setJunM3(e.target.value)} />
                  :
                  <input type="text" className={Style.inp} name="junm3" value={junm3_p} />
                }
								  <span className={Style.unit}>{pyungsu} </span>
                </div>
              </div>
            </li>
            <li>
              <div className={Style.form}>
                <div className={Style.inpWrap}>
                  <a href="#;" onClick={pyungChange}>m² ↔ 평 전환</a>
                </div>
              </div>
            </li>
          </ul>
        </div>

				<div className={cn(Style.photoList)}>
					<div className={Style.photoUpload}>
						<label>
							<input type="file" />
						</label>
					</div>
				</div>

        <dl>
          <dt>
            <img src={HomeIcon} />
            <span>공간개요</span>
          </dt>
          <dd>
            <div className={Style.formItem}>
              <textarea className={Style.textarea} ref={gayofocusRef} value={gayo} onChange={(e) => setGayo(e.target.value)}>&nbsp;</textarea>
            </div>
          </dd>


        </dl>
				<div className={Style.mbtn}>
					<button type="button" onClick={() => onReg()}>공간생성</button>
				</div>

			</section>


		</div>
	)
}

export default Main
