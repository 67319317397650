import React, {useState, useRef, useEffect} from 'react'
import axios from "axios";
import cn from 'classnames'
import { getToken } from "../../services/TokenService";

//preview
import { useDropzone } from 'react-dropzone';
import Zoom from 'react-medium-image-zoom'

// css
import Style from './index.module.css'
import User from '@/assets/image/ico/ico-dalla.svg'
import SearchImg from '@/assets/image/ico/ico-search-white.svg'
import Upload from '@/assets/image/ico/ico-upload01.png'
import popClose from '@/assets/image/ico/ico-pop-close.svg'
import Dialog from './dialog.module.css'
import { Button } from '@/components/ui/button'
import { X } from "lucide-react"

import { Document, pdfjs, Page } from 'react-pdf'
import PdfViewerDialog from '../../components/pdfViewerDialog';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const Index = () => {
	const [isPopup, setIsPopup] = useState(false);
	const [memPopup, setMemPopup] = useState(false);
	const [mngName, setMngName] = useState("");
	const [mngList, setMngList] = useState([]);
	const [selMng, setSelMng] = useState({
		username: '',
		id: '',
		idx: ''
	});
	const { username, id, idx } = selMng;
	const [users, setUsers] = useState("유저검색");
	const [usersid, setUsersId] = useState("");
	const [houseList, setHouseList] = useState([]);
	const [bankname, setBankname] = useState("");
	const [banknametext, setBanknametext] = useState("");
	const [banknumber, setBanknumber] = useState("");
	const [bankyename, setBankyename] = useState("");
	const [banklist, setBankList] = useState([]);
	const [pnbankname, setPnBankname] = useState("");
	const [pnbanknametext, setPnBanknametext] = useState("");
	const [pnbanknumber, setPnBanknumber] = useState("");
	const [pnbankyename, setPnBankyename] = useState("");
	const [pnbankseq, setPnBankseq] = useState("");
	const [pnbankhousegubun, setPnBankhousegubun] = useState("");
	const [deposit, setDeposit] = useState("");
	const [rentfee, setRentfee] = useState("");
	const [house, setHouse] = useState("");
	const [floor, setFloor] = useState("");
	const [hosu, setHosu] = useState("");
	const [paymentdate, setPaymentDate] = useState("");
	const [startyear, setStartYear] = useState("");
	const [startmonth, setStartMonth] = useState("");
	const [startday, setStartDay] = useState("");
	const [endyear, setEndYear] = useState("");
	const [endmonth, setEndMonth] = useState("");
	const [endday, setEndDay] = useState("");
	const [addmemo, setAddMemo] = useState("");
	const [enteredNum, setEnterdNum] = useState("");
	const [bankhousechoice, setBankHouseChoice] = useState("");

	/* 이미지 업로더 시작 */
	// 드롭 박스 겸 프리뷰 Component
	const [isOpenedPdfViewerDialog, setIsOpenedPdfViewerDialog] = useState(false);
	const [pdfFileToViewer, setPdfFileToViewer] = useState();
	const MyImagePdfDropBox = ({onDrop, imagePreviewFile, setImagePreviewFile, file, setFile, setPdfFileToViewer, setIsOpenedPdfViewerDialog}) => {
		const { getRootProps }
			= useDropzone({accept: {'image/jpeg': [], 'image/png': [], 'application/pdf': []}, onDrop: onDrop, noClick: imagePreviewFile !== undefined || file !== undefined, noKeyboard: true,});
	
		return (
			<div className={Style.filearea} style={{height:'340px'}} {...getRootProps()}>
				<div className='relative'>
					<label className={Style.label} style={{height:'340px'}} id="imagepreview"> 
					{
						(imagePreviewFile === undefined && file === undefined)
						?
						<img src={Upload}  />
						:
						imagePreviewFile !== undefined
						?
						<Zoom>
							<img src={imagePreviewFile}  />
						</Zoom>
						:
						<Document file={file} onClick={()=>{setPdfFileToViewer(file);setIsOpenedPdfViewerDialog(true);}} >
							<Page pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false}/>
						</Document>
					}
					</label>
					{
						(imagePreviewFile !== undefined || file !== undefined) 
						&& <Button className="absolute top-[0px] right-0 hover:text-red-950 pr-2" variant="icon"
							onClick={(e)=>{
								setFile();
								if(imagePreviewFile !== undefined){
									setImagePreviewFile();
								}else{
									setPdfFileToViewer();
								}}}>
							<X/>
							</Button> 
					}
				</div>
			</div>
		);
	}
	// onDrop 핸들러
	const getOnDropHander = (setFile, setImagePreview) => {
		return (acceptedFiles) => {
			if(acceptedFiles){
				setFile(acceptedFiles[0]);
				
				if( acceptedFiles[0].type === "application/pdf" ){
					setPdfFileToViewer(acceptedFiles[0]);
				}else{
					const reader = new FileReader();
					reader.readAsDataURL(acceptedFiles[0]);
					reader.onload = () => {
						setImagePreview(reader.result);
					};
				}
			}
		}
	}
	// 계약서 파일
	const [imagePreviewForContract, setImagePreviewForContract] = useState();
	const [fileForContract, setFileForContract] = useState();
	// 소득 증빙 서류 파일
	const [imagePreviewForIncome, setImagePreviewForIncome] = useState();
	const [fileForIncome, setFileForIncome] = useState();
	// 자산 증빙 서류 파일
	const [imagePreviewForAsset, setImagePreviewForAsset] = useState();
	const [fileForAsset, setFileForAsset] = useState();
	// 개인 동의서 파일
	const [imagePreviewForAgreement, setImagePreviewForAgreement] = useState();
	const [fileForAgreement, setFileForAgreement] = useState();
	/* 이미지 업로더 끝 */

	const namefocusRef = useRef(null);
	const depositfocusRef = useRef(null);
	const rentfeefocusRef = useRef(null);
	const now = new Date();

	const handleOpen = () => {
		setIsPopup(true);
		popBanklist();
	}

	const handleMemOpen = () => {
		setMemPopup(true);
	}
 
	const handleClose = () => {
		setIsPopup(false);
	}

  	const handleMemClose = () => {
		setMemPopup(false);
	}

	const _ = require('lodash');
	const years = _.range(now.getFullYear() - 5, now.getFullYear() + 1, 1);
	const years_end = _.range(now.getFullYear() - 5, now.getFullYear() + 5, 1);
	const floors = _.range(1, 51, 1);

  	const nextId = useRef(0);

	const gethouseList = async (e) => {
		//const resp = await (await axios.get(process.env.REACT_APP_API_MANAGERHOUSELIST)).data; 
		//setHouseList(resp.list); 
		const token = getToken();
		
		if(token !== null){
			const local_token = JSON.parse(token);

			axios({
				method: 'post',
				url: process.env.REACT_APP_API_MANAGERHOUSELIST,
				mode: "cors",
				credentials: "same-origin",
				data:{
					company: local_token.company,
				},
			}).then((res) => {
				setHouseList(res.data.list);
			}).catch((err) => {	
				console.log("에러", err);
			})
		}
	}

	const banknameSet = (e) => {
		setBanknametext(e.target.selectedOptions[0].text);
		setBankname(e.target.value);
	}

  	const onCreate = (e) => {
		if(e !== null){
			const selText = e.split('/');
			const t1 = selText[0];
			const t2 = selText[1];
			
			setUsers(t1);
			setUsersId(t2);
			//console.log(users);
			nextId.current += 1;
			handleMemClose();
		}
	};

	const managerSearch = () => {
		if(mngName === ""){
			alert("이름이나 이메일주소를 입력해주세요.");
			namefocusRef.current.focus();
			return;
		}else{
			const token = getToken();
		
			if(token !== null){
				const local_token = JSON.parse(token);

				axios({
					method: 'post',
					url: process.env.REACT_APP_API_MEMBERSEARCH,
					data: {
						searchword: mngName,
						company: local_token.company,
					}
				}).then((res) => {
					if(res.data.cnt > 0){
						setMngList(res.data.list); 
					}else{
						console.log("검색결과 없습니다.");
					}
				}).catch((err) => {	
					console.log("에러", err);
				})
			}
		}
	}

	const onBankset = () => {
	
		if(bankname === ""){
			alert("은행을 선택해주세요.");
			return;
		}
		if(banknumber === ""){
			alert("은행계좌번호를 입력해주세요.");
			return;
		}
		if(bankyename === ""){
			alert("예금주를 입력해주세요.");
			return;
		}

		const token = getToken();
		
		if(token !== null){
			const local_token = JSON.parse(token);
			axios({
				method: 'post',
				url: process.env.REACT_APP_API_MEMBERBANKREG,
				data: {
					bankname: bankname,
					banknametext: banknametext,
					banknum: banknumber,
					bankyename: bankyename,
					bankhouse: bankhousechoice,
					company: local_token.company
				}
			}).then((res) => {
				if(res.data.code == '200'){
					setBankList(res.data.list); 

					setBankname("");
					setBanknametext("");
					setBanknumber("");
					setBankyename("");
					setBankHouseChoice("");
				}else{
					console.log("리스트가 없습니다.");
				}
			}).catch((err) => {	
				console.log("에러", err);
			})
		}
	}

	const popBanklist = () => {
		//if(usersid !== ""){
		const token = getToken();
		
		if(token !== null){
			const local_token = JSON.parse(token);
			axios({
				method: 'post',
				url: process.env.REACT_APP_API_MEMBERBANKLIST,
				data:{
					company: local_token.company,
				},
			}).then((res) => {
				if(res.data.code == '200'){
					setBankList(res.data.list); 
				}else{
					console.log("리스트가 없습니다.");
				}
			}).catch((err) => {	
				console.log("에러", err);
			})
		}
		//}
	}

	const bankDel = (m) =>{
		if(window.confirm("정말로 삭제하시겠습니까?")){
			axios({
				method: 'post',
				url: process.env.REACT_APP_API_MEMBERBANKDEL,
				data: {
					idx: m
				}
			}).then((res) => {
				if(res.data.code == '200'){
					setBankList(res.data.list); 
				}else{
					console.log("리스트가 없습니다.");
				}
			}).catch((err) => {	
				console.log("에러", err);
			})
		}
	}

	const getBank = (e) => {
		if(e !== null){
			const selText = e.split('/');
			const t1 = selText[0];
			const t2 = selText[1];
			const t3 = selText[2];
			const t4 = selText[3];
			const t5 = selText[4];
			const t6 = selText[5];
			
			setPnBanknametext(t1);
			setPnBankname(t2);
			setPnBanknumber(t3);
			setPnBankyename(t4);
			setPnBankseq(t5);
			setPnBankhousegubun(t6);
			handleClose();
		}
	}

	const onKeyEnter = (e) => {
		if(e.key === 'Enter'){
			managerSearch();
		}
	}

	const onReg = () => {

		if(usersid === ""){
			alert("회원을 먼저 검색해주세요.");
			return;
		}
		
		if(house === ""){
			alert("주택을 선택해주세요.");
			return;
		}

		if(floor === ""){
			alert("층수를 선택해주세요.");
			return;
		}

		if(hosu === ""){
			alert("호수를 선택해주세요.");
			return;
		}

		if(enteredNum === ""){
			alert("보증금을 입력해주세요.");
			depositfocusRef.current.focus();
			return;
		}

		if(rentfee === ""){
			alert("임대료를 입력해주세요.");
			rentfeefocusRef.current.focus();
			return;
		}

		if(pnbankseq === ""){
			alert("계좌정보를 선택해주세요.");
			return;
		}

		const token = getToken();
		
		if(token !== null){
			const local_token = JSON.parse(token);

			const formData = new FormData();
			formData.append('attach[]',fileForContract)
			formData.append('attach1[]',fileForIncome)
			formData.append('attach2[]',fileForAsset)
			formData.append('attach3[]',fileForAgreement)
			formData.append('userid',usersid)
			formData.append('house',house)
			formData.append('floor',floor)
			formData.append('hosu',hosu)
			formData.append('deposit',enteredNum)
			formData.append('rentfee',rentfee)
			formData.append('paymentdate',paymentdate)
			formData.append('startyear',startyear)
			formData.append('startmonth',startmonth)
			formData.append('startday',startday)
			formData.append('endyear',endyear)
			formData.append('endmonth',endmonth)
			formData.append('endday',endday)
			formData.append('bankseq',pnbankseq)
			formData.append('bankname',pnbankname)
			formData.append('banknametext',pnbanknametext)
			formData.append('banknumber',pnbanknumber)
			formData.append('bankyename',pnbankyename)
			formData.append('addmemo',addmemo)
			formData.append('company',local_token.company)

			axios({
				method:'post',
				url:process.env.REACT_APP_API_MEMBERCONTRACTREG,
				mode: "cors",
				headers: {
					"Content-Type": "multipart/form-data", // Content-Type을 반드시 이렇게 하여야 한다.
				},
				data: formData,
			})
				.then((result)=>{
					if(result.data.code == 200){
						alert("등록 되었습니다.");
						window.location.href="/admin/userList";
					}else{
						console.log(result)
					}
			})
				.catch((error)=>{console.log('요청실패')
				console.log(error)  
			})
		}
	}

	const changeEnteredNum = (e) => {
		const value = e.target.value;
		const removedCommaValue = Number(value.replaceAll(",", ""));
		setEnterdNum(removedCommaValue.toLocaleString());
	};

	const changeRentfeeNum = (e) => {
		const value = e.target.value;
		const removedCommaValue = Number(value.replaceAll(",", ""));
		setRentfee(removedCommaValue.toLocaleString());
	};

	const bankAddDel = () => {
		setPnBanknametext("");
		setPnBankname("");
		setPnBanknumber("");
		setPnBankyename("");
		setPnBankseq("");
		setPnBankhousegubun("");
	}

	useEffect(() => {
		gethouseList();
		popBanklist();
	}, []);

	return (
		<div className={Style.wrapper}>

			<div className={Style.subTitle}>
				<img src={User} />
				<span>비용 및 계약 입력</span>
			</div>

			<div className={Style.contractSection}>

				<div className={Style.formRow}>
					<div className={Style.formCols}>
						<div className={Style.label}>입주자</div>
						<div className={Style.form}>
							<button type="button" className={Style.btnSearch} onClick={handleMemOpen}>
								<span>{users}</span>
								<img src={SearchImg} />
							</button>
						</div>
					</div>
					<div className={Style.formCols}>
						<div className={Style.label}>공간</div>
						<div className={cn(Style.form, Style.type03)}>
							<select className={Style.select} onChange={(e) => setHouse(e.target.value)}>
							<option value="">주택선택</option>
								{houseList.map((list) => (
									<option key={list.seq} value={list.seq}>{list.house_name}</option>
								))}
							</select>
							<select className={Style.select} onChange={(e) => setFloor(e.target.value)}>
								<option value="">층수선택</option>
								<option value="-5">B5</option>
								<option value="-4">B4</option>
								<option value="-3">B3</option>
								<option value="-2">B2</option>
								<option value="-1">B1</option>
								{
									floors.map((option) => (
										<option key={option} value={option}>{option}층</option>
									))
								}
							</select>
							<select className={Style.select} name="gonggan">
								<option value="S1">개별거주</option>
							</select>
							<select className={Style.select} onChange={(e) => setHosu(e.target.value)}>
								<option>호수 선택</option>
								{
									floors.map((option) => (
										<option key={option} value={option}>{option}호</option>
									))
								}
							</select>
						</div>
					</div>
				</div>

				<div className={Style.formRow}>
					<div className={Style.formCols}>
						<div className={Style.label}>보증금 / 임대료</div>
						<div className={Style.form}>
							<div className={Style.inpWrap}>
								<input type="text" className={Style.inp} ref={depositfocusRef} value={enteredNum} onChange={changeEnteredNum} />
								<span className={Style.unit}>원</span>
							</div>
							<div className={Style.inpWrap}>
								<input type="text" className={Style.inp} ref={rentfeefocusRef} value={rentfee} onChange={changeRentfeeNum} />
								<span className={Style.unit}>원</span>
							</div>
						</div>
					</div>
					<div className={Style.formCols}>
						<div className={Style.label}>월세납부일</div>
						<div className={Style.form}>
							<div className={cn(Style.inpWrap, Style.type01)}>
								<select className={Style.select} onChange={(e) => setPaymentDate(e.target.value)}>
									<option value="">납부일선택</option>
								{
									Array(31).fill(0).map((_, i) => (
										<option key={i + 1} value={i + 1}>{i + 1}일</option>
									))
								}
								</select>

								{/*}
								<input type="text" className={Style.inp} />
								<span className={Style.unit}>일</span>
								{*/}
							</div>
						</div>
					</div>
				</div>

				<div className={Style.formRow}>
					<div className={Style.formCols}>
						<div className={Style.label}>기간</div>
						<div className={cn(Style.form, Style.type01)}>
							<div className={Style.sitem}>
								<div className={Style.e02}>시작일</div>
								<select className={Style.select} onChange={(e) => setStartYear(e.target.value)}>
									<option value="">년도선택</option>
								{
									years.map((option) => (
										<option key={option} value={option}>{option}년</option>
									))
								}
								</select>
								<select className={Style.select} onChange={(e) => setStartMonth(e.target.value)}>
								<option value="">월선택</option>
								{
									Array(12).fill(0).map((_, i) => (
										<option key={i + 1} value={i + 1}>{i + 1}월</option>
									))
								}
								</select>
								<select className={Style.select} onChange={(e) => setStartDay(e.target.value)}>
									<option value="">일선택</option>
								{
									Array(31).fill(0).map((_, i) => (
										<option key={i + 1} value={i + 1}>{i + 1}일</option>
									))
								}
								</select>
							</div>
							<div className={Style.sitem}>
								<div className={Style.e02}>-     종료일</div>
								<select className={Style.select} onChange={(e) => setEndYear(e.target.value)}>
									<option value="">년도선택</option>
								{
									years_end.map((option) => (
										<option key={option} value={option}>{option}년</option>
									))
								}
								</select>
								<select className={Style.select} onChange={(e) => setEndMonth(e.target.value)}>
									<option value="">월선택</option>
								{
									Array(12).fill(0).map((_, i) => (
										<option key={i + 1} value={i + 1}>{i + 1}월</option>
									))
								}
								</select>
								<select className={Style.select} onChange={(e) => setEndDay(e.target.value)}>
									<option value="">일선택</option>
								{
									Array(31).fill(0).map((_, i) => (
										<option key={i + 1} value={i + 1}>{i + 1}일</option>
									))
								}
								</select>
							</div>
						</div>
					</div>
				</div>

				<div className={Style.formRow}>
					<div className={Style.formCols}>
						<div className={Style.label}>계좌정보</div>
						<div className={cn(Style.form, Style.type02)}>
						<input type="text" readOnly="readonly" className={Style.inp} value={pnbanknametext !== "" ? pnbankhousegubun + "  /  " + pnbanknametext + "  /  " + pnbanknumber + "  /  " + pnbankyename : ""} />
							<div className={Style.func}>
								<button type={'button'} onClick={handleOpen}>등록</button>
								<button type={'button'} onClick={bankAddDel}>삭제</button>
							</div>
						</div>
					</div>
				</div>

				<div className={Style.formRow}>
					<div className={Style.formCols}>
						<div className='flex flex-wrap justify-between'>
							<div className='flex flex-col mt-5 w-68'>
								<div className={Style.label}>계약서 스캔본</div>
								<div className={Style.form}>
									<MyImagePdfDropBox onDrop={getOnDropHander(setFileForContract, setImagePreviewForContract)} imagePreviewFile={imagePreviewForContract} 
										setImagePreviewFile={setImagePreviewForContract} file={fileForContract} setFile={setFileForContract} setPdfFileToViewer={setPdfFileToViewer} setIsOpenedPdfViewerDialog={setIsOpenedPdfViewerDialog}/>
								</div>
							</div>
							<div className='flex flex-col mt-5 w-68'>
								<div className={Style.label}>소득 증빙 서류</div>
								<div className={Style.form}>
									<MyImagePdfDropBox onDrop={getOnDropHander(setFileForIncome, setImagePreviewForIncome)} imagePreviewFile={imagePreviewForIncome} 
										setImagePreviewFile={setImagePreviewForIncome} file={fileForIncome} setFile={setFileForIncome} setPdfFileToViewer={setPdfFileToViewer} setIsOpenedPdfViewerDialog={setIsOpenedPdfViewerDialog}/>
								</div>
							</div>
							<div className='flex flex-col mt-5 w-68'>
								<div className={Style.label}>자산 증빙 서류</div>
								<div className={Style.form}>
									<MyImagePdfDropBox onDrop={getOnDropHander(setFileForAsset, setImagePreviewForAsset)} imagePreviewFile={imagePreviewForAsset} 
										setImagePreviewFile={setImagePreviewForAsset} file={fileForAsset} setFile={setFileForAsset} setPdfFileToViewer={setPdfFileToViewer} setIsOpenedPdfViewerDialog={setIsOpenedPdfViewerDialog}/>
								</div>
							</div>
							<div className='flex flex-col mt-5 w-68'>
								<div className={Style.label}>개인 동의서</div>
								<div className={Style.form}>
									<MyImagePdfDropBox onDrop={getOnDropHander(setFileForAgreement, setImagePreviewForAgreement)} imagePreviewFile={imagePreviewForAgreement} 
										setImagePreviewFile={setImagePreviewForAgreement} file={fileForAgreement} setFile={setFileForAgreement} setPdfFileToViewer={setPdfFileToViewer} setIsOpenedPdfViewerDialog={setIsOpenedPdfViewerDialog}/>
								</div>
							</div>
						</div>
						
						
						{/* <div style={{padding:'10px'}}>
							<ul style={{display:'flex',justifyContent:'flex-start'}}>
							{
								preview.map((prev, index) => {
									return(
									<li key={index} style={{width:'200px',backgroundColor:'#fff',padding:'5px',borderRadius:'10px',border:'1px dotted gray', marginLeft:'10px'}}>
										<div style={{float:'right'}}><img src={popClose} /></div>
										<img src={prev} style={{width:'100%', borderRadius:'10px'}} />
									</li>
									);
								})
							}
							</ul>
						</div> */}

					</div>
				</div>

				<div className={Style.formRow}>
					<div className={Style.formCols}>
						<div className={Style.label}>추가사항 기록</div>
						<div className={Style.form}>
							<textarea className={Style.textarea} value={addmemo} onChange={(e) => setAddMemo(e.target.value)}></textarea>
						</div>
					</div>

				</div>

				<div className={Style.btnGroup}>
					<button type={'button'} onClick={onReg}>등록</button>
				</div>
			</div>


      	{/*검색팝업.*/}
      	<div className={cn(Style.popup, `${isPopup?Style.on:''}`)}>
        	<button type="button" className={Style.popClose} onClick={handleClose}><img src={popClose} /></button>
        
			<div className={Style.addLabel}>기존 계좌 추가</div>
			<div className={Style.tblWrap01}>
				<table>
					{banklist.map((bklist, index) => (
						<tr key={index} >
							<th style={{cursor:'pointer', textAlign: 'start', paddingLeft: '12px'}} data={bklist.banknametext+"/"+bklist.banknamecode+"/"+bklist.banknumber+"/"+bklist.bankyename+"/"+bklist.seq+"/"+bklist.housegubun} onClick={(e) => getBank(e.currentTarget.getAttribute('data'))} >{bklist.housegubun}</th>
							<td>
								<div className={Style.addBox}>
									<div className={Style.addBuilding}>{bklist.banknametext}</div>
									<div className={Style.addNum}>{bklist.banknumber}</div>
									<div className={Style.addName}>{bklist.bankyename}</div>
									<button type={'button'} data={bklist.seq} onClick={(e) => bankDel(e.currentTarget.getAttribute('data'))}>삭제</button>
								</div>
							</td>
						</tr>
					))}
				</table>
			</div>

				<div className={Style.addLabel}>등록 계좌 추가</div>
				<div className={Style.addForm}>
					<select className={Style.select} value={bankhousechoice} name="bankhousechoice" onChange={(e) => setBankHouseChoice(e.target.value)}>
						<option value="">주택선택</option>
						{houseList.map((list) => (
							<option key={list.seq} value={list.house_name}>{list.house_name}</option>
						))}
					</select>
					<select className={Style.select} name="bankname" value={bankname} onChange={(e) => banknameSet(e)}>
						<option value="">은행선택</option>
						<option value="bank1">기업은행</option>
						<option value="bank2">우리은행</option>
						<option value="bank3">농협은행</option>
						<option value="bank4">국민은행</option>
						<option value="bank5">신한은행</option>
						<option value="bank6">하나은행</option>
						<option value="bank7">지역농축협</option>
						<option value="bank8">SC은행</option>
						<option value="bank9">한국씨티은행</option>
						<option value="bank10">우체국</option>
						<option value="bank11">경남은행</option>
						<option value="bank12">광주은행</option>
						<option value="bank13">대구은행</option>
						<option value="bank14">부산은행</option>
						<option value="bank15">산업은행</option>
						<option value="bank16">새마을금고</option>
						<option value="bank17">수협</option>
						<option value="bank18">신협</option>
						<option value="bank19">전북은행</option>
						<option value="bank20">제주은행</option>
						<option value="bank21">카카오뱅크</option>
						<option value="bank22">케이뱅크</option>
						<option value="bank23">토스뱅크</option>
					</select>
					<input type={'text'} className={Style.inp} name="banknumber" placeholder={'계좌번호'} value={banknumber} onChange={(e) => setBanknumber(e.target.value)} />
					<input type={'text'} className={cn(Style.inp, Style.w01)} name="bankyename" placeholder={'예금주'} value={bankyename} onChange={(e) => setBankyename(e.target.value)} />
				</div>
				<div className={Style.addBtn}>
					<button type="button" onClick={() => onBankset()}>추가</button>
				</div>	
      	</div>

		<div className={cn(Dialog.dialog, `${memPopup?Dialog.on:''}`)}>
		</div>

	  	{/*검색팝업.*/}
	  	<div className={cn(Style.popup, `${memPopup?Style.on:''}`)}>
			<button type="button" className={Style.popClose} onClick={handleMemClose}><img src={popClose} /></button>
			<div className={Style.searchForm}>
				<input type="text" className={Style.inp} value={mngName} ref={namefocusRef} placeholder={'이름/핸드폰번호 검색'} onKeyPress={onKeyEnter} onChange={(e) => setMngName(e.target.value)} />
				<button type="button" onClick={managerSearch}>검색</button>
			</div>

			<div className={Style.tblWrap}>
				<table>
					<tr>
						<th>이름</th>
						<th>나이/성별</th>
						<th>핸드폰</th>
						<th>거주상태</th>
					</tr>
					{mngList.map((list) => (
					<tr key={list.seq} style={{cursor:'pointer'}} data={list.name+"/"+list.seq} onClick={(e) => onCreate(e.currentTarget.getAttribute('data'))}>
						<td>{list.name}</td>
						<td>{list.age}/{list.sex}</td>
						<td>{list.hptel}</td>
						<td>{list.house_status}</td>
					</tr>
					))}	
				</table>
			</div>
		</div>

	</div>

	)
}

export default Index

