import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom"
import axios from "axios";
import cn from 'classnames'
import { getToken } from "../../services/TokenService";
import { ColorRing } from 'react-loader-spinner';

// css
import Style from './index.module.css'
import Smile from '@/assets/image/ico/ico-smile.svg'

const Contract_view = () => {
	const { idx } = useParams();
	const [boardList, setBoardList] = useState([]);
	const [iframeloading, setIframeLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const hostname = window.location.hostname;
	const pathname = window.location.pathname;

	const contract_go = () => {
		//setLoading(true);
        const token = getToken();

		if(token !== null){
			const local_token = JSON.parse(token);
            axios({
                method: 'post',
                url: process.env.REACT_APP_API_CONTRACT_SIGN_PART2_VIEW,
                mode: "cors",
                credentials: "same-origin",
                headers: {
					Authorization: "Bearer " + local_token.access_token
				},
                data:{
                    sort: '2'
                },
            }).then((res) => {
                //console.log(res.data.code);
                if(res.data.code === '200'){
                    setLoading(false);
                    setIframeLoading(true);

                    const ucansignIframe = document.getElementById("ucansign-iframe");
                    console.log(ucansignIframe);
                    ucansignIframe.setAttribute("src", res.data.url);
                    //ucansignIframe.src = 'https://siso.live';

                    //document.querySelector('iframe').setAttribute("src", 'https://siso.live')

                }else if(res.data.code === '400'){
                
                }

            }).catch((err) => {	
                console.log("에러", err);
            })
        }

	}
	
	useEffect(() => {
		setLoading(true);
		setTimeout(function(){
			contract_go();
		}, 1000);
	}, []);

	return (
		<>
		{ loading ?  <div style={{zIndex:'500', position: 'absolute', width: '80px', height: '80px', top: 'calc( 50% - 100px )', left: 'calc( 50% )'}}>
			<ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}/></div> : "" }

			<div className={Style.wrapper}>
				<div className={Style.etc} style={{width:'100%', margin:'0 auto', justifyContent:'center', paddingTop:'5%', position:'relative'}}>
					
					
					<div style={{display:'flex', justifyContent:'center'}}>
						<iframe id="ucansign-iframe" style={{border:'0px', width:'1000px', height:'900px'}} src="" />
					</div>
										
				</div>
			</div>
		
		</>
	)
}

export default Contract_view
