import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom"
import axios from 'axios';
import cn from 'classnames'

// css
import Style from './index.module.css'
import User from '@/assets/image/ico/ico-user01.svg'

const Index = () => {
    const { idx } = useParams();
    const [board, setBoard] = useState({});

    const navigate = useNavigate();

    const getBoard = async () => {
      axios({
        method: 'post',
        url: process.env.REACT_APP_API_MEMBERUSERVIEW,
        data:{
          seq: `${idx}`
        },
      }).then((res) => {
        setBoard(res.data);
      }).catch((err) => {	
        console.log("에러", err);
      })
    };

    const contractedit = () => {
      navigate('/admin/space/contractedit/'+`${idx}`);
    }

    useEffect(() => {
      getBoard();
    }, []);

	return (
		<div className={Style.wrapper}>

			<div className={Style.subTitle}>
				<img src={User} />
				<span>유저 상세페이지</span>
			</div>

			<div className={Style.detailSection}>
				<dl>
					<dt>
            {board.house ?
						<div className={Style.stit}>{board.name}님은 {board.house} 거주 중입니다.</div>
            :
            <div className={Style.stit}>{board.name}님은 미거주 중입니다.</div>
            }
					</dt>
					<dd>
            <ul>
              <li>
                <div className={Style.key}>이름</div>
                <div className={Style.value}>{board.name}</div>
							</li>
              <li>
                <div className={Style.key}>닉네임</div>
                <div className={Style.value}>{board.nickname}</div>
							</li>
              <li>
                <div className={Style.key}>나이/성별</div>
                <div className={Style.value}>{board.age} / {board.sex}</div>
							</li>
              <li>
                <div className={Style.key}>연락처</div>
                <div className={Style.value}>{board.hptel}</div>
              </li>
              <li>
                <div className={Style.key}>이메일</div>
                <div className={Style.value}>{board.email}</div>
              </li>
              <li>
                <div className={Style.key}>주소</div>
                <div className={Style.value}>{board.address} {board.address_detail}</div>
              </li>
						</ul>
					</dd>
				</dl>
        
       
        <dl>
          <dt>
            <div className={Style.stit}>거주정보</div>
          </dt>
          <dd>
          {board.house ?
          <>
            <ul>
              <li>
                <div className={Style.value}>
									<span>계약기간 : {board.house_list.indate} ~ {board.house_list.outdate}</span>
								</div>
              </li>
              <li>
                <div className={Style.value}>거주기간 : {board.house_list.gigandate}일</div>
              </li>
              <li>
                <div className={Style.value}>{board.house_list.houseraddr}</div>
              </li>
            </ul>
						<div className={Style.sitem}>
							<span>{board.house}</span>
              <span>{board.house_list.floor}층</span>
              <span>거주자 개별공간</span>
              <span>{board.house_list.hosu}호</span>
						</div>

            <ul>
              <li>
                <div className={Style.value}>
                  <span>보증금 / 임대료 / 관리비 : {board.house_list.deposit} / {board.house_list.rentfee} / 0</span>

                </div>
              </li>
              <li>
                <div className={Style.value}>월세납부일 | 매월 {board.house_list.paymentdate}일</div>
              </li>
              <li>
                <div className={Style.value}>
									<span>계좌정보 : {board.house_list.bankname} {board.house_list.banknumber} 예금주 : {board.house_list.bankyename}</span>
									<button type={'button'}>납부기록</button>
								</div>
              </li>
            </ul>
          </>
            :
            <div style={{fontSize: '16px', paddingTop: '50px', color: '#fff', fontWeight: '600', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'center'}}>
              거주 데이터가 없습니다.
            </div>
          }
          </dd>
        </dl>
       

        {board.house &&
				<div className={Style.btnGroup}>
					<button type={'button'} onClick={contractedit}>공간비용 계약정보 수정</button>
				</div>
        }
			</div>

		</div>
	)
}

export default Index
