import React, {useEffect, useState } from 'react'
import { getToken } from "../../services/TokenService";
import { OuterTabs, OuterTabsContent, OuterTabsList, OuterTabsTrigger } from "@/components/ui/tabs_outer"
import SendMessagesTab from "./tabs/send_messages"
import SendHistoryTab from "./tabs/send_history"
import TemplateSettingsTab from "./tabs/template_settings"
import GroupSettingsTab from "./tabs/group_settings"
import AutoSendSettingsTab from "./tabs/auto_send_settings"
import './output.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Link, useParams, useNavigate } from "react-router-dom";
import { Toaster } from "@/components/ui/toaster"


const Index = ({outerTab}) => {
	const [admChk, setAdmChk] = useState("N");
	const navigate = useNavigate();
	const queryClient = new QueryClient()
	var { tab, innerTab } = useParams();

	if(tab === undefined) tab = outerTab;

	// if(tab == null) tab = "sendMessages";
	// const [activeTab, setActiveTab] = React.useState(tab);

    const handleTabChange = (value) => {
        // setActiveTab(value);
        // console.log("activeTab : " + activeTab);
		// <Link to={`../${value}`} />
		var url = `/admin/messages/${value}`
		if(value === "sendHistory" || value === "templateSettings") url = url + "/push"
		navigate(url);
      };

	return (
		<>
			<div className="wrapper lg:pl-[110px] lg:w-[100%-110px] lg:mx-0 mx-3.5">
				<QueryClientProvider client={queryClient}>
					<OuterTabs className="w-full" onValueChange={handleTabChange} value={tab}>
						<OuterTabsList className="flex justify-center">
							<OuterTabsTrigger value="sendMessages">메시지 전송</OuterTabsTrigger>
							<OuterTabsTrigger value="sendHistory">전송 내역</OuterTabsTrigger>
							<OuterTabsTrigger value="templateSettings">템플릿 설정</OuterTabsTrigger>
							<OuterTabsTrigger value="groupSettings">그룹 설정</OuterTabsTrigger>
							<OuterTabsTrigger value="autoSendSettings">자동 전송 설정</OuterTabsTrigger>
						</OuterTabsList>
						<OuterTabsContent value="sendMessages"><SendMessagesTab/></OuterTabsContent>
						<OuterTabsContent value="sendHistory"><SendHistoryTab innerTab={innerTab}/></OuterTabsContent>
						<OuterTabsContent value="templateSettings"><TemplateSettingsTab innerTab={innerTab}/></OuterTabsContent>
						<OuterTabsContent value="groupSettings"><GroupSettingsTab/></OuterTabsContent>
						<OuterTabsContent value="autoSendSettings"><AutoSendSettingsTab/></OuterTabsContent>
					</OuterTabs>
				</QueryClientProvider>
			</div>
			<Toaster/>
		</>
	)
}

export default Index
