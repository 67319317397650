import React, {useEffect, useState} from 'react'
import cn from 'classnames'
import Axios from "@/components/lib/axios";
import { ColorRing } from 'react-loader-spinner';
import { GreenLabel, Label } from "@/components/ui/label"
import SpaceReq from '@/assets/image/ico/ico-req01.svg'
import { MySpinner } from '@/components/ui/spinner'
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useQuery, useMutation } from "@tanstack/react-query";
import { Pencil, X, FolderCog } from 'lucide-react';
import { useParams, useNavigate } from 'react-router-dom';
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "@/components/ui/select"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { Textarea } from "@/components/ui/textarea"
import ImageDropBox from '@/components/ImageDropBox';
import ImageUploadPopup from '@/components/ImageUploadPopUp';
import Zoom from 'react-medium-image-zoom'
import '@/components/imageZoomStyles.css'
import Resizer from "react-image-file-resizer";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "@/components/imageSlider.css";
import { getToken } from "@/services/TokenService";
import axios from 'axios';
import image_dropbox_background from "@/assets/image/bg/bg-upload.png";
import RemoveIcon from '@/assets/image/ico/ico-remove01.svg'

const FacilityRegister = () => {

	const { id } = useParams();
	const isFromCommunicationBoard = typeof id === "undefined" ? false : true;
	const navigate = useNavigate();

	const [isPopUpShowing, setIsPopUpShowing] = useState(false)
	
	const [radioPriority, setRadioPriority] = useState("0");
	const [inputSubject, setInputSubject] = useState("");
	const [inputFloor, setInputFloor] = useState("");
	const [inputDetailedLocation, setInputDetailedLocation] = useState("");
	const [inputContent, setInputContent] = useState("");
	const [selectedImgFiles, setSelectedImgFiles] = useState([]);
  	const [previewImgUrls, setPreviewImgUrls] = useState([]);
	const [selectEngineerSeq, setSelectEngineerSeq] = useState();
	const [selectHouseSeq, setSelectHouseSeq] = useState();

	const { isLoading, data, error } = useQuery({queryKey:["facility_register_initial", id !== undefined ? id : 0 ],
		queryFn: () =>
			Axios.get(isFromCommunicationBoard ? process.env.REACT_APP_API_REQUEST_REGISTER_START + `/${id}` : process.env.REACT_APP_API_REQUEST_REGISTER_START_BASIC)
			.then((res) => {
				console.log(res.data);
				return res.data;
			})});

	const onImageDeleteButtonClicked = async (params, event) => {
		const index = params[0];
		previewImgUrls.splice(index, 1);
		const newPreviewImgUrls = [...previewImgUrls];
		setPreviewImgUrls(newPreviewImgUrls);

		selectedImgFiles.splice(index, 1);
		const newSelectedImgFiles = [...selectedImgFiles];
		setSelectedImgFiles(newSelectedImgFiles);
	};

	useEffect(() => {
		console.log(previewImgUrls);
	  }, [previewImgUrls]);
	
	const onImagesDropped = (acceptedFiles) => {
		acceptedFiles.map((file, index) => {
			
			Resizer.imageFileResizer( file, 1920, 1920, "JPEG", 90, 0,
				(file) => {
					setSelectedImgFiles((prevState) => [...prevState, file]);
					
					const reader = new FileReader();
		
					reader.onload = (e) => {
						setPreviewImgUrls((prevState) => [e.target.result, ...prevState]);
					};
		
					reader.readAsDataURL(file);
				},
				"file");
		});
	};

	const onSubmitCliked = async () => {
		if(inputSubject === "") {alert("제목을 입력 해 주세요");return;}
		if(selectEngineerSeq === undefined) {alert("시설 기사를 선택 해 주세요");return;}
		if(selectHouseSeq === undefined) {alert("주택을 선택 해 주세요");return;}
		if(inputFloor === "") {alert("층수 정보를 입력 해 주세요");return;}
		if(inputDetailedLocation === "") {alert("세부 위치를 입력 해 주세요");return;}
		if(inputContent === "") {alert("요청 내용을 작성 해 주세요");return;}

		const token = getToken();
		if(token !== null){
			const local_token = JSON.parse(token);
			const formData = new FormData();
			selectedImgFiles.forEach((file) => {
				formData.append('attach[]', file);
			});

			formData.append('priority', radioPriority);
			formData.append('buildingname', selectHouseSeq);
			formData.append('floor', inputFloor);
			formData.append('detailedlocation', inputDetailedLocation);
			isFromCommunicationBoard && formData.append('communicationboardid', id);
			formData.append('subject', inputSubject);//To DO
			formData.append('engineer', selectEngineerSeq);
			formData.append('requestcontent', inputContent);
			
			await axios({
				method: 'post',
				url: process.env.REACT_APP_API_REQUEST_REGISTER,
				data: formData,
				mode: "cors",
            	credentials: "same-origin",
				headers: {
					Authorization: "Bearer " + local_token.access_token
				},
			}).then((res) => {
				console.log(res.data);
				if(res.data.code === 200){
					navigate('/admin/space/reqList');
				}else if(res.data.code === 400){
					console.log(res.data);
				}
			}).catch((err) => {	
				console.log("에러", err);
			})
			
		}
		
	};

	const responsive = {
		superLargeDesktop: {
		  // the naming can be any, depends on you.
		  breakpoint: { max: 4000, min: 3000 },
		  items: 5
		},
		desktop: {
		  breakpoint: { max: 3000, min: 1024 },
		  items: 4
		},
		tablet: {
		  breakpoint: { max: 1024, min: 464 },
		  items: 2
		},
		mobile: {
		  breakpoint: { max: 464, min: 0 },
		  items: 1,
		}
	  };
	
	return (
		<>
		{
		isLoading
		? <MySpinner/>
		:
		<div className="wrapper lg:pl-[110px] lg:w-[100%-110px] lg:mx-0 mx-3.5">
			<div className='px-2 lg:px-20 flex flex-col'>
				<div className="title w-full text-2xl"><img className='w-6 h-6 mr-2' src={SpaceReq} />시설 관리</div>
				{
					isFromCommunicationBoard &&
					<div className='p-4 mt-10 lg:p-10 lg:m-10 flex flex-col bg-section rounded-md'>
						<div className='flex flex-row gap-2 items-center'>
							<Pencil className='w-6 h-6 mr-1 text-accent-foreground'/>
							<GreenLabel className='text-xl'>거주자 - 불편 사항 및 수리 요청 글</GreenLabel>
						</div>
						<div className='p-2 mt-4 lg:m-10 flex flex-col gap-2'>
							<div className='flex flex-col lg:flex-row gap-1'>
								<Label className='text-lg lg:mr-22'>{`${data.communicationinfo.buildingname} ${data.communicationinfo.floor}층 ${data.communicationinfo.roominfo} ${data.communicationinfo.username}`}</Label>
								<Label className='text-lg'>{data.communicationinfo.subject}</Label>
							</div>
							<div className='p-2 mt-2 lg:p-10 lg:mt-10 bg-facility-contents-bg rounded-md text-lg' dangerouslySetInnerHTML={{__html: data.communicationinfo.content}}/>
						</div>
					</div>
				}
				
				<div className='p-4 mt-10 lg:p-10 lg:m-10 flex flex-col bg-section rounded-md'>
					<div className='flex flex-row gap-2 items-center'>
						<Pencil className='w-6 h-6 mr-1 text-accent-foreground'/>
						<GreenLabel className='text-xl'>주택관리자 - 시설 관리 요청서 작성 중</GreenLabel>
					</div>
					<div className='p-2 mt-4 lg:m-10'>
						<div className='flex flex-wrap lg:w-full gap-2 items-end'>
							<div className='w-full lg:w-2/3 lg:w-fit flex flex-row items-center lg:mr-22'>
								<Label className='w-12 text-lg mr-4 lg:mr-10'>제목</Label>
								<Input className="w-full text-lg" value={inputSubject} onChange={(event)=>setInputSubject(event.target.value)}
									placeholder="요청 제목 입력"/>
							</div>
							<div className='w-full lg:w-fit flex flex-row justify-between items-end lg:mr-10'>
								<Label className='w-18 text-lg mr-4 lg:mr-10 mb-2.5'>시설 기사</Label>
								<Select value={selectEngineerSeq} onValueChange={(value)=>setSelectEngineerSeq(value)}>
									<SelectTrigger className="w-52 mt-5 h-12">
										<SelectValue placeholder="기사님 배정"/>
									</SelectTrigger>
									<SelectContent>
										<SelectGroup>
											{data.engineerlist.map((item, index)=>(
												<SelectItem value={item.seq}>{item.eng_name}</SelectItem>
											))}
										</SelectGroup>
									</SelectContent>
								</Select>
							</div>
							<div className='w-full lg:w-fit flex flex-row justify-between items-end lg:mr-10'>
								<Label className='w-12 text-lg mr-4 lg:mr-10 mb-2.5'>주택명</Label>
								<Select value={selectHouseSeq} onValueChange={(value)=>setSelectHouseSeq(value)}>
									<SelectTrigger className="w-52 mt-5 h-12">
										<SelectValue placeholder="주택 선택"/>
									</SelectTrigger>
									<SelectContent>
										<SelectGroup>
											{data.houselist.map((item, index)=>(
												<SelectItem value={item.seq}>{item.house_name}</SelectItem>
											))}
										</SelectGroup>
									</SelectContent>
								</Select>
							</div>
							<div className='w-full lg:w-fit flex flex-row justify-between items-center mt-4 lg:mr-10'>
								<Label className='w-11 text-lg mr-7'>층수</Label>
								<div className='relative'>
									<Input className="w-40 text-lg" value={inputFloor} onChange={(event)=>setInputFloor(event.target.value)}
										placeholder="층수만 입력"/>
									<Label className='absolute text-lg text-black right-4 bottom-2.5'>층</Label>
								</div>
							</div>
							<div className='w-full lg:w-fit flex flex-row justify-between items-center mt-4 lg:mr-10'>
								<Label className='w-25.5 text-lg mr-3'>세부 위치</Label>
								<Input className="w-40 text-lg" value={inputDetailedLocation} onChange={(event)=>setInputDetailedLocation(event.target.value)}
									placeholder="호수나 세부 위치 입력"/>
							</div>
							<div className='w-full lg:w-fit flex flex-row justify-between items-center mt-4'>
								<Label className='w-25.5 text-lg mr-3'>우선 순위</Label>
								<RadioGroup className="flex flex-row space-x-2 h-12" value={radioPriority}  onValueChange={(value)=>setRadioPriority(value)}>
									<div className="flex flex-row items-center space-x-2">
										<RadioGroupItem value="0" id="0" />
										<Label className="ml-2 text-lg">일반</Label>
									</div>
									<div className="flex flex-row items-center space-x-2">
										<RadioGroupItem value="1" id="1" />
										<Label>긴급</Label>
									</div>
								</RadioGroup>
							</div>
							<Textarea
								className="w-full resize-none mt-4 lg:mt-10 text-lg"
								rows="6"
								value={inputContent}
								onChange={(event)=>setInputContent(event.target.value)}
								placeholder="요청 내용을 작성 해 주세요"
							/>
							
						</div>
						{
						isPopUpShowing && <ImageUploadPopup isPopUpShowing={isPopUpShowing} setIsPopUpShowing={setIsPopUpShowing} previewImgUrls={previewImgUrls} onImagesDropped={onImagesDropped} onDeleteButtonClicked={onImageDeleteButtonClicked} isObject={false} />}

						{
						previewImgUrls.length === 0 ?
						<div className='w-full h-72 bg-white mt-10 rounded-md' style={{ backgroundImage: `url(${image_dropbox_background})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
							<ImageDropBox onDrop={onImagesDropped}/>
						</div>
						:
						<div>
							<div className='mt-6 flex justify-end'>
								<Button variant='ghost' className='w-52 self-end mt-6' onClick={()=>setIsPopUpShowing(true)}><FolderCog className='w-4 h-4 mr-1'/>사진 삭제 및 변경</Button>
							</div>
							<Carousel className='w-full h-72 bg-white mt-4 rounded-md' responsive={responsive} swipeable={true} draggable={true}>
								{previewImgUrls.map((image, index) => (
									<div className='max-w-full max-h-56 self-center flex justify-center relative overflow-hidden'>
										<Zoom>
											<img className='max-w-full max-h-full object-cover' src={image}/>
										</Zoom>
										<Button className='absolute right-2 top-1' variant='icon' size='icon' onClick={(e)=>onImageDeleteButtonClicked([index], e)}><X className='w-5.5 h-5.5'/></Button>
									</div>
								))}
							</Carousel>
						</div>
						}
						<div className='mt-14 flex justify-end gap-4'>
							<Button className='text-lg' variant="ghost" size="big" onClick={() => navigate(-1)}>취소</Button>
							<Button className='text-lg' size="big" onClick={() => onSubmitCliked()}>저장</Button>
						</div>
					</div>
				</div>
				{/* <Button variant="big" size="big" onClick={() => insertAutoSend.mutate()}>저장</Button>
				<Button className='w-64 self-end mt-10' variant="big" size="big" onClick={()=>{}}><Pencil className='w-5.5 h-5.5 mr-2'/>시설 관리 요청</Button> */}
			</div>
		</div>
		}
		</>
	)
}

export default FacilityRegister
