import {useState, useEffect, useContext} from 'react'
import { Button } from '../ui/button'

import { cn } from "@/components/lib/utils"
import { Input } from "../ui/input"
import { Label } from "../ui/label"
import { GreenLabel } from "@/components/ui/label"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select"
import { Textarea } from "../ui/textarea"
import { toast } from "../ui/use-toast"
import { MySpinner } from '../ui/spinner'
import { useQuery } from "@tanstack/react-query";
import Axios from "@/components/lib/axios";

const InAppTemplateEditor = ({inputSubject, setInputSubject, inputContent, setInputContent, inputLinkedPageUrl, setInputLinkedPageUrl}) => {
	const { isLoading: isLoadingAutoVariableNames, error, data: autoVariableNames } = useQuery({queryKey:["auto_variable_name"], queryFn: () =>
		Axios.get('/api_message/common/auto_variables').then((res) => {
			return res.data;
		})});

	useEffect(() => {
		
	}, []);

	function onSubmit(data) {
		toast({
			title: "You submitted the following values:",
			description: (
			<pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
				<code className="text-white">{JSON.stringify(data, null, 2)}</code>
			</pre>
			),
		})
	}
	
	return (
		<div className="w-full mt-12 lg:flex lg:flex-row justify-between">
            <div className="lg:w-1/2  flex flex-col w-full">
                <GreenLabel className="">제목</GreenLabel>
                <Input className="mt-3" value={inputSubject} onChange={(event)=>setInputSubject(event.target.value)}/>
				<GreenLabel className="mt-8">내용</GreenLabel>
                <Textarea
					className="resize-none mt-3"
					rows="14"
					value={inputContent}
					onChange={(event)=>setInputContent(event.target.value)}
                />
				<GreenLabel className="mt-8">시스템 자동 입력 변수</GreenLabel>
				{
					isLoadingAutoVariableNames
					? <MySpinner/>
					: 	<div className="flex flex-col items-center justify-center">
							<div className="w-full p-4 rounded-md">
								<div className="flex flex-wrap justify-start gap-2">
									{
										autoVariableNames.map(variableName => {
											return <Button onClick={()=>setInputContent((prev)=> prev + variableName.variable_name)}>{variableName.variable_name}</Button>})
									}
								</div>
							</div>
						</div>
				}
				<GreenLabel className="mt-4">연결 페이지 URL</GreenLabel>
				<Input className="mt-3" value={inputLinkedPageUrl} onChange={(event)=>setInputLinkedPageUrl(event.target.value)}/>
            </div>
			
			<div className="relative lg:w-1/2 w-full">
				<div className="sticky pt-4 top-0">
					<div className='block'>
						<div className="w-[532px] h-[576px] bg-[url('/public/inapp.svg')] m-auto bg-no-repeat">
							<div className="relative top-[60px] m-auto w-[450px] select-none">
								<div className='flex flex-col bg-white p-6 rounded-xl shadow gap-3'>
									<div className='text-base font-bold line-clamp-1'>{inputSubject}</div>
									<pre className='text-base line-clamp-[15]'>{inputContent}</pre>
									{ inputLinkedPageUrl !== "" && 
										<Button variant="outline" className="border-none bg-transparent text-red-700 w-20">자세히 보기</Button> }
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default InAppTemplateEditor