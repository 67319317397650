import React, {useState, useEffect, useRef} from 'react'
import axios from "axios";
import { useNavigate } from "react-router-dom"
import cn from 'classnames'
import DashboardList from '../../../../components/dashboardlist';

// css
import Style from './index.module.css'

import PagePrev from '@/assets/image/ico/ico-page-prev.svg'
import PageNext from '@/assets/image/ico/ico-page-next.svg'
import AdminIcon from '@/assets/image/ico/ico-access-list01.svg'
import PhotoImg01 from '@/assets/image/etc/etc-photo-list01.png'
import RemoveIcon from '@/assets/image/ico/ico-remove01.svg'
import SpaceReport from '@/assets/image/ico/ico-report.svg'
import SpaceReq from '@/assets/image/ico/ico-req02.svg'
import ReqImg from '@/assets/image/etc/etc-req01.jpg'
import CntList01 from '@/assets/image/ico/ico-space-cnt01.svg'
import CntList02 from '@/assets/image/ico/ico-space-cnt01.svg'
import CntList03 from '@/assets/image/ico/ico-space-cnt01.svg'
import CntList04 from '@/assets/image/ico/ico-space-cnt01.svg'
import Cmm from '@/assets/image/ico/ico-cmm.svg'
import Notice from '@/assets/image/ico/ico-noti.svg'
import More from '@/assets/image/ico/ico-arr-more.svg'

import Smile from '@/assets/image/ico/ico-smile.svg'
import SpaceHome from '@/assets/image/ico/ico-space-home.svg'
import SpaceIcon01 from '@/assets/image/ico/ico-space-list01.svg'
import SpaceIcon02 from '@/assets/image/ico/ico-space-list02.svg'
import SpaceIcon03 from '@/assets/image/ico/ico-space-list03.svg'
import SpaceIcon04 from '@/assets/image/ico/ico-space-list04.svg'

import CostList from '@/assets/image/ico/ico-cost-list.svg'
import SpacePrev from '@/assets/image/ico/ico-space-prev.svg'
import SpaceNext from '@/assets/image/ico/ico-space-next.svg'

const Index = ({shName, shIdx}) => {
  const [houseList, setHouseList] = useState([]);
  const [oneres, setOneRes] = useState("");
  const [houseListS1, setHouseListS1] = useState([]);
  const [houseListS2, setHouseListS2] = useState([]);
  const [houseListS3, setHouseListS3] = useState([]);
  const [houseListS4, setHouseListS4] = useState([]);
  const [houselive, setHouseLive] = useState([]);
  const navigate = useNavigate();

  const [ createToggle, setCreateToggle] = useState(false);
  const handleToggle = () => {
    setCreateToggle(!createToggle);
  }

	const [spaceActive, setSpaceActive] = useState(1);
	const handlePrev = () => {
  		if(spaceActive > 1){
			setSpaceActive(spaceActive - 1);
		}
	}
	const handleNext = () => {
		if(spaceActive < 3){
			setSpaceActive(spaceActive + 1);
		}
	}

  const pinCreate1 = () => {
		navigate("/admin/space/pincreate1");
	}

  const pinCreate2 = () => {
		navigate("/admin/space/pincreate2");
	}

  const geTheHousePinInfo = async () => {
      axios.post(process.env.REACT_APP_API_HOUSERECENTONE, {
        seq: shIdx
      })
      .then(function (response) {
        if(response.data.code == '200'){
          setHouseListS1(response.data.data_list.data_s1);
          setHouseListS2(response.data.data_list.data_s2);
          setHouseListS3(response.data.data_list.data_s3);
          setHouseListS4(response.data.data_list.data_s4);
          setHouseLive(response.data.data_list.data_g);
          //console.log(response.data.data_list.data_g);
        }else if(response.data.code == '400'){
          console.log(response.data.code);
        }
        }).catch(function (error) {
          console.log("에러", error);
      });
  }

  const sharehouseDetail = (e) => {
    //console.log(e);
    navigate('/admin/space/detail/'+e);
    //console.log(e);
  }

  useEffect(() => {
		geTheHousePinInfo();
	}, []);

	return (
		<div className={Style.wrapper}>

        <section className={Style.spacedHeader}>

        <div className={Style.pageTitle}>
          <span>{shName}</span>
        </div>
        </section>

        <section className={Style.spaceWrap}>
        <div className={Style.spaceControl}>
        <button type={'button'} className={`${spaceActive === 1 ? Style.disabled : ''}`} onClick={handlePrev}><img src={SpacePrev} /></button>
        <button type={'button'} className={`${spaceActive === 3 ? Style.disabled : ''}`} onClick={handleNext}><img src={SpaceNext} /></button>
        </div>

        <div className={cn(Style.sitem, `${spaceActive === 1 ? Style.active : ''}`)}>
          <div className={Style.sico}>
            <img src={SpaceIcon01} />
            <span>개별거주공간</span>
          </div>
          <div className={Style.sbox}>
            <dl className={Style.type01}>
              {
              houseListS1.map((list, index) => {
                let livedata = [];
                {houselive.map((list) => {
                  livedata[list.hosu] = list.name;
                })}

                return (
                  <>
                    <dt key={index}><strong>{list.floor}층 {list.hosu}개</strong> 호실</dt>
                    <dd>
                      <ul>
                        
                        { list.sub_list.map((slist, index1) => {
                          //console.log(slist);
                          let hosu = slist.floor + String(index1+1).padStart(2, "0");
                          let hosudata = livedata[hosu];
                          
                          if(hosudata){
                            return (
                              <li key={index1} className={Style.on} onClick={() => sharehouseDetail(slist.idx)} style={{cursor:'pointer'}}>
                                  <div className={Style.key}>{slist.floor}{String(slist.hosu).padStart(2, "0")}</div>
                                  <div className={Style.value}>{hosudata}</div>
                              </li>
                            );
                          }else{
                            return (
                              <li key={index1} onClick={() => sharehouseDetail(slist.idx)} style={{cursor:'pointer'}}>
                                  <div className={Style.key}>{slist.floor}{String(slist.hosu).padStart(2, "0")}</div>
                                  <div className={Style.value}>-</div>
                              </li>
                            );
                          }
                        })}

                        {/*}
                        <DashboardList style={Style} data={list.hosu} floor={list.floor} houselive={houselive} />
                        {*/}
                        {/*}
                        {Array(list.hosu).fill(0).map((_, i) => {
                          return (
                            <li>
                              <div className={Style.key}>{list.floor}0{i+1}</div>
                              <div className={Style.value}>-</div>
                            </li>
                          )
                        })}
                        {*/}
                      </ul>
                    </dd>
                  </>
                );
              })}
            </dl>
          </div>
        </div>
        <div className={cn(Style.sitem, `${spaceActive === 2 ? Style.active : ''}`)}>
          <div className={Style.sico}>
            <img src={SpaceIcon02} />
            <span>공유공간</span>
          </div>
          <div className={Style.sbox}>
            <dl className={Style.type02}>
              {houseListS2.map((list, index) => {
                return (
                  <>
                    <dt key={index}><strong>{list.floor}층</strong></dt>
                    <dd>
                      <ul>
                        <li>
                          <div className={Style.type}>{list.etctxt}</div>
                          <div className={Style.minfo}>{list.pyungsu}평 / {list.juyoinfo}</div>
                          <div className={Style.mdesc}>{list.gayo}</div>
                        </li>
                      </ul>
                    </dd>
                  </>
                )
              })}
            </dl>
          </div>
        </div>
        <div className={cn(Style.sitem, `${spaceActive === 3 ? Style.active : ''}`)}>
          <div className={Style.sico}>
            <img src={SpaceIcon03} />
            <span>기타공간</span>
          </div>
          <div className={Style.sbox}>
            <dl className={Style.type02}>
              {houseListS4.map((list, index) => {
                return (
                  <>
                    <dt key={index}><strong>{list.floor}층</strong></dt>
                    <dd>
                      <ul>
                        <li>
                          <div className={Style.type}>{list.etctxt}</div>
                          <div className={Style.minfo}>{list.pyungsu}평 / {list.juyoinfo}</div>
                          <div className={Style.mdesc}>{list.gayo}</div>
                        </li>
                      </ul>
                    </dd>
                  </>
                )
              })}
            </dl>
          </div>
        </div>

        </section>

		</div>
	)
}

export default Index
