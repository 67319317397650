import { Button } from "@/components/ui/button"
import { Switch } from "@/components/ui/switch"
import { Checkbox } from "@/components/ui/checkbox"

import { Send, Pencil, Trash, Circle, X } from "lucide-react"

export const getColumns = (activeToggle, onEditButtonClicked, onDeleteButtonClicked) => {
  var columns;

  columns = [
    {
      accessorKey: "event_name",
      header: () => <div className="text-left">자동 전송 조건 / 이벤트</div>,
      cell: ({ row }) => <div>{row.getValue("event_name")}</div>,
    },
    {
      id: "actions",
      header: () => <div className="flex justify-end align-middle">
                        <div className="w-16 mr-1">활성</div>
                        <div className="w-16">편집</div>
                        <div className="w-16">삭제</div>
                    </div>,
      enableHiding: false,
      cell: ({ row }) => {
        const autoSend = row.original
  
        return (
          <div className="flex justify-end align-middle mr-5">
            <div className="flex items-center mr-4">
              <Switch id="switch_enable" checked={autoSend.is_auto_send_active} onCheckedChange={()=>activeToggle.mutate({autoSendId: autoSend.id, isAutoSendActive: !autoSend.is_auto_send_active})}/>
            </div>
            <Button variant="icon" size="icon" className="w-15 mr-1" onClick={()=>onEditButtonClicked(autoSend.id)}>
              <Pencil className="h-5 w-5"/>
            </Button>
            <Button variant="icon" size="icon" className="w-15" onClick={()=>onDeleteButtonClicked(autoSend.id)}>
              <Trash className="h-5 w-5"/>
            </Button>
         </div>

// <div className="flex justify-end align-middle mr-5">
// <Button variant="icon" size="icon" className="w-15 mr-1">
//   <Send className="h-5 w-5"/>
// </Button>
// <Button variant="icon" size="icon" className="w-15 mr-1">
//   <Pencil className="h-5 w-5"/>
// </Button>
// <Button variant="icon" size="icon" className="w-15">
//   <Trash className="h-5 w-5"/>
// </Button>
// </div>
        )
      },
    },
  ]
  

  return columns;
}