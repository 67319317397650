import React, { useState } from 'react'
import axios from "axios";
import { useNavigate } from "react-router-dom"
import { useGoogleLogin } from '@react-oauth/google'

import googleIcon from '@/assets/image/ico/ico-signup03.svg'

const GoogleSNSSocialLogin = () => {

  const navigate = useNavigate();

  const googleSocialLogin = useGoogleLogin({
    scope: "email profile",
    onSuccess: //(codeResponse) => console.log(codeResponse),
      async (res) => {
        //console.log(res.access_token);
        await axios({
          method: "post",
          url: process.env.REACT_APP_GOOGLE_AUTHCHK,
          data: { code: res.access_token, mode: "join" },
        })
        .then((res) => {
          //console.log(res);
          if(res.data.code == '200'){
              navigate("/joinsns", { state: res.data.snsdata });
              //window.alert("회원가입이 완료 되었습니다.");  
              //window.location.href="/login";
          }else if(res.data.code == '300'){
              window.alert("이미 가입된 회원입니다.");  
              window.location.href="/login";
          }else if(res.data.code == '400'){
              console.log('400');
          }
        })
        .catch((e) => console.log(e));
      },
    onError: (errorResponse) => {
      console.error(errorResponse);
    },
    //flow: 'auth-code',
  })

  {/*}
    <div className='social_login_box google' onClick={() => googleSocialLogin()}>
      <div className='social_login_image_box'>
        <img src={googleIcon} alt='google_login' />
      </div>
      <div className='social_login_text_box'>구글로 시작하기</div>
      <div className='social_login_blank_box'> </div>
    </div>
  {*/}

  return (
    <a href="#" onClick={() => googleSocialLogin()}>
				<img src={googleIcon} />
				<span>구글로 간편 가입하기</span>
		</a>
  )
}


export default GoogleSNSSocialLogin