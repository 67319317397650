import React, {useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom'
import cn from 'classnames'
import { GoogleOAuthProvider } from '@react-oauth/google'


// css
import Style from './index.module.css'
import signup01 from '@/assets/image/ico/ico-signup01.svg'
import signup02 from '@/assets/image/ico/ico-signup02.svg'
import signup03 from '@/assets/image/ico/ico-signup03.svg'
import Logo from '@/assets/video/logo.mp4'


import GoogleSNSSocialLogin from '../../components/googleSNSSocialLogin'

const Index = () => {
	const [statemode, setStatemode] = useState("");
	const { state } = useLocation();

	const REST_API_KEY = process.env.REACT_APP_KAKAO_RESTAPI_KEY;
  	const REDIRECT_URI = process.env.REACT_APP_KAKAO_REDIRECT_URI;
	//const REDIRECT_URI = "https://project-code.kr/oauth/kakao/callback";
  	const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code`;
	const GOOGLE_REST_API_KEY = process.env.REACT_APP_GOOGLE_CLIENT_ID;

	const modechange = () => {
		if(state){
			setStatemode(state);
		}
	}

	useEffect(() => {
		modechange();
	}, []);


	return (
		<div className={Style.wrapper}>

			<section className={Style.signupWrap}>
				<div className={Style.slogo}>
					<video width="100%" height="100%" id="v_player" playsinline="" allowfullscreen="true" autoPlay loop muted>
						<source src={Logo} type="video/mp4" />
					</video>
				</div>
				<div className={Style.stit}>
				{ statemode === 'Y' ?
					`어서오세요. 시소 어드민 회원가입 입니다.`
					:
					`어서오세요. 아이부키 공유주택 입니다.`
				}
				</div>
				
				<div className={Style.sdesc}>회원가입하고 행복한 공유생활 하세요</div>
				<ul>
					<li>
						<a href="/join_step1">
							<img src={signup01} />
							<span>이메일로 가입하기</span>
						</a>
					</li>
					<li>
						<a href={KAKAO_AUTH_URL}>
							<img src={signup02} />
							<span>카카오톡 간편 가입하기</span>
						</a>
					</li>

					<li>
						<GoogleOAuthProvider clientId={`${GOOGLE_REST_API_KEY}`}>
							<GoogleSNSSocialLogin />
						</GoogleOAuthProvider>
					</li>

					{/*}
					<li>
						<a href="#">
							<img src={signup03} />
							<span>구글로 간편 가입하기</span>
						</a>
					</li>
					{*/}
				</ul>
			</section>

		</div>
	)
}

export default Index
