import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom"
import axios from "axios";
import { ColorRing } from 'react-loader-spinner';
import { getToken } from "../../services/TokenService";
import cn from 'classnames'
import { Button } from '@/components/ui/button'

// css
import Style from './index.module.css'
import Cmm from '@/assets/image/ico/ico-cmm.svg'
import PagePrev from '@/assets/image/ico/ico-page-prev.svg'
import PageNext from '@/assets/image/ico/ico-page-next.svg'
import ProfileImg from '@/assets/image/etc/etc-profile01.png'
import SpaceReq from '@/assets/image/ico/ico-req03.png'


const Index = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const [board, setBoard] = useState({});
	const [replylist, setReplyList] = useState([]);
	const [admChk, setAdmChk] = useState("N");
	const [inputCount, setInputCount] = useState(0);
	const [replyContent, setReplyContent] = useState("");
	const [replyloading, setReplyLoading] = useState(false);

	const onFacilityReg = () => {
		navigate("/admin/space/reqRegister/"+`${id}`);
	}

	const getBoard = async () => {
	
		const token = getToken();

		if(token !== null){
			const local_token = JSON.parse(token);
			axios({
				method: 'get',
				url: process.env.REACT_APP_API_COMMUNICATION_VIEW + `/${id}`,
				mode: "cors",
				credentials: "same-origin",
				headers: {
					Authorization: "Bearer " + local_token.access_token
				},
			}).then((res) => {
				setBoard(res.data);
			}).catch((err) => {	
				console.log("에러", err);
			})
		}
	};

	const getReply = () => {
		const token = getToken();

		if(token !== null){
			const local_token = JSON.parse(token);
			axios({
				method: 'post',
				url: process.env.REACT_APP_API_COMMUNICATION_REPLYLIST,
				mode: "cors",
				credentials: "same-origin",
				headers: {
					Authorization: "Bearer " + local_token.access_token
				},
				data:{
					seq: `${id}`,
					page: 1
				},
			}).then((res) => {
				setReplyList(res.data.list);
				setReplyLoading(true);
			}).catch((err) => {	
				console.log("에러", err);
			})
		}
	};

	const MAX_LENGTH = 1000;
	const onTextareaHandler = (e) => {
		if (e.target.value.length > MAX_LENGTH) {
			e.target.value = e.target.value.slice(0, MAX_LENGTH);
		  }
		  setInputCount(e.target.value.length);
		  setReplyContent(e.target.value);
	};

	const replysave = () => {
		if(replyContent === ""){
			alert("댓글 내용을 입력해주세요.");
			return false;
		}else{
			const token = getToken();

			if(token !== null){
				const local_token = JSON.parse(token);
				
				axios({
					method: 'post',
					url: process.env.REACT_APP_API_COMMUNICATION_REPLYSAVE,
					mode: "cors",
					credentials: "same-origin",
					headers: {
						Authorization: "Bearer " + local_token.access_token
					},
					data:{
						seq: `${id}`,
						contents: replyContent
					},
				}).then((res) => {
					//console.log(res.data);
					if(res.data.code == '200'){
						window.location.reload();
					}else if(res.data.code == '400'){
						console.log("에러", res.data.result);	
					}
				}).catch((err) => {	
					console.log("에러", err);
				})
			}
		}
	}

	const replydel = (e) => {
		if(window.confirm("정말로 삭제하시겠습니까?")){
			const token = getToken();

			if(token !== null){
				const local_token = JSON.parse(token);
				
				axios({
					method: 'post',
					url: process.env.REACT_APP_API_COMMUNICATION_REPLYDEL,
					mode: "cors",
					credentials: "same-origin",
					headers: {
						Authorization: "Bearer " + local_token.access_token
					},
					data:{
						seq: `${id}`,
						idx: e
					},
				}).then((res) => {
					//console.log(res.data);
					if(res.data.code === '200'){
						alert("삭제되었습니다.");
						window.location.reload();
					}else if(res.data.code === '300'){
						alert("삭제권한이 없습니다.");
					}else if(res.data.code === '400'){
						console.log("에러", res.data.result);	
					}
				}).catch((err) => {	
					console.log("에러", err);
				})
			}
		}
	}

	const tokencall = () => {
		const token = getToken();

		if(token !== null){
			const local_token = JSON.parse(token);
			//console.log(local_token.adchk);
			if(local_token.adchk === "Y"){
				setAdmChk("Y");
			}else{
				setAdmChk("N");
			}
		}
	}

	const chgState = (e) => {
		//console.log(e.target.value);
		if(window.confirm("현재 상태값을 변경하시겠습니까?")){
			//console.log(e.target.value);
			const token = getToken();

			if(token !== null){
				const local_token = JSON.parse(token);
				
				axios({
					method: 'post',
					url: process.env.REACT_APP_API_COMMUNICATION_STATECHANGE,
					mode: "cors",
					credentials: "same-origin",
					headers: {
						Authorization: "Bearer " + local_token.access_token
					},
					data:{
						seq: `${id}`,
						changedata: e.target.value
					},
				}).then((res) => {
					//console.log(res.data);
					if(res.data.code == '200'){
						window.location.reload();
					}else if(res.data.code == '400'){
						console.log("에러", res.data.result);	
					}
				}).catch((err) => {	
					console.log("에러", err);
				})
			}
		}
	}

	useEffect(() => {
		tokencall();
		getBoard();
		getReply();
	}, []);

	return (
		<div className={Style.wrapper}>

            <div className={Style.subTitle}>
				<img src={Cmm} />
				<span>1:1 커뮤니케이션 관리페이지</span>
				
				<div className={Style.profile}>
					<div className={Style.upic} style={{background:`url(${ProfileImg})`}}>&nbsp;</div>
					<div className={Style.uname}>{board.housename ? board.housename : ""} {board.hosu ? board.hosu + "호" : "" } {board.member_name}</div>
				</div>

			</div>

			<div className={Style.viewHeader}>
				<div className={Style.vtit}>[{board.gubun}] {board.subject}</div>
				<div className={Style.lt}>
					<select className={Style.select} value={board.gubun_code} name="category" >
						<option value="S1">임대료 / 관리비</option>
						<option value="S2">주거시설</option>
						<option value="S3">공유공간</option>
						<option value="S4">생활커뮤니티</option>
						<option value="S5">기타문의</option>
					</select>

					{admChk === "Y" ?
					<select className={Style.select} value={board.status} onChange={(e) => chgState(e)}>
						<option value="1">접수</option>
						<option value="2">확인</option>
						<option value="3">처리완료</option>
					</select>
					:""
					}
				</div>
				<div className={Style.rt}>

					<div className={Style.vinfo}>
						<span>{board.regdate}</span>
					</div>
				</div>
			</div>

			<div className={Style.viewCont} dangerouslySetInnerHTML={{__html: board.contents}}>		
			</div>
			
			<div className={Style.viewEtc}>
				
				{
				admChk === "Y" ?
					board.gubun_code === "S2" && board.status === "1" ?
					<div className='flex flex-row justify-center mt-10'>
						<Button variant="big" size="big" onClick={() => onFacilityReg()}>
							시설관리 요청서 작성
						</Button>
					</div>
					:""
				:""
				}


				{/*}
				<div className={Style.overview}>
					<textarea>&nbsp;</textarea>
					<div className={Style.cnt}>0/100</div>
				</div>
				{*/}
				
				
				{
					replyloading === true ?
						replylist.map((list, index) => {
							return(
							<div className={Style.answer} key={index}>
								<div className={Style.ainfo}>
									<div className={Style.aname}>{list.memname}
									{
									admChk === "Y" ?
									<div style={{marginLeft: '10px'}}><button type="button" onClick={(e) => replydel(list.idx)} style={{color: '#000',fontWeight: '700',padding: '4px 7px',background: '#40EFC1',borderRadius: '2px',border: '0'}}>삭제</button></div>
									:
										list.self === "Y"?
											<div style={{marginLeft: '10px'}}><button type="button" onClick={(e) => replydel(list.idx)} style={{color: '#000',fontWeight: '700',padding: '4px 7px',background: '#40EFC1',borderRadius: '2px',border: '0'}}>삭제</button></div>
										:""
									}
									</div>
									<div className={Style.adate}>{list.regdate}</div>
								</div>
								<div className={Style.adesc}>{list.contents}</div>
							</div>
							)
						})
					:""
				}
				
				<div className={Style.overview}>
					<textarea name="reply" onChange={onTextareaHandler} value={replyContent} maxLength={1000}>{replyContent}</textarea>
					<div className={Style.cnt}>{inputCount}/1000</div>
				</div>
				
				<div className={Style.mbtn}>
					<button type="button" onClick={replysave}>등록하기</button>
				</div>
			</div>
			

		</div>
	)
}

export default Index
