import React, {useState, useEffect, useRef} from 'react'
import axios from "axios";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import styled from 'styled-components'

import cn from 'classnames'

// css
import Style from './index.module.css'
import Notice from '@/assets/image/ico/ico-noti.svg'
import PagePrev from '@/assets/image/ico/ico-page-prev.svg'
import PageNext from '@/assets/image/ico/ico-page-next.svg'
import SearchImg from '@/assets/image/ico/ico-search.svg'

import { getToken, removeToken } from "../../services/TokenService";

const customUploadAdapter = (loader) => { // (2)
	return {
		upload(){
			return new Promise ((resolve, reject) => {
				const data = new FormData();
				 loader.file.then( (file) => {
						data.append("attach", file);

						axios.post(process.env.REACT_APP_API_NOTICE_IMAGEUPLOAD, data)
							.then((res) => {
								resolve({
									default: res.data.imgUrl
								});
							})
							.catch((err)=>reject(err));
					})
			})
		}
	}
}

function uploadPlugin (editor){ // (3)
	editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
		return customUploadAdapter(loader);
	}
}

const Index = () => {
	const [movieContent, setMovieContent] = useState({content: ''})
	const [houseList, setHouseList] = useState([]);
	const [gubun, setGubun] = useState("1");
	const [gethouse, setGetHouse] = useState("");
	const [title, setTitle] = useState("");

	const [tagItem, setTagItem] = useState('')
  	const [tagList, setTagList] = useState([])

	const titlefocusRef = useRef(null);

	const onKeyPress = e => {
		if (e.target.value.length !== 0 && e.key === 'Enter') {
			submitTagItem()
		}
	}

	const submitTagItem = (props) => {
		let cnt = tagList.length;
		if(cnt < 10){
			let updatedTagList = [...tagList]
			updatedTagList.push(tagItem)
			setTagList(updatedTagList)
			setTagItem('') 
		}
	}

	const deleteTagItem = e => {
		const deleteTagItem = e.target.parentElement.firstChild.innerText
		const filteredTagList = tagList.filter(tagItem => tagItem !== deleteTagItem)
		setTagList(filteredTagList)
	}

	const gethouseList = async (e) => {
		//const resp = await (await axios.get(process.env.REACT_APP_API_MANAGERHOUSELIST)).data; 
		//setHouseList(resp.list); 
		const token = getToken();
		
		if(token !== null){
			const local_token = JSON.parse(token);

			axios({
				method: 'post',
				url: process.env.REACT_APP_API_MANAGERHOUSELIST,
				mode: "cors",
				credentials: "same-origin",
				data:{
					company: local_token.company,
				},
			}).then((res) => {
				setHouseList(res.data.list); 
			}).catch((err) => {	
				console.log("에러", err);
			})
		}
	}

	const onReg = () => {
		//console.log(tagList);
				
		if(title === ""){
			alert("제목을 입력해주세요.");
			titlefocusRef.current.focus();
			return;
		}

		if(movieContent.content  === ""){
			alert("내용을 입력해주세요.");
			return;
		}
		
		const token = getToken();

		if(token !== null){
			const local_token = JSON.parse(token);

			const formData = new FormData();
			formData.append('title',title)
			formData.append('gubun',gubun)
			formData.append('house',gethouse)
			formData.append('tags',tagList)
			formData.append('content',movieContent.content)
			formData.append('company', local_token.company)
			
			axios({
				method:'post',
				url:process.env.REACT_APP_API_NOTICE_REG,
				mode: "cors",
				credentials: "same-origin",
				headers: {
					"Content-Type": "multipart/form-data", 
					Authorization: "Bearer " + local_token.access_token,
				},
				data: formData,
			})
				.then((result)=>{
					if(result.data.code == 200){
						alert("등록 되었습니다.");
						window.location.href="/admin/notice";
					}else{
						console.log(result)
					}
			})
				.catch((error)=>{console.log('요청실패')
				console.log(error)  
			})
		}
	}

	useEffect(() => {
		gethouseList();
	}, []);

	return (
		<div className={Style.wrapper}>

            <div className={Style.subTitle}>
				<img src={Notice} />
				<span>공지사항 페이지</span>
			</div>

			<div className={Style.regForm}>
				<div className={Style.formRow}>
					<div className={Style.formItem}>
						<input type="text" className={Style.inp} placeholder="제목을 입력하세요" ref={titlefocusRef} value={title} onChange={(e) => setTitle(e.target.value)} />
					</div>
				</div>

				<div className={Style.formRow}>
					<div className={Style.formItem}>
						<select className={Style.select} value={gubun} onChange={(e) => setGubun(e.target.value)}>
							<option value="1">주택별 공지</option>
							<option value="2">전체공지</option>
						</select>
						{gubun === '1'?
						<select className={Style.select} value={gethouse} onChange={(e) => setGetHouse(e.target.value)}>
							<option value="">주택 선택</option>
							{houseList.map((list) => (
								<option key={list.seq} value={list.seq}>{list.house_name}</option>
							))}
						</select>
						: ""
						}
					</div>
				</div>

				{/*}
				<div className={Style.formRow}>
					<div className={Style.formItem}>
						<input type="text" className={Style.inp} placeholder="태그를 설정하세요(최대10개)" />
					</div>
				</div>
				{*/}

				
				<TagBox>
					{tagList.map((tagItem, index) => {
					return (
						<TagItem key={index}>
						<Text>{tagItem}</Text>
						<Button onClick={deleteTagItem}>X</Button>
						</TagItem>
					)
					})}
					<TagInput
					type='text'
					placeholder='태그를 설정하세요.(최대 10개)'
					tabIndex={2}
					onChange={e => setTagItem(e.target.value)}
					value={tagItem}
					onKeyPress={onKeyPress}
					style={{color: '#40EFC1', fontSize: '20pt', fontWeight: '700'}}
					/>
				</TagBox>
					

				<div className={Style.formRow}>
					<div className={Style.editor} style={{height:'100%'}}>
					<CKEditor
						editor={DecoupledEditor}
						data=""
						config={{
							extraPlugins: [uploadPlugin],
							mediaEmbed: {
								previewsInData: true
							},
							toolbar: ['heading', '|', 'bold', 'italic', '|', 'imageUpload', , 'mediaEmbed', '|', 'blockQuote', 'link', '|', 'numberedList', 'bulletedList', '|', 'undo', 'redo']
						}}
						onReady={editor => {
							if( editor.ui.getEditableElement() ){
								editor.ui.getEditableElement().parentElement.insertBefore(
									editor.ui.view.toolbar.element,
									editor.ui.getEditableElement()
								);
							}
							// You can store the "editor" and use when it is needed.
							//console.log('Editor is ready to use!', editor);
						}}
						onChange={(event, editor) => {
							const data = editor.getData();
							//console.log({ event, editor, data });
							setMovieContent({
							content: data
							})
						}}
						onBlur={(event, editor) => {
							//console.log('Blur.', editor);
						}}
						onFocus={(event, editor) => {
							//console.log('Focus.', editor);
						}}
					/>
					</div>
				</div>

				<div className={Style.btnGroup}>
					<button type="button" className={Style.btnCancel}>취소</button>
					<button type="button" className={Style.btnConfirm} onClick={onReg}>등록</button>
				</div>
			</div>

		</div>
	)
}

const WholeBox = styled.div`
  padding: 10px;
  height: 100vh;
`

const TagBox = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 50px;
  margin: 10px;
  /*padding: 0 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);*/
  border-radius: 10px;

  &:focus-within {
    border-color: tomato;
  }
`

const TagItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
  padding: 5px;
  background-color: white;
  border-radius: 5px;
  color: white;
  font-size: 15px;
`

const Text = styled.span`
  color: black;
`

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  margin-left: 5px;
  background-color: white;
  border-radius: 50%;
  color: tomato;
  font-size: 7.5pt;
  font-weight: 800;
`

const TagInput = styled.input`
  display: inline-flex;
  min-width: 150px;
  background: transparent;
  border: none;
  outline: none;
  cursor: text;
`

export default Index
