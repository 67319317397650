import * as React from "react"
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { ArrowUpDown, ChevronDown, MoreHorizontal } from "lucide-react"

import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"

import { Input } from "@/components/ui/input"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { getColumns } from "./columns"
import { DataTablePagination } from "@/components/ui/table_bottom_pagination"
import { SendMessageContext } from '../index'
import { useQuery, useMutation } from "@tanstack/react-query";
import Axios from "@/components/lib/axios";

export function UserSearchTable({type}) {
  const [sorting, setSorting] = React.useState([])
  const [columnFilters, setColumnFilters] = React.useState([])
  const [columnVisibility, setColumnVisibility] = React.useState({})
  const [rowSelection, setRowSelection] = React.useState({})
  //const columnsToUse = type === "alimtalk" ? columns_alimtalk : columns
  const { addedMembersList, setAddedMembersList } = React.useContext(SendMessageContext);
  const { isLoading, error, data } = useQuery(
    {
      queryKey:["user_search_table"], 
      queryFn: async () => {
        const res = await Axios.get('/api_message/send/rs_members');
        return res.data;
      },
      initialData: []
    }
  );

  const getMemberObject = (user) => {
    return ({
      id: user.mem_seq,
      building_name: user.sh_name,
      room_number: user.hs_hosu,
      name: user.mem_name, 
      phone_number: user.mem_hptel,
      email: user.mem_email
    })
  }
  const clickAddButton = (user) => {
    setAddedMembersList((prev)=>[...prev, getMemberObject(user)])
  }
  const columns = getColumns(type, clickAddButton);
  const [inputSearchName, setInputSearchName] = React.useState("")
  
  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  })

  return (
    <div className="w-full mt-6">
      <div className="flex items-center justify-between pb-4">
        <Input
          placeholder="회원 이름 검색"
          value={(table.getColumn("mem_name")?.getFilterValue()) ?? ""}
          onChange={(event) => {
            table.getColumn("mem_name")?.setFilterValue(event.target.value)
            setInputSearchName(event.target.value)
          }}
          className="flex max-w-sm"
        />
      </div>
      {
        inputSearchName !== "" 
        && <div>
            <Table>
              <TableBody>
                { table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map((row) => (
                      <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
                        {row.getVisibleCells().map((cell) => (
                          <TableCell key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={columns.length} className="h-24 text-center">
                        데이터가 없습니다.
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          <div className="py-4">
            <DataTablePagination table={table} />
          </div>
        </div>
      }
      
        
      
      
    </div>
  )
}
