import * as React from "react"
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { ArrowUpDown, ChevronDown, MoreHorizontal } from "lucide-react"

import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "@/components/ui/select"

import { Input } from "@/components/ui/input"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { getColumns } from "./columns"
import { DataTablePagination } from "@/components/ui/table_bottom_pagination"
import { useQuery } from "@tanstack/react-query";
import Axios from "@/components/lib/axios";

export function FacilityListTable({data, onArticleDeleteButtonClicked, memberType, isMobile}) {
  const [sorting, setSorting] = React.useState([])
  const [columnFilters, setColumnFilters] = React.useState([])
  const [columnVisibility, setColumnVisibility] = React.useState({})
  const [rowSelection, setRowSelection] = React.useState({})
  const columns = getColumns(onArticleDeleteButtonClicked, memberType, isMobile);
  const [shNamesList, setShNameList] = React.useState();
  const [memNamesList, setMemNameList] = React.useState();

  React.useEffect(() => {
    const shNames = new Set(data.map((item) => item.sh_name));
    const memNames = new Set(data.map((item) => item.mem_name));
    setShNameList(Array.from(shNames));
    setMemNameList(Array.from(memNames));
  }, [])

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  })

  const onSelectedStatus = (value) => {
    if(value === '-1')
      value = '';
    table.getColumn("fm_state")?.setFilterValue(value)
  }

  const onSelectedShNames = (value) => {
    if(value === '-1')
      value = '';
    table.getColumn("sh_name")?.setFilterValue(value)
  }

  const onSelectedMemNames = (value) => {
    if(value === '-1')
      value = '';
    table.getColumn("mem_name")?.setFilterValue(value)
  }

  return (
    <div className="w-full">
      {
        data.length > 1 &&
        <div className="flex flex-col lg:flex-row items-center">
          <Input
            placeholder="제목 검색"
            value={(table.getColumn("fm_title")?.getFilterValue()) ?? ""}
            onChange={(event) =>
              table.getColumn("fm_title")?.setFilterValue(event.target.value)
            }
            className="flex max-w-xs lg:mr-4"
          />
          <div className="flex flex-row w-full gap-4 pb-4">
            <Select onValueChange={(value)=>onSelectedStatus(value)}>
              <SelectTrigger className="w-52 mt-5 h-12 bg-sheet text-black self-center">
                <SelectValue placeholder="상태"/>
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value='-1'>전체 상태</SelectItem>
                  <SelectItem value='0'>요청 접수</SelectItem>
                  <SelectItem value='1'>수리 시작</SelectItem>
                  <SelectItem value='3'>수리 완료</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
            {
              shNamesList !== undefined && isMobile === false
              &&
              <Select onValueChange={(value)=>onSelectedShNames(value)}>
                <SelectTrigger className="w-52 mt-5 h-12 bg-sheet text-black self-center">
                  <SelectValue placeholder="주택"/>
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value='-1'>전체 주택</SelectItem>
                    {shNamesList.map((item, index)=>(
                      <SelectItem value={item}>{item}</SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            }
            {
              memNamesList !== undefined  && isMobile === false && memberType === 'AD'
              &&
              <Select onValueChange={(value)=>onSelectedMemNames(value)}>
                <SelectTrigger className="w-52 mt-5 h-12 bg-sheet text-black self-center">
                  <SelectValue placeholder="기사"/>
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value='-1'>전체 기사</SelectItem>
                    {memNamesList.map((item, index)=>(
                      <SelectItem value={item}>{item}</SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            }
          </div>
        </div>
      }
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  데이터가 없습니다.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {
        data.length > 1 &&
        <div className="py-4">
          <DataTablePagination table={table} />
        </div>
      }
      
    </div>
  )
}
