import React, {useState, useEffect, useRef} from 'react'
import axios from "axios";
import cn from 'classnames'

// css
import Style from './index.module.css'
import PagePrev from '@/assets/image/ico/ico-page-prev.svg'
import PageNext from '@/assets/image/ico/ico-page-next.svg'
import AdminIcon from '@/assets/image/ico/ico-access-list01.svg'
import ManagerIcon from '@/assets/image/ico/ico-access-list02.svg'
import PenIcon from '@/assets/image/ico/ico-pen01.svg'
import SpaceReq from '@/assets/image/ico/ico-dalla.svg'
import ExcelIcon from '@/assets/image/ico/ico-excel.svg'
import popClose from '@/assets/image/ico/ico-pop-close.svg'
import Dialog from './dialog.module.css'


const ReqList = () => {
	const [isPopup, setIsPopup] = useState(false);
	const [isPopup2, setIsPopup2] = useState(false);
	const [houseList, setHouseList] = useState([]);
	const [houserentmakelist, setHouseRentMakeList] = useState([]);
	const [rentmakelist, setRentMakeList] = useState([]);
	const [costdatey, setCostDatey] = useState("");
	const [costdatem, setCostDatem] = useState("");
	const [managerhouse, setManagerHouse] = useState("");
	const [managerfloor, setManagerFloor] = useState("");
	const [managerhosu, setManagerHosu] = useState("");
	const [listloading, setListLoading] = useState(false);
	const [popuptitle, setPopupTitle] = useState("");
	const [updatenum, setUpdateNum] = useState("");
	const [updateindex, setUpdateIndex] = useState("");
	const [uppopdcost, setUppopdCost] = useState("0");
	const [uppopkcost, setUppopkCost] = useState("0");
	const [choicenum, setChoiceNum] = useState(""); //수정팝업시 선택된 idx
	const [addnum, setAddnum] = useState("1");
	//수정팝업 기타 기본비용
	const [uppopetcdcost1, setUppopetcdCost1] = useState("0");
	const [uppopetcdcost2, setUppopetcdCost2] = useState("0");
	const [uppopetcdcost3, setUppopetcdCost3] = useState("0");
	const [uppopetcdcost4, setUppopetcdCost4] = useState("0");
	const [uppopetcdcost5, setUppopetcdCost5] = useState("0");
	//수정팝업 기타 추가비용
	const [uppopetckcost1, setUppopetckCost1] = useState("0");
	const [uppopetckcost2, setUppopetckCost2] = useState("0");
	const [uppopetckcost3, setUppopetckCost3] = useState("0");
	const [uppopetckcost4, setUppopetckCost4] = useState("0");
	const [uppopetckcost5, setUppopetckCost5] = useState("0");
	
	//기본비용
	const [defcost, setDefCost] = useState("");
	//반복적용
	const [repeatset, setRepeatSet] = useState("N");
	//전기비용 기본
	const [electcost, setElectCost] = useState("");
	//전기비용 개별
	const [electetccost, setElectetcCost] = useState("");
	//수도비용 기본
	const [sudocost, setSudoCost] = useState("");
	//수도비용 개별
	const [sudoetccost, setSudoetcCost] = useState("");
	//가스비용 기본
	const [gascost, setGasCost] = useState("");
	//가스비용 개별
	const [gasetccost, setGasetcCost] = useState("");
	//추가비용 항목
	const [additem, setAddItem] = useState("");
	//추가비용 기본
	const [addcost, setAddCost] = useState("");
	//추가비용 개별
	const [addetccost, setAddetcCost] = useState("");
	const [extraFees, setExtraFees] = useState([{ additemname: '', additemcost: '', additemeachcost: ''}]);
	const [additemhap, setAdditemhap] = useState("0");
	const [additemeachhap, setAdditemeachhap] = useState("0");
	const [kitalist, setKitaList] = useState([]);
	//엑셀업로드
	const [muploadexcelname, setMuploadexcelname] = useState("");
	const [uploadexcelfile, setUploadexcelfile] = useState("");
	const [excelFile, setExcelFile] = useState();
	const [excellist, setExcelList] = useState([{
		hosu: '',
		jungi: '',
		sudo: '',
		gas: '',
		etc1: '',
		etc1_d: '',
		etc1_k: '',
		etc2: '',
		etc2_d: '',
		etc2_k: '',
		etc3: '',
		etc3_d: '',
		etc3_k: '',
		etc4: '',
		etc4_d: '',
		etc4_k: '',
		etc5: '',
		etc5_d: '',
		etc5_k: ''
	}]);
	
	const handleChange = (index, event) => {
		const values = [...extraFees];
		const value = event.target.value;
		if(event.target.name !== 'additemname'){
			const removedCommaValue = Number(value.replaceAll(",", ""));
			//setSudoetcCost(removedCommaValue.toLocaleString());
			values[index][event.target.name] = removedCommaValue.toLocaleString();
		}else{
			values[index][event.target.name] = value;
		}
		setExtraFees(values);
	};

	const addOneExtraFee = () => {
		const num = parseInt(addnum) + 1;
		if(num > 5){
			alert("최대 추가갯수는 5개입니다.");
		}else{
			setExtraFees([...extraFees, { additemname: '', additemcost: '', additemeachcost: ''}]);
			setAddnum(num);
		}
	};

	const removeOneExtraFee = (index) => {
		const values = [...extraFees];
		values.splice(index, 1);
		setExtraFees(values);
		const num = parseInt(addnum) - 1;
		setAddnum(num);
	};
	
	const now = new Date();
	const _ = require('lodash');
	const years = _.range(now.getFullYear(), now.getFullYear() - 5, -1);
	
	const handleOpen = () => {
		setIsPopup(true);
	}

	const handleClose = () => {
		setIsPopup(false);
	}

	const handleClose2 = () => {
		setIsPopup2(false);
	}

	const gethouseList = async (e) => {
		const resp = await (await axios.get(process.env.REACT_APP_API_MANAGERHOUSELIST)).data; 
		setHouseList(resp.list); 
	}

	const addcostpanel = () => {
		const element = document.getElementById("cost_panel");
		const html = `<li>추가</li>`;
		element.append(html);

		//element.innerHTML = html;
	}

	const checkedHandler = (e) => {
		if (e.target.checked) { 
			setRepeatSet("Y");
		} else {
			setRepeatSet("N");
		}
	}

	const changeDefNum = (e) => {
		const value = e.target.value;
		const removedCommaValue = Number(value.replaceAll(",", ""));
		setDefCost(removedCommaValue.toLocaleString());
	};
	const changeElectNum = (e) => {
		const value = e.target.value;
		const removedCommaValue = Number(value.replaceAll(",", ""));
		setElectCost(removedCommaValue.toLocaleString());
	};
	const changeElectetcNum = (e) => {
		const value = e.target.value;
		const removedCommaValue = Number(value.replaceAll(",", ""));
		setElectetcCost(removedCommaValue.toLocaleString());
	};
	const changeSudoNum = (e) => {
		const value = e.target.value;
		const removedCommaValue = Number(value.replaceAll(",", ""));
		setSudoCost(removedCommaValue.toLocaleString());
	};
	const changeSudoetcNum = (e) => {
		const value = e.target.value;
		const removedCommaValue = Number(value.replaceAll(",", ""));
		setSudoetcCost(removedCommaValue.toLocaleString());
	};
	const changeGasNum = (e) => {
		const value = e.target.value;
		const removedCommaValue = Number(value.replaceAll(",", ""));
		setGasCost(removedCommaValue.toLocaleString());
	};
	const changeGasetcNum = (e) => {
		const value = e.target.value;
		const removedCommaValue = Number(value.replaceAll(",", ""));
		setGasetcCost(removedCommaValue.toLocaleString());
	};
	const changeAddNum = (e) => {
		const value = e.target.value;
		const removedCommaValue = Number(value.replaceAll(",", ""));
		setAddCost(removedCommaValue.toLocaleString());
	};
	const changeAddetcNum = (e) => {
		const value = e.target.value;
		const removedCommaValue = Number(value.replaceAll(",", ""));
		setAddetcCost(removedCommaValue.toLocaleString());
	};

	const rentfeemake = () => {
		if(costdatey === "" || costdatem === ""){
			alert("부과 년/월을 선택해주세요.");
			return false;
		}
		if(managerhouse === ""){
			alert("부과 주택을 선택해주세요.");
			return false;
		}
		if(defcost === ""){
			alert("기본비용을 입력해주세요.");
			return false;
		}
		if(electcost === ""){
			alert("전기 기본비용을 입력해주세요.");
			return false;
		}
		if(sudocost === ""){
			alert("수도 기본비용을 입력해주세요.");
			return false;
		}
		if(gascost === ""){
			alert("가스 기본비용을 입력해주세요.");
			return false;
		}

		let additemtotalcost = 0;
		let additemeachtotalcost = 0;
		let additemcnt = 0;
		extraFees.map((feedata) => {
			if(feedata.additemname !== ""){
				additemtotalcost = parseInt(additemtotalcost) + parseInt(removeComma(feedata.additemcost));
				additemeachtotalcost = parseInt(additemeachtotalcost) + parseInt(removeComma(feedata.additemeachcost));
				additemcnt++;
			}
		})
		//console.log(additemtotalcost+"/"+additemeachtotalcost);
		if(additemtotalcost > 0){
			setAdditemhap(additemtotalcost);
		}
		if(additemeachtotalcost > 0){
			setAdditemeachhap(additemeachtotalcost);
		}
		//setAdditemhap(additemtotalcost);
		//setAdditemeachhap(additemeachtotalcost);

		axios({
			method: 'post',
			url: process.env.REACT_APP_API_GETRENTFEEMAKE,
			headers: {
				"Content-Type": "multipart/form-data", // Content-Type을 반드시 이렇게 하여야 한다.
			  },
			data:{
			  excelfile: excelFile,
			  houseidx: managerhouse,
			  floor: managerfloor,
			  hosu: managerhosu,
			  defcost: defcost,
			  electcost: electcost,
			  electetccost: electetccost,
			  sudocost: sudocost,
			  sudoetccost: sudoetccost,
			  gascost: gascost,
			  gasetccost: gasetccost,
			  extrafees: JSON.stringify(extraFees)
			},
		  }).then((res) => {
			setHouseRentMakeList(res.data.list);
			setUploadexcelfile(res.data.excelfile);

			let data_arr = [];
			res.data.list.map((data)=>{
				if(data.useridx){
					//console.log(additemtotalcost);
					//console.log(additemtotalcost+"/"+additemeachtotalcost);
					let ex_etccost = 0;
					let ex_etceccost = 0;
					let ex_itemcnt = 0;
					let ex_items = [];
					let ex_additems = [];
					let ex_kiboncost = 0;
					let ex_etcjungi_d = 0;
					let ex_etcjungi = 0;
					let ex_etcsudo_d = 0;
					let ex_etcsudo = 0;
					let ex_etcgas_d = 0;
					let ex_etcgas = 0;
					if(data.exceldata === "Y"){
						ex_kiboncost = data.kiboncost ? dataforprice(data.kiboncost) : removeComma(defcost);
						ex_etcjungi_d = data.jungi_d ? dataforprice(data.jungi_d) : removeComma(electcost);
						ex_etcjungi = data.jungi ? dataforprice(data.jungi) : removeComma(electetccost);
						ex_etcsudo_d = data.sudo_d ? dataforprice(data.sudo_d) : removeComma(sudocost);
						ex_etcsudo = data.sudo ? dataforprice(data.sudo) : removeComma(sudoetccost);
						ex_etcgas_d = data.gas_d ? dataforprice(data.gas_d) : removeComma(gascost);
						ex_etcgas = data.gas ? dataforprice(data.gas) : removeComma(gasetccost);
						ex_etccost = parseInt(additemtotalcost) + parseInt(data.excel_etcdef_cost);
						ex_etceccost = parseInt(additemeachtotalcost) + parseInt(data.excel_etcobj_cost);
						ex_itemcnt = additemcnt + data.etc_cnt;
						ex_items = data.excel_etccost_list;
						if(extraFees){
							ex_additems = extraFees;
						}
					}else{
						ex_kiboncost = removeComma(defcost);
						ex_etcjungi_d = removeComma(electcost);
						ex_etcjungi = removeComma(electetccost);
						ex_etcsudo_d = removeComma(sudocost);
						ex_etcsudo = removeComma(sudoetccost);
						ex_etcgas_d = removeComma(gascost);
						ex_etcgas = removeComma(gasetccost);
						ex_etccost = additemtotalcost;
						ex_etceccost = additemeachtotalcost;
						ex_itemcnt = additemcnt;
						if(extraFees){
							ex_additems = extraFees;
						}
					}
					data_arr.push({
						houseidx: managerhouse,
						floor: data.floor,
						hosu: data.hosu,
						useridx: data.useridx,
						costdatey: costdatey,
						costdatem: costdatem,
						defcost: ex_kiboncost,
						ecost: ex_etcjungi_d,
						e_etc_cost: ex_etcjungi,
						scost: ex_etcsudo_d,
						s_etc_cost: ex_etcsudo,
						gcost: ex_etcgas_d,
						g_etc_cost: ex_etcgas,
						additemlist: ex_items,
						adddirectitemlist: ex_additems,
						additemcnt: ex_itemcnt,
						addcost: ex_etccost,
						addetccost: ex_etceccost
					});
				}
			})
			setRentMakeList(rentmakelist.concat(data_arr));
			setListLoading(true);
		  }).catch((err) => {	
			console.log("에러", err);
			setListLoading(false);
		  })
	}

	const rentfeeimpose =() => {
		if(listloading === false){
			alert("먼저 관리비생성버튼을 눌러서 관리비생성을 해주세요.");
			return false;
		}else{
			if(window.confirm("관리비를 부과하시겠습니까?")){
				//console.log(rentmakelist);
				const formData = new FormData();
				formData.append('houseidx',managerhouse)
				formData.append('costdatey',costdatey)
				formData.append('costdatem',costdatem)
				formData.append('uploadexcel', uploadexcelfile);
				formData.append('muploadexcelname', muploadexcelname);
				formData.append('rentmakelist',JSON.stringify(rentmakelist))

				axios({
					method:'post',
					url:process.env.REACT_APP_API_RENTFEEIMPOSE,
					data: formData,
				})
					.then((result)=>{
						if(result.data.code == 200){
							alert("등록 되었습니다.");
							window.location.href="/admin/space/admCostList";
						}else if(result.data.code == 300){
							alert("이미 등록된 관리비내역이 있습니다.");
						}else{
							console.log(result);
						}
				})
					.catch((error)=>{console.log('요청실패')
					console.log(error)  
				})
			}
		}
	}

	const popupload = (plist) => {
		//console.log(plist);
		setIsPopup(true);
		setKitaList(plist);
	}

	const updatepopup = (m, i, j) => {
		setUpdateNum(m);
		setUpdateIndex(i);
		setChoiceNum(j);
		if(m === "1"){ setPopupTitle("기본관리비"); setUppopdCost(rentmakelist[i].defcost); setUppopkCost("0"); }
		else if(m === "2"){ setPopupTitle("전기비용"); setUppopdCost(rentmakelist[i].ecost); setUppopkCost(rentmakelist[i].e_etc_cost); }
		else if(m === "3"){ setPopupTitle("수도비용"); setUppopdCost(rentmakelist[i].scost); setUppopkCost(rentmakelist[i].s_etc_cost); }
		else if(m === "4"){ setPopupTitle("가스비용"); setUppopdCost(rentmakelist[i].gcost); setUppopkCost(rentmakelist[i].g_etc_cost); }
		setIsPopup2(true);
		//setKitaList(plist);
	}

	const changeupcost = (e, m) => {
		const value = e.target.value;
		const removedCommaValue = Number(value.replaceAll(",", ""));
		if(m === "1"){
			setUppopdCost(removedCommaValue.toLocaleString());
		}else{
			setUppopkCost(removedCommaValue.toLocaleString());
		}
	};

	const changeupetccost = (e, m, j) => {
		const value = e.target.value;
		console.log(value);
		const removedCommaValue = Number(value.replaceAll(",", ""));
		if(m === "1"){
			if(j === "0"){ setUppopetcdCost1(removedCommaValue.toLocaleString());	}
			else if(j === "1"){ setUppopetcdCost2(removedCommaValue.toLocaleString()); }
			else if(j === "2"){ setUppopetcdCost3(removedCommaValue.toLocaleString()); }
			else if(j === "3"){ setUppopetcdCost4(removedCommaValue.toLocaleString()); }
			else if(j === "4"){ setUppopetcdCost5(removedCommaValue.toLocaleString()); }
		}else{
			if(j === "0"){ setUppopetckCost1(removedCommaValue.toLocaleString());	}
			else if(j === "1"){ setUppopetckCost2(removedCommaValue.toLocaleString()); }
			else if(j === "2"){ setUppopetckCost3(removedCommaValue.toLocaleString()); }
			else if(j === "3"){ setUppopetckCost4(removedCommaValue.toLocaleString()); }
			else if(j === "4"){ setUppopetckCost5(removedCommaValue.toLocaleString()); }
		}
	}

	//수정
	const updatekwanfee = (e, i, j) => {
		//rentmakelist
		console.log(e+"/"+i+"/"+j);
		if(i !== ""){
			if(e === "1"){
				rentmakelist[i].defcost = uppopdcost;
				houserentmakelist[j].kiboncost = uppopdcost;
			}else if(e === "2"){
				rentmakelist[i].ecost = uppopdcost;
				rentmakelist[i].e_etc_cost = uppopkcost;
				houserentmakelist[j].jungi_d = uppopdcost;
				houserentmakelist[j].jungi = uppopkcost;
			}else if(e === "3"){
				rentmakelist[i].scost = uppopdcost;
				rentmakelist[i].s_etc_cost = uppopkcost;
				houserentmakelist[j].sudo_d	= uppopdcost;
				houserentmakelist[j].sudo = uppopkcost;
			}else if(e === "4"){
				rentmakelist[i].gcost = uppopdcost;
				rentmakelist[i].g_etc_cost = uppopkcost;
				houserentmakelist[j].gas_d = uppopdcost;
				houserentmakelist[j].gas = uppopkcost;
			}
		}
		setIsPopup2(false);

		console.log(rentmakelist);
	}

	const fileInput = React.useRef(null);

	const excelupload = () => {
		fileInput.current.click();
		{/*}
		const formData = new FormData();
		formData.append('attach[]',"")
		
		axios({
			method:'post',
			url:process.env.REACT_APP_API_RENTFEEEXCELUPLOAD,
			mode: "cors",
			headers: {
				"Content-Type": "multipart/form-data", // Content-Type을 반드시 이렇게 하여야 한다.
			  },
			data: formData,
		})
			.then((result)=>{
				if(result.data.code == 200){
					
					console.log(result.data.list);

					result.data.list.forEach(element => {
						console.log(element);
					});
				}else{
					console.log(result)
				}
		})
			.catch((error)=>{console.log('요청실패')
			console.log(error)  
		})
		{*/}
	}

	const handleChangefile = (e) => {
		// 선택한 파일 정보를 콘솔에 출력
		if(e.target.files[0]){
			setExcelFile(e.target.files[0]);
			setMuploadexcelname(e.target.files[0].name);
		}
		//console.log(e.target.files[0].name);
	  };

	//let values = [];
	function addexceldata(event, index){
		let values = [];

		values[event.hosu]['jungi'] = event.jungi;
		values[event.hosu]["sudo"] = event.sudo;
		values[event.hosu]["gas"] = event.gas;
		if(event.etc1){
			values[event.hosu]["etc1"] = event.etc1;	
			values[event.hosu]["etc1_d"] = event.etc1_d;
			values[event.hosu]["etc1_k"] = event.etc1_k;
		}
		if(event.etc2){
			values[event.hosu]["etc2"] = event.etc2;	
			values[event.hosu]["etc2_d"] = event.etc2_d;
			values[event.hosu]["etc2_k"] = event.etc2_k;
		}
		if(event.etc3){
			values[event.hosu+"_etc3"] = event.etc3;	
			values[event.hosu+"_etc3_d"] = event.etc3_d;
			values[event.hosu+"_etc3_k"] = event.etc3_k;
		}
		if(event.etc4){
			values[event.hosu+"_etc4"] = event.etc4;	
			values[event.hosu+"_etc4_d"] = event.etc4_d;
			values[event.hosu+"_etc4_k"] = event.etc4_k;
		}
		if(event.etc5){
			values[event.hosu+"_etc5"] = event.etc5;	
			values[event.hosu+"_etc5_d"] = event.etc5_d;
			values[event.hosu+"_etc5_k"] = event.etc5_k;
		}		

		console.log("v=>"+values);
		//setExcelList([...excellist, values]);
	};

	function commaintvalue(num){
		let com = 0;
		if(num){
			com = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		}
		return com;
	}

	function removeComma(dt){
		let com = 0;
		if(dt){
			com = dt.replaceAll(",", "");
		}
		return com;
	}

	function dataforprice(num){
		let s = 0;
		if(num){
			s = parseInt(num);
		}
		return s;
	}
		
	useEffect(() => {
		gethouseList();
	}, []);

	useEffect(() => {
		console.log(houserentmakelist);
	  }, [houserentmakelist]);
	
	useEffect(() => {
	console.log(rentmakelist);
	}, [rentmakelist]);
	  //rentmakelist

	useEffect(() => {
		console.log(extraFees);
	}, [extraFees]);

	return (
		<div className={Style.wrapper}>

			<section className={Style.spacedHeader}>

				<div className={cn(Style.pageTitle, Style.type02)}>
					<img src={SpaceReq} />
					<span>관리비용 부과</span>
				</div>
			</section>

			<section className={Style.excelDownload}>
				<button type={'button'} onClick={excelupload}>
					<img src={ExcelIcon} />
					<span>엑셀 업로드</span>
					<input type="file" name="excel" ref={fileInput} onChange={handleChangefile} style={{width:'0px', height:'0px', display: 'none'}}/>
				</button>
			</section>

			<section className={Style.chargeForm}>
				<div className={Style.formItem}>
					<div className={Style.label}>부과 년/월</div>
					{/*}
					<input type={'text'} className={Style.inp} value={'2023년 11월'} />
					{*/}

					<select className={Style.select} value={costdatey} onChange={(e) => setCostDatey(e.target.value)} style={{width:'130px'}}>
						<option value="">년</option>
						{
							years.map((option) => (
								<option key={option} value={option}>{option}년</option>
							))
						}
					</select>
					<select className={cn(Style.select, Style.minSize)} value={costdatem} onChange={(e) => setCostDatem(e.target.value)} style={{width:'90px', marginRight: '40px'}}>
						<option value="">월</option>
						{
							Array(12).fill(0).map((_, i) => (
								<option key={i + 1} value={i + 1}>{i + 1}월</option>
							))
						}
					</select>
					
					<div className={Style.label}>부과 주택</div>
					<select className={cn(Style.select, Style.w01)} value={managerhouse} onChange={(e) => setManagerHouse(e.target.value)}>
						<option>공유주택 선택</option>
						{houseList.map((list) => (
							<option key={list.seq} value={list.seq}>{list.house_name}</option>
						))}
					</select>
					<select className={cn(Style.select, Style.w01)} style={{marginLeft: '10px'}} onChange={(e) => setManagerFloor(e.target.value)}>
						<option value="">층수 선택</option>
						{
							Array(70).fill(0).map((_, i) => (
								<option key={i + 1} value={i + 1}>{i + 1}</option>
							))
						}
					</select>
					<span className={Style.e01} style={{fontSize: '16px', color: '#fff', fontWeight: '600', padding: '5px'}}>층</span>
					<select className={cn(Style.select, Style.w01)} onChange={(e) => setManagerHosu(e.target.value)}>
						<option value="">호수 선택</option>
						{
							Array(30).fill(0).map((_, i) => (
								<option key={i + 1} value={i + 1}>{i + 1}</option>
							))
						}
					</select>
					<span className={Style.e01} style={{fontSize: '16px', color: '#fff', fontWeight: '600', padding: '5px'}}>호</span>
				</div>

				<ul id="cost_panel">
					<li>
						<div className={Style.label}>기본비용</div>
						<input type={'text'} className={Style.inp01} placeholder={'숫자만 입력'} value={defcost} onChange={changeDefNum} />
						<span className={Style.e01}>원</span>
						<label>
							<input type={'checkbox'} onChange={checkedHandler} />
							<span>반복적용</span>
						</label>
					</li>
					<li>
						<div className={Style.label}>전기비용</div>
						<input type={'text'} className={cn(Style.inp01, Style.w01)} placeholder={'기본'} value={electcost} onChange={changeElectNum} />
						<span className={Style.e01}>원</span>
						<input type={'text'} className={cn(Style.inp01, Style.w01)} placeholder={'개별부과'} value={electetccost} onChange={changeElectetcNum} />
						<span className={Style.e01}>원</span>
					</li>
					<li>
						<div className={Style.label}>수도비용</div>
						<input type={'text'} className={cn(Style.inp01, Style.w01)} placeholder={'기본'} value={sudocost} onChange={changeSudoNum} />
						<span className={Style.e01}>원</span>
						<input type={'text'} className={cn(Style.inp01, Style.w01)} placeholder={'개별부과'} value={sudoetccost} onChange={changeSudoetcNum} />
						<span className={Style.e01}>원</span>
					</li>
					<li>
						<div className={Style.label}>가스비용</div>
						<input type={'text'} className={cn(Style.inp01, Style.w01)} placeholder={'기본'} value={gascost} onChange={changeGasNum} />
						<span className={Style.e01}>원</span>
						<input type={'text'} className={cn(Style.inp01, Style.w01)} placeholder={'개별부과'} value={gasetccost} onChange={changeGasetcNum} />
						<span className={Style.e01}>원</span>
					</li>

					{extraFees.map((fee, index) => (
					<li key={index}>
						<div className={Style.cols}>
							{ index === 0 ?
								<button type={'button'} onClick={addOneExtraFee}>추가</button>
								:
								<button type={'button'} onClick={() => removeOneExtraFee(index)}>삭제</button>
							}
							<input type={'text'} className={cn(Style.inp01, Style.w02)} placeholder={'항목입력'} name="additemname" value={fee.additemname} onChange={(e) => handleChange(index, e)} />
						</div>
						<div className={Style.cols}>
							<input type={'text'} className={cn(Style.inp01, Style.w01)} placeholder={'기본'} name="additemcost" value={fee.additemcost} onChange={(e) => handleChange(index, e)} />
							<span className={Style.e01}>원</span>
							<input type={'text'} className={cn(Style.inp01, Style.w01)} placeholder={'개별부과'} name="additemeachcost" value={fee.additemeachcost} onChange={(e) => handleChange(index, e)} />
							<span className={Style.e01}>원</span>
						</div>
					</li>
					))}
					
				</ul>


			</section>
			
			{listloading === false &&
			<div className={Style.boardSearch}>
				<button className={cn(Style.btnReq, Style.type01)} onClick={rentfeemake}>
					<span>관리비 생성</span>
				</button>
			</div>
			}

			<div className={cn(Style.scrolls, Style.type01)}>
				<table className={cn(Style.webList)}>
					<colgroup>
						<col width="130" />
						<col width="90" />
						<col width="100" />
						<col width="80" />
						<col width="100" />
						<col width="130" />
						<col width="160" />
						<col width="160" />
						<col width="160" />
						<col width="" />
					</colgroup>
					<tr>
						<th>공유주택</th>
						<th>층수</th>
						<th>호수</th>
						<th>거주자</th>
						<th>년/월</th>
						<th>기본관리비</th>
						<th>전기비용</th>
						<th>수도비용</th>
						<th>가스비용</th>
						<th>기타비용</th>
					</tr>
					
					{listloading ?
						
                		houserentmakelist.map((board, index) => (
							<tr key={index}>
								<td>{board.housename}</td>
								<td>{board.floor}층</td>
								<td>{board.hosu}호</td>
								<td>{board.username}</td>
								<td>{costdatey}년 {costdatem}월</td>
								{board.username ?
								<>
									{board.exceldata === 'Y' ?
										<>
											<td><a style={{display: 'block', fontSize: '16px', fontWeight: '500'}} href="javascript:void(0);" onClick={(e) => updatepopup('1', board.dataindex, index)}>{board.kiboncost ? commaintvalue(board.kiboncost) : commaintvalue(board.kiboncost)}원</a></td>
											<td><a style={{display: 'block', fontSize: '16px', fontWeight: '500'}} href="javascript:void(0);" onClick={(e) => updatepopup('2', board.dataindex, index)}>{board.jungi_d ? commaintvalue(board.jungi_d) : commaintvalue(board.jungi_d)}원 / {board.jungi ? commaintvalue(parseInt(board.jungi)) : commaintvalue(board.jungi)}원</a></td>
											<td><a style={{display: 'block', fontSize: '16px', fontWeight: '500'}} href="javascript:void(0);" onClick={(e) => updatepopup('3', board.dataindex, index)}>{board.sudo_d ? commaintvalue(board.sudo_d) : commaintvalue(board.sudo_d)}원 / {board.sudo ? commaintvalue(parseInt(board.sudo)) : commaintvalue(board.sudo)}원</a></td>
											<td><a style={{display: 'block', fontSize: '16px', fontWeight: '500'}} href="javascript:void(0);" onClick={(e) => updatepopup('4', board.dataindex, index)}>{board.gas_d ? commaintvalue(board.gas_d) : commaintvalue(board.gas_d)}원 / {board.gas ? commaintvalue(parseInt(board.gas)) : commaintvalue(board.gas)}원</a></td>
											<td><a style={{display: 'block', fontSize: '16px'}} href="javascript:void(0);" onClick={(e) => popupload(board.excel_etccost_list)}>{commaintvalue(parseInt(additemhap)+parseInt(board.excel_etcdef_cost))}원 / {commaintvalue(parseInt(additemeachhap)+parseInt(board.excel_etcobj_cost))}원</a></td>
										</>
										:
										<>
											<td><a style={{display: 'block', fontSize: '16px', fontWeight: '500'}} href="javascript:void(0);" onClick={(e) => updatepopup('1', board.dataindex, index)}>{commaintvalue(board.kiboncost)}원</a></td>
											<td><a style={{display: 'block', fontSize: '16px', fontWeight: '500'}} href="javascript:void(0);" onClick={(e) => updatepopup('2', board.dataindex, index)}>{commaintvalue(board.jungi_d)}원 / {commaintvalue(board.jungi)}원</a></td>
											<td><a style={{display: 'block', fontSize: '16px', fontWeight: '500'}} href="javascript:void(0);" onClick={(e) => updatepopup('3', board.dataindex, index)}>{commaintvalue(board.sudo_d)}원 / {commaintvalue(board.sudo)}원</a></td>
											<td><a style={{display: 'block', fontSize: '16px', fontWeight: '500'}} href="javascript:void(0);" onClick={(e) => updatepopup('4', board.dataindex, index)}>{commaintvalue(board.gas_d)}원 / {commaintvalue(board.gas)}원</a></td>
											{additemhap > 0 || additemeachhap > 0 ?
											<td><a style={{display: 'block', fontSize: '16px'}} href="javascript:void(0);" onClick={(e) => popupload()}>{commaintvalue(additemhap)}원 / {commaintvalue(additemeachhap)}원</a></td>
											:
											<td>{commaintvalue(additemhap)}원 / {commaintvalue(additemeachhap)}원</td>
											}
										</>
									}
								</>
								:
								<>
									<td>{commaintvalue(0)}원</td>
									<td>{commaintvalue(0)}원 / {commaintvalue(0)}원</td>
									<td>{commaintvalue(0)}원 / {commaintvalue(0)}원</td>
									<td>{commaintvalue(0)}원 / {commaintvalue(0)}원</td>
									<td>{commaintvalue(0)}원 / {commaintvalue(0)}원</td>
								</>
								}
							</tr>
						))
					:
						<tr>
							<td colspan="10" style={{textAlign:'center'}}>생성된 리스트가 없습니다.</td>
						</tr>
					}

				</table>

			</div>

			<div className={Style.boardSearch}>
				<button className={cn(Style.btnReq, Style.type01)} onClick={rentfeeimpose}>
					<span>부과하기</span>
				</button>
			</div>
			
			{/*검색팝업.*/}
			<div className={cn(Style.popup, `${isPopup?Style.on:''}`)}>
        		<button type="button" className={Style.popClose} onClick={handleClose}><img src={popClose} /></button>
        
				<div className={Style.addLabel}>기타비용 리스트</div>
				<div className={Style.tblWrap01}>
					<table>
						<tr>
							<th>항목명</th>
							<td>비용</td>
						</tr>
						{extraFees ?
							extraFees.map((list, index) => {
								return (
								list.additemname !== "" ?
								<tr>
									<th>
									<input type={'text'} style={{height:'36px',width:'160px',border:'0',background:'#fff',textAlign:'center',fontSize:'16px',color:'rgba(20, 27, 45, 1)',margin:'10px 0'}} className={cn(Style.inp01, Style.w01)} placeholder={'항목명'} value={list.additemname} onChange={(e) => changeupetccost(e, '1', index)} />
										{/*list.additemname*/}
									</th>
									<td>
									<input type={'text'} style={{height:'36px',width:'180px',border:'0',background:'#fff',textAlign:'center',fontSize:'16px',color:'rgba(20, 27, 45, 1)',margin:'10px 0'}} className={cn(Style.inp01, Style.w01)} placeholder={'기본'} defalutValue={list.additemcost} onChange={(e) => changeupetccost(e, '1', index)} /> 원
									/
									<input type={'text'} style={{height:'36px',width:'180px',border:'0',background:'#fff',textAlign:'center',fontSize:'16px',color:'rgba(20, 27, 45, 1)',margin:'10px 0'}} className={cn(Style.inp01, Style.w01)} placeholder={'개별부과'} defalutValue={list.additemeachcost} onChange={(e) => changeupetccost(e, '2', index)} /> 원
									{/*list.additemcost +"원 / "+ list.additemeachcost+"원"*/}
					<div style={{display:'block',float:'right'}}>
						<button className={cn(Style.btnReq, Style.type01)} onClick={(e,i) => updatekwanfee(updatenum, updateindex, choicenum)} style={{height:'40px',width:'50px',marginTop:'8px',borderRadius:'5px',border:'1px solid #7d7b7b',background:'#40EFC1',display:'flex',justifyContent:'center',alignItems:'center'}}>
							<span>수정</span>
						</button>
					</div>
									</td>
								</tr>
								: ""
								)
							})
							:
							""
						}
						{kitalist ?
							kitalist.map((list) => {
								return (
								<tr>
									<th>{list.etc}</th>
									<td>{commaintvalue(list.etc_d) +"원 / "+ commaintvalue(list.etc_k)+"원"}</td>
								</tr>
								)
							})
							:
							""
						}
					</table>
				</div>
      		</div>

			<div className={cn(Style.popup, `${isPopup2?Style.on:''}`)}>
        		<button type="button" className={Style.popClose} onClick={handleClose2}><img src={popClose} /></button>
        
				<div className={Style.addLabel} style={{fontWeight: 'bold', fontSize: '12pt'}}>{popuptitle} 수정</div>
				<div className={Style.tblWrap01}>
					<table>
						<tr>
							<th>기본부과비용</th>
							<td><input type={'text'} style={{height:'36px',border:'0',background:'#fff',textAlign:'center',fontSize:'16px',color:'rgba(20, 27, 45, 1)',margin:'10px 0'}} className={cn(Style.inp01, Style.w01)} placeholder={'기본'} value={uppopdcost} onChange={(e) => changeupcost(e, '1')} /> 원</td>
						</tr>
						{updatenum !== "1" &&
						<tr>
							<th style={{borderTop:'1px solid #c5c5c5'}}>개별부과비용</th>
							<td style={{borderTop:'1px solid #c5c5c5'}}><input type={'text'} style={{height:'36px',border:'0',background:'#fff',textAlign:'center',fontSize:'16px',color:'rgba(20, 27, 45, 1)',margin:'10px 0'}} className={cn(Style.inp01, Style.w01)} placeholder={'개별부과'} value={uppopkcost} onChange={(e) => changeupcost(e, '2')} /> 원</td>
						</tr>
						}
					</table>
				</div>
					<div style={{display:'block',float:'right'}}>
						<button className={cn(Style.btnReq, Style.type01)} onClick={(e,i) => updatekwanfee(updatenum, updateindex, choicenum)} style={{height:'40px',width:'100px',borderRadius:'5px',border:'1px solid #7d7b7b',background:'#40EFC1',display:'flex',justifyContent:'center',alignItems:'center'}}>
							<span>수정하기</span>
						</button>
					</div>
      		</div>

			<div className={cn(Dialog.dialog, `${isPopup?Dialog.on:''}`)}>
			</div>

			<div className={cn(Dialog.dialog, `${isPopup2?Dialog.on:''}`)}>
			</div>

		</div>
	)
}

export default ReqList
