import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"

import { Send, Pencil, Trash, Circle, X } from "lucide-react"
import { getDateString } from "@/components/lib/date"
import { getNumberString } from "@/components/lib/number"
import { ArrowUpDown, ChevronDown, MoreHorizontal } from "lucide-react"
import { Link } from "react-router-dom";

const getStatusString = ( statusNumber ) => {
  switch (statusNumber) {
    case "0":
      return "요청 접수";
    case "1":
      return "수리 시작";
    case "2":
      return "수리 완료";
    case "3":
      return "수리 완료";
  }
}

export const getColumns = (onDeleteButtonClicked, memberType, isMobile) => {
  var columns;
  
  if(isMobile){
    if (memberType === 'AD'){
      columns = [
        {
          accessorKey: "idx",
          header: () => <div className="text-center">ID</div>,
          cell: ({ row }) => <div className="text-center">{row.getValue("idx")}</div>,
        },
        {
          accessorKey: "fm_state",
          header: () => <div className="text-center">상태</div>,
          cell: ({ row }) => <div className="text-center">{getStatusString(row.getValue("fm_state"))}</div>,
        },
        {
          accessorKey: "fm_title",
          header: () => <div className="text-center">제목</div>,
          cell: ({ row }) => <Link to={`/admin/space/reqView/${row.original.idx}`}><div className="text-center">{row.getValue("fm_title")}</div></Link>,
        }
      ]
    }else if (memberType === 'SM'){
      columns = [
        {
          accessorKey: "idx",
          header: () => <div className="text-center">ID</div>,
          cell: ({ row }) => <div className="text-center">{row.getValue("idx")}</div>,
        },
        {
          accessorKey: "fm_state",
          header: () => <div className="text-center">상태</div>,
          cell: ({ row }) => <div className="text-center">{getStatusString(row.getValue("fm_state"))}</div>,
        },
        {
          accessorKey: "fm_title",
          header: () => <div className="text-center">제목</div>,
          cell: ({ row }) => <Link to={`/admin/space/reportView/${row.original.idx}`}><div className="text-center">{row.getValue("fm_title")}</div></Link>,
        },
      ]
    }
  }else{
    if (memberType === 'AD'){
      columns = [
        {
          accessorKey: "idx",
          header: () => <div className="text-center">ID</div>,
          cell: ({ row }) => <div className="text-center">{row.getValue("idx")}</div>,
        },
        {
          accessorKey: "fm_state",
          header: () => <div className="text-center">상태</div>,
          cell: ({ row }) => <div className="text-center">{getStatusString(row.getValue("fm_state"))}</div>,
        },
        {
          accessorKey: "sh_name",
          header: () => <div className="text-center">주택</div>,
          cell: ({ row }) => <div className="text-center">{row.getValue("sh_name")}</div>,
        },
        {
          accessorKey: "fm_location",
          header: () => <div className="text-center">세부 위치</div>,
          cell: ({ row }) => <Link to={`/admin/space/reqView/${row.original.idx}`}><div className="text-center">{row.getValue("fm_location")}</div></Link>,
        },
        {
          accessorKey: "fm_title",
          header: () => <div className="text-center">제목</div>,
          cell: ({ row }) => <Link to={`/admin/space/reqView/${row.original.idx}`}><div className="text-center">{row.getValue("fm_title")}</div></Link>,
        },
        {
          accessorKey: "mem_name",
          header: () => <div className="text-center">시설 기사</div>,
          cell: ({ row }) => <div className="text-center">{row.getValue("mem_name")}</div>,
        },
        {
          accessorKey: "fm_regdate",
          header: ({ column }) => {
            return (
              <div className="flex justify-center align-middle">
                <Button
                  className="text-center self-center"
                  variant="header"
                  onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
                  작성일
                  <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
              </div>
            )
          },
          cell: ({ row }) => <div className="text-center">{getDateString(row.getValue("fm_regdate"))}</div>,
        },
        {
          id: "click_delete",
          header: () => <div className="text-center">삭제</div>,
          enableHiding: false,
          cell: ({ row }) => {
            const facility = row.original
      
            return (
              <div className="flex justify-center align-middle">
                <Button variant="icon" onClick={()=>{onDeleteButtonClicked(facility.idx)}}>
                  <X/>
                </Button>
              </div>
            )
          },
        },
      ]
    }else if (memberType === 'SM'){
      columns = [
        {
          accessorKey: "idx",
          header: () => <div className="text-center">ID</div>,
          cell: ({ row }) => <div className="text-center">{row.getValue("idx")}</div>,
        },
        {
          accessorKey: "fm_state",
          header: () => <div className="text-center">상태</div>,
          cell: ({ row }) => <div className="text-center">{getStatusString(row.getValue("fm_state"))}</div>,
        },
        {
          accessorKey: "sh_name",
          header: () => <div className="text-center">주택</div>,
          cell: ({ row }) => <div className="text-center">{row.getValue("sh_name")}</div>,
        },
        {
          accessorKey: "fm_location",
          header: () => <div className="text-center">세부 위치</div>,
          cell: ({ row }) => <Link to={`/admin/space/reportView/${row.original.idx}`}><div className="text-center">{row.getValue("fm_location")}</div></Link>,
        },
        {
          accessorKey: "fm_title",
          header: () => <div className="text-center">제목</div>,
          cell: ({ row }) => <Link to={`/admin/space/reportView/${row.original.idx}`}><div className="text-center">{row.getValue("fm_title")}</div></Link>,
        },
        {
          accessorKey: "mem_name",
          header: () => <div className="text-center">시설 기사</div>,
          cell: ({ row }) => <div className="text-center">{row.getValue("mem_name")}</div>,
        },
        {
          accessorKey: "fm_regdate",
          header: ({ column }) => {
            return (
              <div className="flex justify-center align-middle">
                <Button
                  className="text-center self-center"
                  variant="header"
                  onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
                  작성일
                  <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
              </div>
            )
          },
          cell: ({ row }) => <div className="text-center">{getDateString(row.getValue("fm_regdate"))}</div>,
        }
      ]
    }
  }
  

  return columns;
}