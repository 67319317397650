import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom"
import axios from "axios";
import cn from 'classnames'
import { getToken } from "../../services/TokenService";
import { ColorRing } from 'react-loader-spinner';
import styled from "@emotion/styled";

// css
import Style from './index.module.css'
import './add.modules.css'
import Smile from '@/assets/image/ico/ico-smile.svg'
import {
	Tabs,
	TabsHeader,
	TabsBody,
	Tab,
	TabPanel,
  } from "@material-tailwind/react";


const Contract_sign = () => {
	const { idx } = useParams();
	const [boardList, setBoardList] = useState([]);
	const [iframeloading, setIframeLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
    let [tab, setTab] = useState(0);
	const [activeTab, setActiveTab] = React.useState("0");

	const data = [
		{
		  label: "계약서",
		  value: "0",
		  desc: <div style={{display:'flex', justifyContent:'center'}}>
					<iframe id="ucansign-iframe" style={{border:'0px', width:'1000px', height:'900px'}} src="" />
				</div>,
		},
		{
		  label: "원상복구 특약사항",
		  value: "1",
		  desc: <div style={{display:'flex', justifyContent:'center'}}>
					<iframe id="ucansign-iframe2" style={{border:'0px', width:'1000px', height:'900px'}} src="" />
				</div>,
		},
		{
		  label: "살구마을 동의서",
		  value: "2",
		  desc: `내용2`,
		},
		{
		  label: "개인정보수집 동의서",
		  value: "3",
		  desc: `내용3`,
		},
		{
		  label: "LH개인정보수정 동의서",
		  value: "4",
		  desc: `We're not always in the position that we want to be at.
		  We're constantly growing. We're constantly making mistakes. We're
		  constantly trying to express ourselves and actualize our dreams.`,
		},
	  ];
	
	const hostname = window.location.hostname;
	const pathname = window.location.pathname;
	
	const contract_go = () => {
		//setLoading(true);
		axios({
			method: 'post',
			url: process.env.REACT_APP_API_CONTRACT_SIGN_VIEW,
			mode: "cors",
			credentials: "same-origin",
			data:{
				seq: `${idx}`,
				sort: '1'
			  },
		}).then((res) => {
			//console.log(res.data.code);
			//console.log(res.data.list);
			if(res.data.code === '200'){
				setLoading(false);
				setIframeLoading(true);
				const ucansignIframe = document.getElementById("ucansign-iframe");
				//const ucansignIframe2 = document.getElementById("ucansign-iframe2");
				//ucansignIframe.setAttribute("src", res.data.url);
								
				for (let i = 0; i < res.data.cnt; i++) {
					//console.log(res.data.list[i]);
					if(i === 0){
						ucansignIframe.setAttribute("src", res.data.list[i].emb_url);
						console.log(ucansignIframe);
					}else if(i === 1){
						setTimeout(function(){
							const ucansignIframe2 = document.getElementById("ucansign-iframe2");
							ucansignIframe2.setAttribute("src", res.data.list[i].emb_url);
							console.log(ucansignIframe2);
						}, 500);
					}
				}
				//ucansignIframe.src = 'https://siso.live';
				//document.querySelector('iframe').setAttribute("src", 'https://siso.live')

			}else if(res.data.code === '400'){
			
			}

		}).catch((err) => {	
			console.log("에러", err);
		})

	}

    function TabContent(props){
        if(props.tab===0){
            return <div>내용0</div>
        }else if(props.tab===1){
            return <div>내용1</div>
        }else if(props.tab===2){
            return <div>내용2</div>
        }
    }
	
	useEffect(() => {
		setLoading(true);
		setTimeout(function(){
			contract_go();
		}, 1000);
	}, []);

	return (
		<div className={Style.wrapper}>
			<div className={Style.etc} style={{width:'100%', margin:'0 auto', justifyContent:'center', paddingTop:'5%', position:'relative', marginTop:'70px'}}>
				<Tabs value={activeTab}>
					<TabsHeader
						className="rounded-none border-b border-blue-gray-50 bg-transparent p-0"
						indicatorProps={{
						className:
							"bg-transparent border-b-2 border-gray-900 shadow-none rounded-none",
						}}
					>
						{data.map(({ label, value }) => (
						<Tab
							key={value}
							value={value}
							onClick={() => setActiveTab(value)}
							className={activeTab === value ? "text-gray-900" : ""}
						>
							{label}
						</Tab>
						))}
					</TabsHeader>
					<div className={"bgcolor"}>
						<TabsBody>
							{data.map(({ value, desc }) => (
							<TabPanel key={value} value={value}>
								{desc}
							</TabPanel>
							))}
						</TabsBody>
					</div>
				</Tabs>
			</div>
		</div>
		
	)
}

export default Contract_sign
