import {useState, useEffect, useContext, useRef} from 'react'
import { Button } from '../ui/button'

import { cn } from "@/components/lib/utils"
import { Input } from "@/components/ui/input"
import { Label, GreenLabel } from "@/components/ui/label"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Textarea } from "../ui/textarea"
import { toast } from "../ui/use-toast"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { ChevronLeft, AlignJustify } from "lucide-react"
import { MySpinner } from '../ui/spinner'
import { useQuery, useMutation } from "@tanstack/react-query";
import Axios from "@/components/lib/axios";
import { X } from "lucide-react"

const AlimtalkTemplateEditor = ({inputSubject, setInputSubject, inputContent, setInputContent, alimtalkType, setAlimtalkType,
	emphasisType, setEmphasisType, emphasisTextMainTitle, setEmphasisTextMainTitle, emphasisTextSubTitle, setEmphasisTextSubTitle,
	emphasisImageFileName, setEmphasisImageFileName, emphasisImageFileUrl, setEmphasisImageFileUrl, alimtalkButtons, setAlimtalkButtons,
	sendSmsWhenFailed, setSendSmsWhenFailed, smsType, setSmsType, inputSMSSubject, setInputSMSSubject, inputSMSContent,
	setInputSMSContent, imageOne, setImageOne, imageTwo, setImageTwo, imageThree, setImageThree}) => {
	const emphasisImageFileInputRef = useRef();const fileInputOneRef = useRef();const fileInputTwoRef = useRef();const fileInputThreeRef = useRef();
	const [imgOneLabel, setImgOneLabel] = useState("선택된 파일 없음");const [imgTwoLabel, setImgTwoLabel] = useState("선택된 파일 없음");const [imgThreeLabel, setImgThreeLabel] = useState("선택된 파일 없음");
	const { isLoading: isLoadingAutoVariableNames, error, data: autoVariableNames } = useQuery({queryKey:["auto_variable_name"], queryFn: () =>
		Axios.get('/api_message/common/auto_variables').then((res) => {
			return res.data;
		})});

	const imageUpload = useMutation({
		mutationFn: async ({file, index, fileName}) => {
			const formData = new FormData()
			formData.append('image', file)
			
			const { data } = await Axios.post("/api_message/common/image", formData)
			data["index"] = index
			data["fileName"] = fileName
			return data
		}, 
		onSuccess: (res) => {
			if(res.index === 0){
				setEmphasisImageFileUrl(res.imgUrl)
				setEmphasisImageFileName(res.fileName)
			}
			else if(res.index === 1){
				setImageOne(res.imgUrl)
			}
			else if(res.index === 2){
				setImageTwo(res.imgUrl)
			}
			else if(res.index === 3){
				setImageThree(res.imgUrl)
			}
		},
	});

	useEffect(() => {
		if(imageOne !== ""){
			const imageFileName = imageOne.split("-")[1];
			if(imageFileName !== undefined && imageFileName.length > 0) setImgOneLabel(decodeURI(imageFileName));
		}
	}, [imageOne]);

	useEffect(() => {
		if(imageTwo !== ""){
			const imageFileName = imageTwo.split("-")[1];
			if(imageFileName !== undefined && imageFileName.length > 0) setImgTwoLabel(decodeURI(imageFileName));
		}
	}, [imageTwo]);

	useEffect(() => {
		if(imageThree !== ""){
			const imageFileName = imageThree.split("-")[1];
			if(imageFileName !== undefined && imageFileName.length > 0) setImgThreeLabel(decodeURI(imageFileName));
		}
	}, [imageThree]);

	const handleChangeForEmphasisImage = (event) => {
		const imageToUpload = event.target.files[0];
		imageUpload.mutate({file:imageToUpload, index:0, fileName: imageToUpload.name});
	};
	const handleChangeForImageOne = (event) => {
		const imageToUpload = event.target.files[0];
		imageUpload.mutate({file:imageToUpload, index:1, fileName: imageToUpload.name});
	};
	const handleChangeForImageTwo = (event) => {
		const imageToUpload = event.target.files[0];
		imageUpload.mutate({file:imageToUpload, index:2, fileName: imageToUpload.name});
	};
	const handleChangeForImageThree = (event) => {
		const imageToUpload = event.target.files[0];
		imageUpload.mutate({file:imageToUpload, index:3, fileName: imageToUpload.name});
	};

	function onSubmit(data) {
		toast({
			title: "You submitted the following values:",
			description: (
			<pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
				<code className="text-white">{JSON.stringify(data, null, 2)}</code>
			</pre>
			),
		})
	}

	useEffect(() => {
		console.log(alimtalkButtons);
	}, [alimtalkButtons]);

	const addAlimtalkButton = () => {
		setAlimtalkButtons( (prev) => {
			const newAlimtalkButtons = [...prev];
			newAlimtalkButtons.push({
				name: "",
				linkType: "",
				linkTypeName: "",
				linkMo: "",
				linkPc: "",
				linkIos: "",
				linkAnd: ""
			})
			return newAlimtalkButtons
		})
	}
	const inputButtonLinkTypeHandler = (index, value) => {
		setAlimtalkButtons( (prev) => {
			const newAlimtalkButtons = [...prev];
			newAlimtalkButtons[index].linkType = value;
			if(value === "WL")
				newAlimtalkButtons[index].linkTypeName = "웹링크";
			if(value === "AL")
				newAlimtalkButtons[index].linkTypeName = "앱링크";
			return newAlimtalkButtons
		})
	}
	const inputButtonNameHandler = (index, value) => {
		setAlimtalkButtons( (prev) => {
			const newAlimtalkButtons = [...prev];
			newAlimtalkButtons[index].name = value;
			return newAlimtalkButtons
		})
	}
	const inputButtonLinkMoHandler = (index, value) => {
		setAlimtalkButtons( (prev) => {
			const newAlimtalkButtons = [...prev];
			newAlimtalkButtons[index].linkMo = value;
			return newAlimtalkButtons
		})
	}
	const inputButtonLinkPcHandler = (index, value) => {
		setAlimtalkButtons( (prev) => {
			const newAlimtalkButtons = [...prev];
			newAlimtalkButtons[index].linkPc = value;
			return newAlimtalkButtons
		})
	}
	const inputButtonLinkIosHandler = (index, value) => {
		setAlimtalkButtons( (prev) => {
			const newAlimtalkButtons = [...prev];
			newAlimtalkButtons[index].linkIos = value;
			return newAlimtalkButtons
		})
	}
	const inputButtonLinkAndHandler = (index, value) => {
		setAlimtalkButtons( (prev) => {
			const newAlimtalkButtons = [...prev];
			newAlimtalkButtons[index].linkAnd = value;
			return newAlimtalkButtons
		})
	}
	const removeAlimtalkButton = (index) => {
		setAlimtalkButtons( (prev) => {
			const newAlimtalkButtons = [...prev];
			newAlimtalkButtons.splice(index, 1);
			return newAlimtalkButtons
		})
	}
	
	return (
		<div className="w-full flex flex-col">
			<div className="w-full mt-12 lg:flex lg:flex-row justify-between">
				<div className="lg:w-1/2  flex flex-col w-full">
					<GreenLabel className="">알림톡 제목</GreenLabel>
					<Input className="mt-3" value={inputSubject} onChange={(event)=>setInputSubject(event.target.value)}/>
					<GreenLabel className="mt-8">알림톡 타입</GreenLabel>
					<RadioGroup className="mt-3 flex flex-row space-x-2" value={alimtalkType}  onValueChange={(value)=>setAlimtalkType(value)}>
						<div className="flex items-center space-x-2">
							<RadioGroupItem value="BA" id="BA" />
							<Label htmlFor="BA">기본형</Label>
						</div>
						<div className="flex items-center space-x-2">
							<RadioGroupItem value="EX" id="EX" />
							<Label htmlFor="EX">부가 정보형</Label>
						</div>
						<div className="flex items-center space-x-2">
							<RadioGroupItem value="AD" id="AD" />
							<Label htmlFor="AD">광고 추가형</Label>
						</div>
						<div className="flex items-center space-x-2">
							<RadioGroupItem value="MI" id="MI" />
							<Label htmlFor="MI">복합형</Label>
						</div>
					</RadioGroup>
					<GreenLabel className="mt-8">강조 유형</GreenLabel>
					<RadioGroup className="mt-3 flex flex-row space-x-2" value={emphasisType}  onValueChange={(value)=>setEmphasisType(value)}>
						<div className="flex items-center space-x-2">
							<RadioGroupItem value="NONE" id="NONE" />
							<Label htmlFor="NONE">기본형</Label>
						</div>
						<div className="flex items-center space-x-2">
							<RadioGroupItem value="TEXT" id="TEXT" />
							<Label htmlFor="TEXT">강조 표기형</Label>
						</div>
						<div className="flex items-center space-x-2">
							<RadioGroupItem value="IMAGE" id="IMAGE" />
							<Label htmlFor="IMAGE">이미지형</Label>
						</div>
					</RadioGroup>
					{
						emphasisType === "TEXT"
						? <>
							<GreenLabel className="mt-8">서브 타이틀</GreenLabel>
							<Input className="mt-3" value={emphasisTextSubTitle} onChange={(event)=>setEmphasisTextSubTitle(event.target.value)}/>
							<GreenLabel className="mt-8">타이틀</GreenLabel>
							<Input className="mt-3" value={emphasisTextMainTitle} onChange={(event)=>setEmphasisTextMainTitle(event.target.value)}/>
						</>
						: emphasisType === "IMAGE"
						? <>
							<GreenLabel className="mt-8">이미지</GreenLabel>
							<div className='flex flew-row mt-3 items-center gap-4'>
								<Button onClick={()=>emphasisImageFileInputRef.current.click()}>업로드</Button>
								<Label>{emphasisImageFileName}</Label>
								{
									emphasisImageFileUrl !== "" 
									&&
									<Button variant="icon" size="icon" onClick={()=>{setEmphasisImageFileUrl("");setEmphasisImageFileName("선택된 파일이 없음")}}><X/></Button>
								}
								<input onChange={handleChangeForEmphasisImage} multiple={false} ref={emphasisImageFileInputRef} type='file' accept="image/*" hidden/>
							</div>
						</>
						: null
					}
					<GreenLabel className="mt-8">알림톡 내용</GreenLabel>
					<Textarea
						className="resize-none mt-3"
						rows="14"
						value={inputContent}
						onChange={(event)=>setInputContent(event.target.value)}
					/>
					<GreenLabel className="mt-8">시스템 자동 입력 변수</GreenLabel>
					{
						isLoadingAutoVariableNames
						? <MySpinner/>
						: 	<div className="flex flex-col items-center justify-center">
								<div className="w-full p-4 rounded-md">
									<div className="flex flex-wrap justify-start gap-2">
										{
											autoVariableNames.map(variableName => {
												return <Button onClick={()=>setInputContent((prev)=> prev + variableName.variable_name)}>{variableName.variable_name}</Button>})
										}
									</div>
								</div>
							</div>
					}
					<div className="mt-8 flex flex-row gap-10 items-center">
						<GreenLabel >알림톡 버튼</GreenLabel>
						<Button className=" w-20" onClick={()=>addAlimtalkButton()}>추가</Button>
					</div>
					
					{
						alimtalkButtons.map((button, index) => {
							return <div className='p-4 flex flex-col gap-2 ml-4'>
								<div className='flex flex-row items-center h-10'>
									<GreenLabel className="basis-40">버튼 {index+1} 타입</GreenLabel>
									<RadioGroup className="flex flex-row space-x-2" value={button.linkType}  onValueChange={(value)=>inputButtonLinkTypeHandler(index, value)}>
										<div className="flex items-center space-x-2">
											<RadioGroupItem value="WL" id="WL" />
											<Label htmlFor="WL">웹링크</Label>
										</div>
										<div className="flex items-center space-x-2">
											<RadioGroupItem value="AL" id="AL" />
											<Label htmlFor="AL">앱링크</Label>
										</div>
									</RadioGroup>
								</div>
								<div className='flex flex-row gap-4 items-center'>
									<GreenLabel className="basis-40">버튼 {index+1} 이름</GreenLabel>
									<Input value={button.name} onChange={(event)=>inputButtonNameHandler(index, event.target.value)}/>
								</div>
								{
									button.linkType === "WL"
									?
									<>
										<div className='flex flex-row gap-4 items-center'>
											<GreenLabel className="basis-40">버튼 {index+1} 모바일 링크</GreenLabel>
											<Input value={button.linkMo} onChange={(event)=>inputButtonLinkMoHandler(index, event.target.value)}/>
										</div>
										<div className='flex flex-row gap-4 items-center'>
											<GreenLabel className="basis-40">버튼 {index+1} PC 링크</GreenLabel>
											<Input value={button.linkPc} onChange={(event)=>inputButtonLinkPcHandler(index, event.target.value)}/>
										</div>
									</>
									:
									button.linkType === "AL" && 
									<>
										<div className='flex flex-row gap-4 items-center'>
											<GreenLabel className="basis-40">버튼 {index+1} iOS 링크</GreenLabel>
											<Input value={button.linkIos} onChange={(event)=>inputButtonLinkIosHandler(index, event.target.value)}/>
										</div>
										<div className='flex flex-row gap-4 items-center'>
											<GreenLabel className="basis-40">버튼 {index+1} AOS 링크</GreenLabel>
											<Input value={button.linkAnd} onChange={(event)=>inputButtonLinkAndHandler(index, event.target.value)}/>
										</div>
									</>
								}
								<div className='flex flex-row justify-end'>
									<Button onClick={()=>removeAlimtalkButton(index)}>버튼 {index+1} 삭제</Button>
								</div>
							</div>
						})
					}
					
					<GreenLabel className="mt-8">대체 문자 전송</GreenLabel>
					<RadioGroup className="mt-3 flex flex-row space-x-2" value={sendSmsWhenFailed}  onValueChange={(value)=>setSendSmsWhenFailed(value)}>
						<div className="flex items-center space-x-2">
							<RadioGroupItem value="Y" id="Y" />
							<Label htmlFor="Y">전송</Label>
						</div>
						<div className="flex items-center space-x-2">
							<RadioGroupItem value="N" id="N" />
							<Label htmlFor="N">전송 안함</Label>
						</div>
					</RadioGroup>
				</div>
				
				<div className="relative lg:w-1/2 w-full">
					<div className="sticky pt-4 top-0">
						<div className='block'>
							<div className="w-[450px] h-[660px] bg-[url('/public/android.svg')] m-auto">
								<div className="relative top-[49px] m-auto w-[420px] h-[500px] select-none">
									<div className='flex flex-col bg-alimtalk-bg p-5 h-[576px] rounded-tl-3xl rounded-tr-3xl shadow'>
										<div className="relative top-[0px] flex flex-row justify-between w-[380px] select-none">
											<ChevronLeft />
											<div className='text-lg font-semibold'>아이부키</div>
											<AlignJustify />
										</div>
										<div className="relative top-[0px] m-auto w-[380px] select-none flex flex-col justify-center">
											<div className='bg-alimtalk-header p-4 rounded-tl-3xl rounded-tr-3xl flex flex-row justify-center items-start'>
												<div className='text-base font-semibold'>알림톡 도착</div>
											</div>
											<div className='flex flex-col bg-zinc-100 p-4 rounded-bl-xl rounded-br-xl shadow'>
											{
												emphasisType === "TEXT"
												? <><div className='text-xs line-clamp-1'>{emphasisTextSubTitle}</div>
												<div className='text-xl line-clamp-1 mt-1'>{emphasisTextMainTitle}</div>
												<div className='text-sm mt-4 line-clamp-[18] pre-wrap'>{inputContent}</div></>
												: emphasisType === "IMAGE" && emphasisImageFileUrl !== ""
												? <>
													<img src={Axios.defaults.baseURL+'message_images/'+emphasisImageFileUrl} className='aspect-[2/1] object-center'/>
													<div className='text-sm p-4 line-clamp-[13] pre-wrap'>{inputContent}</div>
												</>
												: <div className='text-sm line-clamp-[22] pre-wrap'>{inputContent}</div>
											}
											{
												alimtalkButtons.map((button, index) => {
													return <Button className="bg-alimtalk-button text-secondary mt-3 p-2">{button.name}</Button>
												})
											}
											</div>
										</div>
									</div>
								</div>
								
							</div>
						</div>
					</div>
				</div>
			</div>
			{
				sendSmsWhenFailed === "Y"
				? <div className="w-full mt-12 lg:flex lg:flex-row justify-between">
					<div className="lg:w-1/2  flex flex-col w-full">
						<GreenLabel className="">문자 타입</GreenLabel>
						<RadioGroup className="mt-3 flex flex-row space-x-2" value={smsType}  onValueChange={(value)=>setSmsType(value)}>
							<div className="flex items-center space-x-2">
								<RadioGroupItem value="SMS" id="SMS" />
								<Label htmlFor="SMS">SMS</Label>
							</div>
							<div className="flex items-center space-x-2">
								<RadioGroupItem value="LMS" id="LMS" />
								<Label htmlFor="LMS">LMS</Label>
							</div>
							<div className="flex items-center space-x-2">
								<RadioGroupItem value="MMS" id="MMS" />
								<Label htmlFor="MMS">MMS</Label>
							</div>
						</RadioGroup>
						{
							smsType !== "SMS"
							? <>
								<GreenLabel className="mt-8">제목</GreenLabel>
								<Input className="mt-3" value={inputSMSSubject} onChange={(event)=>setInputSMSSubject(event.target.value)} />
							</>
							: null
						}
						<GreenLabel className="mt-8">내용</GreenLabel>
						<Textarea
							className="resize-none mt-3"
							rows="14"
							value={inputSMSContent}
							onChange={(event)=>setInputSMSContent(event.target.value)}
						/>
						<GreenLabel className="mt-8">시스템 자동 입력 변수</GreenLabel>
						{
							isLoadingAutoVariableNames
							? <MySpinner/>
							: 	<div className="flex flex-col items-center justify-center">
									<div className="w-full p-4 rounded-md">
										<div className="flex flex-wrap justify-start gap-2">
											{
												autoVariableNames.map(variableName => {
													return <Button onClick={()=>setInputSMSContent((prev)=> prev + variableName.variable_name)}>{variableName.variable_name}</Button>})
											}
										</div>
									</div>
								</div>
						}
						{
							smsType === "MMS"
							&& <>
								<GreenLabel className="mt-8">이미지</GreenLabel>
								<div className='flex flew-row mt-3 items-center gap-4'>
									<Button onClick={()=>fileInputOneRef.current.click()}>업로드</Button>
									<Label>{imgOneLabel}</Label>
									{
										imageOne !== "" 
										&&
										<Button variant="icon" size="icon" onClick={()=>{setImageOne("");setImgOneLabel("선택된 파일이 없음")}}><X/></Button>
									}
									<input onChange={handleChangeForImageOne} multiple={false} ref={fileInputOneRef} type='file' accept="image/*" hidden/>
								</div>
								<GreenLabel className="mt-8">이미지</GreenLabel>
								<div className='flex flew-row mt-3 items-center gap-4'>
									<Button onClick={()=>fileInputTwoRef.current.click()}>업로드</Button>
									<Label>{imgTwoLabel}</Label>
									{
										imageTwo !== "" 
										&&
										<Button variant="icon" size="icon" onClick={()=>{setImageTwo("");setImgTwoLabel("선택된 파일이 없음")}}><X/></Button>
									}
									<input onChange={handleChangeForImageTwo} multiple={false} ref={fileInputTwoRef} type='file' accept="image/*" hidden/>
								</div>
								<GreenLabel className="mt-8">이미지</GreenLabel>
								<div className='flex flew-row mt-3 items-center gap-4'>
									<Button onClick={()=>fileInputThreeRef.current.click()}>업로드</Button>
									<Label>{imgThreeLabel}</Label>
									{
										imageThree !== "" 
										&&
										<Button variant="icon" size="icon" onClick={()=>{setImageThree("");setImgThreeLabel("선택된 파일이 없음")}}><X/></Button>
									}
									<input onChange={handleChangeForImageThree} multiple={false} ref={fileInputThreeRef} type='file' accept="image/*" hidden/>
								</div>
							</>
						}
					</div>
					
					<div className="relative lg:w-1/2 w-full">
						<div className="sticky pt-4 top-0">
							<div className='block'>
								<div className="w-[450px] h-[660px] bg-[url('/public/android.svg')] m-auto">
									<div className="relative top-[50px] m-auto w-[416px] select-none">
										<div className='flex flex-col bg-zinc-50 p-5 rounded-tl-2xl rounded-tr-2xl shadow'>
											<div className='flex flex-col items-center'>
												<img className="w-10 h-10 rounded-full" src="https://siso.live/static/media/etc-sys02.c24fdd49a864aef85e58c6a99213fed9.svg"/>
												<Label className="mt-3 text-gray-900">아이부키</Label>
											</div>
										</div>
									</div>
									<div className="relative top-[52px] m-auto w-[416px] h-[500px] select-none bg-white">
										<div className="relative top-[50px] m-auto w-[380px] select-none">
											<div className='flex flex-col bg-zinc-100 p-3 rounded-xl shadow'>
												<div className='text-sm font-semibold line-clamp-1'>{inputSMSSubject}</div>
												<pre className='text-sm'>{inputSMSContent}</pre>
											</div>
											{
												smsType === "MMS"
												&& <div className='flex flex-row mt-3 gap-2'>
													{
														imageOne !== ""
														&& <img src={Axios.defaults.baseURL+'message_images/'+imageOne} className='aspect-square object-center w-1/3'/>
													}
													{
														imageTwo !== ""
														&& <img src={Axios.defaults.baseURL+'message_images/'+imageTwo} className='aspect-square object-center w-1/3'/>
													}
													{
														imageThree !== ""
														&& <img src={Axios.defaults.baseURL+'message_images/'+imageThree} className='aspect-square object-center w-1/3'/>
													}
												</div>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				: null
			}
			
		</div>
		
	)
}

export default AlimtalkTemplateEditor