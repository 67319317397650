import {useState, useEffect, useContext, createContext} from 'react'
import { GroupTable } from './table/table'
import { Button } from '@/components/ui/button'
import { MySpinner } from '@/components/ui/spinner'
import { Link, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import Axios from "@/components/lib/axios";
import {
	AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle,
} from "@/components/ui/alert-dialog"
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "@/components/ui/select"

const ContentTable = () => {
	const navigate = useNavigate();
	const queryClient = useQueryClient()
	const [isOpenedMessageTypeDialog, setIsOpenedMessageTypeDialog] = useState(false);
	const [isOpenedDeleteDialog, setIsOpenedDeleteDialog] = useState(false);
	const [messageType, setMessageType] = useState("");
	const [selectedGroupId, setSelectedGroupId] = useState(0);

	const { isLoading, error, data } = useQuery({queryKey:["message_group_list"], queryFn: () =>
		Axios.get(`/api_message/group`).then((res) => {
			return res.data;
		})
	});

	const onSendButtonClicked = (groupId) => {
		setSelectedGroupId(groupId)
		setIsOpenedMessageTypeDialog(true)
	}

	const onSendContinueButtonClicked = () => {
		if(messageType === ""){
			alert("메시지 타입을 선택 해 주세요"); return;
		}
		setIsOpenedMessageTypeDialog(false)
		navigate(`/admin/messages/sendMessages?type=${messageType}&targetType=group&selectedGroupId=${selectedGroupId}`)
	}
	
	const onEditButtonClicked = (templateId) => {
		navigate(`/admin/messages/groupSettings?mode=edit&id=${templateId}`)
	}

	const onDeleteButtonClicked = (groupId) => {
		setSelectedGroupId(groupId)
		setIsOpenedDeleteDialog(true)
	}

	const onDeleteContinueButtonClicked = () => {
		setIsOpenedDeleteDialog(false)
		deleteGroup.mutate({groupId: selectedGroupId})
	}

	// 삭제 버튼 누르면 해당 row를 삭제하고 리스트 업데이트
	const deleteGroup = useMutation({
		mutationFn: async ({groupId}) => {
			console.log("delete mutation")
			const result = await Axios.delete(`/api_message/group/${groupId}`)
			console.log(result)
		}, 
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["message_group_list"] })
		},
	});

	return (
		<>
		{
			isLoading
            ? <MySpinner/>
            :
			<div className="w-full mt-8">
				<div className="flex flex-row justify-end mr-4">
					<Link to={`/admin/messages/groupSettings?mode=create`}><Button>그룹 생성</Button></Link>
				</div>
				<GroupTable data={data} onSendButtonClicked={onSendButtonClicked} onEditButtonClicked={onEditButtonClicked} 
							onDeleteButtonClicked={onDeleteButtonClicked}/>
			</div>
			
		}
		<AlertDialog open={isOpenedMessageTypeDialog} onOpenChange={setIsOpenedMessageTypeDialog}>
			<AlertDialogContent>
				<AlertDialogHeader>
					<AlertDialogTitle>해당 그룹에 전송할 메시지 타입을 선택 해 주세요</AlertDialogTitle>
					<AlertDialogDescription>
						<Select value={messageType} onValueChange={(value)=>setMessageType(value)}>
							<SelectTrigger className="w-52 mt-5">
								<SelectValue placeholder="메시지 타입을 선택하세요"/>
							</SelectTrigger>
							<SelectContent>
								<SelectGroup>
									<SelectItem value="push">푸쉬</SelectItem>
									<SelectItem value="inapp">인앱</SelectItem>
									<SelectItem value="email">이메일</SelectItem>
									<SelectItem value="sms">문자</SelectItem>
									<SelectItem value="alimtalk">알림톡</SelectItem>
								</SelectGroup>
							</SelectContent>
						</Select>
					</AlertDialogDescription>
					</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel onClick={()=>setIsOpenedMessageTypeDialog(false)}>취소</AlertDialogCancel>
					<AlertDialogAction onClick={()=>onSendContinueButtonClicked()}>계속</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
		<AlertDialog open={isOpenedDeleteDialog} onOpenChange={setIsOpenedDeleteDialog}>
			<AlertDialogContent>
				<AlertDialogHeader>
					<AlertDialogTitle>정말 삭제하시겠습니까?</AlertDialogTitle>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel onClick={()=>setIsOpenedDeleteDialog(false)}>취소</AlertDialogCancel>
					<AlertDialogAction onClick={()=>onDeleteContinueButtonClicked()}>계속</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
		</>
	)
}

export default ContentTable
