import * as React from "react"
import * as TabsPrimitive from "@radix-ui/react-tabs"

import { cn } from "@/components/lib/utils"

const OuterTabs = TabsPrimitive.Root

const OuterTabsList = React.forwardRef(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(
      "inline-flex h-10 items-center justify-center p-1 text-muted-foreground gap-2",
      className
    )}
    {...props} />
))
OuterTabsList.displayName = TabsPrimitive.List.displayName

const OuterTabsTrigger = React.forwardRef(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      "inline-flex items-center justify-center whitespace-nowrap rounded-sm px-2 lg:px-5 py-1.5 text-xl font-semibold ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 text-white data-[state=active]:text-accent data-[state=active]:text-xl data-[state=active]:shadow-sm data-[state=active]:underline data-[state=active]:underline-offset-16 data-[state=active]:decoration-2 data-[state=active]:decoration-color bg-transparent border-none ",
      className
    )}
    {...props} />
))
OuterTabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const OuterTabsContent = React.forwardRef(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      "mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 lg:p-20 py-6",
      className
    )}
    {...props} />
))
OuterTabsContent.displayName = TabsPrimitive.Content.displayName

export { OuterTabs, OuterTabsList, OuterTabsTrigger, OuterTabsContent}
