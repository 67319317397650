import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"

import { Send, Pencil, Trash, Circle, X } from "lucide-react"

export const getColumns = (onSendButtonClicked, onEditButtonClicked, onDeleteButtonClicked) => {
  var columns;

  columns = [
    {
      accessorKey: "name",
      header: () => <div className="text-left">그룹 이름</div>,
      cell: ({ row }) => <div>{row.getValue("name")}</div>,
    },
    {
      accessorKey: "num_of_members",
      header: () => <div className="text-center">그룹원</div>,
      cell: ({ row }) => <div className="text-center">{row.getValue("num_of_members")}</div>,
    },
    {
      id: "group_setting_table_actions",
      header: () => <div className="flex justify-end align-middle">
                        <div className="w-16">전송</div>
                        <div className="w-16">편집</div>
                        <div className="w-16">삭제</div>
                    </div>,
      enableHiding: false,
      cell: ({ row }) => {
        const group = row.original
  
        return (
          <div className="flex justify-end align-middle mr-5">
            <Button variant="icon" size="icon" className="w-15 mr-1" onClick={()=>onSendButtonClicked(group.id)}>
              <Send className="h-5 w-5"/>
            </Button>
            <Button variant="icon" size="icon" className="w-15 mr-1" onClick={()=>onEditButtonClicked(group.id)}>
              <Pencil className="h-5 w-5"/>
            </Button>
            <Button variant="icon" size="icon" className="w-15" onClick={()=>onDeleteButtonClicked(group.id)}>
              <Trash className="h-5 w-5"/>
            </Button>
         </div>
        )
      },
    },
  ]
  

  return columns;
}