import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { ColorRing } from 'react-loader-spinner';
import cn from 'classnames'

// css
import Style from './index.module.css'
import Notice from '@/assets/image/ico/ico-noti.svg'
import PagePrev from '@/assets/image/ico/ico-page-prev.svg'
import PageNext from '@/assets/image/ico/ico-page-next.svg'
import SearchImg from '@/assets/image/ico/ico-search.svg'


const Index = () => {

	const pathname = window.location.pathname;
	
	const { idx } = useParams();

	const [board, setBoard] = useState({});
	const [loading, setLoading] = useState(false);
	const [houseList, setHouseList] = useState([]);

	const [pathfind, setPathFind] = useState("N");

	const getPath = () => {
		const path = pathname.indexOf("/admin/NoticeView");
		
		if(path >= 0){
			setPathFind("Y");
		}
	}

	const getBoard = async () => {
		const resp = await (await axios.get(process.env.REACT_APP_API_NOTICE_VIEW + `/${idx}`)).data;
		setBoard(resp);
		setLoading(false);
	};

	const gethouseList = async (e) => {
		const resp = await (await axios.get(process.env.REACT_APP_API_MANAGERHOUSELIST)).data; 
		setHouseList(resp.list); 
	}

	const gotoDelete = () => {
		
		if(window.confirm("삭제하시겠습니까?")){
			axios.post(process.env.REACT_APP_API_NOTICE_DEL, {
				num: `${idx}`
			  })
			  .then(function (response) {
				if(response.data.code == '200'){
					alert("삭제되었습니다.");
					window.location.href="/admin/notice";
				}else if(response.data.code == '400'){
				  console.log(response.data.code);
				}
				}).catch(function (error) {
				  console.log("에러", error);
			  });
		}
	}

	useEffect(() => {
		setLoading(true);
		getBoard();
		gethouseList();
		getPath();
	  }, []);

	return (
		<>
		{ loading ?  <div style={{zIndex:'500', position: 'absolute', width: '80px', height: '80px', top: 'calc( 50% - 100px )', left: 'calc( 50% )'}}>
				<ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}/></div> : (

		<div className={Style.wrapper}>

            <div className={Style.subTitle}>
				<img src={Notice} />
				<span>공지사항 페이지</span>
			</div>

			<div className={Style.viewHeader}>
				<div className={Style.lt}>
					<select className={Style.select} value={board.noticetype}>
						<option value="1">주택별 공지</option>
						<option value="2">전체공지</option>
					</select>
					{board.noticetype === '1' ?
					<select className={Style.select} value={board.nohousetype}>
						<option value="">주택 선택</option>
						{houseList.map((list) => (
							<option key={list.seq} value={list.seq}>{list.house_name}</option>
						))}
					</select>
					:""
					}
				</div>
				<div className={Style.rt}>
					<div className={Style.vtit}>[{board.notype}] {board.subject}</div>
					<div className={Style.vinfo}>
						<span></span>
						<span>{board.regdate}</span>
					</div>
				</div>
			</div>

			<div className={Style.viewCont} dangerouslySetInnerHTML={{__html: board.contents}}>
			</div>
			
			{pathfind === "Y" && 
			<div style={{width:'100%', height:'auto', overflow:'hidden', display:'flex', justifyContent:'flex-end', alignItems:'center', margin:'30px auto 0 auto', maxWidth:'1300px'}}>
				<button className={Style.btn} type={'button'} onClick={gotoDelete}>삭제</button>
			</div>
			}

		</div> )}
		</>
	)
}

export default Index
