import {React} from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import FacilityRegister from './reqRegister/facilityRegister'

const ReqRegister = () => {
	const queryClient = new QueryClient()

	return (
		<QueryClientProvider client={queryClient}>
			<FacilityRegister/>
		</QueryClientProvider>
	)
}

export default ReqRegister