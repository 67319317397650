import React from 'react'
import cn from 'classnames'

// css
import Style from './index.module.css'
import User from '@/assets/image/ico/ico-user01.svg'

const Index = () => {
	return (
		<div className={Style.wrapper}>

			<div className={Style.subTitle}>
				<img src={User} />
				<span>나의정보</span>
				<div className={Style.etc}>나의정보</div>
			</div>

			<div className={Style.detailSection}>
				<dl>
					<dt>
						<div className={Style.stit}>김민석님은 장안생활 거주 중입니다.</div>
					</dt>
					<dd>
            <ul>
							<li>
                <div className={Style.key}>성별/셍년월일</div>
                <div className={Style.value}>
									<div className={Style.formItem}>
										<input type="text" className={Style.inp} readOnly value={'남자'} />
										<input type="text" className={Style.inp} readOnly value={'1993'} />
										<input type="text" className={Style.inp} readOnly value={'10'} />
										<input type="text" className={Style.inp} readOnly value={'22'} />
									</div>
								</div>
							</li>
              <li>
                <div className={Style.key}>연락처</div>
                <div className={Style.value}>
									<div className={Style.formItem}>
										<input type="text" className={cn(Style.inp, Style.tel)} value={'010'} />
										<div className={Style.e01}>-</div>
										<input type="text" className={cn(Style.inp, Style.tel)} value={'1234'} />
										<div className={Style.e01}>-</div>
										<input type="text" className={cn(Style.inp, Style.tel)} value={'5678'} />
									</div>
								</div>
              </li>
              <li>
                <div className={Style.key}>이메일</div>
                <div className={Style.value}>
									<div className={Style.formItem}>
										<input type="text" className={cn(Style.inp, Style.email)} value={'phyun1030'} />
										<div className={Style.e01}>@</div>
										<input type="text" className={cn(Style.inp, Style.email)} value={'naver.com'} />
									</div>
								</div>
              </li>
              <li>
                <div className={Style.key}>주소</div>
                <div className={cn(Style.value, Style.full)}>
									<div className={Style.formItem}>
										<input type="text" className={cn(Style.inp, Style.addr)} value={'경기도 오산시 어쩌고 어쩌고 어쩌고 어쩌고 어쩌고 어쩌고동 어쩌고 어쩌고'} />
									</div>
								</div>
              </li>
						</ul>
					</dd>
				</dl>

        <dl>
          <dt className={Style.type01}>
            <div className={Style.stit}>거주정보</div>
						<label>
							<input type="checkbox" checked />
							<span>거주상태</span>
						</label>
          </dt>
          <dd>
            <ul>
              <li>
                <div className={Style.value}>
									<span>계약기간 : 2023.03.24 – 2024.03.23</span>
								</div>
              </li>
              <li>
                <div className={Style.value}>거주기간 : 213일</div>
              </li>
              <li>
                <div className={Style.value}>경기도 오산시 어쩌고 어쩌고 어쩌고 어쩌고 어쩌고 어쩌고동 어쩌고 어쩌고</div>
              </li>
            </ul>
						<div className={Style.sitem}>
							<span>장안주택</span>
              <span>7층</span>
              <span>거주자 개별공간</span>
              <span>703호</span>
						</div>

            <ul>
              <li>
                <div className={Style.value}>
                  <span>보증금 / 임대료 / 관리비 : 5000만 / 60만 / 5만</span>
                  <button type={'button'}>납부기록</button>
                </div>
              </li>
              <li>
                <div className={Style.value}>월세납부일 | 매월 24일</div>
              </li>
              <li>
                <div className={Style.value}>계좌정보 : 우리은행 1002-000-00000-0000 예금주 : 아이부키</div>
              </li>
            </ul>
          </dd>
        </dl>

				<div className={Style.btnGroup}>
					<button type={'button'}>수정하기</button>
				</div>
			</div>

		</div>
	)
}

export default Index
