import {React, useState, useEffect, createContext} from 'react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import ContentTable from './content_table'
import GroupCreator from './group_creator'
import GroupEditor from './group_editor'
import { MySpinner } from '@/components/ui/spinner'
import { Link, useParams, useNavigate, useSearchParams  } from "react-router-dom";
export const GroupSettingsContext = createContext({
    addedMembersList: [],
    setAddedMembersList: () => {},
  });

const GroupSettingsTab = () => {
    const [addedMembersList, setAddedMembersList] = useState([]);
    const contextValue = { addedMembersList, setAddedMembersList };
    const [searchParams, setSearchParams] = useSearchParams();
    const createMode = searchParams.get('mode') == "create";
    const editMode = searchParams.get('mode') == "edit";
    const groupId = searchParams.get('id');

	return (
		<>
        <GroupSettingsContext.Provider value={contextValue}>
        {
            createMode ? <GroupCreator/> 
                        : editMode ? <GroupEditor groupId={groupId}/>
                        : <ContentTable/>
        }
        </GroupSettingsContext.Provider>
		</>
	)
}

export default GroupSettingsTab
