import React, {useState, useEffect, useRef} from 'react'
import cn from 'classnames'

// css
import Style from './index.module.css'
import HistoryIcon from '@/assets/image/ico/ico-time.svg'
import SpaceHome from '@/assets/image/ico/ico-space-home.svg'
import FilterIcon01 from '@/assets/image/ico/ico-filter-list01.svg'
import FilterIcon02 from '@/assets/image/ico/ico-filter-list02.svg'
import FilterIcon03 from '@/assets/image/ico/ico-filter-list03.svg'
import FilterIcon04 from '@/assets/image/ico/ico-filter-list04.svg'
import DensityIcon01 from '@/assets/image/ico/ico-density-list01.png'
import DensityIcon02 from '@/assets/image/ico/ico-density-list02.png'
import DensityIcon03 from '@/assets/image/ico/ico-density-list03.png'
import PagePrev from '@/assets/image/ico/ico-page-prev.svg'
import PageNext from '@/assets/image/ico/ico-page-next.svg'
import SearchImg from '@/assets/image/ico/ico-search.svg'
const CostList = () => {
	const [isPopup, setIsPopup] = useState(false);
	
	const columnOpen = () => {
		if(isPopup === false){
			setIsPopup(true);
		}else{
			setIsPopup(false);
		}
	}

	return (
		<div className={Style.wrapper}>

			<sction className={Style.spacedHeader}>

        <div className={Style.pageTitle}>
					<img src={SpaceHome} />
          <span>거주자별 비용 리스트</span>
        </div>
      </sction>

      <section className={Style.spaceList}>
        <div className={Style.optWrap}>
          <div className={Style.filter}>
            <div className={Style.filterItemmWrap}>
              <a href="#" className={Style.on} onClick={columnOpen}>
                <img src={FilterIcon01} />
                <span>COLUMNS</span>
              </a>
              {isPopup === true && 
			  <div className={Style.filterResultWrap}>
                <div className={Style.formItem}>
                  <input type="text" className={Style.inp} placeholder={'Find column'} />
                </div>
                <ul>
                  <li>
                    <label>
                      <input type="checkbox" />
                      <span>이름</span>
                    </label>
                  </li>
									<li>
										<label>
											<input type="checkbox" />
											<span>공유주택</span>
										</label>
									</li>
									<li>
										<label>
											<input type="checkbox" />
											<span>층수</span>
										</label>
									</li>
									<li>
										<label>
											<input type="checkbox" />
											<span>공간타입</span>
										</label>
									</li>
									<li>
										<label>
											<input type="checkbox" />
											<span>호수</span>
										</label>
									</li>
									<li>
										<label>
											<input type="checkbox" />
											<span>보증금</span>
										</label>
									</li>
									<li>
										<label>
											<input type="checkbox" />
											<span>월세</span>
										</label>
									</li>
									<li>
										<label>
											<input type="checkbox" />
											<span>관리비</span>
										</label>
									</li>
									<li>
										<label>
											<input type="checkbox" />
											<span>거주/잔여차수</span>
										</label>
									</li>
									<li>
										<label>
											<input type="checkbox" />
											<span>입주기간</span>
										</label>
									</li>
                </ul>
                <div className={Style.toggle}>
                  <a href="#" className={Style.on}>HIDE ALL</a>
									<a href="#">SHOW ALL</a>
                </div>
              </div>
			}
            </div>
						{/*}
						<div className={Style.filterItemmWrap}>
							<a href="#">
								<img src={FilterIcon02} />
								<span>FILTERS</span>
							</a>
						</div>
						<div className={Style.filterItemmWrap}>
							<a href="#">
								<img src={FilterIcon03} />
								<span>DENSITY</span>
							</a>
							<div className={Style.filterResultWrap}>
								<ul>
									<li>
										<a href="#">
											<img src={DensityIcon01} />
											<span>Compact</span>
										</a>
									</li>
									<li>
										<a href="#">
											<img src={DensityIcon02} />
											<span>Standard</span>
										</a>
									</li>
									<li>
										<a href="#">
											<img src={DensityIcon03} />
											<span>Comfortable</span>
										</a>
									</li>
								</ul>

							</div>
						</div>
						<div className={Style.filterItemmWrap}>
							<a href="#">
								<img src={FilterIcon04} />
								<span>EXPORT</span>
							</a>
							<div className={Style.filterResultWrap}>
								<ul>
									<li>
										<a href="#">
											<span>Download as CSV</span>
										</a>
									</li>
									<li>
										<a href="#">
											<span>Print</span>
										</a>
									</li>
								</ul>
							</div>
						</div>
						{*/}

          </div>
          <select className={Style.select}>
            <option>20개</option>
          </select>
        </div>
        <div className={Style.scrolls}>
          <table className={cn(Style.webList)}>
            <colgroup>
              <col width="" />
							<col width="" />
							<col width="" />
							<col width="" />
							<col width="" />
							<col width="" />
							<col width="" />
							<col width="" />
							<col width="" />
							<col width="180" />
            </colgroup>
            <thead>
						<tr>
							<th rowSpan={'2'}>이름</th>
							<th colSpan={'4'}>거주공간</th>
							<th colSpan={'3'}>공간비용</th>
							<th rowSpan={'2'}>거주/잔여차수</th>
							<th rowSpan={'2'}>입주기간</th>
						</tr>
						<tr>
							<th className={Style.bol}>공유주택</th>
							<th>층수</th>
							<th>공간타입</th>
							<th>호수</th>
							<th>보증금</th>
							<th>월세</th>
							<th>관리비</th>
						</tr>
						</thead>
            <tr>
							<td>박수진</td>
              <td>장안생활</td>
							<td>7층</td>
							<td>개별거주공간</td>
							<td>712호</td>
							<td>5천만원</td>
							<td>60만원</td>
							<td>2만4천원</td>
							<td>5개월/7개월</td>
							<td>2022.9.13-2023.9.12</td>
            </tr>
						<tr>
							<td>박수진</td>
							<td>장안생활</td>
							<td>7층</td>
							<td>개별거주공간</td>
							<td>712호</td>
							<td>5천만원</td>
							<td>60만원</td>
							<td>2만4천원</td>
							<td>5개월/7개월</td>
							<td>2022.9.13-2023.9.12</td>
						</tr>
          </table>

					<ul className={cn(Style.mobList)}>
						<li>
							<div className={Style.chk}>
								<input type="checkbox" />
							</div>
							<table>
								<colgroup>
									<col width="80" />
									<col width="80" />
									<col width="" />
								</colgroup>
								<tr>
									<th colSpan={2} className={Style.al}>이름</th>
									<td className={Style.al}>박수진</td>
								</tr>
								<tr>
									<th className={Style.al} rowSpan={4}>거주공간</th>
									<th className={Style.al}>공유주택</th>
									<td className={Style.al}>장안생활</td>
								</tr>
								<tr>
									<th className={cn(Style.al, Style.bol)}>층수</th>
									<td className={Style.al}>7층</td>
								</tr>
								<tr>
									<th className={cn(Style.al, Style.bol)}>공간타입	</th>
									<td className={Style.al}>개별거주공간	</td>
								</tr>
								<tr>
									<th className={cn(Style.al, Style.bol)}>호수</th>
									<td className={Style.al}>	712호</td>
								</tr>
								<tr>
									<th className={Style.al} rowSpan={3}>공간비용</th>
									<th className={Style.al}>보증금</th>
									<td className={Style.al}>5천만원</td>
								</tr>
								<tr>
									<th className={cn(Style.al, Style.bol)}>월세</th>
									<td className={Style.al}>	60만원</td>
								</tr>
								<tr>
									<th className={cn(Style.al, Style.bol)}>관리비</th>
									<td className={Style.al}>	2만4천원</td>
								</tr>
								<tr>
									<th className={Style.al} colSpan={2}>거주/잔여차수	</th>
									<td className={Style.al}>	5개월/7개월</td>
								</tr>
								<tr>
									<th className={Style.al} colSpan={2}>입주기간</th>
									<td className={Style.al}>	2022.9.13-2023.9.12</td>
								</tr>
							</table>
						</li>
						<li>
							<div className={Style.chk}>
								<input type="checkbox" />
							</div>
							<table>
								<colgroup>
									<col width="80" />
									<col width="80" />
									<col width="" />
								</colgroup>
								<tr>
									<th colSpan={2} className={Style.al}>이름</th>
									<td className={Style.al}>박수진</td>
								</tr>
								<tr>
									<th className={Style.al} rowSpan={4}>거주공간</th>
									<th className={Style.al}>공유주택</th>
									<td className={Style.al}>장안생활</td>
								</tr>
								<tr>
									<th className={cn(Style.al, Style.bol)}>층수</th>
									<td className={Style.al}>7층</td>
								</tr>
								<tr>
									<th className={cn(Style.al, Style.bol)}>공간타입	</th>
									<td className={Style.al}>개별거주공간	</td>
								</tr>
								<tr>
									<th className={cn(Style.al, Style.bol)}>호수</th>
									<td className={Style.al}>	712호</td>
								</tr>
								<tr>
									<th className={Style.al} rowSpan={3}>공간비용</th>
									<th className={Style.al}>보증금</th>
									<td className={Style.al}>5천만원</td>
								</tr>
								<tr>
									<th className={cn(Style.al, Style.bol)}>월세</th>
									<td className={Style.al}>	60만원</td>
								</tr>
								<tr>
									<th className={cn(Style.al, Style.bol)}>관리비</th>
									<td className={Style.al}>	2만4천원</td>
								</tr>
								<tr>
									<th className={Style.al} colSpan={2}>거주/잔여차수	</th>
									<td className={Style.al}>	5개월/7개월</td>
								</tr>
								<tr>
									<th className={Style.al} colSpan={2}>입주기간</th>
									<td className={Style.al}>	2022.9.13-2023.9.12</td>
								</tr>
							</table>
						</li>
					</ul>
        </div>

				<div className={Style.paging}>
					<button type="button"><img src={PagePrev} /></button>
					<ul>
						<li><a href="#" className={Style.on}>1</a></li>
						<li><a href="#">2</a></li>
						<li><a href="#">3</a></li>
						<li><a href="#">4</a></li>
						<li><a href="#">5</a></li>
						<li><a href="#">6</a></li>
					</ul>
					<button type="button"><img src={PageNext} /></button>
				</div>

				<div className={Style.boardSearch}>
					<div className={Style.searchForm}>
						<select className={Style.select}>
							<option>제목검색</option>
						</select>
						<input type="text" className={Style.inp} />
						<button type="button"><img src={SearchImg} /></button>
					</div>
				</div>


			</section>

		</div>
	)
}

export default CostList
