import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import { Input } from "@/components/ui/input"

import { Send, Pencil, Trash, Circle, X } from "lucide-react"

export const getColumns = (variableData, inputDifferentMessage) => {
  var columns;

  columns = [
    {
      accessorKey: "building_name",
      header: () => <div className="text-left">건물명</div>,
      cell: ({ row }) => <div>{row.getValue("building_name")}</div>,
    },
    {
      accessorKey: "room_number",
      header: () => <div className="text-center">호수</div>,
      cell: ({ row }) => <div className="text-center">{row.getValue("room_number")}</div>,
    },
    {
      accessorKey: "name",
      header: () => <div className="text-center">이름</div>,
      cell: ({ row }) => <div className="text-center">{row.getValue("name")}</div>,
    },
    {
      accessorKey: "phone_number",
      header: () => <div className="text-center">전화번호</div>,
      cell: ({ row }) => <div className="text-center">{row.getValue("phone_number")}</div>,
    },
  ]

  variableData.forEach( (variable, index) => {
    columns.push(
      {
        id: "different_message_table actions",
        enableHiding: false,
        header: ( ) => <div className="text-center">{variable.variable_name}</div>,
        width: 300,
        cell: ({ row }) => {
          const differentMessage = row.original
          const propertyName = `var_${index+1}_value`;
          const onBlur = (event) => {
            inputDifferentMessage(row.index, propertyName, event.target.value)
          }
    
          if(variable.is_auto_variable === true){
            return (
              <div className="text-center">{differentMessage[propertyName]}</div>
            )
          }else{
            return (
              <div className="flex flex-row justify-center">
                  { 
                    differentMessage[propertyName] !== ""
                    ? <Button variant="intable" onClick={()=>{inputDifferentMessage(row.index, propertyName, "")}}>{differentMessage[propertyName]}</Button> //inputSameContent(row.index, "")
                    : <Input
                      className="flex max-w-sm"
                      onBlur={onBlur}
                    />
                  }
              </div>
            )
          }
        },
      },
    )
  })
  
  

  return columns;
}