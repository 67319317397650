import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import axios from "axios";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { getToken } from "./services/TokenService";

import { BrowserRouter } from 'react-router-dom';

//axios.defaults.baseURL = "http://localhost:3000";
//axios.defaults.headers['Access-Control-Allow-Origin'] = 'https://project-code.kr';
axios.defaults.baseURL = "https://siso.live";
//axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.withCredentials = true;

//const navigate = useNavigate();
const container = document.getElementById('root');
const root = createRoot(container);

const hostname = window.location.hostname;
const pathname = window.location.pathname;

if(hostname.indexOf('http://') >= 0){
  window.location.href = "https://"+process.env.REACT_APP_HOME_DOMAIN+pathname;
}

if(hostname.indexOf('www') >= 0){
  window.location.href = "https://"+process.env.REACT_APP_HOME_DOMAIN+pathname;
}

const token = getToken();

if(token !== null){
  const local_token = JSON.parse(token);

  if(local_token.adchk === 'Y'){
    if((pathname === '/admin') || (pathname === '/')){
      window.location.href="/admin/main";
    }
  }
}

root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
