import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import { Input } from "@/components/ui/input"
import { Switch } from "@/components/ui/switch"

import { Send, Pencil, Trash, Circle, X } from "lucide-react"
import { Label } from "@radix-ui/react-select"

export const getColumns = (toggleIsSameContent, inputSameContent) => {
  var columns;

  columns = [
    {
      accessorKey: "variable_name",
      header: () => <div className="text-left">변수명</div>,
      cell: ({ row }) => <div>{row.getValue("variable_name")}</div>,
    },
    {
      accessorKey: "is_auto_variable",
      header: () => <div className="text-center">자동 입력</div>,
      cell: ({ row }) => <div className="text-center align-middle">{row.getValue("is_auto_variable") === true ? <Circle className="w-5 h-5 mt-1"/> : ""}</div>,
    },
    {
      id: "different_message_variable_table actions_toggle",
      enableHiding: false,
      header: () => <div className="text-center">동일 내용 전송</div>,
      cell: ({ row }) => {
        const variable = row.original
  
        return (
          <>
            {
              !variable.is_auto_variable
              &&
              <div className="flex justify-center align-middle">
                <Switch id="switch_enable" checked={variable.is_same_content} onCheckedChange={()=>{toggleIsSameContent(row.index)}}/>
              </div>
            }
          </>
        )
      },
    },
    {
      id: "different_message_variable_table actions_input",
      enableHiding: false,
      header: () => <div className="text-center">전송할 내용</div>,
      cell: ({ row }) => {
        const variable = row.original
        const onBlur = (event) => {
          inputSameContent(row.index, event.target.value)
        }
  
        return (
          <>
            {
              variable.is_same_content
              ? <div className="flex justify-center align-middle">
                  { 
                    variable.same_content !== ""
                    ? <Button variant="intable" onClick={()=>inputSameContent(row.index, "")}>{variable.same_content}</Button>
                    : <Input
                      className="flex max-w-sm"
                      onBlur={onBlur}
                      onKeyPress={ (key) => key === 'Enter' ? onBlur() : null }
                    />
                  }
              </div>
              : null
            }
          </>
        )
      },
    },
  ]

  return columns;
}