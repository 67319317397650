import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import cn from 'classnames'

// css
import Style from './index.module.css'
import ProductImg from '@/assets/image/etc/etc-product01.png'
import MainImg from '@/assets/image/etc/etc-main-list01.png'
import SpaceHome from '@/assets/image/ico/ico-adm.svg'
import MnList01 from '@/assets/image/ico/ico-mn-list01.svg'
import MnList02 from '@/assets/image/ico/ico-mn-list02.svg'
import MnList03 from '@/assets/image/ico/ico-mn-list03.svg'
import MnList04 from '@/assets/image/ico/ico-mn-list04.svg'
import MnList05 from '@/assets/image/ico/ico-mn-list05.svg'
import MnList06 from '@/assets/image/ico/ico-mn-list06.svg'
import Cmm from '@/assets/image/ico/ico-cmm.svg'
import Notice from '@/assets/image/ico/ico-noti.svg'
import More from '@/assets/image/ico/ico-arr-more.svg'
import Profile from "@/assets/image/etc/etc-product01.png";
import SearchBtn from "@/assets/image/ico/ico-search.svg";
import RemoveIco from "@/assets/image/ico/ico-remove-white.svg";
import profile from '@/assets/image/etc/etc-product01.png'



const Index = () => {
	const { idx } = useParams();
	const [board, setBoard] = useState({});
	const [isPopup, setIsPopup] = useState(true);
	const handleClose = () => {
		setIsPopup(false);
	}

	const getBoard = async () => {
		axios({
			method: 'post',
			url: process.env.REACT_APP_API_TEAMMEMBERVIEW,
			data:{
				seq: `${idx}`
			},
		}).then((res) => {
			setBoard(res.data);
		}).catch((err) => {	
			console.log("에러", err);
		})
	};

	useEffect(() => {
		getBoard();
	}, []);

	return (
		<div className={Style.wrapper}>

			<section className={Style.spacedHeader}>

				<div className={Style.pageTitle}>
					<img src={SpaceHome} />
					<span>매니저 정보</span>
				</div>

			</section>

			<section className={Style.registerWrap}>

				<div className={Style.photoList}>
					<div className={Style.pic} style={{background:`url(${profile}`}}></div>
				</div>

				<div className={Style.formGroup}>
					<div className={Style.formRows}>
						<div className={Style.label}>매니저 클래스</div>
						<div className={Style.formItem}>
              			<div className={cn(Style.value, Style.name)}>{board.membertype}</div>
						</div>
					</div>
					<div className={Style.formRows}>
						<div className={Style.label}>이름</div>
						<div className={Style.formItem}>
              			<div className={cn(Style.value, Style.name)}>{board.name}</div>
						</div>
					</div>
					<div className={Style.formRows}>
						<div className={Style.label}>성별/생년월일</div>
						<div className={Style.formItem}>
							<div className={Style.value}>{board.sex}</div>
							<div className={Style.value}>{board.birthy}</div>
							<div className={Style.value}>{board.birthm}</div>
							<div className={Style.value}>{board.birthd}</div>
						</div>
					</div>
					<div className={Style.formRows}>
						<div className={Style.label}>연락처</div>
						<div className={Style.formItem}>
							<div className={cn(Style.value, Style.tel)}>{board.hptel1}</div>
							<span className={Style.e01}>-</span>
							<div className={cn(Style.value, Style.tel)}>{board.hptel2}</div>
              <span className={Style.e01}>-</span>
							<div className={cn(Style.value, Style.tel)}>{board.hptel3}</div>
						</div>
					</div>

          <div className={Style.formRows}>
            <div className={Style.label}>이메일</div>
            <div className={Style.formItem}>
							<div className={cn(Style.value, Style.email)}>{board.email1}</div>
              <span className={Style.e01}>@</span>
							<div className={cn(Style.value, Style.email)}>{board.email2}</div>
            </div>
          </div>

          <div className={Style.formRows}>
            <div className={Style.label}>관리주택</div>
            <div className={Style.formItem}>
              			<div className={cn(Style.value, Style.name)}>{board.house}</div>
						</div>
          </div>

				</div>


				<div className={Style.btnGroup}>
					{/*}
					<button type="button" className={Style.btnCancel}>취소</button>
					<button type="button" className={Style.btnConfirm}>등록</button>
					{*/}
				</div>
			</section>


		</div>
	)
}

export default Index
