import {React, useState, useEffect, useRef} from 'react'
import { useNavigate } from "react-router-dom"
import cn from 'classnames'

// css
import Style from './index.module.css'
import popClose from '@/assets/image/ico/ico-pop-close.svg'
import Logo from '@/assets/video/logo.mp4'

const Index = () => {
	
	const emailfocusRef = useRef(null);
	const passfocusRef = useRef(null);
	const passconfocusRef = useRef(null);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordconfirm, setPasswordconfirm] = useState("");
    const [nickname, setNickname] = useState("");
	const [validPwd, setValidPwd] = useState(false);
	const [dbvalidPwd, setDbValidPwd] = useState(false);

	const navigate = useNavigate();

	/*useEffect(()=>{
        setTimeout(function(){
            handleOpen();
		},1000)
	},[]);*/

	const handlePress = (e) => {
		const regex = /^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*()_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]{10,}$/;
				
		if(e.target.value.match(regex)===null) { 
			setValidPwd(false);
			//console.log('비밀번호 형식을 확인해주세요');
			//return;
		  }else{ 
			//console.log('비밀번호 형식이 맞아요');
			setValidPwd(true);
		  }
	}

	const handleDbPress = (e) => {
		const regex = /^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*()_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]{10,}$/;
				
		if(e.target.value.match(regex)===null) { 
			setDbValidPwd(false);
		  }else{ 
			setDbValidPwd(true);
		  }
	}
	
	const [member, setMember] = useState({
		nickname: '',
		hptel: '',
		hptel_auth_num: '',
	});

	const JoinStepFunc = (e) => {
		e.preventDefault();
		if(email === ""){
			alert("이메일 주소를 입력해주세요.");
			emailfocusRef.current.focus();
			return;
		}
		if(password === ""){
			alert("비밀번호를 입력해주세요.");
			passfocusRef.current.focus();
			return;
		}
		if(validPwd === false){
			alert("대/소문자, 숫자, 특수문자 중 2가지 이상의 조합으로 10자 이상 입력해주세요.");
			passfocusRef.current.focus();
			return;
		}
		if(passwordconfirm === ""){
			alert("비밀번호 확인을 입력해주세요.");
			passconfocusRef.current.focus();
			return;
		}
		if(dbvalidPwd === false){
			alert("대/소문자, 숫자, 특수문자 중 2가지 이상의 조합으로 10자 이상 입력해주세요.");
			passconfocusRef.current.focus();
			return;
		}
		if(password !== passwordconfirm){
			alert("입력한 비밀번호가 같지 않습니다. 확인해주세요.");
			passfocusRef.current.focus();
			return;
		}

		navigate("/join", { state: { "email": email, "password": password } });
	}

	return (
		<>
			<div className={Style.wrapper}>

				<section className={cn(Style.signupWrap, Style.type01)}>
					<div className={Style.slogo}>
						<video width="100%" height="100%" id="v_player" playsinline="" allowfullscreen="true" autoPlay loop muted>
							<source src={Logo} type="video/mp4" />
						</video>
					</div>
					<div className={Style.stit}>회원가입</div>
					<ul>
						<li>
							<div className={Style.formItem}>
								<input type="text" id="email" value={email} className={Style.inp} ref={emailfocusRef} placeholder="이메일주소" onChange={(e) => setEmail(e.target.value)} />
							</div>
						</li>
						<li>
							<div className={Style.formItem}>
								<input type="password" id="password" name="password" value={password} ref={passfocusRef} className={Style.inp} placeholder="비밀번호" onChange={(e) => setPassword(e.target.value)} onBlur={handlePress}/>
							</div>
						</li>
						<li>
							<div className={Style.formItem}>
								<input type="password" id="passwordconfirm" name="passwordconfirm" value={passwordconfirm} ref={passconfocusRef} className={Style.inp} placeholder="비밀번호 한번 더" onChange={(e) => setPasswordconfirm(e.target.value)} onBlur={handleDbPress} />
							</div>
						</li>
					</ul>
					<div className={Style.helpmsg}>대/소문자, 숫자, 특수문자 중 2가지 이상의 조합으로 10자 이상</div>
					<div className={Style.btnGroup}>
						<button type="button" onClick={JoinStepFunc}>다음</button>
					</div>
				</section>

			</div>

		</>
	)
}

export default Index
