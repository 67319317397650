import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import cn from 'classnames'

// css
import Style from './index.module.css'
import ProductImg from '@/assets/image/etc/etc-product01.png'
import MainImg from '@/assets/image/etc/etc-main-list01.png'
import SpaceHome from '@/assets/image/ico/ico-adm.svg'
import MnList01 from '@/assets/image/ico/ico-mn-list01.svg'
import MnList02 from '@/assets/image/ico/ico-mn-list02.svg'
import MnList03 from '@/assets/image/ico/ico-mn-list03.svg'
import MnList04 from '@/assets/image/ico/ico-mn-list04.svg'
import MnList05 from '@/assets/image/ico/ico-mn-list05.svg'
import MnList06 from '@/assets/image/ico/ico-mn-list06.svg'
import Cmm from '@/assets/image/ico/ico-cmm.svg'
import Notice from '@/assets/image/ico/ico-noti.svg'
import More from '@/assets/image/ico/ico-arr-more.svg'
import Profile from "@/assets/image/etc/etc-product01.png";
import SearchBtn from "@/assets/image/ico/ico-search.svg";
import RemoveIco from "@/assets/image/ico/ico-remove-white.svg";
import profile from '@/assets/image/etc/etc-product01.png'



const Index = () => {
	const { idx } = useParams();
	const [board, setBoard] = useState({});
	const [isPopup, setIsPopup] = useState(true);
	const handleClose = () => {
		setIsPopup(false);
	}

	const getBoard = async () => {
		axios({
			method: 'post',
			url: process.env.REACT_APP_API_CPNMEMBERVIEW,
			data:{
				seq: `${idx}`
			},
		}).then((res) => {
			setBoard(res.data);
		}).catch((err) => {	
			console.log("에러", err);
		})
	};

	useEffect(() => {
		getBoard();
	}, []);

	return (
		<div className={Style.wrapper}>

			<section className={Style.spacedHeader}>

				<div className={Style.pageTitle}>
					<img src={SpaceHome} />
					<span>기업 및 위탁 계정 정보</span>
				</div>

			</section>

			<section className={Style.registerWrap}>

				<div className={Style.formGroup}>
					<div className={Style.formRows}>
						<div className={Style.label}>계정 타입</div>
						<div className={Style.formItem}>
							{board.gubun === "A" ?
							<>
							<label htmlFor={'id'}><input type='radio' id={'id'} checked='checked' name="class_gubun" value="A" /><span>기업</span></label> 	
							<label htmlFor={'id2'}><input type='radio' id={'id2'} name="class_gubun" value="B" /><span>위탁</span></label>
							</>
							:
							<>
							<label htmlFor={'id'}><input type='radio' id={'id'} name="class_gubun" value="A" /><span>기업</span></label> 	
							<label htmlFor={'id2'}><input type='radio' id={'id2'} checked='checked' name="class_gubun" value="B" /><span>위탁</span></label>
							</>
							}
						</div>
					</div>
					<div className={Style.formRows}>
						<div className={Style.label}>기업명</div>
						<div className={Style.formItem}>
              			<div className={cn(Style.value, Style.name)}>{board.company}</div>
						</div>
					</div>
					<div className={Style.formRows}>
						<div className={Style.label}>담당자명</div>
						<div className={Style.formItem}>
              			<div className={cn(Style.value, Style.name)}>{board.damdangja}</div>
						</div>
					</div>
					<div className={Style.formRows}>
						<div className={Style.label}>담당자 부서/직책</div>
						<div className={Style.formItem}>
							<div className={cn(Style.value, Style.tel)}>{board.buseo}</div>
							<div className={cn(Style.value, Style.tel)}>{board.pos}</div>
						</div>
					</div>
					<div className={Style.formRows}>
						<div className={Style.label}>연락처</div>
						<div className={Style.formItem}>
							<div className={cn(Style.value, Style.tel)}>{board.hptel1}</div>
							<span className={Style.e01}>-</span>
							<div className={cn(Style.value, Style.tel)}>{board.hptel2}</div>
              				<span className={Style.e01}>-</span>
							<div className={cn(Style.value, Style.tel)}>{board.hptel3}</div>
						</div>
					</div>

					<div className={Style.formRows}>
						<div className={Style.label}>이메일</div>
						<div className={Style.formItem}>
										<div className={cn(Style.value, Style.email)}>{board.email1}</div>
						<span className={Style.e01}>@</span>
										<div className={cn(Style.value, Style.email)}>{board.email2}</div>
						</div>
					</div>
					
				</div>


				<div className={Style.btnGroup}>
					{/*}
					<button type="button" className={Style.btnCancel}>취소</button>
					<button type="button" className={Style.btnConfirm}>등록</button>
					{*/}
				</div>
			</section>


		</div>
	)
}

export default Index
