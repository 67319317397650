import React, {useState, useEffect, useRef} from 'react'
import { useNavigate } from "react-router-dom"
import axios from "axios";
import cn from 'classnames'
import CheckBox from '../../components/checkBox'
import { getToken } from "../../services/TokenService";

// css
import Style from './index.module.css'
import Smile from '@/assets/image/ico/ico-smile.svg'
import PagePrev from '@/assets/image/ico/ico-page-prev.svg'
import PageNext from '@/assets/image/ico/ico-page-next.svg'
import SearchImg from '@/assets/image/ico/ico-search.svg'

const Index = () => {
	const [memberList, setMemberList] = useState([]);
	const [pageList, setPageList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState({
		page: 1,
		sck: '',
		scv: '',
	  });

	  //console.log(mtype);
	
	const [pageNum, setPageNum] = useState("1");
	const [totalPageNum, setTotalPageNum] = useState("1");
	const [searchWd, setSearchWd] = useState("");
	const [searchKey, setSearchKey] = useState("username");

	const navigate = useNavigate();

	const [isPopup, setIsPopup] = useState(true);
	const handleClose = () => {
		setIsPopup(false);
	}

	const teamReg = () => {
		navigate("/admin/teamReg");
	}

	const admView = (e) => {
		navigate("/admin/teamview/"+e);
	}

	const register = () => {
		navigate("/admin/space/contract");
	}

	const userView = (idx) => {
		navigate("/admin/userView/"+idx);
	}

	const getMemberList = async (e) => {
		const token = getToken();

		if(token !== null){
			const local_token = JSON.parse(token);

			if (pageNum === e) return;

			if(e == undefined){
				e = "";	
			}
			const queryString = "/"+e;

			//console.log(queryString)
			
			axios({
				method: 'post',
				url: process.env.REACT_APP_API_MEMBERUSERLIST,
				mode: "cors",
				credentials: "same-origin",
				data:{
					page: e,
					searchword: searchWd,
					searchkey: searchKey,
					company: local_token.company,
				},
			}).then((res) => {
				setMemberList(res.data.list); 

				const pngn = res.data.paging;
				const curPage = pngn.page;
				const curPage2 = pngn.cur_page;
				const pageRow = pngn.page_rows;
				const totalCnt = pngn.total_cnt;
				const totalPageCnt = pngn.num_page_cnt;
				const startPage = pngn.startpage;
				const endPage = pngn.endpage;
				const base_page = 1;

				const tmpPages = [];
				for (let i = startPage - 1; i <= endPage; i++) {
					if (i >= base_page){
						tmpPages.push(i);
					}
				}
				setPageList(tmpPages);
				setTotalPageNum(totalPageCnt);
				setLoading(false);
			}).catch((err) => {	
				console.log("에러", err);
			})
		}
		/*
		const resp = await (await axios.get(process.env.REACT_APP_API_MEMBERUSERLIST + queryString)).data; 
		setMemberList(resp.list); 
		
		const pngn = resp.paging;
		const curPage = pngn.page;
		const curPage2 = pngn.cur_page;
		const pageRow = pngn.page_rows;
		const totalCnt = pngn.total_cnt;
		const totalPageCnt = pngn.num_page_cnt;
		const startPage = pngn.startpage;
		const endPage = pngn.endpage;
		const base_page = 1;

		const tmpPages = [];
		for (let i = startPage - 1; i <= endPage; i++) {
			if (i >= base_page){
				tmpPages.push(i);
			}
		}
		setPageList(tmpPages);
		setTotalPageNum(totalPageCnt);
		setLoading(false);
		*/
	}

	const getSearch = () =>{
		
		if(searchWd === ""){
			alert("검색어를 입력해주세요.");
			return;
		}else{
			setPageNum("1");

			axios({
				method: 'post',
				url: process.env.REACT_APP_API_MEMBERUSERLIST,
				mode: "cors",
				credentials: "same-origin",
				data:{
					page: pageNum,
					searchword: searchWd,
					searchkey: searchKey
				},
			}).then((res) => {
				setMemberList(res.data.list); 
	
				const pngn = res.data.paging;
				const curPage = pngn.page;
				const curPage2 = pngn.cur_page;
				const pageRow = pngn.page_rows;
				const totalCnt = pngn.total_cnt;
				const totalPageCnt = pngn.num_page_cnt;
				const startPage = pngn.startpage;
				const endPage = pngn.endpage;
				const base_page = 1;
	
				const tmpPages = [];
				for (let i = startPage - 1; i <= endPage; i++) {
					if (i >= base_page){
						tmpPages.push(i);
					}
				}
				setPageList(tmpPages);
				setTotalPageNum(totalPageCnt);
				setLoading(false);
			}).catch((err) => {	
				console.log("에러", err);
			})
		}
	}

	//전체선택 부분 시작
	const [checkItems, setCheckItems] = useState([])

	const checkItemHandler = (id, isChecked) => {
		if (isChecked) {
			setCheckItems((prev) => [...prev, id])
		} else {
		//console.log('not')
			setCheckItems(checkItems.filter((item) => item !== id))
		}
		//console.log(checkItems)
	}

	const allCheckedHandler = (e) => {
		if (e.target.checked) { 
			setCheckItems(memberList.map((item) => item.seq))
		} else {
			setCheckItems([]);
		}
		//console.log(`allCheck = `, e.target.checked)
	}

	const selectDataDelete = (e) => {
		if(checkItems.length == 0){
			alert('삭제하실 데이터를 선택해주세요.');
		}else{
			//console.log(checkItems.length);

			if(window.confirm("정말로 삭제 하시겠습니까?")){
				const formData = new FormData();
				formData.append('chkidx',checkItems)
				axios({
					method:'post',
					url:process.env.REACT_APP_API_USERLISTSELECTDELETE,
					mode: "cors",
					credentials: "same-origin",
					headers: {
						"Content-Type": "multipart/form-data",
					},
					data: formData,
				})
					.then((result)=>{
						if(result.data.code == 200){
							alert("삭제 되었습니다.");
							window.location.href="/admin/userList";
						}else{
							console.log(result)
						}
				})
					.catch((error)=>{console.log('요청실패')
					console.log(error)  
				})
			}
		}
	}
	//전체선택 부분 끝

	const onBtnClick = (event) => {
		let value = event.target.getAttribute('data');
		//console.log(value);
		/*setSearch({
		  ...search,
		  page: value,
		});*/
		setPageNum(value);
		//console.log(pageNum);
		getMemberList(value);
	};

	const onBtnClick2 = (e) => {
		let val = e.totalPageNum;
		//console.log(val);
		/*setSearch({
		  ...search,
		  page: value,
		});*/
		setPageNum(val);
		//console.log(pageNum);
		getMemberList(val);
	};

    //계약추가
    const onBtnContract = (m, j) => {
        if(m !== ""){
			if(j === "A"){
				axios({
					method: 'post',
					url: process.env.REACT_APP_API_CONTRACT_SIGN,
					mode: "cors",
					credentials: "same-origin",
					data:{
						useridx: m,
					},
				}).then((res) => {
					if(res.data.code == 200){
						alert("전자계약문서 생성이 완료되었습니다. 다음페이지에서 서명을 완료해주세요.");
						window.location.href="/admin/contract/"+m;
					}else{
						console.log(res);
					}
				}).catch((err) => {	
					console.log("에러", err);
				})
			}else{
				window.location.href="/admin/contract/"+m;
			}
        }
    }


	useEffect(() => {
		setLoading(true);
		getMemberList();
	}, []);

	return (
		<div className={Style.wrapper}>

            <div className={Style.subTitle}>
				<img src={Smile} />
				<span>유저리스트</span>
				<div className={Style.etc}>
					<button type={'button'} onClick={register}>계약생성</button>
				</div>
			</div>
			
			<div className={Style.scrolls}>
				<div className={Style.etc} style={{paddingBottom: '15px'}}>
					<button className={Style.btn} type={'button'} onClick={selectDataDelete}>선택삭제</button>
				</div>

				<table className={cn(Style.webList)}>
					<colgroup>
						<col width="50" />
						<col width="100" />
						<col width="94" />
						<col width="104" />
						<col width="94" />
						<col width="94" />
						<col width="210" />
						<col width="72" />
						<col width="80" />
						<col width="60" />
						<col width="60" />
						<col width="90" />
						{/*}
						<col width="90" />
						<col width="" />
						{*/}
					</colgroup>
					<tr>
						<th><input type="checkbox" onChange={allCheckedHandler} 
          checked={checkItems.length === memberList.length ? true : false} /></th>
						<th>공유주택</th>
						<th>상태</th>
						<th>이름</th>
						<th>나이/성별</th>
						<th>호수</th>
						<th>입주일-만료일</th>
						<th>거주기간</th>
						<th>보증금</th>
						<th>월세</th>
						<th>관리비</th>
						<th>계약</th>
						{/*}
						<th>공간옵션</th>
						<th>계약정보</th>
						{*/}
					</tr>

					{memberList.map((list) => (
					<tr>
						<td><CheckBox key={list.seq} id={list.seq} checkItemHandler={checkItemHandler}
            checked={checkItems.includes(list.seq) ? true : false} /></td>
						<td>{list.house}</td>
						<td>{list.house_status}</td>
						<td style={{cursor:'pointer'}} onClick={() => userView(list.seq)}>{list.mem_name}</td>
						<td>{list.birth}/{list.mem_sex === "M" ? "남" : list.mem_sex === "W" ? "여" : ""}</td>
						<td>{list.hosu}</td>
						<td>{list.house_indate} - {list.house_outdate}</td>
						<td>0d</td>
						<td>{list.deposit}</td>
						<td>{list.rentfee}</td>
						<td>0</td>
						{list.hosu ?
							list.contract_status === 'A' ?
							<td><button type={'button'} style={{color:'black', padding:'5px'}} onClick={(e) => onBtnContract(list.seq, 'B')}>계약서명</button></td>
							:
								list.contract_status === 'B' ?
							<td><button type={'button'} style={{color:'black', padding:'5px'}} onClick={(e) => onBtnContract(list.seq, 'C')}>계약대기</button></td>
								:
									list.contract_status === 'C' ? 
							<td><button type={'button'} style={{color:'black', padding:'5px'}} onClick={(e) => onBtnContract(list.seq, 'C')}>계약대기</button></td>
									:
										list.contract_status === 'D' ? 
							<td><button type={'button'} style={{color:'black', padding:'5px'}} onClick={(e) => onBtnContract(list.seq, 'D')}>계약완료</button></td>
										:
							<td><button type={'button'} style={{color:'black', padding:'5px'}} onClick={(e) => onBtnContract(list.seq, 'A')}>계약생성</button></td>	
						:
						<td></td>
						}
						{/*}
						<td>(추후정리)</td>
						<td>정보열람</td>
						{*/}
					</tr>
					))}

				</table>

				<div className={Style.paging}>
					<button type="button" onClick={() => onBtnClick2('1')} value="1"><img src={PagePrev} /></button>
					<ul>
					
					{pageList.map((page, index) => (
						{...pageNum == page ? 
						<li key={index}><a href="#" className={Style.on} onClick={onBtnClick} data={page}>{page}</a></li>
						:
						<li key={index}><a href="#" onClick={onBtnClick} data={page}>{page}</a></li>
						}
					))}
					
					</ul>
					<button type="button" onClick={() => onBtnClick2({totalPageNum})} value={totalPageNum}><img src={PageNext} /></button>
				</div>

				<div className={Style.etc}>
					<button className={Style.btn} type={'button'} onClick={selectDataDelete}>선택삭제</button>
				</div>

				<div className={Style.boardSearch}>
					<div className={Style.searchForm}>
						<select className={Style.select} name="search_key" onChange={(e) => setSearchKey(e.target.value)}>
							<option value="username">이름검색</option>
							<option value="hosu">호수검색</option>
						</select>
						<input type="text" className={Style.inp} name="search_word" onChange={(e) => setSearchWd(e.target.value)} />
						<button type="button"><img src={SearchImg} onClick={getSearch} /></button>
					</div>
				</div>

				<ul className={cn(Style.mobList)}>
				</ul>
			</div>

		</div>
	)
}

export default Index
