import {React} from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import AdminDashboard from './dashboard/admin_dashboard'

const SpaceDashboard = () => {
	const queryClient = new QueryClient()

	return (
		<QueryClientProvider client={queryClient}>
			<AdminDashboard/>
		</QueryClientProvider>
	)
}

export default SpaceDashboard