import {React, useState, useEffect, createContext} from 'react'
import cn from 'classnames'

// css
import Style from '../index.module.css'
import MnList01 from '@/assets/image/ico/ico-mn-list01.svg'
import MnList02 from '@/assets/image/ico/ico-mn-list02.svg'
import MnList03 from '@/assets/image/ico/ico-mn-list03.svg'
import MnList04 from '@/assets/image/ico/ico-mn-list04.svg'
import MnList05 from '@/assets/image/ico/ico-mn-list05.svg'
import MnList06 from '@/assets/image/ico/ico-mn-list06.svg'
import Cmm from '@/assets/image/ico/ico-cmm.svg'
import Notice from '@/assets/image/ico/ico-noti.svg'
import More from '@/assets/image/ico/ico-arr-more.svg'
import Profile from "@/assets/image/etc/etc-product01.png";
import Upload from '@/assets/image/ico/ico-upload01.png'
import { X } from "lucide-react"
import Zoom from 'react-medium-image-zoom'
import Facility from '@/assets/image/ico/ico-facility.svg'

import { GreenLabel, Label } from "@/components/ui/label"
import { Button } from "@/components/ui/button"
import { MySpinner } from '@/components/ui/spinner'
import Axios from "@/components/lib/axios";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
	AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle,
} from "@/components/ui/view-dialog"
import { StatsRenteeTable } from './rentee_table/table'
import { useNavigate } from "react-router-dom"
import RenteeInfoDialog from './info_dialog/rentee_info_dialog'
import { getDateString } from '@/components/lib/date'
import { useDropzone } from 'react-dropzone';

import MainImg from '@/assets/image/etc/etc-main-list01.png'

import Calendar from './calendar'
import Pin from './pin'

const ManagerHome = () => {
	const navigate = useNavigate();
	const [basicInfo, setBasicInfo] = useState();
	const [statsInfo, setStatsInfo] = useState();
	const [commBoardInfo, setCommBoardInfo] = useState();
	const [noticeBoardInfo, setNoticeBoardInfo] = useState();
	const [renteeTableTitle, setRenteeTableTitle] = useState();
	const [renteeTableData, setRenteeTableData] = useState();
	const [isOpenedDialog, setIsOpenedDialog] = useState(false);
	const [isDialogUtility, setIsDialogUtility] = useState(false);
	const [calendarData, setCalendarData] = useState();
	const [mainImageUrl, setMainImageUrl] = useState();
	const [facilityBoardInfo, setFacilityBoardInfo] = useState();

	const { isLoading, error } = useQuery({queryKey:["house_manager_main"], queryFn: () =>
		Axios.get(`/api_house/main`).then((res) => {
            if(res.data.basicInfo !== null) setBasicInfo(res.data.basicInfo);
			if(res.data.statsInfo !== null) setStatsInfo(res.data.statsInfo);
			if(res.data.commBoardInfo !== null) setCommBoardInfo(res.data.commBoardInfo);
			if(res.data.noticeBoardInfo !== null) setNoticeBoardInfo(res.data.noticeBoardInfo);
			if(res.data.calendarData !== null) setCalendarData(res.data.calendarData);
			if(res.data.mainImgUrl !== null) setMainImageUrl(res.data.mainImgUrl);
			if(res.data.facilityBoardInfo !== null) setFacilityBoardInfo(res.data.facilityBoardInfo);
			return res.data;
		})});

	const onUserInfoButtonClicked = (userId) => {
		navigate(`/admin/userView/${userId}`);
	}

	const getGubunString = (gubun) => {
		switch (gubun) {
			case 'S1':
			  return "임대료/관리비";
			case 'S2':
				return "주거시설";
			case 'S3':
				return "공유공간";
			case 'S4':
				return "생활커뮤니티";
			case 'S5':
				return "기타문의";
		  }
	}
	const getStateString = (state) => {
		switch (state) {
			case '1':
			  return "접수";
			case '2':
				return "확인";
			case '3':
				return "처리완료";
		  }
	}
	const getFacilityStateString = (state) => {
		switch (state) {
			case '0':
			  return "요청중";
			case '1':
				return "기사확인";
			case '2':
				return "방문예정";
			case '3':
				return "수리완료";
		  }
	}
	const openUserInfoDialog = (title, mem_seq) => {
		setRenteeTableTitle(title);
		const found = statsInfo.rentee_data.find((rentee) => rentee.mem_seq === parseInt(mem_seq));
		setIsDialogUtility(false);
		setRenteeTableData([found]);
		setIsOpenedDialog(true);
	}

	const { getRootProps, getInputProps }
			= useDropzone({accept: {'image/jpeg': [], 'image/png': []}, onDrop: onDropForMainImage, noClick: mainImageUrl !== undefined, noKeyboard: true, multiple: false});
	
	async function onDropForMainImage(acceptedFiles){
		const reader = new FileReader();

		const formData = new FormData();
		formData.append('image', acceptedFiles[0]);
		formData.append('sh_idx', basicInfo.sh_idx);
		
		const { data } = await Axios.post("/api_house/main/image", formData);
		setMainImageUrl(data.imgUrl);

		// Axios 전송 후 img url 업데이트 
		/*
		if(acceptedFiles){
			reader.readAsDataURL(acceptedFiles[0]);
			setImageForContract(acceptedFiles[0]);
		}

		reader.onload = (e) => {
			setPreviewForContract(reader.result);
		};*/
	}

	const onFacilityRowClicked = (item) => {
		const idx = item.idx;
		const status = item.fm_state;

		if( status === "0" ){
			navigate(`/admin/space/reqView/${idx}`)
		}else if( status === "1" || status === "2" ){
			navigate(`/admin/space/reportRegister/${idx}`)
		}else if( status === "3" ){
			navigate(`/admin/space/reportView/${idx}`)
		}else{
			console.log("Error");
		}
	}

	return (
		<>
		{ 
			isLoading
            ? <MySpinner/>
            :
			<div className={Style.wrapper}>

			<section className={Style.managerInfo}>

				<div className={Style.managerRela}>
					<div className={Style.ustatus}><strong>{basicInfo.sh_name} 매니저</strong>로 로그인 하셨습니다.</div>
				</div>
				<div className={Style.managerRela}>
                    <div className={Style.uinfo01}>
                        <ul>
                            <li>
                                <div className={Style.key}>주소</div>
                                <div className={Style.value}>{basicInfo.sh_addr_detail}</div>
                            </li>
                            <li>
                                <div className={Style.key}>층수 / 호수</div>
                                <div className={Style.value}>{basicInfo.sh_floor_hosu}</div>
                            </li>
                            <li>
                                <div className={Style.key}>총 보증금 / 총 임대료 / 총 관리비</div>
                                <div className={Style.value}>{basicInfo.hs_deposit_rent}</div>
                            </li>
                            <li>
                                <div className={Style.key}>담당 매니저</div>
                                <div className={Style.value}>{basicInfo.shm_name_email}</div>
                            </li>
                        </ul>
                    </div>
                    
				</div>

			</section>

			<section className={cn(Style.cntWrap, Style.type01)}>
				<ul>
					<li>
						<div className={Style.ico}><img src={MnList01} /></div>
						<div className={Style.value}>입주 정보</div>
						<div className={Style.key} onClick={()=>{setIsOpenedDialog(true);setRenteeTableTitle("입주 정보");
							setRenteeTableData(statsInfo.rentee_data);setIsDialogUtility(false);}}>입주율 {statsInfo.occupancy_rate}%</div>
						<div className={Style.key} onClick={()=>{setIsOpenedDialog(true);setRenteeTableTitle("입주 정보");
							setRenteeTableData(statsInfo.rentee_data);setIsDialogUtility(false);}}>공실 {statsInfo.emptry_hosu}호실 / 전체 {statsInfo.total_hosu}호실</div>
					</li>
                    <li>
                        <div className={Style.ico}><img src={MnList02} /></div>
                        <div className={Style.value}>계약 만료 예정자</div>
                        <div className={Style.key} onClick={()=>{setIsOpenedDialog(true);setRenteeTableTitle("계약 만료 예정자 (6-3개월)");
							setRenteeTableData(statsInfo.list_expire_six);setIsDialogUtility(false);}}>6-3개월 : {statsInfo.num_expire_six}명</div>
						<div className={Style.key} onClick={()=>{setIsOpenedDialog(true);setRenteeTableTitle("계약 만료 예정자 (3-1개월)");
							setRenteeTableData(statsInfo.list_expire_three);setIsDialogUtility(false);}}>3-1개월 : {statsInfo.num_expire_three}명</div>
						<div className={Style.key} onClick={()=>{setIsOpenedDialog(true);setRenteeTableTitle("계약 만료 예정자 (1개월 이내)");
							setRenteeTableData(statsInfo.list_expire_one);setIsDialogUtility(false);}}>1개월 이내 : {statsInfo.num_expire_one}명</div>
                    </li>
                    <li>
                        <div className={Style.ico}><img src={MnList03} /></div>
                        <div className={Style.value}>관리비</div>
                        <div className={Style.key} onClick={()=>{setIsOpenedDialog(true);setRenteeTableTitle(`관리비 (${statsInfo.utility_last_month})`);
							setRenteeTableData(statsInfo.rentee_utility_last_month);setIsDialogUtility(true);}}>{`${statsInfo.utility_last_month} : ${statsInfo.num_utility_last_month}`}</div>
						<div className={Style.key} onClick={()=>{setIsOpenedDialog(true);setRenteeTableTitle(`관리비 (${statsInfo.utility_this_month})`);
							setRenteeTableData(statsInfo.rentee_utility_this_month);setIsDialogUtility(true);}}>{`${statsInfo.utility_this_month} : ${statsInfo.num_utility_this_month}`}</div>
                    </li>
                    <li>
                        <div className={Style.ico}><img src={MnList04} /></div>
                        <div className={Style.value}>거주비 입금 예정</div>
                        <div className={Style.key} onClick={()=>{setIsOpenedDialog(true);setRenteeTableTitle("입금 예정자 (7일 이내)");
							setRenteeTableData(statsInfo.people_pay_seven);setIsDialogUtility(false);}}>7일 이내 입금 예정 : {statsInfo.num_pay_seven}명 / {statsInfo.total_pay_seven}</div>
                    </li>
                    <li>
                        <div className={Style.ico}><img src={MnList05} /></div>
                        <div className={Style.value}>연체자 </div>
                        <div className={Style.key}>-</div>
                    </li>
                    <li>
                        <div className={Style.ico}><img src={MnList06} /></div>
                        <div className={Style.value}>가상계좌</div>
                        <div className={Style.key}>-</div>
                    </li>
				</ul>
			</section>

			<section className={Style.latestWrap}>
				<div  className={Style.headerWrap}>
					<div className={Style.lt}>
						<img src={Cmm} />
						<strong>1:1 커뮤니케이션</strong>
						<ul>
							<li>접수 <span>{commBoardInfo.count["1"]}</span></li>
							<li>확인 <span>{commBoardInfo.count["2"]}</span></li>
							<li>처리완료 <span>{commBoardInfo.count["3"]}</span></li>
						</ul>
					</div>
					<div className={Style.rt}>
						<a href='/admin/communication'>
							<span>더보기</span>
							<img src={More} />
						</a>
					</div>
				</div>
				<div  className={cn(Style.contWrap, Style.type01)}>
					<ul>
						{
							commBoardInfo.list.map((item, index) => {
								return (
									<li>
										<a href={`/admin/communicationView/${item.idx}`} >
											<div className={Style.comm01}>{item.sh_name !== null ? item.sh_name : ""}</div>
											<div className={Style.comm02}>{item.hs_hosu !== null ? item.hs_hosu+"호" : ""} {item.mem_name !== null ? item.mem_name : ""}</div>
											<div className={Style.cate}>[{getGubunString(item.com_gubun)}]</div>
											<div className={Style.comm03}>{getStateString(item.com_state)}</div>
											<div className={Style.tit}>{item.com_title}</div>
											<div className={Style.date}>{getDateString(item.com_regdate)}</div>
										</a>
									</li>
								)
							})
						}
					</ul>
				</div>
			</section>

			<section className={Style.latestWrap}>
				<div  className={cn(Style.headerWrap, Style.t01)}>
					<div className={Style.lt}>
						<img src={Notice} />
						<strong>공지사항</strong>
					</div>
					<div className={Style.rt}>
						<a href="/admin/notice">
							<span>더보기</span>
							<img src={More} />
						</a>
					</div>
				</div>
				<div  className={Style.contWrap}>
					<ul>
						{
							noticeBoardInfo.list.map((item, index) => {
								return (
									<li>
										<a href={`/admin/NoticeView/${item.idx}`}>
											<div className={Style.cate}>[{item.no_type === '2' ? "전체공지" : item.sh_name}]</div>
											<div className={Style.tit}>{item.no_subject}</div>
											<div className={Style.date}>{getDateString(item.no_regdate)}</div>
										</a>
									</li>
								)
							})
						}
					</ul>
				</div>
			</section>
			<Calendar style = {{ paddingLeft : 0, marginLeft : 370 }} calendarData={calendarData} openUserInfoDialog={openUserInfoDialog} shIdx={basicInfo.sh_idx}/>
			<section className={Style.residenceWrap}>
				<div className={Style.residenceCont}>
					<div className='relative flex flex-row justify-center bg-white h-full items-center' {...getRootProps()}>
						<label className={Style.label} id="imagepreview"> 
						{
							mainImageUrl === undefined
							?
							<img src={Upload}  />
							:
							<Zoom>
								<img src={mainImageUrl}  />
							</Zoom>
						}
						</label>
						{ mainImageUrl !== undefined && <Button className="absolute top-[0px] right-0 pr-4 pt-4" variant="icon" onClick={(e)=>setMainImageUrl()}><X/></Button> }
					</div>
				</div>
			</section>
			<Pin shName={basicInfo.sh_name} shIdx={basicInfo.sh_idx}/>
			<section className={Style.latestWrap}>
				<div  className={cn(Style.headerWrap, Style.t01)}>
					<div className={Style.lt}>
						<img src={Facility} className='w-11 h-11 text-accent-foreground'/>
						<strong>시설 관리</strong>
					</div>
					<div className={Style.rt}>
						<a href="/admin/space/reqList">
							<span>더보기</span>
							<img src={More} />
						</a>
					</div>
				</div>
				<div  className={Style.contWrap}>
					<ul>
						{
							facilityBoardInfo.list.map((item, index) => {
								return (
									<li>
										<a onClick={()=>onFacilityRowClicked(item)}>
											<div className={Style.fcate} >[{basicInfo.sh_name + "/" + item.fm_floor + "/" +
												 item.fm_detail_location}]</div>
											<div className={Style.tit}>{item.fm_title}</div>
											<div className={Style.comm03}>{item.mem_name}</div>
											<div className={Style.comm01}>{getFacilityStateString(item.fm_state)}</div>
											<div className={Style.date}>{getDateString(item.fm_regdate)}</div>
										</a>
									</li>
								)
							})
						}
					</ul>
				</div>
			</section>
			{
				renteeTableTitle && renteeTableData &&
				<RenteeInfoDialog isOpenedDialog={isOpenedDialog} setIsOpenedDialog={setIsOpenedDialog}
					renteeTableTitle={renteeTableTitle} renteeTableData={renteeTableData} onUserInfoButtonClicked={onUserInfoButtonClicked}
					isUtility={isDialogUtility}/>
			}
			
		</div>
		}
		
		</>
	)
}

export default ManagerHome
