import React, {useState, useRef, useEffect} from 'react'
import axios from 'axios';
import cn from 'classnames'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import koLocale from '@fullcalendar/core/locales/ko';
import listPlugin from "@fullcalendar/list";
import styled from "@emotion/styled";

//calendar
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from "moment";

// css
import Style from './index.module.css'
import SchAdd from '@/assets/image/ico/ico-sch-add.svg'
import SchRemove from '@/assets/image/ico/ico-sch-remove.svg'
import popClose from '@/assets/image/ico/ico-pop-close.svg'

import { getToken } from "../../services/TokenService";

export const StyleWrapper = styled.div`
  .fc td {
    
  }
  .fc-prev-button + button{display:none;}
  .fc-toolbar-title + button{display:none;}
  .fc-toolbar-title{color:#40EFC1;}
  .fc-toolbar-chunk{display:flex;justify-content:center;align-items:center;}
  .fc .fc-button-primary{}
  .fc-today-button{background:#40EFC1 !important;border-color:#40EFC1 !important;color:#000 !important;}
  .fc .fc-daygrid-day.fc-day-today{background: rgba(64, 239, 193, 0.22) !important;}
  .fc-theme-standard tr>td:first-child,
  .fc-theme-standard tr>td:nth-child(7){background: rgba(217, 217, 217, 0.16) !important;}
  .fc .fc-daygrid-day-number{color:#fff}
  .fc .fc-col-header-cell-cushion{color:#fff;}
  .fc-event-title { color: #fff; }
  @media screen and (max-width: 1024px) {
  	.fc-header-toolbar{display:block;}
  	.fc-toolbar-chunk{margin:20px 0 0 0;}
  }
`

const Index = () => {

	const [isPopup, setIsPopup] = useState(false);
	const [isdis1, setIsdis1] = useState(false);
	const [isdis2, setIsdis2] = useState(false);
	const [isdis3, setIsdis3] = useState(false);
	const [title, setTitle] = useState("");
	const [memo, setMemo] = useState("");
	const [year, setYear] = useState("");
	const [month, setMonth] = useState("");
	const [day, setDay] = useState("");
	const [ampm, setAmpm] = useState("AM");
	const [hours, setHours] = useState("");
	const [mins, setMins] = useState("");
	const [all_day, setAllday] = useState(false);
	const [boardList, setBoardList] = useState([]);
	const [isPopup01, setIsPopup01] = useState(false);

	const [value, onChange] = useState(new Date());
	
	//calendar start
	const [nowDate, setNowDate] = useState("날짜선택");
	const [isOpen, setIsOpen] = useState(false);
	const [seldate, setSelDate] = useState("");

	const handleToggleCalendar = () => {
		setIsOpen(!isOpen);
	};

	const handleDateChange = (selectedDate) => {
		onChange(selectedDate);
		setIsOpen(false);
		setNowDate(moment(selectedDate).format("YYYY년 MM월 DD일"));
		setSelDate(moment(selectedDate).format("YYYY-MM-DD"));
		//console.log(seldate);
	};

	const CalendarContainer = styled.div`
		position: relative;
	`;

	const DropdownButton = styled.button`
		width: 200px;
		height: 30px;
		border: 0.8px solid var(--festie-gray-600, #949494);
		/*border-radius: 10px;*/
		padding: 0px 12px;
		color: var(--festie-gray-800, #3a3a3a);
		font-family: SUIT Variable;
		font-size: 15px;
		font-style: normal;
		font-weight: 500;
		line-height: 140%;
		text-align: start;
		appearance: none;
		background-color: white;
		background-image: url("");
		background-repeat: no-repeat;
		background-position: right 12px center;
		background-size: 12px;
	`;

	const CalendarWrapper = styled.div`
		z-index: 11;
		position: absolute;
		top: 100%;
		left: 0;
		display: ${(props) => (props.isOpen ? "block" : "none")};
	`;
	//calendar end

	const handleClose01 = () => {
		setIsPopup01(false);
	}

	const handleClose = () => {
		setIsPopup01(false);
	}

	const handleOpen = () => {
		setIsPopup01(true);
	}

	const allday = (e) => {
		if(e === true){
			setIsdis1(true);
			setIsdis2(true);
			setIsdis3(true);
		}else{
			setIsdis1(false);
			setIsdis2(false);
			setIsdis3(false);
		}
		setAllday(e);
	}

	const [events, setEvents] = useState([]);
	let livedate = "";
	let real_livedate = "";

	const getBoardList = async (e) => {
		const resp = await (await axios.get(process.env.REACT_APP_API_SCHEDULE_LIST)).data; 
		if(resp.cnt > 0){
			setBoardList(resp.list); 
			setEvents(resp.list);
			//console.log(resp.list);
		}

	}

	const calendarEvent = (e) => {
		//console.log(e.event);
	}

	const now = new Date();
	const _ = require('lodash');
	const years = _.range(now.getFullYear() - 3, now.getFullYear() + 2, 1);
	const years_end = _.range(now.getFullYear() - 5, now.getFullYear() + 3, 1);
	const floors = _.range(1, 51, 1);

	const titlefocusRef = useRef(null);
	const memofocusRef = useRef(null);
	
	const onReg = () => {
		
		const token = getToken();
		if(token !== null){
			const local_token = JSON.parse(token);

			if(title === ""){
				alert("제목을 입력해주세요.");
				titlefocusRef.current.focus();
				return;
			}
			
			if(seldate === ""){
				alert("날짜를 선택해주세요.");
				return;
			}

			if(memo === ""){
				alert("내용을 입력해주세요.");
				memofocusRef.current.focus();
				return;
			}

			const formData = new FormData();
			formData.append('title',title)
			formData.append('seldate',seldate)
			//formData.append('month',month)
			//formData.append('day',day)
			formData.append('ampm',ampm)
			formData.append('hours',hours)
			formData.append('mins',mins)
			formData.append('allday',all_day)
			formData.append('memo',memo)

			axios({
				method:'post',
				url:process.env.REACT_APP_API_SCHEDULE_REG,
				mode: "cors",
            	credentials: "same-origin",
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: "Bearer " + local_token.access_token
				},
				data: formData,
			})
				.then((result)=>{
					if(result.data.code == 200){
						alert("등록 되었습니다.");
						window.location.reload();
					}else{
						console.log(result)
					}
			})
				.catch((error)=>{console.log('요청실패')
				console.log(error)  
			})
		}
	}

	const scheduleDel = (e) => {
		if(window.confirm("정말로 일정을 삭제하시겠습니까?")){
    
			axios({
			  method:'post',
			  url:process.env.REACT_APP_API_SCHEDULE_DEL,
			  mode: "cors",
			  credentials: "same-origin",
			  data:{
				seq: e
			  },
			})
			  .then((result)=>{
				if(result.data.code == 200){
				  alert("삭제 되었습니다.");
				  window.location.reload();
				}else{
				  console.log(result)
				}
			})
			  .catch((error)=>{console.log('요청실패')
			  console.log(error)  
			})
		}
	}

	const handleMonthChange = async (selected) => {
		//console.log(selected.startStr+"/"+selected.endStr);
	};
		
	useEffect(() => {
		getBoardList();

		//let calendarApi = this.calendarRef.current.getApi()
    	//calendarApi.next()
	}, []);

	return (
		<>
			<div className={Style.wrapper}>
				<div className={Style.titlebar}>
					<strong>Calendar</strong>
				</div>
				<div className={Style.descbar}>Events</div>
				<div className={Style.calendarRela}>
					<div className={Style.side}>
						<button type={'button'} className={Style.btnAdd} onClick={handleOpen}>
							<span>일정 만들기</span>
							<img src={SchAdd} />
						</button>
						<ul>
						{boardList.map((board) => (
							<li className={Style.status01}>
								<div className={Style.mdesc}>{board.title}</div>
								<div className={Style.mdate}>{board.date}</div>
								<button type={'button'} className={Style.btnRemove} onClick={(e) => scheduleDel(board.id)}>
									<img src={SchRemove} />
								</button>
							</li>
						))}
							{/*}
							<li className={Style.status01}>
								<div className={Style.mdesc}>김민석 거주자 월세 납부일</div>
								<div className={Style.mdate}>2023.07.24</div>
								<button type={'button'} className={Style.btnRemove}>
									<img src={SchRemove} />
								</button>
							</li>
							<li className={Style.status02}>
								<div className={Style.mdesc}>김민석 거주자 월세 납부일</div>
								<div className={Style.mdate}>2023.07.24</div>
							</li>
							{*/}
						</ul>
					</div>
					
					<div className={Style.acticle} id="not_all">
						<StyleWrapper>
							<FullCalendar 
								plugins={[dayGridPlugin,timeGridPlugin,interactionPlugin, listPlugin ]}
								initialView={'dayGridMonth'}
								headerToolbar={
									{
										left: "today",
										center: 'prev, title, next',
										right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek"
									}
								}
								height={"85vh"}
								locale={koLocale}
								//dateClick={this.dateClick}
								events={events}
								eventClick={(e)=>calendarEvent(e)}
								datesSet= {handleMonthChange}
							/>
						</StyleWrapper>

					</div>
				</div>
			</div>			

			{/*이벤트를 기입해주세요.*/}
			<div className={cn(Style.popup, `${isPopup?Style.on:''}`)}>
				<div className={Style.ptit}>이벤트를 기입해주세요.</div>
				<div className={Style.pdesc}>Please enter a new title for your event</div>
				<div  className={Style.formItem}>
					<input type="text" className={Style.inp} />
				</div>
				<div className={Style.btnGroup}>
					<button type="button" className={Style.btnConfirm} onClick={handleClose}>확인</button>
					<button type="button" className={Style.btnCancel} onClick={handleClose}>취소</button>
				</div>
			</div>

			<div className={cn(Style.popup, `${isPopup01?Style.on:''}`)}>
				<button type="button" style={{position: 'absolute', right: '30px', top: '30px', border: '0', padding: '0', background: 'transparent'}} onClick={handleClose}><img src={popClose} /></button>

				<div  className={Style.formItem01}>
					<input type="text" className={Style.inp} ref={titlefocusRef} placeholder={'제목 추가'} value={title} onChange={(e) => setTitle(e.target.value)} />
				</div>
				<div className={Style.optWrap}>
				<div className={Style.dateWrap}>
					{/*}
					<select className={Style.select} value={year} onChange={(e) => setYear(e.target.value)}>
						<option value="">선택</option>
					{
						years.map((option) => (
							<option key={option} value={option}>{option}년</option>
						))
					}
					</select>

					<select className={Style.select} value={month} onChange={(e) => setMonth(e.target.value)}>
						<option value="">선택</option>
					{
						Array(12).fill(0).map((_, i) => (
							<option key={i + 1} value={i + 1}>{i + 1}월</option>
						))
					}
					</select>

					<select className={Style.select} value={day} onChange={(e) => setDay(e.target.value)}>
						<option value="">선택</option>
					{
						Array(31).fill(0).map((_, i) => (
							<option key={i + 1} value={i + 1}>{i + 1}일</option>
						))
					}
					</select>
					{*/}

					<CalendarContainer>
						<DropdownButton onClick={handleToggleCalendar}>{nowDate}</DropdownButton>
						<CalendarWrapper isOpen={isOpen}>
							<Calendar calendarType = "gregory" onChange={handleDateChange} value={value} formatDay={(locale, date) => moment(date).format("DD")}></Calendar>
						</CalendarWrapper>
					</CalendarContainer>
				</div>

				<div className={Style.e01}></div>
					<div className={Style.dateWrap}>
						<select className={Style.select} name="ampm" disabled={isdis1} value={ampm} onChange={(e) => setAmpm(e.target.value)}>
							<option value="AM">오전</option>
							<option value="PM">오후</option>
						</select>
						<select className={Style.select} name="hours" disabled={isdis2} value={hours} onChange={(e) => setHours(e.target.value)}>
						{
							Array(12).fill(0).map((_, i) => (
								<option key={i + 1} value={i + 1}>{i + 1}</option>
							))
						}
						</select>
						<select className={Style.select} name="mins" disabled={isdis3} value={mins} onChange={(e) => setMins(e.target.value)}>
							<option value="00">00</option>
						{
							Array(59).fill(0).map((_, i) => (
								<option key={i + 1} value={i + 1}>{i + 1}</option>
							))
						}
						</select>
					</div>
				</div>
				<div  className={Style.formItem01}>
					<label>
						<input type={'checkbox'} onClick={(e) => allday(e.target.checked)} />
						<span>종일</span>
					</label>
				</div>
				<div className={Style.textareaWrap}>
					<textarea className={Style.textarea} ref={memofocusRef} onChange={(e) => setMemo(e.target.value)}>{memo}</textarea>
				</div>
				<div className={Style.formItem01}>
					<select className={Style.select}>
						<option>항목구분</option>
					</select>
				</div>

				<div className={Style.btnGroup}>
					<button type="button" className={Style.btnSave} onClick={onReg}>일정 저장 </button>
				</div>
			</div>
		</>
	)
}

export default Index
