import React, {useState, useEffect} from 'react'
import {Routes, Route} from 'react-router-dom'
import {useSelector} from 'react-redux'
import axios from "axios";

// component
import Header from './components/header/header'
import Topnav from './components/topnav/index'

// pages
import MainPage from './pages/main'
import TotalMainPage from './pages/main/total_index'
import MainLoginPage from './pages/main/login'
import MainManagerPage from './pages/main/manager'
import ResidencePage from './pages/main/residence'
import Dashboard from './pages/dashboard'
import TeamList from './pages/dashboard/teamList'
import Calendar from './pages/calendar'

import Account from './pages/account'
import Communication from './pages/communication'
import CommunicationView from './pages/communication/view'
import CommunicationRegister from './pages/communication/register'
import Notice from './pages/notice'
import NoticeView from './pages/notice/view'
import NoticeRegister from './pages/notice/register'
import UserList from './pages/userList'
import UserView from './pages/userList/view'
//전자문서설정
import EdocuList from './pages/esign'

//추가
import UserList2 from './pages/userList/userlist'
import UserList3 from './pages/userList/contract_tab'

import Signup from './pages/signup'
import Join from './pages/signup/join'
import Joinsns from './pages/signup/join_sns'
import JoinStep1 from './pages/signup/join_step1'

import RoomNuber from './pages/roomNuber'
import Login from './pages/login'

import SpaceHome from './pages/space/main'
import SpaceDetail from './pages/space/detail'
import CostList from './pages/space/costList'
import ShareList from './pages/space/shareList'
import ReqList from './pages/space/reqList'
import ReqView from './pages/space/reqView'
import ReqRegister from './pages/space/reqRegister'
import ReportView from './pages/space/reportView'
import ReportRegister from './pages/space/reportRegister'
import SpaceDashboard from './pages/space/dashboard'
import MyInfo from './pages/space/myinfo'
import MyInfoEdit from './pages/space/myinfo-edit'
import Contract from './pages/space/contract-register'
import ContractEdit from './pages/space/contract-edit'
import PinCreate1 from './pages/space/pin01'
import PinCreate2 from './pages/space/pin02'
import AdmCostList from './pages/space/admCostList'
import AdmCostCharge from './pages/space/admCostCharge'

import HouseList from './pages/house'
import HouseView from './pages/house/view'
import HouseRegister from './pages/house/register'
import AdmList from './pages/adm'
import AdmRegister from './pages/adm/register'
import AdmView from './pages/adm/view'
import TotalAdmList from './pages/adm/total_index'
import TotalAdmRegister from './pages/adm/total_register'
import TotalAdmView from './pages/adm/total_view'
import FacilityMain from './pages/facility/req01'
import FacilityDetail from './pages/facility/req02'

import Auth from "./Auth"
import Auth_login from "./Auth_login"

import Messages from './pages/messages'

import Contract_sign from './pages/userList/contract_sign'
import Contract_tab from './pages/userList/contract_tab'
import Contract_partsign from './pages/account/contract_view'

import { BrowserRouter } from 'react-router-dom';
import { getToken, removeToken } from "./services/TokenService";


// css
import './styles/global.css'

export const API_BASE_URL = process.env.REACT_APP_API_ROOT;
export const HOME_URL = process.env.REACT_APP_HOME_URL;

const Root = () => {
	
	const homeurl = `/`;
	const mainurl = `/main`;
	const mainManagerurl = `/main`;
	const residenceurl = `/mainManager`;
	const noticeurl = `/notice`;

	const login_gubun = 'N';

	const [isLogin, SetIsLogin] = useState(false);
	const [lcToken, SetLcToken] = useState("");
	const [memberType, SetMemberType] = useState("");

	useEffect(() => {
		const token = getToken();
		//console.log("token="+token);
		const pathname = window.location.pathname;
		//console.log(pathname);

		if(token !== null){
			const local_token = JSON.parse(token);
			//console.log(local_token.access_token);
			SetLcToken(local_token.access_token);
			
			axios({
				method: 'get',
				url: process.env.REACT_APP_API_TOKENCHECK,
				mode: "cors",
				credentials: "same-origin",
				headers: {
					Authorization: "Bearer " + local_token.access_token
				},
			}).then((res) => {
				//console.log(res.data.profile.mtype);
				//console.log(res.data.code);
				if(res.data.code === 200){
					SetMemberType(res.data.profile.mtype);
					//if(local_token !== ""){
						SetIsLogin(true);
					//}
				}else if(res.data.code === 400){
					removeToken();
					if(pathname === "/admin" || pathname === "/admin/main"){
						window.location.href="/login";
					}
				}

			}).catch((err) => {	
				console.log("에러", err);
			})
			
		}else{
			if(pathname === "/admin" || pathname === "/admin/main"){
				window.location.href="/login";
			}
		}
		
	}, []);

	if(!isLogin){
		return (
			<>
				<Header/>
				<Routes>
					<Route path="/" index element={<MainLoginPage/>} />
					<Route path="/signup" element={<Signup/>}/>
					<Route path="/join" element={<Join/>}/>
					<Route path="/joinsns" element={<Joinsns/>}/>
					<Route path="/join_step1" element={<JoinStep1/>}/>

					<Route path="/login" element={<Login/>}/>
					<Route path="/oauth/kakao/callback" element={<Auth />}/>
					<Route path="/oauth/kakao/login" element={<Auth_login />}/>

					
				</Routes>
			</>
		);
	}

	if(memberType === 'RS'){
		return (
			<>
				<Header membertype={memberType} />
				<Topnav membertype={memberType} />
				<Routes>
					{/* 거주자용 메인 RS - /residence */}
					<Route path="/" index element={<ResidencePage/>}/>
					<Route path="/calendar" element={<Calendar/>}/>
	
					<Route path="/account" element={<Account/>}/>
					<Route path="/communication" element={<Communication/>}/>
					<Route path="/communicationView/:id" element={<CommunicationView/>}/>
					<Route path="/communicationRegister" element={<CommunicationRegister/>}/>
					<Route path={noticeurl} element={<Notice/>}/>
					<Route path="/noticeView/:idx" element={<NoticeView/>}/>
					<Route path="/noticeRegister" element={<NoticeRegister/>}/>
					<Route path="/userList" element={<UserList/>}/>
	
					<Route path="/signup" element={<Signup/>}/>
					<Route path="/join" element={<Join/>}/>
					<Route path="/join_step1" element={<JoinStep1/>}/>
					<Route path="/myinfo" element={<MyInfo/>}/>
					<Route path="/myinfo-edit" element={<MyInfoEdit/>}/>

					<Route path="/contract" element={<Contract_partsign/>}/>

					<Route path="/roomNuber" element={<RoomNuber/>}/>
					<Route path="/login" element={<Login/>}/>
					<Route path="/oauth/kakao/callback" element={<Auth />}/>
					
				</Routes>
			</>
		);
	}else if(memberType === 'JM'){
		return (
			<>
				<Header membertype={memberType} />
				<Topnav membertype={memberType} />
				<Routes>
					{/* 매니저용 메인 JM - /mainManager */}
					<Route path="/" index element={<MainManagerPage/>}/>
					<Route path="/admin/calendar" element={<Calendar/>}/>
	
					<Route path="/admin/account" element={<Account/>}/>
					<Route path="/admin/communication" element={<Communication/>}/>
					<Route path="/admin/communicationView/:id" element={<CommunicationView/>}/>
					<Route path="/admin/notice" element={<Notice/>}/>
					<Route path="/admin/noticeView/:idx" element={<NoticeView/>}/>
					<Route path="/admin/noticeRegister" element={<NoticeRegister/>}/>
					<Route path="/admin/userList" element={<UserList/>}/>
	
					<Route path="/signup" element={<Signup/>}/>
					<Route path="/join" element={<Join/>}/>
					<Route path="/join_step1" element={<JoinStep1/>}/>
	
					<Route path="/admin/roomNuber" element={<RoomNuber/>}/>
					<Route path="/admin" element={<Login/>}/>
					<Route path="/oauth/kakao/callback" element={<Auth />}/>

					<Route path="/admin/main" index element={<MainManagerPage/>}/>

					<Route path="/admin/space/home" element={<SpaceHome/>}/>
					<Route path="/admin/space/detail/:idx" element={<SpaceDetail/>}/>
					<Route path="/admin/space/costList" element={<CostList/>}/>
					<Route path="/admin/space/shareList" element={<ShareList/>}/>
					<Route path="/admin/space/reqList" element={<ReqList/>}/>
					<Route path="/admin/space/reqView/:id" element={<ReqView/>}/>
					<Route path="/admin/space/reqRegister" element={<ReqRegister/>}/>
					<Route path="/admin/space/reqRegister/:id" element={<ReqRegister/>}/>
					<Route path="/admin/space/reportView/:id" element={<ReportView/>}/>
					<Route path="/admin/space/reportRegister/:id" element={<ReportRegister/>}/>
					<Route path="/admin/space/dashboard" element={<SpaceDashboard/>}/>

					<Route path="/admin/myinfo" element={<MyInfo/>}/>
					<Route path="/admin/myinfo-edit" element={<MyInfoEdit/>}/>
					
				</Routes>
			</>
		);
	}else if(memberType === 'SM'){
		return (
			<>
				<Header membertype={memberType} />
				<Topnav membertype={memberType} />
				<Routes>
					{/* 시설관리자 메인 SM - /FacilityMain */}
					<Route path="/" index element={<FacilityMain/>}/>
					<Route path="/admin/calendar" element={<Calendar/>}/>
	
					<Route path="/admin/communication" element={<Communication/>}/>
					<Route path="/admin/communicationView/:id" element={<CommunicationView/>}/>
					<Route path="/admin/notice" element={<Notice/>}/>
					<Route path="/admin/noticeView/:idx" element={<NoticeView/>}/>
	
					<Route path="/signup" element={<Signup/>}/>
					<Route path="/join" element={<Join/>}/>
					<Route path="/join_step1" element={<JoinStep1/>}/>
	
					<Route path="/admin" element={<Login/>}/>
					<Route path="/oauth/kakao/callback" element={<Auth />}/>

					<Route path="/admin/main" index element={<FacilityMain/>}/>

					<Route path="/admin/space/home" element={<SpaceHome/>}/>
					<Route path="/admin/space/detail/:idx" element={<SpaceDetail/>}/>
					<Route path="/admin/space/shareList" element={<ShareList/>}/>
					<Route path="/admin/space/reqList" element={<ReqList/>}/>
					<Route path="/admin/space/reqView/:id" element={<ReqView/>}/>
					<Route path="/admin/space/reqRegister" element={<ReqRegister/>}/>
					<Route path="/admin/space/reqRegister/:id" element={<ReqRegister/>}/>
					<Route path="/admin/space/reportView/:id" element={<ReportView/>}/>
					<Route path="/admin/space/reportRegister/:id" element={<ReportRegister/>}/>
					<Route path="/admin/space/dashboard" element={<SpaceDashboard/>}/>

					<Route path="/admin/myinfo" element={<MyInfo/>}/>
					<Route path="/admin/myinfo-edit" element={<MyInfoEdit/>}/>
					
				</Routes>
			</>
		);
	}else if(memberType === 'AD'){

		return (
			<>
				<Header membertype={memberType} />
				<Topnav membertype={memberType} />
				<Routes>
					{/* 관리자용 메인 AD - /dashboard */}
					<Route path="/" index element={<MainPage/>}/>
					<Route path="/admin/calendar" element={<Calendar/>}/>
	
					<Route path="/admin/account" element={<Account/>}/>
					<Route path="/admin/communication" element={<Communication/>}/>
					<Route path="/admin/communicationView/:id" element={<CommunicationView/>}/>
					<Route path="/admin/communicationRegister" element={<CommunicationRegister/>}/>
					<Route path="/admin/notice" element={<Notice/>}/>
					<Route path="/admin/noticeView/:idx" element={<NoticeView/>}/>
					<Route path="/admin/noticeRegister" element={<NoticeRegister/>}/>
					<Route path="/admin/userList" element={<UserList/>}/>
					<Route path="/admin/userView/:idx" element={<UserView/>}/>
					<Route path="/admin/teamList" element={<AdmList/>}/>
					<Route path="/admin/teamview/:idx" element={<AdmView/>}/>
					<Route path="/admin/teamReg" element={<AdmRegister/>}/>
	
					<Route path="/admin/signup" element={<Signup/>}/>
					<Route path="/admin/join" element={<Join/>}/>
					<Route path="/admin/join_step1" element={<JoinStep1/>}/>
	
					<Route path="/admin/roomNuber" element={<RoomNuber/>}/>
					<Route path="/admin" element={<Login/>}/>
					<Route path="/oauth/kakao/callback" element={<Auth />}/>

					<Route path="/admin/main" index element={<MainPage/>}/>

					<Route path="/admin/space/home" element={<SpaceHome/>}/>
					<Route path="/admin/space/detail/:idx" element={<SpaceDetail/>}/>
					<Route path="/admin/space/costList" element={<CostList/>}/>
					<Route path="/admin/space/shareList" element={<ShareList/>}/>
					<Route path="/admin/space/reqList" element={<ReqList/>}/>
					<Route path="/admin/space/reqView/:id" element={<ReqView/>}/>
					<Route path="/admin/space/reqRegister" element={<ReqRegister/>}/>
					<Route path="/admin/space/reqRegister/:id" element={<ReqRegister/>}/>
					<Route path="/admin/space/reportView/:id" element={<ReportView/>}/>
					<Route path="/admin/space/reportRegister/:id" element={<ReportRegister/>}/>
					<Route path="/admin/space/dashboard" element={<SpaceDashboard/>}/>
					<Route path="/admin/space/myinfo" element={<MyInfo/>}/>
					<Route path="/admin/space/myinfo-edit" element={<MyInfoEdit/>}/>
					<Route path="/admin/space/contract" element={<Contract/>}/>
					<Route path="/admin/space/contractedit/:idx" element={<ContractEdit/>}/>
					<Route path="/admin/space/pincreate1" element={<PinCreate1/>}/>
					<Route path="/admin/space/pincreate2" element={<PinCreate2/>}/>
					<Route path="/admin/space/admCostList" element={<AdmCostList/>}/>
					<Route path="/admin/space/admCostCharge" element={<AdmCostCharge/>}/>

					<Route path="/admin/house/list" element={<HouseList/>}/>
					<Route path="/admin/house/view/:idx" element={<HouseView/>}/>
					<Route path="/admin/house/register" element={<HouseRegister/>}/>

					<Route path="/admin/messages/sendHistory/:innerTab" element={<Messages outerTab="sendHistory"/>}/>
					<Route path="/admin/messages/templateSettings/:innerTab" element={<Messages outerTab="templateSettings"/>}/>
					<Route path="/admin/messages/:tab" element={<Messages/>}/>

					<Route path="/admin/myinfo" element={<MyInfo/>}/>
					<Route path="/admin/myinfo-edit" element={<MyInfoEdit/>}/>

					<Route path="/admin/contract/:idx" element={<Contract_tab/>}/>

					<Route path="/admin/userList2" element={<UserList2/>}/>
					<Route path="/admin/userList3" element={<UserList3/>}/>

					<Route path="/admin/edocu" element={<EdocuList/>}/>
				</Routes>
			</>
		);
	}else if(memberType === "TA"){

		return (
			<>
				<Header membertype={memberType} />
				<Topnav membertype={memberType} />
				<Routes>
					{/* 총괄용 메인 AD - /dashboard */}
					<Route path="/" index element={<TotalMainPage/>}/>
					<Route path="/admin/calendar" element={<Calendar/>}/>
	
					<Route path="/admin/companyList" element={<TotalAdmList/>}/>
					<Route path="/admin/companyview/:idx" element={<TotalAdmView/>}/>
					<Route path="/admin/companyReg" element={<TotalAdmRegister/>}/>
				</Routes>
			</>
		);
	}

	
}

export default Root
