import React, {useEffect, useState} from 'react'
import cn from 'classnames'
import { useNavigate } from "react-router-dom"
import Axios from "@/components/lib/axios";
import { ColorRing } from 'react-loader-spinner';
import { GreenLabel, Label } from "@/components/ui/label"
import SpaceReq from '@/assets/image/ico/ico-req01.svg'
import { FacilityListTable } from "./table/table"
import { MySpinner } from '@/components/ui/spinner'
import { Button } from '@/components/ui/button';
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Pencil } from 'lucide-react';
import {
	AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle,
} from "@/components/ui/alert-dialog"

const FacilityList = () => {
	
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const [isOpenedDeleteDialog, setIsOpenedDeleteDialog] = React.useState(false);
	const [idxToDelete, setIdxToDelete] = React.useState()
	const { isLoading, data, error } = useQuery({queryKey:["admin_facility_list"], queryFn: () =>
		Axios.get(`/api_facility/list`).then((res) => {
			return res.data;
	})});
	const [isMobile, setIsMobile] = useState(window.innerWidth < 680);

	const handleWindowSizeChange = () => {
		if(window.innerWidth < 680)
			setIsMobile(true);
		else if (window.innerWidth > 680)
			setIsMobile(false);
	}

	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
	}, []);

	const deleteFacilityArticle = useMutation({
		mutationFn: async () => {
			const result = await Axios.delete(`/api_facility/article/${idxToDelete}`)
		}, 
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["admin_facility_list"] })
		},
	});

	const onArticleDeleteButtonClicked = (idx) => {
		setIdxToDelete(idx);
		setIsOpenedDeleteDialog(true);
	}
	
	return (
		<>
		{ 
		isLoading
		? <MySpinner/>
		:
		<div className="wrapper lg:pl-[110px] lg:w-[100%-110px] lg:mx-0 mx-3.5">
			<div className='lg:px-20 flex flex-col'>
				<div className="mb-14 title w-full text-2xl"><img className='w-6 h-6 mr-2' src={SpaceReq} />시설 관리 리스트</div>
				<FacilityListTable className='mt-10' data={data.facilityList} onArticleDeleteButtonClicked={onArticleDeleteButtonClicked} memberType={data.memberType} isMobile={isMobile}/>
				{
					data.memberType === 'AD'
					&&
					<Button className='w-52 self-end mt-6' onClick={()=>{navigate('/admin/space/reqRegister')}}><Pencil className='w-4 h-4 mr-1'/>시설 관리 요청</Button>
				}
			</div>
		</div>
		}
		<AlertDialog open={isOpenedDeleteDialog} onOpenChange={setIsOpenedDeleteDialog}>
			<AlertDialogContent>
				<AlertDialogHeader>
					<AlertDialogTitle>정말 삭제하시겠습니까?</AlertDialogTitle>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel onClick={()=>setIsOpenedDeleteDialog(false)}>취소</AlertDialogCancel>
					<AlertDialogAction onClick={()=>deleteFacilityArticle.mutate()}>계속</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
		</>
	)
}

export default FacilityList
