import {React, useState, useEffect, createContext} from 'react'

import {
	AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle,
} from "@/components/ui/view-dialog"
import { StatsRenteeTable } from '../rentee_table/table'

const RenteeInfoDialog = ({isOpenedDialog, setIsOpenedDialog, renteeTableTitle, renteeTableData, onUserInfoButtonClicked, isUtility}) => {
	return (
		<AlertDialog open={isOpenedDialog} onOpenChange={setIsOpenedDialog}>
			<AlertDialogContent>
				<AlertDialogHeader>
					<div className="mb-5 title w-full mt-2">{renteeTableTitle}</div>
					<StatsRenteeTable data={renteeTableData} onUserInfoButtonClicked={onUserInfoButtonClicked} isUtility={isUtility}/>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel onClick={()=>{}}>닫기</AlertDialogCancel>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	)
}

export default RenteeInfoDialog
