import React, {useEffect, useState, useRef} from 'react'
import { useNavigate } from "react-router-dom"
import axios from "axios";
import { ColorRing } from 'react-loader-spinner';
import { getToken } from "../../services/TokenService";
import cn from 'classnames'

// css
import Style from './index.module.css'
import Notice from '@/assets/image/ico/ico-noti.svg'
import PagePrev from '@/assets/image/ico/ico-page-prev.svg'
import PageNext from '@/assets/image/ico/ico-page-next.svg'
import SearchImg from '@/assets/image/ico/ico-search.svg'
import Pen from '@/assets/image/ico/ico-pen.svg'


const Index = (mtype) => {
	const searchvaluefocusRef = useRef(null);

	const [boardList, setBoardList] = useState([]);
	const [pageList, setPageList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState({
		page: 1,
		sck: 'subject',
		scv: '',
	  });

	  //console.log(mtype);
	
	const [pageNum, setPageNum] = useState("1");
	const [totalPageNum, setTotalPageNum] = useState("1");
	const [admChk, setAdmChk] = useState("N");

	const navigate = useNavigate();
	
	const getBoardList = async (e) => {
		const token = getToken();
		
		if(token !== null){
			const local_token = JSON.parse(token);

			if (pageNum === e) return; //현재 페이지와 누른 페이지가 같으면 return
			
			if(e == undefined){
				e = "";	
			}
			let queryString = "/"+e;
			//queryString = queryString + "/"+search.sck;
			//queryString = queryString + "/"+search.scv;

			axios({
				method: 'post',
				url: process.env.REACT_APP_API_NOTICE_LIST,
				mode: "cors",
				credentials: "same-origin",
				data:{
					page: e,
					searchword: search.scv,
					searchkey: search.sck,
					company: local_token.company,
				},
				headers: {
					Authorization: "Bearer " + local_token.access_token
				},
			}).then((res) => {
				setBoardList(res.data.list); 

				const pngn = res.data.paging;
				const curPage = pngn.page;
				const curPage2 = pngn.cur_page;
				const pageRow = pngn.page_rows;
				const totalCnt = pngn.total_cnt;
				const totalPageCnt = pngn.num_page_cnt;
				const startPage = pngn.startpage;
				const endPage = pngn.endpage;
				const base_page = 1;

				const tmpPages = [];
				for (let i = startPage - 1; i <= endPage; i++) {
					if (i >= base_page){
						tmpPages.push(i);
					}
				}
				setPageList(tmpPages);
				setTotalPageNum(totalPageCnt);
				setLoading(false);
			}).catch((err) => {	
				console.log("에러", err);
			})
		}

		/*const resp = await (await axios.get(process.env.REACT_APP_API_NOTICE_LIST + queryString)).data; 
		setBoardList(resp.list); 
		
		const pngn = resp.paging;
		const curPage = pngn.page;
		const curPage2 = pngn.cur_page;
		const pageRow = pngn.page_rows;
		const totalCnt = pngn.total_cnt;
		const totalPageCnt = pngn.num_page_cnt;
		const startPage = pngn.startpage;
		const endPage = pngn.endpage;
		const base_page = 1;

		const tmpPages = [];
		for (let i = startPage - 1; i <= endPage; i++) {
			if (i >= base_page){
				tmpPages.push(i);
			}
		}
		setPageList(tmpPages);
		setTotalPageNum(totalPageCnt);
		setLoading(false);*/
	}
	
	const noticeRegister =() => {
		navigate("/admin/NoticeRegister");
	}

	const onBtnClick = (event) => {
		let value = event.target.getAttribute('data');
		//console.log(value);
		/*setSearch({
		  ...search,
		  page: value,
		});*/
		setPageNum(value);
		//console.log(pageNum);
		getBoardList(value);
	};

	const onBtnClick2 = (e) => {
		let val = e.totalPageNum;
		//console.log(val);
		/*setSearch({
		  ...search,
		  page: value,
		});*/
		setPageNum(val);
		//console.log(pageNum);
		getBoardList(val);
	};

	const onBtnSearch = () => {
		if(search.scv === ""){
			alert("검색어를 입력해주세요.");
			searchvaluefocusRef.current.focus();
			return;
		}else{
			getBoardList();
		}
	}

	const searchkeychoice = (e) => {
		setSearch({
			...search,
			sck: e.target.value,
		});
	}
	
	const tokencall = () => {
		const token = getToken();

		if(token !== null){
			const local_token = JSON.parse(token);
			//console.log(local_token.adchk);
			if(local_token.adchk === "Y"){
				setAdmChk("Y");
			}else{
				setAdmChk("N");
			}
		}
	}

	useEffect(() => {
		setLoading(true);
		tokencall();
		getBoardList();
	  }, []);

	return (
		<>
		{ loading ?  <div style={{zIndex:'500', position: 'absolute', width: '80px', height: '80px', top: 'calc( 50% - 100px )', left: 'calc( 50% )'}}>
				<ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}/></div> : "" }

		<div className={Style.wrapper}>

            <div className={Style.subTitle}>
				<img src={Notice} />
				<span>공지사항</span>
			</div>

			<div className={Style.cmmList}>
				<ul>
				{boardList.map((board) => (
					<li key={board.seq}>
					{admChk === "Y" ? 
						<a href={`/admin/NoticeView/${board.seq}`}>
							<div className={Style.cate}>[{board.notype}]</div>
							<div className={Style.tit}>{board.subject}</div>
							<div className={Style.date}>{board.regdate}</div>
						</a>
						:
						<a href={`/NoticeView/${board.seq}`}>
							<div className={Style.cate}>[{board.notype}]</div>
							<div className={Style.tit}>{board.subject}</div>
							<div className={Style.date}>{board.regdate}</div>
						</a>
					}
					</li>
				))}	
				</ul>
			</div>
			
			{admChk === "Y" ? 
			<div className={Style.boardBtn}>
				<button type="button" onClick={noticeRegister}>
					<img src={Pen} />
					<span>글쓰기</span>
				</button>
			</div>
			: "" }

			<div className={Style.paging}>
				<button type="button" onClick={() => onBtnClick2('1')} value="1"><img src={PagePrev} /></button>
				<ul>
				
				{pageList.map((page, index) => (
					{...pageNum == page ? 
					<li key={index}><a href="#" className={Style.on} onClick={onBtnClick} data={page}>{page}</a></li>
					:
					<li key={index}><a href="#" onClick={onBtnClick} data={page}>{page}</a></li>
					}
				))}
				
				</ul>
				<button type="button" onClick={() => onBtnClick2({totalPageNum})} value={totalPageNum}><img src={PageNext} /></button>
			</div>

			<div className={Style.boardSearch}>
				<div className={Style.searchForm}>
					<select className={Style.select} onChange={searchkeychoice}>
						<option value="subject">제목검색</option>
						<option value="contents">내용검색</option>
					</select>
					<input type="text" className={Style.inp} value={search.scv} ref={searchvaluefocusRef} onChange={(e) => setSearch({...search, scv:e.target.value})} />
					<button type="button" onClick={onBtnSearch}><img src={SearchImg} /></button>
				</div>
			</div>

		</div>
		</>
	)
}

export default Index
