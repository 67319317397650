import React, {useState, useEffect, useRef} from 'react'
import axios from "axios";
import { useNavigate } from "react-router-dom"
import cn from 'classnames'
import CheckBox from '../../components/checkBox'

// css
import Style from './index.module.css'
import PagePrev from '@/assets/image/ico/ico-page-prev.svg'
import PageNext from '@/assets/image/ico/ico-page-next.svg'
import AdminIcon from '@/assets/image/ico/ico-access-list01.svg'
import ManagerIcon from '@/assets/image/ico/ico-access-list02.svg'
import PenIcon from '@/assets/image/ico/ico-pen01.svg'
import SpaceReq from '@/assets/image/ico/ico-dalla.svg'
import SearchImg from '@/assets/image/ico/ico-search.svg'
import popClose from '@/assets/image/ico/ico-pop-close.svg'
import Dialog from './dialog.module.css'


const ReqList = () => {
	const navigate = useNavigate();
	const [houseList, setHouseList] = useState([]);
	const [rentfeeList, setRentfeeList] = useState([]);
	const [listloading, setListLoading] = useState(false);
	const [pageList, setPageList] = useState([]);
	const [pageNum, setPageNum] = useState("1");
	const [totalPageNum, setTotalPageNum] = useState("1");
	const [kitalist, setKitaList] = useState([]);
	const [isPopup, setIsPopup] = useState(false);
	const [searchWd, setSearchWd] = useState("");
	const [searchKey, setSearchKey] = useState("username");
	const [loading, setLoading] = useState(false);

	const now = new Date();
	const _ = require('lodash');
	const years = _.range(now.getFullYear(), now.getFullYear() - 5, -1);

	const rentfee = () => {
		navigate('/admin/space/admCostCharge');
	}

	const gethouseList = async (e) => {
		const resp = await (await axios.get(process.env.REACT_APP_API_MANAGERHOUSELIST)).data; 
		setHouseList(resp.list); 
	}

	const getrentfeelist = (e) => {
		if(e == undefined){
			e = "";	
		}

		axios({
			method: 'post',
			url: process.env.REACT_APP_API_RENTFEELIST,
			data:{
				page: e,
				searchword: searchWd,
				searchkey: searchKey
			},
		  }).then((res) => {
			setRentfeeList(res.data.list);
			const pngn = res.data.paging;
			const curPage = pngn.page;
			const curPage2 = pngn.cur_page;
			const pageRow = pngn.page_rows;
			const totalCnt = pngn.total_cnt;
			const totalPageCnt = pngn.num_page_cnt;
			const startPage = pngn.startpage;
			const endPage = pngn.endpage;
			const base_page = 1;

			const tmpPages = [];
			for (let i = startPage - 1; i <= endPage; i++) {
				if (i >= base_page){
					tmpPages.push(i);
				}
			}
			setPageList(tmpPages);
			setTotalPageNum(totalPageCnt);
			setListLoading(true);
		  }).catch((err) => {	
			console.log("에러", err);
			setListLoading(false);
		  })
	}

	const getSearch = () =>{
		
		if(searchWd === ""){
			alert("검색어를 입력해주세요.");
			return;
		}else{
			setPageNum("1");

			axios({
				method: 'post',
				url: process.env.REACT_APP_API_RENTFEELIST,
				mode: "cors",
				credentials: "same-origin",
				data:{
					page: pageNum,
					searchword: searchWd,
					searchkey: searchKey
				},
			}).then((res) => {
				setRentfeeList(res.data.list);
	
				const pngn = res.data.paging;
				const curPage = pngn.page;
				const curPage2 = pngn.cur_page;
				const pageRow = pngn.page_rows;
				const totalCnt = pngn.total_cnt;
				const totalPageCnt = pngn.num_page_cnt;
				const startPage = pngn.startpage;
				const endPage = pngn.endpage;
				const base_page = 1;
	
				const tmpPages = [];
				for (let i = startPage - 1; i <= endPage; i++) {
					if (i >= base_page){
						tmpPages.push(i);
					}
				}
				setPageList(tmpPages);
				setTotalPageNum(totalPageCnt);
				setLoading(false);
			}).catch((err) => {	
				console.log("에러", err);
			})
		}
	}

	const handleClose = () => {
		setIsPopup(false);
	}

	const popupload = (plist) => {
		//console.log(plist);
		setIsPopup(true);
		setKitaList(plist);
	}

	function commaintvalue(num){
		let com = 0;
		if(num){
			com = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		}
		return com;
	}

	const onBtnClick = (event) => {
		let value = event.target.getAttribute('data');
		//console.log(value);
		/*setSearch({
		  ...search,
		  page: value,
		});*/
		setPageNum(value);
		//console.log(pageNum);
		getrentfeelist(value);
	};

	const onBtnClick2 = (e) => {
		let val = e.totalPageNum;
		//console.log(val);
		/*setSearch({
		  ...search,
		  page: value,
		});*/
		setPageNum(val);
		//console.log(pageNum);
		getrentfeelist(val);
	};

	//전체선택 부분 시작
	const [checkItems, setCheckItems] = useState([])

	const checkItemHandler = (id, isChecked) => {
		if (isChecked) {
			setCheckItems((prev) => [...prev, id])
		} else {
		//console.log('not')
			setCheckItems(checkItems.filter((item) => item !== id))
		}
		//console.log(checkItems)
	}

	const allCheckedHandler = (e) => {
		if (e.target.checked) { 
			setCheckItems(rentfeeList.map((item) => item.seq))
		} else {
			setCheckItems([]);
		}
		//console.log(`allCheck = `, e.target.checked)
	}

	const selectDataDelete = (e) => {
		if(checkItems.length == 0){
			alert('삭제하실 데이터를 선택해주세요.');
		}else{
			//console.log(checkItems.length);

			if(window.confirm("정말로 삭제 하시겠습니까?")){
				const formData = new FormData();
				formData.append('chkidx',checkItems)
				axios({
					method:'post',
					url:process.env.REACT_APP_API_RENTFEESELECTDELETE,
					mode: "cors",
					credentials: "same-origin",
					headers: {
						"Content-Type": "multipart/form-data",
					},
					data: formData,
				})
					.then((result)=>{
						if(result.data.code == 200){
							alert("삭제 되었습니다.");
							window.location.href="/admin/space/admCostList";
						}else{
							console.log(result)
						}
				})
					.catch((error)=>{console.log('요청실패')
					console.log(error)  
				})
			}
		}
	}
	//전체선택 부분 끝

	useEffect(() => {
		gethouseList();
		getrentfeelist();
	}, []);

	return (
		<div className={Style.wrapper}>

			<section className={Style.spacedHeader}>

				<div className={cn(Style.pageTitle, Style.type02)}>
					<img src={SpaceReq} />
					<span>관리비 리스트</span>
				</div>
			</section>

			<section className={Style.listOptForm} style={{position: 'relative'}}>
				<div className={Style.formItem}>
					<select className={Style.select}>
						<option>공유주택 선택</option>
						{houseList.map((list) => (
							<option key={list.seq} value={list.seq}>{list.house_name}</option>
						))}
					</select>
					<select className={cn(Style.select, Style.w01)}>
						<option value="">층수 선택</option>
						{
							Array(70).fill(0).map((_, i) => (
								<option key={i + 1} value={i + 1}>{i + 1}</option>
							))
						}
					</select>
					<span className={Style.e01}>층</span>
					<select className={cn(Style.select, Style.w01)}>
						<option value="">호수 선택</option>
						{
							Array(30).fill(0).map((_, i) => (
								<option key={i + 1} value={i + 1}>{i + 1}</option>
							))
						}
					</select>
					<span className={Style.e01}>호</span>
				</div>

				<div className={Style.formItem}>
					<select className={Style.select}>
						<option value="">년</option>
						{
							years.map((option) => (
								<option key={option} value={option}>{option}년</option>
							))
						}
					</select>
					<select className={cn(Style.select, Style.w01)}>
						<option value="">월</option>
						{
							Array(12).fill(0).map((_, i) => (
								<option key={i + 1} value={i + 1}>{i + 1}월</option>
							))
						}
					</select>

					<div style={{paddingLeft:'10px'}}>
						<button className={Style.btn} type={'button'} style={{marginTop:'0'}} onClick={rentfee}>검색</button>
					</div>
				</div>

				<div style={{position:'absolute', right: '0', fontSize: '21px', color: '#fff', fontWeight: '600'}}>
					<button className={Style.btn} type={'button'} style={{marginTop:'0'}} onClick={rentfee}>관리비부과</button>
				</div>
			</section>

			<div className={cn(Style.scrolls, Style.type01)}>
				<table className={cn(Style.webList)}>
					<colgroup>
						<col width="50" />
						<col width="130" />
						<col width="90" />
						<col width="100" />
						<col width="80" />
						<col width="100" />
						<col width="130" />
						<col width="160" />
						<col width="160" />
						<col width="160" />
						<col width="" />
					</colgroup>
					<tr>
						<th><input type="checkbox" onChange={allCheckedHandler} 
          				checked={checkItems.length === rentfeeList.length ? true : false} /></th>
 						<th>공유주택</th>
						<th>층수</th>
						<th>호수</th>
						<th>거주자</th>
						<th>년/월</th>
						<th>기본관리비</th>
						<th>전기비용</th>
						<th>수도비용</th>
						<th>가스비용</th>
						<th>기타비용</th>
					</tr>
					{listloading ?
                		rentfeeList.map((board) => (
							<tr>
								<td><CheckBox key={board.seq} id={board.seq} checkItemHandler={checkItemHandler}
            checked={checkItems.includes(board.seq) ? true : false} /></td>
								<td>{board.housename}</td>
								<td>{board.floor}층</td>
								<td>{board.hosu}호</td>
								<td>{board.username}</td>
								<td>{board.datey}년 {board.datem}월</td>
								<td>{board.defcost}원</td>
								<td>{board.ecost}원 / {board.ecost_e}원</td>
								<td>{board.scost}원 / {board.scost_e}원</td>
								<td>{board.gcost}원 / {board.gcost_e}원</td>
								<td><a style={{display: 'block', fontSize: '16px'}} href="javascript:void(0);" onClick={(e) => popupload(board.sub_itemlist)}>{board.etc1}원 / {board.etc1_e}원</a></td>
							</tr>
						))
							:
							<tr>
								<td colspan="10" style={{textAlign:'center'}}>등록된 관리비내역이 없습니다.</td>
							</tr>
						}
				</table>

				<div className={Style.paging}>
					<button type="button" onClick={() => onBtnClick2('1')} value="1"><img src={PagePrev} /></button>
					<ul>
					
					{pageList.map((page, index) => (
						{...pageNum == page ? 
						<li key={index}><a href="#" className={Style.on} onClick={onBtnClick} data={page}>{page}</a></li>
						:
						<li key={index}><a href="#" onClick={onBtnClick} data={page}>{page}</a></li>
						}
					))}
					
					</ul>
					<button type="button" onClick={() => onBtnClick2({totalPageNum})} value={totalPageNum}><img src={PageNext} /></button>
				</div>
				
				<div className={Style.etc}>
					<button className={Style.btn} type={'button'} onClick={selectDataDelete}>선택삭제</button>
				</div>

				<div className={Style.boardSearch}>
					<div className={Style.searchForm}>
						<select className={Style.select} name="search_key" onChange={(e) => setSearchKey(e.target.value)}>
							<option value="username">이름검색</option>
							<option value="hosu">호수검색</option>
						</select>
						<input type="text" className={Style.inp} name="search_word" onChange={(e) => setSearchWd(e.target.value)} />
						<button type="button"><img src={SearchImg} onClick={getSearch} /></button>
					</div>
				</div>

			</div>

			<div className={cn(Style.popup, `${isPopup?Style.on:''}`)}>
        		<button type="button" className={Style.popClose} onClick={handleClose}><img src={popClose} /></button>
        
				<div className={Style.addLabel}>기타비용 리스트</div>
				<div className={Style.tblWrap01}>
					<table>
						<tr>
							<th>항목명</th>
							<td>비용</td>
						</tr>
						{kitalist ?
							kitalist.map((list) => {
								return (
								<tr>
									<th>{list.etc_item}</th>
									<td>{commaintvalue(list.etc_itemcost) +"원 / "+ commaintvalue(list.etc_itemeachcost)+"원"}</td>
								</tr>
								)
							})
							:
							""
						}
					</table>
				</div>
      		</div>

			<div className={cn(Dialog.dialog, `${isPopup?Dialog.on:''}`)}>
			</div>

		</div>
	)
}

export default ReqList
