import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"

import { Send, Pencil, Trash, Circle, X } from "lucide-react"

export const getColumns = (type, deleteAddedMemebers) => {
  var columns;

  columns = [
    {
      accessorKey: "building_name",
      header: () => <div className="text-left">건물명</div>,
      cell: ({ row }) => <div>{row.getValue("building_name")}</div>,
    },
    {
      accessorKey: "room_number",
      header: () => <div className="text-center">호수</div>,
      cell: ({ row }) => <div className="text-center">{row.getValue("room_number")}</div>,
    },
    {
      accessorKey: "name",
      header: () => <div className="text-center">이름</div>,
      cell: ({ row }) => <div className="text-center">{row.getValue("name")}</div>,
    },
    {
      accessorKey: "phone_number",
      header: () => <div className="text-center">전화번호</div>,
      cell: ({ row }) => <div className="text-center">{row.getValue("phone_number")}</div>,
    },
    {
      accessorKey: "email",
      header: () => <div className="text-center">이메일</div>,
      cell: ({ row }) => <div className="text-center">{row.getValue("email")}</div>,
    },
    {
      id: "added_members_table_actions",
      header: () => <div className="flex justify-end align-middle">
                        <div className="w-12">삭제</div>
                    </div>,
      enableHiding: false,
      cell: ({ row }) => {
        const template = row.original
  
        return (
          <div className="flex justify-end align-middle mr-3">
            <Button variant="icon" size="icon" className="w-11" onClick={()=>deleteAddedMemebers(row.index)}>
              <Trash className="h-5 w-5"/>
            </Button>
         </div>
        )
      },
    },
  ]
  

  return columns;
}