import React, {useState, useEffect} from 'react'
import { useNavigate } from "react-router-dom"
import axios from "axios";
import cn from 'classnames'
import { getToken } from "../../services/TokenService";


import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import styled from "@emotion/styled";

// css
import Style from './index.module.css'
import ProductImg from '@/assets/image/etc/etc-product01.png'
import SpaceHome from '@/assets/image/ico/ico-home01.svg'
import SpaceReq from '@/assets/image/ico/ico-house01.svg'
import Tel from '@/assets/image/ico/ico-tel.svg'


const Index = () => {
  const [boardList, setBoardList] = useState([]);
  const [loading, setLoading] = useState(false);
	const [isPopup, setIsPopup] = useState(true);
  const navigate = useNavigate();

	const handleClose = () => {
		setIsPopup(false);
	}

  const getBoardList = async (e) => {

		//const resp = await (await axios.get(process.env.REACT_APP_API_ADMINHOUSELIST)).data; 
		//setBoardList(resp.list); 
		//setLoading(false);
    const token = getToken();

    if(token !== null){
			const local_token = JSON.parse(token);

			axios({
				method: 'post',
				url: process.env.REACT_APP_API_ADMINHOUSELIST,
				mode: "cors",
				credentials: "same-origin",
				data:{
					company: local_token.company,
				},
			}).then((res) => {
				setBoardList(res.data.list); 
		    setLoading(false);
			}).catch((err) => {	
				console.log("에러", err);
			})
		}
	}

	const [ activeTab, setActiveTab] = useState(1);
	const handleTabs = (n) => {
    setActiveTab(n);
	}

  const goHouseDetail = (e) => {
    navigate("/admin/house/view/"+e);
  }

  useEffect(() => {
		setLoading(true);
		getBoardList();
	}, []);

	return (
		<div className={Style.wrapper}>

			<section className={Style.spacedHeader}>

				<div className={Style.pageTitle}>
					<img src={SpaceHome} />
					<span>{ activeTab === 1 ? '주택목록':'주택리스트'}</span>

					<ul>
						<li><a className={activeTab===1? Style.on:''} onClick={() => handleTabs(1)}>카드보드</a></li>
						<li><a className={activeTab===2? Style.on:''} onClick={() => handleTabs(2)}>게시판</a></li>
					</ul>
				</div>

			</section>


			{/*
			<div className={Style.titlebar}>
				<strong>Dashboard</strong>
				<span>주택목록</span>
				<ul>
					<li><a href="#" className={Style.on}>카드보드</a></li>
					<li><a href="#">게시판</a></li>
				</ul>
			</div>
			*/}
			<div className={Style.listFunc}>
				<select className={Style.select}>
					<option>등록 최신순</option>
					<option>거주자 순</option>
					<option>입주율 많은 순</option>
					<option>입주율 적은 순</option>
				</select>
			</div>
			{ activeTab === 1 && (
        <ul className={Style.housingList}>

          {boardList.map((board) => (
          <li>
            <a href={`/admin/house/view/${board.seq}`}>
              <div className={Style.stit}>{board.house_name}</div>
              <div className={Style.snum}>{board.house_id ? board.house_id : <br/>}</div>
              <div className={Style.spic} style={{backgroundImage:`url(${ProductImg})`}}></div>
              <div className={Style.addr}>
                <img src={Tel} />
                <span>매니저 연락하기</span>
              </div>
              <ul>
                <li>
                  <div className={Style.key}>층수 / 호수</div>
                  <div className={Style.value}>층 호</div>
                </li>
                <li>
                  <div className={Style.key}>보증금 / 임대료</div>
                  <div className={Style.value}>0 / 0</div>
                </li>
              </ul>
              <ul>
                <li>
                  <div className={Style.key}>입주율</div>
                  <div className={Style.value}>0%</div>
                </li>
                <li>
                  <div className={Style.key}>7일이내 입금예정</div>
                  <div className={Style.value}>0명</div>
                </li>
                <li>
                  <div className={Style.key}>연체자</div>
                  <div className={Style.value}>0명</div>
                </li>
              </ul>
            </a>
          </li>
          ))}	
          
        </ul>
			)}

      { activeTab === 2 && (
        <div className={cn(Style.scrolls, Style.type01)}>
          <table className={cn(Style.webList)}>
            <colgroup>
              <col width="120" />
              <col width="120" />
              <col width="" />
              <col width="140" />
              <col width="180" />
              <col width="100" />
              <col width="150" />
              <col width="150" />
            </colgroup>
            <tr>
              <th>준공일</th>
              <th>건물명</th>
              <th>주소</th>
              <th>층수/호수</th>
              <th>보증금/임대로</th>
              <th>입주율</th>
              <th>7일이내 입금예정</th>
              <th>연체자</th>
            </tr>
            {boardList.map((board) => (
            <tr>
              <td>2023.07.13</td>
              <td style={{cursor:'pointer'}} onClick={() => goHouseDetail(board.seq)}>{board.house_name}</td>
              <td>{board.addr} {board.addr_detail}</td>
              <td>0층 / 0호</td>
              <td>0 / 0</td>
              <td>0%</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            ))}
           
            {/*}
            <tr>
              <td>2023.07.13</td>
              <td>장안생활</td>
              <td>서울시 서울구 서울동 </td>
              <td>9층 / 32호</td>
              <td>312백만 / 12백만</td>
              <td>89%</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            {*/}
          </table>

          <ul className={cn(Style.mobList)}>
            <li>
              <table>
                <colgroup>
                  <col width="120" />
                  <col width="" />
                </colgroup>
                <tr>
                  <th className={Style.al}>준공일</th>
                  <td className={Style.al}>2023.07.13</td>
                </tr>
                <tr>
                  <th className={Style.al}>건물명</th>
                  <td className={Style.al}>장안생활</td>
                </tr>
                <tr>
                  <th className={Style.al}>주소</th>
                  <td className={Style.al}>서울시 서울구 서울동</td>
                </tr>
                <tr>
                  <th className={Style.al}>층수/호수	</th>
                  <td className={Style.al}>9층 / 32호</td>
                </tr>
                <tr>
                  <th className={Style.al}>보증금/임대로</th>
                  <td className={Style.al}>312백만 / 12백만</td>
                </tr>
                <tr>
                  <th className={Style.al}>입주율	</th>
                  <td className={Style.al}>89%	</td>
                </tr>
                <tr>
                  <th className={Style.al}>7일이내 입금예정</th>
                  <td className={Style.al}>&nbsp;</td>
                </tr>
                <tr>
                  <th className={Style.al}>연체자</th>
                  <td className={Style.al}>&nbsp;</td>
                </tr>
              </table>
            </li>
            <li>
              <table>
                <colgroup>
                  <col width="120" />
                  <col width="" />
                </colgroup>
                <tr>
                  <th className={Style.al}>준공일</th>
                  <td className={Style.al}>2023.07.13</td>
                </tr>
                <tr>
                  <th className={Style.al}>건물명</th>
                  <td className={Style.al}>장안생활</td>
                </tr>
                <tr>
                  <th className={Style.al}>주소</th>
                  <td className={Style.al}>서울시 서울구 서울동</td>
                </tr>
                <tr>
                  <th className={Style.al}>층수/호수	</th>
                  <td className={Style.al}>9층 / 32호</td>
                </tr>
                <tr>
                  <th className={Style.al}>보증금/임대로</th>
                  <td className={Style.al}>312백만 / 12백만</td>
                </tr>
                <tr>
                  <th className={Style.al}>입주율	</th>
                  <td className={Style.al}>89%	</td>
                </tr>
                <tr>
                  <th className={Style.al}>7일이내 입금예정</th>
                  <td className={Style.al}>&nbsp;</td>
                </tr>
                <tr>
                  <th className={Style.al}>연체자</th>
                  <td className={Style.al}>&nbsp;</td>
                </tr>
              </table>
            </li>
          </ul>
        </div>
      )}





		</div>
	)
}

export default Index
