import {useState, useEffect, useContext, useRef} from 'react'
import { Button } from '../ui/button'

import { cn } from "@/components/lib/utils"
import { Input } from "../ui/input"
import { Label, GreenLabel } from "../ui/label"
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../ui/select"
import { Textarea } from "../ui/textarea"
import { toast } from "../ui/use-toast"
import { MySpinner } from '../ui/spinner'
import { useQuery, useMutation } from "@tanstack/react-query";
import Axios from "@/components/lib/axios";
import { X } from "lucide-react"

const PushTemplateEditor = ({inputSubject, setInputSubject, inputContent, setInputContent, imageOne, setImageOne}) => {
	const [targetDevice, setTargetDevice] = useState("android");
	const fileInputRef = useRef();const [imgOneLabel, setImgOneLabel] = useState("선택된 파일 없음");
	const { isLoading: isLoadingAutoVariableNames, error, data: autoVariableNames } = useQuery({queryKey:["auto_variable_name"], queryFn: () =>
		Axios.get('/api_message/common/auto_variables').then((res) => {
			return res.data;
		})});
	
	const imageUpload = useMutation({
		mutationFn: async ({file, index, fileName}) => {
			const formData = new FormData()
			formData.append('image', file)
			
			const { data } = await Axios.post("/api_message/common/image", formData)
			data["index"] = index
			data["fileName"] = fileName
			return data
		}, 
		onSuccess: (res) => {
			setImageOne(res.imgUrl)
		},
	});

	useEffect(() => {
		if(imageOne !== ""){
			const imageFileName = imageOne.split("-")[1];
			if(imageFileName !== undefined && imageFileName.length > 0) setImgOneLabel(decodeURI(imageFileName));
		}
	}, [imageOne]);

	function onSubmit(data) {
		toast({
			title: "You submitted the following values:",
			description: (
			<pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
				<code className="text-white">{JSON.stringify(data, null, 2)}</code>
			</pre>
			),
		})
	}

	const handleChangeForImageOne = (event) => {
		const imageToUpload = event.target.files[0];
		imageUpload.mutate({file:imageToUpload, index:0, fileName: imageToUpload.name});
	};
	
	return (
		<div className="w-full mt-12 lg:flex lg:flex-row justify-between">
            <div className="lg:w-1/2  flex flex-col w-full">
                <GreenLabel className="">제목</GreenLabel>
                <Input className="mt-3" value={inputSubject} onChange={(event)=>setInputSubject(event.target.value)}/>
				<GreenLabel className="mt-8">내용</GreenLabel>
                <Textarea
					className="resize-none mt-3"
					rows="14"
					value={inputContent}
					onChange={(event)=>setInputContent(event.target.value)}
                />
				<GreenLabel className="mt-8">시스템 자동 입력 변수</GreenLabel>
				{
					isLoadingAutoVariableNames
					? <MySpinner/>
					: 	<div className="flex flex-col items-center justify-center">
							<div className="w-full p-4 rounded-md">
								<div className="flex flex-wrap justify-start gap-2">
									{
										autoVariableNames.map(variableName => {
											return <Button onClick={()=>setInputContent((prev)=> prev + variableName.variable_name)}>{variableName.variable_name}</Button>})
									}
								</div>
							</div>
						</div>
				}
				<GreenLabel className="mt-4">이미지</GreenLabel>
				<div className='flex flew-row mt-3 items-center gap-4'>
					<Button onClick={()=>fileInputRef.current.click()}>업로드</Button>
					<Label>{imgOneLabel}</Label>
					{
						imageOne !== "" 
						&&
						<Button variant="icon" size="icon" onClick={()=>{setImageOne("");setImgOneLabel("선택된 파일이 없음")}}><X/></Button>
					}
					<input onChange={handleChangeForImageOne} multiple={false} ref={fileInputRef} type='file' accept="image/*" hidden/>
				</div>
            </div>
			
			<div className="lg:w-1/2 w-full flex items-center flex-col gap-5">
				<div className="pt-4 top-0">
					<div className='block'>
						{
							targetDevice === "android"
							? <div className="w-[450px] h-[660px] bg-[url('/public/android.svg')] m-auto">
								<div className="relative top-[170px] m-auto w-[404px] select-none">
									<div className='flex flex-col bg-white p-5 rounded-xl shadow'>
										<div className='flex flex-row items-center'>
											<img src="https://siso.live/static/media/etc-sys02.c24fdd49a864aef85e58c6a99213fed9.svg" className='w-6 h-6'/>
											<div className='text-sm ml-4'>시소</div>
										</div>
										<div className='mt-3 ml-10'>
											<div className='text-base font-bold line-clamp-1'>{inputSubject}</div>
										</div>
										<div className='mt-3 ml-10'>
											<pre className='text-base line-clamp-2'>{inputContent}</pre>
										</div>
										{
											imageOne !== ""
											&&
											<div className='mt-3 ml-10'>
												<img src={Axios.defaults.baseURL+'message_images/'+imageOne} className='aspect-[2/1] object-center max-w-full'/>
											</div>
										}
									</div>
								</div>
							</div>
							: <div className="w-[424px] h-[660px] bg-[url('/public/ios.svg')] m-auto">
								<div className="relative top-[200px] m-auto w-[360px] select-none">
									<div className='flex flex-row bg-white p-3 rounded-xl shadow w-[340px]'>
										<img src="https://siso.live/static/media/etc-sys02.c24fdd49a864aef85e58c6a99213fed9.svg" className='w-8 h-8'/>
										<div className='flex flex-col ml-3 mr-3 w-[260px]'>
											<div>
												<div className='text-sm font-semibold line-clamp-1'>{inputSubject}</div>
											</div>
											<div>
												<pre className='text-sm line-clamp-4'>{inputContent}</pre>
											</div>
										</div>
										<div className='text-xs w-8'>now</div>
									</div>
								</div>
							</div>
						}
						
					</div>
				</div>
				<Select value={targetDevice} onValueChange={(value)=>setTargetDevice(value)}>
					<SelectTrigger className="w-[180px]">
						<SelectValue/>
					</SelectTrigger>
					<SelectContent>
							<SelectItem value="android" >Android</SelectItem>
							<SelectItem value="ios">iOS</SelectItem>
					</SelectContent>
				</Select>
			</div>
		</div>
	)
}

export default PushTemplateEditor