import React, {useState, useEffect, useRef} from 'react'
import axios from "axios";
import { useNavigate } from "react-router-dom"
import cn from 'classnames'
import DashboardList from '../../components/dashboardlist';
import { getToken } from "../../services/TokenService";

// css
import Style from './index.module.css'

import PagePrev from '@/assets/image/ico/ico-page-prev.svg'
import PageNext from '@/assets/image/ico/ico-page-next.svg'
import AdminIcon from '@/assets/image/ico/ico-access-list01.svg'
import PhotoImg01 from '@/assets/image/etc/etc-photo-list01.png'
import RemoveIcon from '@/assets/image/ico/ico-remove01.svg'
import SpaceReport from '@/assets/image/ico/ico-report.svg'
import SpaceReq from '@/assets/image/ico/ico-req02.svg'
import ReqImg from '@/assets/image/etc/etc-req01.jpg'
import CntList01 from '@/assets/image/ico/ico-space-cnt01.svg'
import CntList02 from '@/assets/image/ico/ico-space-cnt01.svg'
import CntList03 from '@/assets/image/ico/ico-space-cnt01.svg'
import CntList04 from '@/assets/image/ico/ico-space-cnt01.svg'
import Cmm from '@/assets/image/ico/ico-cmm.svg'
import Notice from '@/assets/image/ico/ico-noti.svg'
import More from '@/assets/image/ico/ico-arr-more.svg'

import Smile from '@/assets/image/ico/ico-smile.svg'
import SpaceHome from '@/assets/image/ico/ico-space-home.svg'
import SpaceIcon01 from '@/assets/image/ico/ico-space-list01.svg'
import SpaceIcon02 from '@/assets/image/ico/ico-space-list02.svg'
import SpaceIcon03 from '@/assets/image/ico/ico-space-list03.svg'
import SpaceIcon04 from '@/assets/image/ico/ico-space-list04.svg'

import CostList from '@/assets/image/ico/ico-cost-list.svg'
import SpacePrev from '@/assets/image/ico/ico-space-prev.svg'
import SpaceNext from '@/assets/image/ico/ico-space-next.svg'

const Main = () => {
  const [houseList, setHouseList] = useState([]);
  const [oneres, setOneRes] = useState("");
  const [houseListS1, setHouseListS1] = useState([]);
  const [houseListS2, setHouseListS2] = useState([]);
  const [houseListS3, setHouseListS3] = useState([]);
  const [houseListS4, setHouseListS4] = useState([]);
  const [houselive, setHouseLive] = useState([]);
  const navigate = useNavigate();

  const [ createToggle, setCreateToggle] = useState(false);
  const handleToggle = () => {
    setCreateToggle(!createToggle);
  }

	const [spaceActive, setSpaceActive] = useState(1);
	const handlePrev = () => {
  		if(spaceActive > 1){
			setSpaceActive(spaceActive - 1);
		}
	}
	const handleNext = () => {
		if(spaceActive < 3){
			setSpaceActive(spaceActive + 1);
		}
	}

  const pinCreate1 = () => {
		navigate("/admin/space/pincreate1");
	}

  const pinCreate2 = () => {
		navigate("/admin/space/pincreate2");
	}

  const gethouseList = async (e) => {
		//const resp = await (await axios.get(process.env.REACT_APP_API_MANAGERHOUSELIST)).data; 
		//if(resp.cnt > 0){
		//setHouseList(resp.list); 
		//getHouseOne(resp.baro);
		//}

    const token = getToken();
		
		if(token !== null){
			  const local_token = JSON.parse(token);

        axios({
          method: 'post',
          url: process.env.REACT_APP_API_MANAGERHOUSELIST,
          mode: "cors",
          credentials: "same-origin",
          data:{
            company: local_token.company,
          },
        }).then((res) => {
            if(res.data.cnt > 0){
              setHouseList(res.data.list); 
              getHouseOne(res.data.baro);
            }
        }).catch((err) => {	
          console.log("에러", err);
        })
    }
	}

  const getHouseOne = async (e) => {
      axios.post(process.env.REACT_APP_API_HOUSERECENTONE, {
        seq: e
      })
      .then(function (response) {
        if(response.data.code == '200'){
          setHouseListS1(response.data.data_list.data_s1);
          setHouseListS2(response.data.data_list.data_s2);
          setHouseListS3(response.data.data_list.data_s3);
          setHouseListS4(response.data.data_list.data_s4);
          setHouseLive(response.data.data_list.data_g);
          //console.log(response.data.data_list.data_g);
        }else if(response.data.code == '400'){
          console.log(response.data.code);
        }
        }).catch(function (error) {
          console.log("에러", error);
      });
  }

  const sharehousechoice = (e) => {
    axios.post(process.env.REACT_APP_API_HOUSERECENTONE, {
      seq: e.target.value
    })
      .then(function (response) {
    if(response.data.code == '200'){
      setHouseListS1(response.data.data_list.data_s1);
      setHouseListS2(response.data.data_list.data_s2);
      setHouseListS3(response.data.data_list.data_s3);
      setHouseListS4(response.data.data_list.data_s4);
      setHouseLive(response.data.data_list.data_g);
      //console.log(response.data.data_list.data_g);
    }else if(response.data.code == '400'){
      console.log(response.data.code);
    }
    }).catch(function (error) {
        console.log("에러", error);
    });
  }

  const sharehouseDetail = (e) => {
    //console.log(e);
    navigate('/admin/space/detail/'+e);
    //console.log(e);
  }

  useEffect(() => {
		gethouseList();
	}, []);

	return (
		<div className={Style.wrapper}>

        <section className={Style.spacedHeader}>

        <div className={Style.pageTitle}>
          <img src={SpaceHome} />
          <span>공유주택 공간</span>
          <select className={Style.select} onChange={sharehousechoice}>
            {houseList.length === 0 ?
              <option>공유주택 선택</option>
              :
              houseList.map((list) => (
                <option key={list.seq} value={list.seq}>{list.house_name}</option>
              ))
            }
          </select>

        <div className={Style.sideBtn}>
            <button type={'button'} onClick={handleToggle}>공간생성</button>
            { createToggle && (
              <ul>
                <li><button type={'button'} onClick={() => pinCreate1()}>개별</button></li>
                <li><button type={'button'} onClick={() => pinCreate2()}>일괄</button></li>
              </ul>
            )}

          </div>
        </div>
        </section>

        <section className={Style.spaceWrap}>
        <div className={Style.spaceControl}>
        <button type={'button'} className={`${spaceActive === 1 ? Style.disabled : ''}`} onClick={handlePrev}><img src={SpacePrev} /></button>
        <button type={'button'} className={`${spaceActive === 3 ? Style.disabled : ''}`} onClick={handleNext}><img src={SpaceNext} /></button>
        </div>

        <div className={cn(Style.sitem, `${spaceActive === 1 ? Style.active : ''}`)}>
          <div className={Style.sico}>
            <img src={SpaceIcon01} />
            <span>개별거주공간</span>
          </div>
          <div className={Style.sbox}>
            <dl className={Style.type01}>
              {
              houseListS1.map((list, index) => {
                let livedata = [];
                {houselive.map((list, index1) => {
                  livedata[list.hosu] = list.name;
                })}

                return (
                  <>
                    <dt key={index}><strong>{list.floor}층 {list.hosu}개</strong> 호실</dt>
                    <dd>
                      <ul>
                        
                        { list.sub_list.map((slist, index1) => {
                          //console.log(slist);
                          let hosu = slist.floor + String(slist.hosu).padStart(2, "0");
                          let hosudata = livedata[hosu];
                          
                          if(hosudata){
                            return (
                              <li key={index1} className={Style.on} onClick={() => sharehouseDetail(slist.idx)} style={{cursor:'pointer'}}>
                                  <div className={Style.key}>{slist.floor}{String(slist.hosu).padStart(2, "0")}</div>
                                  <div className={Style.value}>{hosudata}</div>
                              </li>
                            );
                          }else{
                            return (
                              <li key={index1} onClick={() => sharehouseDetail(slist.idx)} style={{cursor:'pointer'}}>
                                  <div className={Style.key}>{slist.floor}{String(slist.hosu).padStart(2, "0")}</div>
                                  <div className={Style.value}>-</div>
                              </li>
                            );
                          }
                        })}

                        {/*}
                        <DashboardList style={Style} data={list.hosu} floor={list.floor} houselive={houselive} />
                        {*/}
                        {/*}
                        {Array(list.hosu).fill(0).map((_, i) => {
                          return (
                            <li>
                              <div className={Style.key}>{list.floor}0{i+1}</div>
                              <div className={Style.value}>-</div>
                            </li>
                          )
                        })}
                        {*/}
                      </ul>
                    </dd>
                  </>
                );
              })}
            </dl>
          </div>
        </div>
        <div className={cn(Style.sitem, `${spaceActive === 2 ? Style.active : ''}`)}>
          <div className={Style.sico}>
            <img src={SpaceIcon02} />
            <span>공유공간</span>
          </div>
          <div className={Style.sbox}>
            <dl className={Style.type02}>
              {houseListS2.map((list, index) => {
                return (
                  <>
                    <dt key={index}><strong>{list.floor}층</strong></dt>
                    <dd>
                    { list.sub_list.map((slist, index1) => {
                      return(
                      <ul>
                        <li onClick={() => sharehouseDetail(slist.idx)} style={{cursor:'pointer'}}>
                          <div className={Style.type}>{slist.etctxt}</div>
                          <div className={Style.minfo}>{slist.mj1}m² / {slist.mj2}m² / {slist.mj3}m²</div>
                          <div className={Style.mdesc}>{slist.gayo}</div>
                        </li>
                      </ul>
                      );
                    })}
                    </dd>
                  </>
                )
              })}
            </dl>
          </div>
        </div>
        <div className={cn(Style.sitem, `${spaceActive === 3 ? Style.active : ''}`)}>
          <div className={Style.sico}>
            <img src={SpaceIcon03} />
            <span>기타공간</span>
          </div>
          <div className={Style.sbox}>
            <dl className={Style.type02}>
              {houseListS4.map((list, index) => {
                return (
                  <>
                    <dt key={index}><strong>{list.floor}층</strong></dt>
                    <dd>
                    { list.sub_list.map((slist, index1) => {
                      return(
                      <ul>
                        <li onClick={() => sharehouseDetail(slist.idx)} style={{cursor:'pointer'}}>
                          <div className={Style.type}>{slist.etctxt}</div>
                          <div className={Style.minfo}>{slist.mj1}m² / {slist.mj2}m² / {slist.mj3}m²</div>
                          <div className={Style.mdesc}>{slist.gayo}</div>
                        </li>
                      </ul>
                      );
                    })}
                    </dd>
                  </>
                )
              })}
            </dl>
          </div>
        </div>

        </section>

		</div>
	)
}

export default Main
