import {React, useState, useEffect, createContext} from 'react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { MySpinner } from '@/components/ui/spinner'
import { Button } from '@/components/ui/button'
import { Input } from "@/components/ui/input"
import { GreenLabel, Label } from "@/components/ui/label"
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "@/components/ui/select"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { BuildingGroupMembersTable } from './building_group_member_table/table'
import { UserSearchTable } from './user_search_table/table'
import { AddedMembersTable } from './added_member_table/table'
import { TemplateTable } from './template_table/table'
import PushTemplateEditor from '@/components/message_template/push'
import InAppTemplateEditor from '@/components/message_template/inapp'
import EmailTemplateEditor from '@/components/message_template/email'
import SMSTemplateEditor from '@/components/message_template/sms'
import AlimtalkTemplateEditor from '@/components/message_template/alimtalk'
import { DifferentMessagesVariableTable } from './different_message_variable_table/table'
import { DifferentMessageTable } from './different_message_table/table'
import { useQuery, useMutation } from "@tanstack/react-query";
import Axios from "@/components/lib/axios";
import { MemberDirectInputForm } from './member_direct_input_form'
import TemplateLoader from './template_loader'
import { Send } from "lucide-react"
import { Link, useParams, useNavigate, useSearchParams  } from "react-router-dom";
import { useToast } from "@/components/ui/use-toast"
export const SendMessageContext = createContext({
    addedMembersList: [],
    setAddedMembersList: () => {},
    setDifferentMessageList: () => {},
    setAutoInputCustomVariableList: () => {},
  });


const SendMessageTab = () => {
    const { toast } = useToast()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState("");
    const [targetType, setTagetType] = useState("");
    const [addedMembersList, setAddedMembersList] = useState([]);
    const [selectedTemplateId, setSelectedTemplateId] = useState(0);
    const [messageContentType, setMessageContentType] = useState("");
    const [differentMessageList, setDifferentMessageList] = useState([]);
    const [autoInputCustomVariableList, setAutoInputCustomVariableList] = useState([]);
    const [buildingNameList, setBuildingNameList] = useState([]);
    const [groupNameList, setGroupNameList] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState("");
    const [selectedGroupId, setSelectedGroupId] = useState("");
    const [isOpenedSendResultDialog, setIsOpenedSendResultDialog] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const value = {addedMembersList, setAddedMembersList, setDifferentMessageList, setAutoInputCustomVariableList };

    const { isLoading: isLoadingStarterData, error } = useQuery({queryKey:["send_message_starter_data"], queryFn: () =>
		Axios.get(`/api_message/send/starter_data`).then((res) => {
            setBuildingNameList(res.data.building_name_list);
            setGroupNameList(res.data.group_name_list);
			return res.data;
		})});

    useEffect(() => {
        const type = searchParams.get('type');
        if(type !== null) setMessageType(type);

        const templateId = searchParams.get('templateId');
        if(templateId !== null) setSelectedTemplateId(parseInt(templateId));

        const targetType = searchParams.get('targetType');
        if(targetType !== null) setTagetType(targetType);

        const selectedGroupId = searchParams.get('selectedGroupId');
        if(selectedGroupId !== null) setSelectedGroupId(selectedGroupId);
    }, []);

    useEffect(() => {
        setAddedMembersList([])
    }, [targetType]);

    useEffect(() => {
        console.log("selectedTemplateId : "+selectedTemplateId)
    }, [selectedTemplateId]);

    useEffect(()=>{
        console.log("addedMembersList")
        console.log(addedMembersList)
    }, [addedMembersList]);

    useEffect(()=>{
        console.log("autoInputCustomVariableList")
        console.log(autoInputCustomVariableList)
    }, [autoInputCustomVariableList]);

    useEffect(()=>{
        console.log("differentMessageList")
        console.log(differentMessageList)
    }, [differentMessageList]);

    useEffect(()=>{
        if(selectedTemplateId !== 0){
            setMessageContentType("")
            setDifferentMessageList([])
        }
    }, [selectedTemplateId]);

    useEffect(() => {
        const prepareDifferentMessage = async () => {
            if(messageContentType === "different_content"){
                // 선택된 멤버가 있고 자동 입력 변수도 정해졌다
                const idList = [];
                addedMembersList.forEach( (member) => {
                    if(member.id !== null) idList.push(member.id);
                })
                if( idList.length > 0 ){
                    const autoVariableList = [];
                    autoInputCustomVariableList.forEach( (variable, index) => {
                        if(variable.is_auto_variable === true)
                            autoVariableList.push({id: variable.id, variable_name: variable.variable_name});
                    })

                    const newDifferentMessageList = [];
                    if(autoVariableList.length > 0){
                        var autoInputData;
                        try {
                            const {data} = await Axios.get(`/api_message/send/auto_input_data`, {
                                params: {id_list: idList, auto_variable_list: autoVariableList}})
                            autoInputData = [...data]
                        } catch(err) {
                            console.log("Error >>", err);
                        }
                    
                        addedMembersList.forEach( (user) => {
                            const differentMessage = {...user}
                            autoInputCustomVariableList.forEach( (variable, index) => {
                                const propertyNameForName = `var_${index+1}_name`
                                differentMessage[propertyNameForName] = variable.variable_name;

                                const propertyNameForValue = `var_${index+1}_value`
                                if(variable.is_auto_variable){
                                    const foundData = autoInputData.find((autoData) => autoData.member_id === differentMessage.id)
                                    if(foundData !== undefined)
                                        differentMessage[propertyNameForValue] = foundData[propertyNameForValue];
                                }else{
                                    differentMessage[propertyNameForValue] = "";
                                }
                            })
                            newDifferentMessageList.push(differentMessage);
                        })
                    }else{
                        addedMembersList.forEach( (user) => {
                            const differentMessage = {...user}
                            autoInputCustomVariableList.forEach( (variable, index) => {
                                const propertyNameForName = `var_${index+1}_name`
                                differentMessage[propertyNameForName] = variable.variable_name;
                                const propertyNameForValue = `var_${index+1}_value`
                                differentMessage[propertyNameForValue] = "";
                            })
                            newDifferentMessageList.push(differentMessage);
                        })
                    }
                    
                    setDifferentMessageList(newDifferentMessageList)
                }else{
                    const newDifferentMessageList = [];
                    addedMembersList.forEach( (user) => {
                        const differentMessage = {...user}
                        autoInputCustomVariableList.forEach( (variable, index) => {
                            const propertyNameForName = `var_${index+1}_name`
                            differentMessage[propertyNameForName] = variable.variable_name;
                            const propertyNameForValue = `var_${index+1}_value`
                            differentMessage[propertyNameForValue] = "";
                        })
                        newDifferentMessageList.push(differentMessage);
                    })
                    setDifferentMessageList(newDifferentMessageList)
                }
            }
        }
        prepareDifferentMessage()
    }, [messageContentType]);

    const onMessageTypeChanged = (value) => {
        setMessageType(value)
        setSelectedTemplateId(0)
        setMessageContentType("")
        setDifferentMessageList([])
    }

    const onMessageContentTypeClicked = (value) => {
        if(value === "different_content" && addedMembersList.length === 0)
            alert("스텝 2. 전송 대상 선택 부탁드립니다");
        else if (value === "different_content" && autoInputCustomVariableList.length === 0)
            alert("설정된 변수값이 없어 \"전체 동일 내용\"만 선택 가능합니다");
        else 
            setMessageContentType(value);
    }

    const onSendButtonClicked = async () => {
        var target;
       
        if(targetType === "shared_building"){
            const buildingFound = buildingNameList.find((building) => building.idx === parseInt(selectedBuilding))
            target = `건물 - ${buildingFound.sh_name}`;
        }else if(targetType === "group"){
            const groupFound = groupNameList.find((group) => group.id === parseInt(selectedGroupId))
            target = `그룹 - ${groupFound.name}`;
        }else{
            target = `회원 직접 선택`;
        }

        if(messageContentType === "different_content"){
            const params = { selectedTemplateId: selectedTemplateId, differentMessageList: differentMessageList, autoInputCustomVariableList: autoInputCustomVariableList, target: target, numOfVariable: autoInputCustomVariableList.length}
            try{
                const res = await Axios.post(`/api_message/send/different/${messageType}`, params)
            //if(res.data == 200){
                // const url = '/admin/messages/sendHistory/email'
                // navigate(url);
            //}
                console.log(res);
                
            }catch(err){
                console.log(err.message)
            }
        }else if(messageContentType === "same_content"){
            const params = { selectedTemplateId: selectedTemplateId, addedMembersList: addedMembersList, target: target }
            try{
                const res = await Axios.post(`/api_message/send/same/${messageType}`, params)
            //if(res.data == 200){
                // const url = '/admin/messages/sendHistory/email'
                // navigate(url);
            //}
            //setIsOpenedSendResultDialog(true);
                console.log(res);
            }catch(err){
                console.log(err.message)
            }
        }
        toast({
            title: `${getTypeInKorean(messageType)} 메시지를 전송했습니다`,
            description: "전송 내역으로 이동합니다",
        });
        navigate(`/admin/messages/sendHistory/${messageType}`);
    }

    const getTypeInKorean = (type) => {
		switch(type) {
			case 'push':
				return "푸쉬";
			case 'inapp':
				return "인앱";
			case 'email':
				return "이메일";
			case 'sms':
				return "문자";
			case 'alimtalk':
				return "알림톡";
		  }
	}

	return (
		<>
        { loading
            ? <MySpinner/>
            :
            <SendMessageContext.Provider value={value}>
			<div className='flex flex-col'>
                <div className='flex flex-col items-center'>
                    <div className="mb-5 title w-full">1. 메시지 타입</div>
                    <Select value={messageType} onValueChange={(value)=>onMessageTypeChanged(value)}>
                        <SelectTrigger className="w-52 mt-5">
                            <SelectValue placeholder="메시지 타입을 선택하세요"/>
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                <SelectItem value="push">푸쉬</SelectItem>
                                <SelectItem value="inapp">인앱</SelectItem>
                                <SelectItem value="email">이메일</SelectItem>
                                <SelectItem value="sms">문자</SelectItem>
                                <SelectItem value="alimtalk">알림톡</SelectItem>
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>
                {
                    messageType !== "" &&
                    <div className='flex flex-col mt-24'>
                        <div className="mb-5 title w-full">2. 전송 대상</div>
                        <div className='flex flex-col items-center'>
                            <RadioGroup className="mt-6 flex flex-row space-x-2" value={targetType}  onValueChange={(value)=>setTagetType(value)}>
                                <div className="flex flex-row items-center">
                                    <RadioGroupItem value="shared_building" id="shared_building" />
                                    <Label htmlFor="shared_building" className="ml-2">건물</Label>
                                </div>
                                <div className="flex flex-row items-center">
                                    <RadioGroupItem value="group" id="group" />
                                    <Label htmlFor="group" className="ml-2">그룹</Label>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <RadioGroupItem value="member_direct" id="member_direct" />
                                    <Label htmlFor="member_direct">회원 직접 선택</Label>
                                </div>
                            </RadioGroup>
                            {
                                targetType === "shared_building"
                                ? <Select value={selectedBuilding} onValueChange={(value)=>setSelectedBuilding(value)}>
                                    <SelectTrigger className="w-52 mt-6">
                                        <SelectValue placeholder="건물을 선택하세요"/>
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            {buildingNameList.map((building) => {
                                                return (
                                                    <SelectItem value={`${building.idx}`}>{building.sh_name}</SelectItem>
                                                )
                                            })}
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                                : targetType === "group"
                                && <Select value={selectedGroupId} onValueChange={(value)=>setSelectedGroupId(value)}>
                                    <SelectTrigger className="w-52 mt-6">
                                        <SelectValue placeholder="그룹을 선택하세요" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            {groupNameList.map((group) => {
                                                return (
                                                    <SelectItem value={`${group.id}`}>{group.name}</SelectItem>
                                                )
                                            })}
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                            }
                        </div>
                        {
                            targetType === "shared_building" && selectedBuilding !== "" && <BuildingGroupMembersTable targetType={targetType} selectedBuilding={selectedBuilding} selectedGroup={selectedGroupId}/> 
                            || targetType === "group" && selectedGroupId !== "" && <BuildingGroupMembersTable targetType={targetType} selectedBuilding={selectedBuilding} selectedGroup={selectedGroupId}/>
                        }
                        {
                            targetType === "member_direct"
                            && <div className="w-full mt-12 lg:flex lg:flex-row justify-between gap-5">
                                <div className="lg:w-1/2  flex flex-col w-full">
                                    <MemberDirectInputForm/>
                                    <GreenLabel className="mt-6">회원 검색 선택</GreenLabel>
                                    <UserSearchTable/>
                                </div>
                                <div className="lg:w-1/2 w-full">
                                    <AddedMembersTable/>
                                </div>
                            </div>
                        }
                    </div>
                }
                {
                    targetType !== "" && addedMembersList.length > 0 &&
                    <div className='flex flex-col items-center mt-24'>
                        <div className="mb-10 title w-full">3. 템플릿 선택</div>
                        <TemplateTable type={messageType} selectedTemplateId={selectedTemplateId} setSelectedTemplateId={setSelectedTemplateId} />
                    </div>
                }
                {
                    selectedTemplateId !== 0 &&
                    <>
                    <div className='flex flex-col items-center mt-24'>
                        <div className="mb-5 title w-full">4. 메시지 내용</div>
                        <TemplateLoader type={messageType} selectedTemplateId={selectedTemplateId}/>
                    </div>
                
                    <div className='flex flex-col mt-24'>
                        <div className='flex flex-col items-center'>
                            <div className="mb-5 title w-full">5. 메시지 내용 개별 설정</div>
                            <RadioGroup className="mt-6 flex flex-row space-x-2" value={messageContentType}  onValueChange={(value)=>onMessageContentTypeClicked(value)}>
                                <div className="flex flex-row items-center space-x-2">
                                    <RadioGroupItem value="same_content" id="same_content" />
                                    <Label htmlFor="same_content" className="ml-2">전체 동일 내용</Label>
                                </div>
                                <div className="flex flex-row items-center space-x-2">
                                    <RadioGroupItem value="different_content" id="different_content" />
                                    <Label htmlFor="different_content">개별 다른 내용</Label>
                                </div>
                            </RadioGroup>
                        </div>
                        {
                            messageContentType === "different_content"
                            && <div className='flex flex-col mt-8 ml-6'>
                                <GreenLabel>변수 별 내용 전송 설정</GreenLabel>
                                <div className='lg:w-1/2 w-full mt-4'>
                                    <DifferentMessagesVariableTable data={autoInputCustomVariableList}/>
                                </div>
                                <GreenLabel className='mt-12'>개별 전송 메시지 내용</GreenLabel>
                                <div className='w-full mt-4'><DifferentMessageTable data={differentMessageList} variableData={autoInputCustomVariableList}/></div>
                            </div>
                        }
                    </div>
                    </>
                }
                {
                   messageContentType !== "" &&
                    <div className='flex flex-col items-center mt-24'>
                        <Button variant="big" size="big" onClick={()=>onSendButtonClicked()}><Send className='w-5.5 h-5.5 mr-2'/>전송</Button>
                    </div>
                }
            </div>
            </SendMessageContext.Provider>
        }
		</>
	)
}

export default SendMessageTab
