import {Provider} from 'react-redux'
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react'
import { persistStore } from 'redux-persist';
import store from './redux/store'
import { BrowserRouter as Router } from 'react-router-dom';

import Root from './Root'

import './App.css';

let persistor = persistStore(store);

function App() {
  return (
    <Provider store={store}>
     <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Root />
        </Router>
     </PersistGate>
    </Provider>
  );
}

export default App;
