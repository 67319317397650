import {useState, useEffect, useContext} from 'react'
import { Button } from '../ui/button'

import { cn } from "@/components/lib/utils"
import { Input } from "../ui/input"
import { Label, GreenLabel } from "../ui/label"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select"
import { Textarea } from "../ui/textarea"
import { useToast } from "../ui/use-toast"
import EmailEditor from "react-email-editor";
import { MySpinner } from '../ui/spinner'
import { useQuery } from "@tanstack/react-query";
import Axios from "@/components/lib/axios";

const EmailTemplateEditor = ({inputSubject, setInputSubject, emailEditorRef, onReady}) => {
	const { toast } = useToast()
	const { isLoading: isLoadingAutoVariableNames, error, data: autoVariableNames } = useQuery({queryKey:["auto_variable_name"], queryFn: () =>
		Axios.get('/api_message/common/auto_variables').then((res) => {
			return res.data;
		})});

	function onSubmit(data) {
		toast({
			title: "You submitted the following values:",
			description: (
			<pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
				<code className="text-white">{JSON.stringify(data, null, 2)}</code>
			</pre>
			),
		})
	}

	const onClickAutoVariable = (variable) => {
		toast({
			title: `${variable}를 클립보드에 복사`,
			description: "원하는 곳에 붙여넣기 해 주세요",
		  });
		navigator.clipboard.writeText(variable)
	}
	
	return (
		<div className="w-full mt-12 flex flex-col">
			<GreenLabel className="">제목</GreenLabel>
			<Input className="mt-3 lg:w-1/2" value={inputSubject} onChange={(event)=>setInputSubject(event.target.value)}/>
			<GreenLabel className="mt-8">시스템 자동 입력 변수</GreenLabel>
			{
				isLoadingAutoVariableNames
				? <MySpinner/>
				: 	<div className="flex flex-col items-center justify-center mb-5">
						<div className="w-full p-4 rounded-md">
							<div className="flex flex-wrap justify-start gap-2">
								{
									autoVariableNames.map(variableName => {
										return <Button onClick={()=>onClickAutoVariable(variableName.variable_name)}>{variableName.variable_name}</Button>})
								}
							</div>
						</div>
					</div>
			}
			<EmailEditor
				editorId="editor_container"
				ref={emailEditorRef}
				onReady={onReady}
			/>
		</div>
	)
}

export default EmailTemplateEditor