import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { actionCreators as userActions } from "./redux/modules/user";
import axios from "axios";
import { setToken } from "./services/TokenService";
import Spinner from "./Spinner";


const Auth_login = (props) => {
    //const dispatch = useDispatch();

    let code = new URL(window.location.href).searchParams.get("code");

    /*React.useEffect(async () => {
        await dispatch(userActions.kakaoLogin(code));
    }, []);*/
    //console.log("1-"+code);
    let num = 0;
    React.useEffect(() => {
        
        async function fetchData() {
            //console.log("2-"+code);
            await axios({
                method: "POST",
                url: process.env.REACT_APP_KAKAO_AUTHCHK,
                mode: "cors",
            	credentials: "same-origin",
                data: {
                    code: code,
                    mode: "login"
                }
            })
            .then((response) => {
                //console.log(res.data.accessToken);
                //const ACCESS_TOKEN = res.data.accessToken;
                //토큰저장
                //setToken(res.data.accessToken);
                if(response.data.result === true){
                    const token_data = {
                        access_token: response.data.access_token, 
                        token_type: response.data.token_type,
                        exp: response.data.exp
                      }; 
                    if(response.data.adchk === 'Y'){
                        token_data["adchk"] = "Y";
                    }  
                    if(response.data.company){
                        token_data["company"] = response.data.company;
                    }  
                    setToken(JSON.stringify(token_data));
                    alert("로그인 했습니다.");
                    
                    if(response.data.adchk === 'Y'){
                        window.location.href="/admin/main";
                    }else if(response.data.adchk === 'N'){
                        window.location.href="/";
                    }
                }else{
                    if(response.data.msg === 'no member'){
                        alert("등록된 회원이 없습니다. 가입후 이용바랍니다.");
					    window.location.href="/signup";
                    }else{
                        //alert("로그인이 실패하였습니다.");
                       // window.location.href="/login";
                    }
                }
    
            }).catch((err) => {
                
                console.log("에러", err);
                window.alert("로그인에 실패하였습니다.");
                //history.replace("/"); //로그인실패 로그인화면으로 이동
    
            })
        }
        fetchData();
    }, []);

    return (
        <div><Spinner /></div>
    );

    /*return (
        <div>
            { code }
        </div>
    );*/
};

export default Auth_login;