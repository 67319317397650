import {React, useState, useEffect, createContext} from 'react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import ContentTable from './content_table'
import AutoSendCreator from './auto_send_creator'
import AutoSendEditor from './auto_send_editor'
import { MySpinner } from '@/components/ui/spinner'
import { Link, useParams, useNavigate, useSearchParams  } from "react-router-dom";

const AutoSendSettingsTab = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const createMode = searchParams.get('mode') == "create";
    const editMode = searchParams.get('mode') == "edit";
    const autoSendId = searchParams.get('id');

	return (
		<>
        {
            createMode ? <AutoSendCreator/> 
                        : editMode ? <AutoSendEditor autoSendId={autoSendId}/> : <ContentTable/>
        }
		</>
	)
}

export default AutoSendSettingsTab
