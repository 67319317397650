import React from 'react'
import cn from 'classnames'

// css
import Style from './index.module.css'
import House from '@/assets/image/ico/ico-house.svg'

const Index = () => {
	return (
		<div className={Style.wrapper}>

            <div className={Style.subTitle}>
				<img src={House} />
				<span>호수별 관리</span>
			</div>

			<div className={Style.scrolls}>
				<table className={cn(Style.webList)}>
					<colgroup>
						<col width="130" />
						<col width="104" />
						<col width="104" />
						<col width="60" />
						<col width="250" />
						<col width="89" />
						<col width="80" />
						<col width="80" />
						<col width="80" />
						<col width="120" />
                        <col width="120" />
						<col width="" />
					</colgroup>
					<tr>
						<th>호수</th>
						<th>이름</th>
						<th>나이/성별</th>
						<th>상태</th>
						<th>입주일-만료일</th>
                        <th>거주기간</th>
						<th>보증금</th>
						<th>월세</th>
						<th>관리비</th>
						<th>공간크기</th>
						<th>공간옵션</th>
						<th>&nbsp;</th>
					</tr>
					<tr>
						<td>307</td>
						<td>김용명</td>
						<td>32/남</td>
						<td>&nbsp;</td>
						<td>2023.05.27 - 2024.05.56</td>
                        <td>67d</td>
						<td>3,000</td>
						<td>50</td>
						<td>5</td>
						<td>17㎡</td>
						<td>(추후정리)</td>
						<td><a href="#">더보기</a></td>
					</tr>
					<tr>
                        <td>307</td>
                        <td>김용명</td>
                        <td>32/남</td>
                        <td>&nbsp;</td>
                        <td>2023.05.27 - 2024.05.56</td>
                        <td>67d</td>
                        <td>3,000</td>
                        <td>50</td>
                        <td>5</td>
                        <td>17㎡</td>
                        <td>(추후정리)</td>
                        <td><a href="#">더보기</a></td>
					</tr>
					<tr>
                        <td>307</td>
                        <td>김용명</td>
                        <td>32/남</td>
                        <td>&nbsp;</td>
                        <td>2023.05.27 - 2024.05.56</td>
                        <td>67d</td>
                        <td>3,000</td>
                        <td>50</td>
                        <td>5</td>
                        <td>17㎡</td>
                        <td>(추후정리)</td>
                        <td><a href="#">더보기</a></td>
					</tr>
				</table>

				<ul className={cn(Style.mobList)}>
					<li>
						<table>
							<colgroup>
								<col width="120" />
								<col width="" />
							</colgroup>
							<tr>
								<th className={Style.al}>호수</th>
								<td className={Style.al}>307</td>
							</tr>
							<tr>
								<th className={Style.al}>이름</th>
								<td className={Style.al}>김용명</td>
							</tr>
							<tr>
								<th className={Style.al}>나이/성별</th>
								<td className={Style.al}>32/남</td>
							</tr>
							<tr>
								<th className={Style.al}>상태	</th>
								<td className={Style.al}>&nbsp;</td>
							</tr>
							<tr>
								<th className={Style.al}>입주일-만료일</th>
								<td className={Style.al}>2023.05.27 - 2024.05.56</td>
							</tr>
							<tr>
								<th className={Style.al}>거주기간</th>
								<td className={Style.al}>67d</td>
							</tr>
							<tr>
								<th className={Style.al}>보증금</th>
								<td className={Style.al}>3,000</td>
							</tr>
							<tr>
								<th className={Style.al}>월세</th>
								<td className={Style.al}>50</td>
							</tr>
							<tr>
								<th className={Style.al}>관리비</th>
								<td className={Style.al}>5</td>
							</tr>
							<tr>
								<th className={Style.al}>공간크기</th>
								<td className={Style.al}>17㎡</td>
							</tr>
							<tr>
								<th className={Style.al}>공간옵션</th>
								<td className={Style.al}>(추후정리)</td>
							</tr>
							<tr>
								<th className={Style.al}>&nbsp;</th>
								<td className={Style.al}><a href="#">더보기</a></td>
							</tr>
						</table>
					</li>
					<li>
						<table>
							<colgroup>
								<col width="120" />
								<col width="" />
							</colgroup>
							<tr>
								<th className={Style.al}>호수</th>
								<td className={Style.al}>307</td>
							</tr>
							<tr>
								<th className={Style.al}>이름</th>
								<td className={Style.al}>김용명</td>
							</tr>
							<tr>
								<th className={Style.al}>나이/성별</th>
								<td className={Style.al}>32/남</td>
							</tr>
							<tr>
								<th className={Style.al}>상태	</th>
								<td className={Style.al}>&nbsp;</td>
							</tr>
							<tr>
								<th className={Style.al}>입주일-만료일</th>
								<td className={Style.al}>2023.05.27 - 2024.05.56</td>
							</tr>
							<tr>
								<th className={Style.al}>거주기간</th>
								<td className={Style.al}>67d</td>
							</tr>
							<tr>
								<th className={Style.al}>보증금</th>
								<td className={Style.al}>3,000</td>
							</tr>
							<tr>
								<th className={Style.al}>월세</th>
								<td className={Style.al}>50</td>
							</tr>
							<tr>
								<th className={Style.al}>관리비</th>
								<td className={Style.al}>5</td>
							</tr>
							<tr>
								<th className={Style.al}>공간크기</th>
								<td className={Style.al}>17㎡</td>
							</tr>
							<tr>
								<th className={Style.al}>공간옵션</th>
								<td className={Style.al}>(추후정리)</td>
							</tr>
							<tr>
								<th className={Style.al}>&nbsp;</th>
								<td className={Style.al}><a href="#">더보기</a></td>
							</tr>
						</table>
					</li>
				</ul>
			</div>

		</div>
	)
}

export default Index
