import {React, useState, useEffect,} from 'react'
import { useNavigate } from "react-router-dom"
import axios from "axios";
import cn from 'classnames'
import CheckBox from '../../components/checkBox'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import styled from "@emotion/styled";

// css
import Style from './index.module.css'
import ProductImg from '@/assets/image/etc/etc-product01.png'
import Bolpen from '@/assets/image/ico/bolpen.png'
import SpaceHome from '@/assets/image/ico/ico-adm.svg'
import AdminIcon from '@/assets/image/ico/ico-access-list01.svg'
import ManagerIcon from '@/assets/image/ico/ico-access-list02.svg'
import UserIcon from '@/assets/image/ico/ico-access-list03.svg'
import FacilityIcon from '@/assets/image/ico/ico-access-list03.svg'
import PagePrev from '@/assets/image/ico/ico-page-prev.svg'
import PageNext from '@/assets/image/ico/ico-page-next.svg'

const Index = () => {
	const [memberList, setMemberList] = useState([]);
	const [pageList, setPageList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState({
		page: 1,
		sck: '',
		scv: '',
	  });

	  //console.log(mtype);
	
	const [pageNum, setPageNum] = useState("1");
	const [totalPageNum, setTotalPageNum] = useState("1");

	const navigate = useNavigate();

	const [isPopup, setIsPopup] = useState(true);
	const handleClose = () => {
		setIsPopup(false);
	}

	const teamReg = () => {
		navigate("/admin/companyReg");
	}

	const admView = (e) => {
		navigate("/admin/companyview/"+e);
	}

	const getMemberList = async (e) => {
		if (pageNum === e) return;

		if(e == undefined){
			e = "";	
		}
		const queryString = "/"+e;

		//console.log(queryString)
		
		axios({
			method: 'post',
			url: process.env.REACT_APP_API_CPNMEMBERLIST,
			mode: "cors",
			credentials: "same-origin",
			data:{
				page: e,
				searchword: search.scv,
				searchkey: search.sck
			},
		}).then((res) => {
			setMemberList(res.data.list); 

			const pngn = res.data.paging;
			const curPage = pngn.page;
			const curPage2 = pngn.cur_page;
			const pageRow = pngn.page_rows;
			const totalCnt = pngn.total_cnt;
			const totalPageCnt = pngn.num_page_cnt;
			const startPage = pngn.startpage;
			const endPage = pngn.endpage;
			const base_page = 1;

			const tmpPages = [];
			for (let i = startPage - 1; i <= endPage; i++) {
				if (i >= base_page){
					tmpPages.push(i);
				}
			}
			setPageList(tmpPages);
			setTotalPageNum(totalPageCnt);
			setLoading(false);
		}).catch((err) => {	
			console.log("에러", err);
		})
	}

	//전체선택 부분 시작
	const [checkItems, setCheckItems] = useState([])

	const checkItemHandler = (id, isChecked) => {
		if (isChecked) {
			setCheckItems((prev) => [...prev, id])
		} else {
		//console.log('not')
			setCheckItems(checkItems.filter((item) => item !== id))
		}
		//console.log(checkItems)
	}

	const allCheckedHandler = (e) => {
		if (e.target.checked) { 
			setCheckItems(memberList.map((item) => item.seq))
		} else {
			setCheckItems([]);
		}
		//console.log(`allCheck = `, e.target.checked)
	}

	const selectDataDelete = (e) => {
		if(checkItems.length == 0){
			alert('삭제하실 데이터를 선택해주세요.');
		}else{
			//console.log(checkItems.length);

			if(window.confirm("정말로 삭제 하시겠습니까?")){
				const formData = new FormData();
				formData.append('chkidx',checkItems)
				axios({
					method:'post',
					url:process.env.REACT_APP_API_TEAMMEMBERSELECTDELETE,
					mode: "cors",
					credentials: "same-origin",
					headers: {
						"Content-Type": "multipart/form-data",
					},
					data: formData,
				})
					.then((result)=>{
						if(result.data.code == 200){
							alert("삭제 되었습니다.");
							window.location.href="/admin/teamList";
						}else{
							console.log(result)
						}
				})
					.catch((error)=>{console.log('요청실패')
					console.log(error)  
				})
			}
		}
	}
	//전체선택 부분 끝

	const onBtnClick = (event) => {
		let value = event.target.getAttribute('data');
		//console.log(value);
		/*setSearch({
		  ...search,
		  page: value,
		});*/
		setPageNum(value);
		//console.log(pageNum);
		getMemberList(value);
	};

	const onBtnClick2 = (e) => {
		let val = e.totalPageNum;
		//console.log(val);
		/*setSearch({
		  ...search,
		  page: value,
		});*/
		setPageNum(val);
		//console.log(pageNum);
		getMemberList(val);
	};


	useEffect(() => {
		setLoading(true);
		getMemberList();
	}, []);

	return (
		<div className={Style.wrapper}>

			<section className={Style.spacedHeader}>

				<div className={Style.pageTitle}>
					<img src={SpaceHome} />
					<span>기업및 위탁 계정 목록</span>
					<button type={'button'} className={Style.sideBtn} onClick={teamReg}>계정 생성</button>
				</div>

			</section>


			<div className={cn(Style.scrolls, Style.type01)}>
				<table className={cn(Style.webList)}>
					<colgroup>
						<col width="70" />
						<col width="90" />
						<col width="150" />
						<col width="160" />
						<col width="180" />
						<col width="180" />
						<col width="180" />
						<col width="150" />
						<col width="80" />
					</colgroup>
					<tr>
						<th><input type="checkbox" onChange={allCheckedHandler} 
          checked={checkItems.length === memberList.length ? true : false} /></th>
						<th>계정타입</th>
						<th>기업명</th>
						<th>담당자명</th>
						<th>담당자 부서/직책</th>
						<th>연락처</th>
						<th>이메일</th>
						<th>생성일</th>
						<th>수정</th>
					</tr>

					{memberList.map((list) => (
					<tr key={list.num}>
						{list.seq === "3" ? 
							<td><CheckBox key={list.seq} id={list.seq} disabled={"disabled"} checkItemHandler={checkItemHandler}
							checked={checkItems.includes(list.seq) ? true : false} /></td>
							:
							<td><CheckBox key={list.seq} id={list.seq} checkItemHandler={checkItemHandler}
            				checked={checkItems.includes(list.seq) ? true : false} /></td>
						}
						<td>{list.gubun}</td>
						<td onClick={() => admView(list.seq)} style={{cursor:'pointer'}}>{list.company}</td>
						<td>{list.damdangja}</td>
						<td>{list.buseo}/{list.pos}</td>
						<td>{list.tel}</td>
						<td>{list.email}</td>
						<td>{list.regdate}</td>
						<td><img src={Bolpen}></img></td>
					</tr>
					))}
					
				</table>
				
				<div className={Style.etc}>
					<button className={Style.btn} type={'button'} onClick={selectDataDelete}>선택 계정 정지</button>
				</div>

				<div className={Style.paging}>
					<button type="button" onClick={() => onBtnClick2('1')} value="1"><img src={PagePrev} /></button>
					<ul>
					
					{pageList.map((page, index) => (
						{...pageNum == page ? 
						<li key={index}><a href="#" className={Style.on} onClick={onBtnClick} data={page}>{page}</a></li>
						:
						<li key={index}><a href="#" onClick={onBtnClick} data={page}>{page}</a></li>
						}
					))}
					
					</ul>
					<button type="button" onClick={() => onBtnClick2({totalPageNum})} value={totalPageNum}><img src={PageNext} /></button>
				</div>
				
			</div>



		</div>
	)
}

export default Index
