import * as React from "react"
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { ArrowUpDown, ChevronDown, MoreHorizontal } from "lucide-react"

import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"

import { Input } from "@/components/ui/input"
import { Label, GreenLabel } from "@/components/ui/label"
import { SendMessageContext } from '../index'
import { useQuery, useMutation } from "@tanstack/react-query";
import Axios from "@/components/lib/axios";

export function MemberDirectInputForm() {
  const { addedMembersList, setAddedMembersList } = React.useContext(SendMessageContext);
  const getMemberObject = (user) => {
    return ({
      id: null,
      building_name: null,
      room_number: null,
      name: inputName, 
      phone_number: inputPhoneNumber,
      email: inputEmail
    })
  }
  const clickAddButton = () => {
    setAddedMembersList((prev)=>[...prev, getMemberObject()])
  }
  const [inputName, setInputName] = React.useState("")
  const [inputPhoneNumber, setInputPhoneNumber] = React.useState("")
  const [inputEmail, setInputEmail] = React.useState("")
  
  return (
    <>
        <GreenLabel>비회원 직접 입력</GreenLabel>
        <div className='flex flex-row mt-3 items-center'>
            <Input className="basis-[20%] mr-3" placeholder="이름" value={inputName} onChange={(event)=>setInputName(event.target.value)}/>
            <Input className="basis-[30%] mr-3" placeholder="전화번호" value={inputPhoneNumber} onChange={(event)=>setInputPhoneNumber(event.target.value)}/>
            <Input className="basis-[40%] mr-3" placeholder="이메일" value={inputEmail} onChange={(event)=>setInputEmail(event.target.value)}/>
            <Button className="basis-[10%]" onClick={()=>clickAddButton()}>추가</Button>
        </div>
    </>
  )
}
