import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"

import { Send, Pencil, Trash, Circle, X } from "lucide-react"
import { getDateString } from "@/components/lib/date"
import { getNumberString } from "@/components/lib/number"

export const getColumns = (onUserInfoButtonClicked, isUtility) => {
  var columns;

  if(isUtility){
    columns = [
      {
        accessorKey: "uc_hosu",
        header: () => <div className="text-left">호수</div>,
        cell: ({ row }) => <div>{row.getValue("uc_hosu")}</div>,
      },
      {
        accessorKey: "uc_cost_kibon",
        header: () => <div className="text-center">기본 관리비</div>,
        cell: ({ row }) => <div className="text-center">{getNumberString(row.getValue("uc_cost_kibon"))}</div>,
      },
      {
        accessorKey: "uc_cost_electric",
        header: () => <div className="text-center">전기비용</div>,
        cell: ({ row }) => <div className="text-center">{`${getNumberString(row.getValue("uc_cost_electric"))} / ${getNumberString(row.getValue("uc_cost_electric_etc"))}`}</div>,
      },
      {
        accessorKey: "uc_cost_sudo",
        header: () => <div className="text-center">수도비용</div>,
        cell: ({ row }) => <div className="text-center">{`${getNumberString(row.getValue("uc_cost_sudo"))} / ${getNumberString(row.getValue("uc_cost_sudo_etc"))}`}</div>,
      },
      {
        accessorKey: "uc_cost_gas",
        header: () => <div className="text-center">가스비용</div>,
        cell: ({ row }) => <div className="text-center">{`${getNumberString(row.getValue("uc_cost_gas"))} / ${getNumberString(row.getValue("uc_cost_gas_etc"))}`}</div>,
      },
      {
        accessorKey: "uc_cost_etc1",
        header: () => <div className="text-center">기타비용</div>,
        cell: ({ row }) => <div className="text-center">{`${getNumberString(row.getValue("uc_cost_etc1"))} / ${getNumberString(row.getValue("uc_cost_etc1_t"))}`}</div>,
      },
      {
        id: "go_to_user_info",
        enableHiding: false,
        cell: ({ row }) => {
          const rentee = row.original
    
          return (
            <div className="flex justify-end align-middle">
              <Button variant="ghost" onClick={()=>{onUserInfoButtonClicked(rentee.member_seq)}}>
                유저 정보
              </Button>
           </div>
          )
        },
      },
    ]
  }else{
    columns = [
      {
        accessorKey: "hs_hosu",
        header: () => <div className="text-left">호수</div>,
        cell: ({ row }) => <div>{row.getValue("hs_hosu")}</div>,
      },
      {
        accessorKey: "hs_in_date",
        header: () => <div className="text-center">입주일</div>,
        cell: ({ row }) => <div className="text-center">{getDateString(row.getValue("hs_in_date"))}</div>,
      },
      {
        accessorKey: "hs_out_date",
        header: () => <div className="text-center">만료일</div>,
        cell: ({ row }) => <div className="text-center">{getDateString(row.getValue("hs_out_date"))}</div>,
      },
      {
        accessorKey: "hs_deposit",
        header: () => <div className="text-center">보증금</div>,
        cell: ({ row }) => <div className="text-center">{getNumberString(row.getValue("hs_deposit"))}</div>,
      },
      {
        accessorKey: "hs_rent_fee",
        header: () => <div className="text-center">월세</div>,
        cell: ({ row }) => <div className="text-center">{getNumberString(row.getValue("hs_rent_fee"))}</div>,
      },
      {
        accessorKey: "hs_paymentdate",
        header: () => <div className="text-center">납부일</div>,
        cell: ({ row }) => <div className="text-center">{row.getValue("hs_paymentdate")}</div>,
      },
      {
        id: "go_to_user_info",
        enableHiding: false,
        cell: ({ row }) => {
          const rentee = row.original
    
          return (
            <div className="flex justify-end align-middle">
              <Button variant="ghost" onClick={()=>{onUserInfoButtonClicked(rentee.mem_seq)}}>
                유저 정보
              </Button>
           </div>
          )
        },
      },
    ]
  }
  

  return columns;
}