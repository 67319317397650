import {React, useState, useEffect, createContext} from 'react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import ContentTable from './content_table'
import ContentViwer from './content_viewer'
import { Link, useParams, useNavigate, useSearchParams  } from "react-router-dom";
import { MySpinner } from '@/components/ui/spinner'

const SendHistoryTab = ({innerTab}) => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const viewer = searchParams.get('mode') == "view";
    const id = searchParams.get('id');

    const handleTabChange = (value) => {
        // setActiveTab(value);
        // console.log("activeTab : " + activeTab);
		// <Link to={`../${value}`} />
		const url = `/admin/messages/sendHistory/${value}`
		navigate(url);
      };
    
	return (
		<>
        { loading
            ? <MySpinner/>
            :
			<Tabs className="w-full" onValueChange={handleTabChange} value={innerTab}>
                <TabsList className="flex content-center">
                    <TabsTrigger value="push">푸쉬</TabsTrigger>
                    <TabsTrigger value="inapp">인앱</TabsTrigger>
                    <TabsTrigger value="email">이메일</TabsTrigger>
                    <TabsTrigger value="sms">문자</TabsTrigger>
                    <TabsTrigger value="alimtalk">알림톡</TabsTrigger>
                </TabsList>
                <TabsContent value="push">
                    {
                        viewer ? <ContentViwer type="push" id={id}/> : <ContentTable type="push"/> 
                    }
                </TabsContent>
                <TabsContent value="inapp">
                    {
                        viewer ? <ContentViwer type="inapp" id={id}/> : <ContentTable type="inapp"/>
                    }
                </TabsContent>
                <TabsContent value="email">
                    {
                        viewer ? <ContentViwer type="email" id={id}/> : <ContentTable type="email"/>
                    }
                </TabsContent>
                <TabsContent value="sms">
                    {
                        viewer ? <ContentViwer type="sms" id={id}/> : <ContentTable type="sms"/>
                    }
                </TabsContent>
                <TabsContent value="alimtalk">
                    {
                        viewer ? <ContentViwer type="alimtalk" id={id}/> : <ContentTable type="alimtalk"/>
                    }
                </TabsContent>
            </Tabs>
        }
		</>
	)
}

export default SendHistoryTab
