import {React, useState, useRef} from 'react'
import axios from "axios";
import cn from 'classnames'
import styled from 'styled-components'
import { getToken } from "../../services/TokenService";

//preview
import { useDropzone } from 'react-dropzone';

//daum post
import DaumPostcode from 'react-daum-postcode';
import Post from '../../components/post';

// css
import Style from './index.module.css'
import ProductImg from '@/assets/image/etc/etc-product01.png'
import MainImg from '@/assets/image/etc/etc-main-list01.png'
import SpaceHome from '@/assets/image/ico/ico-home01.svg'
import MnList01 from '@/assets/image/ico/ico-mn-list01.svg'
import MnList02 from '@/assets/image/ico/ico-mn-list02.svg'
import MnList03 from '@/assets/image/ico/ico-mn-list03.svg'
import MnList04 from '@/assets/image/ico/ico-mn-list04.svg'
import MnList05 from '@/assets/image/ico/ico-mn-list05.svg'
import MnList06 from '@/assets/image/ico/ico-mn-list06.svg'
import Cmm from '@/assets/image/ico/ico-cmm.svg'
import Notice from '@/assets/image/ico/ico-noti.svg'
import More from '@/assets/image/ico/ico-arr-more.svg'
import Profile from "@/assets/image/etc/etc-product01.png";
import SearchBtn from "@/assets/image/ico/ico-search.svg";
import RemoveIco from "@/assets/image/ico/ico-remove-white.svg";
import popClose from '@/assets/image/ico/ico-pop-close.svg'

import Dialog from './dialog.module.css'



const Index = () => {
	const [isPopup, setIsPopup] = useState(false);
	const [mngInput, setMngInput] = useState("");
	const [mngName, setMngName] = useState("");
	const [housename, setHousename] = useState("");
	const [houseid, setHouseid] = useState("");
	const [floor, setFloor] = useState("");
	const [hosu, setHosu] = useState("");
	const [addr, setAddr] = useState("");
	const [addrdetail, setAddrdetail] = useState("");
	const [mngList, setMngList] = useState([]);
	const [selMng, setSelMng] = useState({
		username: '',
		id: '',
		idx: ''
	  });
	const { username, id, idx } = selMng;
	const [users, setUsers] = useState([]);
	const [usersid, setUsersId] = useState([]);
	//preview
	const [preview, setPreview] = useState("");
	const [image, setImage] = useState(null);
	const {getRootProps, getInputProps} = useDropzone({onDrop});

	const [image2, setImage2] = useState(null);

	function onDrop(acceptedFiles){
		const reader = new FileReader();
		const file = acceptedFiles;

		if(file){
			reader.readAsDataURL(file[0]);
			setImage(file[0]);
		}

		reader.onload = (e) => {
			setPreview(reader.result);
			//document.getElementsByName("imageurl")[0].value = '';

			/*var file_up = new Image();
            file_up.src = reader.result;
            file_up.width = 500; // a fake resize
			file_up.className = {Imgpreview}
            document.getElementById("imagepreview").appendChild(file_up);*/
		};
	}

	const namefocusRef = useRef(null);
	const housenamefocusRef = useRef(null);
	const floorfocusRef = useRef(null);
	const hosufocusRef = useRef(null);
	const addrfocusRef = useRef(null);
	
	/** Daum post */
	const [enroll_company, setEnroll_company] = useState({
		address:'',
	});
	
	const [popup, setPopup] = useState(false);
	
	const handleInput = (e) => {
		setEnroll_company({
			...enroll_company,
			[e.target.name]:e.target.value,
		})
	}
	
	const handleComplete = (data) => {
		setPopup(!popup);
	}
	const handlepostClose = () => {
		setPopup(false);
	}
	/** Daum post */
	
	const handleClose = () => {
		setIsPopup(false);
	}

	const handleOpen = () => {
		setIsPopup(true);
	}
	
	const nextId = useRef(0);

	const onCreate = (e) => {
		if(e !== null){
			const selText = e.split('/');
			const t1 = selText[0];
			const t2 = selText[1];
			//console.log(selText);
			const userb = {
				idx: nextId.current,
				username: t1,
				id: t2,
			  };
						
			  setUsers(users.concat(userb));
			  setUsersId(usersid.concat(t2));
			  
			  /*if(users === ""){
				setUsers(users.concat(userb));
			  }
			  users.map(user => {
				console.log(user.id +"/"+ t2);
				if(user.id !== t2){
					setUsers(users.concat(userb));
				}
			  }
			  )*/

			setSelMng({
				username: '',
				id: ''
			});	

			//console.log(users);
			nextId.current += 1;
		}
		/*setSelMng({
		  username: '',
		  id: '',
		});*/
	  };

	  const onRemove = id => {
		setUsers(users.filter(user => user.id !== id));
		setUsersId(usersid.filter(user1 => user1 !== id));
	  };

	const managerSearch = () => {
		if(mngName === ""){
			alert("매니저 이름이나 이메일주소를 입력해주세요.");
			namefocusRef.current.focus();
			return;
		}else{
			const token = getToken();
			if(token !== null){
				const local_token = JSON.parse(token);
				axios({
					method: 'post',
					url: process.env.REACT_APP_API_MANAGERSEARCH,
					data: {
						searchword: mngName,
						company: local_token.company
					}
				}).then((res) => {
					if(res.data.cnt > 0){
						setMngList(res.data.list); 
					}else{
						console.log("검색결과 없습니다.");
						//alert("이미 등록된 활동명입니다. 다시 입력해주세요.");
					}
				}).catch((err) => {	
					console.log("에러", err);
				})
			}
		}
	}

	const onReg = () => {
		const token = getToken();
		
		if(token !== null){
			const local_token = JSON.parse(token);	
				
			if(housename === ""){
				alert("주택명을 입력해주세요.");
				housenamefocusRef.current.focus();
				return;
			}
			
			if(enroll_company.address === ""){
				alert("주소를 입력해주세요.");
				addrfocusRef.current.focus();
				return;
			}

			const formData = new FormData();
			formData.append('attach[]',image)
			//formData.append('attach[]',image2)
			formData.append('housename',housename)
			formData.append('houseid',houseid)
			formData.append('floor',floor)
			formData.append('hosu',hosu)
			formData.append('addr',enroll_company.address)
			formData.append('addrdetail',addrdetail)
			formData.append('manager',usersid)
			formData.append('company', local_token.company)

			axios({
				method:'post',
				url:process.env.REACT_APP_API_MANAGERHOUSEINPUT,
				mode: "cors",
				headers: {
					"Content-Type": "multipart/form-data", // Content-Type을 반드시 이렇게 하여야 한다.
				},
				data: formData,
			})
				.then((result)=>{
					if(result.data.code == 200){
						alert("등록 되었습니다.");
						window.location.href="/admin/main";
					}else{
						console.log(result)
					}
			})
				.catch((error)=>{console.log('요청실패')
				console.log(error)  
			})
		}
	}


	//preview
	/*const handleChange = (e) => {
		const {name, value} = e.target;
		setValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}))

		if(e.target.name === 'imageurl'){
			setPreview(e.target.value)
		}
	}*/

	return (
		<div className={Style.wrapper}>

			<section className={Style.spacedHeader}>

				<div className={Style.pageTitle}>
					<img src={SpaceHome} />
					<span>주택계정 정보등록</span>
				</div>

			</section>

			<section className={Style.registerWrap}>

				<div className={Style.photoList}>
					<div className={Style.photoUpload} {...getRootProps()}>
						<label id="imagepreview">
							{/*}
							<input type="file" />
							{*/}
							{preview ? 
								<img src={preview} style={{width: '100%'}} />
								:""
							}
						
						</label>
						<input type="file" {...getRootProps()} multiple={false} name="imageurl" />
					</div>
				</div>

				<div className={Style.formGroup}>
					<div className={Style.formRows}>
						<div className={Style.label}>주택명</div>
						<div className={Style.formItem}>
							<input type={'text'} name="house_name" className={Style.inp} id="house_name" ref={housenamefocusRef} value={housename} onChange={(e) => setHousename(e.target.value)} />
						</div>
					</div>
					<div className={Style.formRows}>
						<div className={Style.label}>주택ID</div>
						<div className={Style.formItem}>
							<input type="text" name="houseid" className={Style.inp} id="houseid" value={houseid} onChange={(e) => setHouseid(e.target.value)} />
						</div>
					</div>
						
					<div className={Style.formRows}>
						<div className={Style.label}>주소(기본)</div>
						<div className={Style.formItem}>
							<input type={'text'} name="addr" className={Style.inp} id="addr" ref={addrfocusRef} value={enroll_company.address} />
							<button style={{padding: '8px', marginLeft: '10px', borderRadius: '3px', border: '0', background: '#40EFC1', color: '#000'}} onClick={handleComplete}>우편번호 찾기</button>
						</div>
					</div>
					<div className={Style.formRows}>
						<div className={Style.label}>주소(상세)</div>
						<div className={Style.formItem}>
							<input type={'text'} name="addr_detail" className={Style.inp} id="addr_detail" value={addrdetail} onChange={(e) => setAddrdetail(e.target.value)} />
						</div>
					</div>
					<div className={Style.formRows}>
						<div className={Style.label}>층수</div>
						<div className={Style.formItem}>
							<input type={'number'} name="floor" className={Style.inp} id="floor" value={floor} onChange={(e) => setFloor(e.target.value)} />
						</div>

						<div className={Style.label}>거주호수</div>
						<div className={Style.formItem}>
							<input type={'number'} name="hosu" className={Style.inp} id="hosu" value={hosu} onChange={(e) => setHosu(e.target.value)} />
						</div>
					</div>
				</div>

				<div className={Style.formGroup}>
					<div className={Style.formRows}>
						<div className={Style.label}>매니저 검색</div>
						<div className={Style.formItem}>
							<div className={Style.searchForm}>
								<input type={'text'} className={Style.inp} onClick={handleOpen} />
								<button type={'button'}><img src={SearchBtn} /></button>
							</div>
						</div>
					</div>
					<div className={Style.formRows}>
						<div className={Style.label}>선택매니저</div>
						<div className={Style.formItem}>
							<ul>
								{/*}
								<li>
									<span>김민석</span>
									<a><img src={RemoveIco} /></a>
								</li>
								<li>
									<span>김민석1</span>
									<a><img src={RemoveIco} /></a>
								</li>
								{*/}

								{users.map((list) => (
									<li key={list.idx}>
										<span>{list.username}</span>
										<a onClick={() => onRemove(list.id)}><img src={RemoveIco} /></a>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>

				<div className={Style.btnGroup}>
					<button type="button" className={Style.btnCancel}>취소</button>
					<button type="button" className={Style.btnConfirm} onClick={onReg}>등록</button>
				</div>
			</section>

			<div className={cn(Dialog.dialog, `${isPopup?Dialog.on:''}`)}>
			</div>

			<div className={cn(Dialog.dialog, `${popup?Dialog.on:''}`)}>
			</div>
			
			{/*DAUM POST POPUP*/}
			{popup && 
			<div className={cn(Style.popup, `${popup?Style.on:''}`)}>
				<button type="button" className={Style.popClose} onClick={handlepostClose}><img src={popClose} /></button>
				<Post company={enroll_company} setcompany={setEnroll_company} popclose={handlepostClose}></Post>
			</div>}

			{/*검색팝업.*/}
			<div className={cn(Style.popup, `${isPopup?Style.on:''}`)}>
				<button type="button" className={Style.popClose} onClick={handleClose}><img src={popClose} /></button>
				<div className={Style.searchForm}>
					<input type="text" className={Style.inp} value={mngName} ref={namefocusRef} placeholder={'이름/이메일 검색'} onChange={(e) => setMngName(e.target.value)} />
					<button type="button" onClick={managerSearch}>검색</button>
				</div>

				<div className={Style.tblWrap}>
					<table>
						<tr>
							<th>이름</th>
							<th>나이/성별</th>
							<th>이메일</th>
						</tr>
						{mngList.map((list) => (
						<tr key={list.seq} style={{cursor:'pointer'}} data={list.name+"/"+list.seq} onClick={(e) => onCreate(e.currentTarget.getAttribute('data'))}>
							<td>{list.name}</td>
							<td>{list.age}/{list.sex}</td>
							<td>{list.email}</td>
						</tr>
						))}	
					</table>
				</div>
			</div>




		</div>
	)
}

const Imgpreview = styled.div`
	width:100%;
`

export default Index
