import {React} from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import FacilityList from './reqList/facilityList'

const ReqList = () => {
	const queryClient = new QueryClient()

	return (
		<QueryClientProvider client={queryClient}>
			<FacilityList/>
		</QueryClientProvider>
	)
}

export default ReqList