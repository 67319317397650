import {React, useState, useEffect, createContext} from 'react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import ContentTable from './content_table'
import TemplateCreator from './template_creator'
import TemplateEditor from './template_editor'
import { Link, useParams, useNavigate, useSearchParams  } from "react-router-dom";

const TemplateSettingsTab = ({innerTab}) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const createMode = searchParams.get('mode') == "create";
    const editMode = searchParams.get('mode') == "edit";
    const templateId = searchParams.get('id');

    const handleTabChange = (value) => {
	    const url = `/admin/messages/templateSettings/${value}`
	    navigate(url);
    };
    
	return (
        <Tabs className="w-full" onValueChange={handleTabChange} value={innerTab}>
            <TabsList className="flex content-center">
                <TabsTrigger value="push">푸쉬</TabsTrigger>
                <TabsTrigger value="inapp">인앱</TabsTrigger>
                <TabsTrigger value="email">이메일</TabsTrigger>
                <TabsTrigger value="sms">문자</TabsTrigger>
                <TabsTrigger value="alimtalk">알림톡</TabsTrigger>
            </TabsList>
            <TabsContent value="push">
                {
                    createMode ? <TemplateCreator type="push"/> 
                                : editMode ? <TemplateEditor type="push" templateId={templateId}/>
                                : <ContentTable type="push"/>
                }
            </TabsContent>
            <TabsContent value="inapp">
                {
                    createMode ? <TemplateCreator type="inapp"/> 
                                : editMode ? <TemplateEditor type="inapp" templateId={templateId}/>
                                : <ContentTable type="inapp"/>
                }
            </TabsContent>
            <TabsContent value="email">
                {
                    createMode ? <TemplateCreator type="email"/> 
                                : editMode ? <TemplateEditor type="email" templateId={templateId}/>
                                : <ContentTable type="email"/>
                }
            </TabsContent>
            <TabsContent value="sms">
                {
                    createMode ? <TemplateCreator type="sms"/> 
                                : editMode ? <TemplateEditor type="sms" templateId={templateId}/>
                                : <ContentTable type="sms"/>
                }
            </TabsContent>
            <TabsContent value="alimtalk">
                {
                    createMode ? <TemplateCreator type="alimtalk"/> 
                                : editMode ? <TemplateEditor type="alimtalk" templateId={templateId}/>
                                : <ContentTable type="alimtalk"/>
                }
            </TabsContent>
        </Tabs>
	)
}

export default TemplateSettingsTab
