import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"

import { Send, Pencil, Trash, Circle, X } from "lucide-react"
import { Link, useParams, useNavigate, useSearchParams  } from "react-router-dom";

export const getColumns = (type, onSendButtonClicked, onEditButtonClicked, onDeleteButtonClicked) => {
  var columns;

  type !== "alimtalk"
  ?
  columns = [
    {
      accessorKey: "subject",
      header: () => <div className="text-left">템플릿 이름</div>,
      cell: ({ row }) => <div>{row.getValue("subject")}</div>,
    },
    {
      id: "actions",
      header: () => <div className="flex justify-end align-middle">
                        <div className="w-16">전송</div>
                        <div className="w-16">편집</div>
                        <div className="w-16">삭제</div>
                    </div>,
      enableHiding: false,
      cell: ({ row }) => {
        const template = row.original
  
        return (
          <div className="flex justify-end align-middle mr-5">
            <Button variant="icon" size="icon" className="w-15 mr-1" onClick={()=>onSendButtonClicked(template.id)}>
              <Send className="h-5 w-5"/>
            </Button>
            <Button variant="icon" size="icon" className="w-15 mr-1" onClick={()=>onEditButtonClicked(template.id)}>
              <Pencil className="h-5 w-5"/>
            </Button>
            <Button variant="icon" size="icon" className="w-15" onClick={()=>onDeleteButtonClicked(template.id)}>
              <Trash className="h-5 w-5"/>
            </Button>
         </div>
        )
      },
    },
  ]
  :
  columns = [
    {
      accessorKey: "alimtalk_subject",
      header: () => <div className="text-left">템플릿 이름</div>,
      cell: ({ row }) => <div>{row.getValue("alimtalk_subject")}</div>,
    },
    {
      accessorKey: "send_sms_when_failed",
      header: () => <div className="text-center">대체 문자 전송</div>,
      cell: ({ row }) => <div className="text-center">{row.getValue("send_sms_when_failed") === "Y" ? "◯" : "✕"}</div>,
    },
    {
      accessorKey: "alimtalk_template_approval_status",
      header: () => <div className="text-center">승인 상태</div>,
      cell: ({ row }) => <div className="text-center">{row.getValue("alimtalk_template_approval_status")}</div>,
    },
    {
      id: "actions",
      header: () => <div className="flex justify-end align-middle">
                        <div className="w-16">전송</div>
                        <div className="w-16">편집</div>
                        <div className="w-16">삭제</div>
                    </div>,
      enableHiding: false,
      cell: ({ row }) => {
        const template = row.original
  
        return (
          <div className="flex justify-end align-middle mr-5">
            <Button variant="icon" size="icon" className="w-15 mr-1" onClick={()=>onSendButtonClicked(template.id)}>
              <Send className="h-5 w-5"/>
            </Button>
            <Button variant="icon" size="icon" className="w-15 mr-1" onClick={()=>onEditButtonClicked(template.id)}>
              <Pencil className="h-5 w-5"/>
            </Button>
            <Button variant="icon" size="icon" className="w-15" onClick={()=>onDeleteButtonClicked(template.id)}>
              <Trash className="h-5 w-5"/>
            </Button>
         </div>
        )
      },
    },
  ]

  return columns;
}