import * as React from "react"
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { ArrowUpDown, ChevronDown, MoreHorizontal } from "lucide-react"

import { Button } from "@/components/ui/button"
import { Label } from "@/components/ui/label"
import { Checkbox } from "@/components/ui/checkbox"

import { Input } from "@/components/ui/input"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { getColumns } from "./columns"
import { DataTablePagination } from "@/components/ui/table_bottom_pagination"
import { useQuery, useMutation } from "@tanstack/react-query";
import Axios from "@/components/lib/axios";
import { SendMessageContext } from '../index'

export function BuildingGroupMembersTable({targetType, selectedBuilding, selectedGroup}) {
  const [sorting, setSorting] = React.useState([])
  const [columnFilters, setColumnFilters] = React.useState([])
  const [columnVisibility, setColumnVisibility] = React.useState({})
  const [rowSelection, setRowSelection] = React.useState({})
  const [isAllRowsSelected, setIsAllRowsSelected] = React.useState(false)
  const [buildingGroupName, setBuildingGroupName] = React.useState("")
  const { addedMembersList, setAddedMembersList } = React.useContext(SendMessageContext)

  const { isLoading: isLoadingStarterData, error, data } = 
    useQuery({queryKey:["building_group_table", targetType, targetType === "shared_building" ? selectedBuilding : selectedGroup], 
      queryFn: async () => {
        var endpoint;
        if(targetType === "shared_building")
          endpoint = `/api_message/send/shared_building/${selectedBuilding}`
        else if(targetType === "group")
          endpoint = `/api_message/send/group/${selectedGroup}`

        // console.log(endpoint)

        const res = await Axios.get(endpoint);
        if(targetType === "shared_building")
          setBuildingGroupName("건물")
        else if(targetType === "group")
        setBuildingGroupName("그룹")
        return res.data;
      },
      initialData: []
      }
    );

  const columns = getColumns(targetType);

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  })

  const getMemberObject = (user) => {
    return ({
      id: user.mem_seq,
      building_name: user.sh_name,
      room_number: user.hs_hosu,
      name: user.mem_name, 
      phone_number: user.mem_hptel,
      email: user.mem_email
    })
  }

  React.useEffect(() => {
    const newMemberArray = [];
    table.getFilteredSelectedRowModel().rows.forEach(
      (row, index) => {
        if(targetType === "shared_building"){
          newMemberArray.push({
            id: row.original.mem_seq,
            building_name: row.original.sh_name,
            room_number: row.original.hs_hosu,
            name: row.original.mem_name, 
            phone_number: row.original.mem_hptel,
            email: row.original.mem_email
          })
        }else{
          newMemberArray.push({
            id: row.original.id,
            building_name: row.original.building_name,
            room_number: row.original.room_number,
            name: row.original.name, 
            phone_number: row.original.phone_number,
            email: row.original.email
          })
        }
      }
    )
    setAddedMembersList(newMemberArray);
  }, [rowSelection]);

  React.useEffect(() => {
    console.log(addedMembersList);
  }, [addedMembersList]);
  
  const toggleSelectAllRows = () => {
    if(isAllRowsSelected){
      setRowSelection({})
      setIsAllRowsSelected(false)
    }else{
      const newSelectedRows = {};
      for(let i=0; i < data.length; i++){
        newSelectedRows[i] = true;
      }
      setRowSelection(newSelectedRows);
      setIsAllRowsSelected(true)
    }
  }
  
  return (
    <div className="w-full mt-6">
      <div className="text-base text-muted-foreground flex flex-row justify-between items-center">
          <Button className="mr-5" onClick={()=>toggleSelectAllRows()}>{isAllRowsSelected ? `${buildingGroupName} 전체 해제` : `${buildingGroupName} 전체 선택`}</Button>
          <div className="flex flex-row">
            <Label className="text-accent-foreground font-semibold mr-05">{table.getFilteredSelectedRowModel().rows.length}</Label>
            <Label>명 선택</Label>
          </div>
      </div>
      <div className="rounded-md border mt-3">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  데이터가 없습니다.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="py-4">
        <DataTablePagination table={table} />
      </div>
      
    </div>
  )
}
