import React from 'react';
import Style from '../pages/space/index.module.css';
import cn from 'classnames'
import ImageDropBox from './ImageDropBox';
import popClose from '@/assets/image/ico/ico-pop-close.svg'
import Zoom from 'react-medium-image-zoom'
import './imageZoomStyles.css'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import image_dropbox_background from "@/assets/image/bg/bg-upload.png";
import {
	AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle,
} from "@/components/ui/view-dialog"
import { Button } from '@/components/ui/button';
import { X } from 'lucide-react';

const PopUp = props => {
    const { isPopUpShowing, setIsPopUpShowing, previewImgUrls, onImagesDropped, onDeleteButtonClicked, isObject } = props;
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      }
    };
//popup{width:calc( 100% - 40px - 50px );max-width:687px;height:auto;padding:48px 25px;overflow:hidden;background:#ededed;position:fixed;left:50%;top:80px;transform: translateX(-50%);z-index:99999;display:none;max-height: calc(100vh - 160px);overflow-y: auto;}
    return (
      <>
        <div className={cn(Style.popup, `${isPopUpShowing?Style.on:''}`, 'rounded-md')}>
          <div className='w-full h-72 bg-white mt-6 lg:mt-10 rounded-md' style={{ backgroundImage: `url(${image_dropbox_background})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
              <ImageDropBox onDrop={onImagesDropped}/>
            </div>
            <div>
              <Carousel className='w-full h-72 bg-white mt-4 rounded-md' responsive={responsive} swipeable={true} draggable={true}>
                {previewImgUrls.map((image, index) => (
                  <div className='max-w-full max-h-56 self-center flex justify-center relative overflow-hidden'>
                    <Zoom>
                      <img className='max-w-full max-h-full object-cover' src={isObject ? image.imgurl : image}/>
                    </Zoom>
                    <Button className='absolute right-2 top-1' variant='icon' size='icon' onClick={(e)=>onDeleteButtonClicked([index], e)}><X className='w-5.5 h-5.5'/></Button>
                  </div>
                ))}
              </Carousel>
            </div>
            <div className='flex justify-end'>
              <Button className='text-lg mt-4 align-end flex self-end' size="big" onClick={() => setIsPopUpShowing(false)}>확인</Button>
            </div>
        </div>
        </>
    );
}

export default PopUp;
