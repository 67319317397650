import {React, useState, useEffect, createContext} from 'react'

import {
	AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle,
} from "@/components/ui/view-dialog"
import { StatsPaymentTable } from '../payment_table/table'

const PaymentInfoDialog = ({isOpenedDialog, setIsOpenedDialog, paymentTableTitle, paymentTableData, onUserInfoButtonClicked}) => {
	return (
		<AlertDialog open={isOpenedDialog} onOpenChange={setIsOpenedDialog}>
			<AlertDialogContent>
				<AlertDialogHeader>
					<div className="mb-5 title w-full mt-2">{paymentTableTitle}</div>
					<StatsPaymentTable data={paymentTableData} onUserInfoButtonClicked={onUserInfoButtonClicked}/>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel onClick={()=>{}}>닫기</AlertDialogCancel>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	)
}

export default PaymentInfoDialog
