import {useState, useEffect, useContext, useRef} from 'react'
import { useNavigate } from 'react-router-dom';
import { AutoSendSettingsContext } from './index'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'

import { cn } from "@/components/lib/utils"
import { Input } from "@/components/ui/input"
import { Label, GreenLabel } from "@/components/ui/label"
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Textarea } from "@/components/ui/textarea"
import { toast } from "@/components/ui/use-toast"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { ChevronLeft, AlignJustify } from "lucide-react"
import PushTemplateEditor from "@/components/message_template/push"
import InAppTemplateEditor from '@/components/message_template/inapp'
import EmailTemplateEditor from '@/components/message_template/email'
import SMSTemplateEditor from '@/components/message_template/sms'
import AlimtalkTemplateEditor from '@/components/message_template/alimtalk'
import { useQuery, useMutation } from "@tanstack/react-query";
import Axios from "@/components/lib/axios";
import { AlimtalkTemplateTable } from "./alimtalk_template_table/table"

const AutoSendCreator = () => {
	const navigate = useNavigate();

	const [inputAutoSendEventName, setInputAutoSendEventName] = useState("");
	const [isPushChecked, setIsPushChecked] = useState(false);
	const [isInAppChecked, setInAppChecked] = useState(false);
	const [isEmailChecked, setIsEmailChecked] = useState(false);
	const [isSMSChecked, setIsSMSChecked] = useState(false);
	const [isAlimtalkChecked, setIsAlimtalkChecked] = useState(false);
	const [inputDevelopCommunication, setInputDevelopCommunication] = useState("");

	// PUSH START
	const [inputSubjectPush, setInputSubjectPush] = useState("");
	const [inputContentPush, setInputContentPush] = useState("");
	const [imageOnePush, setImageOnePush] = useState("");
	// PUSH END

	// IN APP START
	const [inputSubjectInApp, setInputSubjectInApp] = useState("");
	const [inputContentInApp, setInputContentInApp] = useState("");
	const [inputLinkedPageUrlInApp, setInputLinkedPageUrlInApp] = useState("");
	// IN APP END

	// EMAIL START
	const [inputSubjectEmail, setInputSubjectEmail] = useState("");
	const [inputContentEmail, setInputContentEmail] = useState("");
	const emailEditorRef = useRef(null);
	// EMAIL END

	// SMS START
	const [smsTypeSMS, setSmsTypeSMS] = useState("SMS"); // SMS, Alimtalk
	const [inputSubjectSMS, setInputSubjectSMS] = useState("");
	const [inputContentSMS, setInputContentSMS] = useState("");
	const [imageOneSMS, setImageOneSMS] = useState("");
	const [imageTwoSMS, setImageTwoSMS] = useState("");
	const [imageThreeSMS, setImageThreeSMS] = useState("");
	// SMS END

	// ALIMTALK START
	const [selectedTemplateIdAlimtalk, setSelectedTemplateIdAlimtalk] = useState(0);
	const [inputSubjectAlimtalk, setInputSubjectAlimtalk] = useState("");
	const [inputContentAlimtalk, setInputContentAlimtalk] = useState("");
	const [alimtalkType, setAlimtalkType] = useState("BA");
	const [emphasisType, setEmphasisType] = useState("NONE");
	const [emphasisTextMainTitle, setEmphasisTextMainTitle] = useState("");
	const [emphasisTextSubTitle, setEmphasisTextSubTitle] = useState("");
	const [emphasisImageFileName, setEmphasisImageFileName] = useState("선택된 파일 없음");
	const [emphasisImageFileUrl, setEmphasisImageFileUrl] = useState("");
	const [alimtalkButtons, setAlimtalkButtons] = useState([]);
	const [sendSmsWhenFailed, setSendSmsWhenFailed] = useState("Y");
	const [smsTypeAlimtalk, setSmsTypeAlimtalk] = useState("SMS"); // SMS, Alimtalk
	const [inputSMSSubjectAlimtalk, setInputSMSSubjectAlimtalk] = useState("");
	const [inputSMSContentAlimtalk, setInputSMSContentAlimtalk] = useState("");
	const [imageOneSMSAlimtalk, setImageOneSMSAlimtalk] = useState("");
	const [imageTwoSMSAlimtalk, setImageTwoSMSAlimtalk] = useState("");
	const [imageThreeSMSAlimtalk, setImageThreeSMSAlimtalk] = useState("");
	// ALIMTALK END

	// onReady Function For Email
	const onReady = () => {
		// editor is ready
		const editorRef = emailEditorRef.current;
		if (editorRef !== null) {
	
			editorRef.editor.registerCallback('image', function(file, done) {
				var data = new FormData()
				data.append('image', file.attachments[0])
				
				fetch('https://siso.live:5010/api_message/common/image', {
					method: 'POST',
					headers: {
					'Accept': 'application/json'
					},
					body: data
				}).then(response => {
					// Make sure the response was valid
					if (response.status >= 200 && response.status < 300) {
						return response
					} else {
						var error = new Error(response.statusText)
						error.response = response
						throw error
					}
				}).then(response => {
					return response.json()
				}).then(data => {
					// Pass the URL back to Unlayer to mark this upload as completed
					done({ progress: 100, url: data.imgUrl })
				})
			})
		}
	}

	useEffect(() => {
		Axios.get(`/api_message/template/alimtalk/${selectedTemplateIdAlimtalk}`).then((res) => {
			const data = res.data;
			setInputSubjectAlimtalk(data.alimtalk_subject);
			setInputContentAlimtalk(data.alimtalk_content);
			setAlimtalkType(data.alimtalk_template_type)
			setEmphasisType(data.alimtalk_template_emphasis_type)
			data.alimtalk_template_emphasis_text_main_title && setEmphasisTextMainTitle(data.alimtalk_template_emphasis_text_main_title)
			data.alimtalk_template_emphasis_text_sub_title && setEmphasisTextSubTitle(data.alimtalk_template_emphasis_text_sub_title)
			data.alimtalk_template_emphasis_image_file_name && setEmphasisImageFileName(data.alimtalk_template_emphasis_image_file_name)
			data.alimtalk_template_emphasis_image_file_url && setEmphasisImageFileUrl(data.alimtalk_template_emphasis_image_file_url)
			data.alimtalk_template_buttons && setAlimtalkButtons(JSON.parse(data.alimtalk_template_buttons))
			setSendSmsWhenFailed(data.send_sms_when_failed)
			data.sms_type && setSmsTypeAlimtalk(data.sms_type)
			data.sms_subject && setInputSMSSubjectAlimtalk(data.sms_subject)
			data.sms_content && setInputSMSContentAlimtalk(data.sms_content)
			data.sms_img_url1 && setImageOneSMSAlimtalk(data.sms_img_url1);
			data.sms_img_url2 && setImageTwoSMSAlimtalk(data.sms_img_url2);
			data.sms_img_url3 && setImageThreeSMSAlimtalk(data.sms_img_url3);
		});
	}, [selectedTemplateIdAlimtalk]);
	
	const insertAutoSend = useMutation({
		mutationFn: async () => {
			var params = {
				event_name: inputAutoSendEventName
			};
			if(inputDevelopCommunication !== ""){
				params["develop_communication"] = inputDevelopCommunication;
			}
			if(isPushChecked){
				params["is_push_on"] = true;
				params["push_subject"] = inputSubjectPush;
				params["push_content"] = inputContentPush;
				if(imageOnePush !== "") params["push_img_url"] = imageOnePush; // img_url: imageOnePush !== "" ? imageOne : null
			} 
			if(isInAppChecked){
				params["is_in_app_on"] = true;
				params["in_app_subject"] = inputSubjectInApp;
				params["in_app_content"] = inputContentInApp;
				if(inputLinkedPageUrlInApp !== "") params["in_app_link_url"] = inputLinkedPageUrlInApp;
			}
			if(isEmailChecked){
				const { design, html } = await getEmailTemplateData()
				params["is_email_on"] = true;
				params["email_subject"] = inputSubjectEmail;
				params["email_content"] = JSON.stringify(design);
				params["email_html"] = html;
			}
			if(isSMSChecked){
				params["is_sms_on"] = true;
				params["sms_type"] = smsTypeSMS;
				params["sms_subject"] = inputSubjectSMS;
				params["sms_content"] = inputContentSMS;
				if(imageOneSMS !== "") params["sms_img_url1"] = imageOneSMS;
				if(imageTwoSMS !== "") params["sms_img_url2"] = imageTwoSMS;
				if(imageThreeSMS !== "") params["sms_img_url3"] = imageThreeSMS;
			}
			if(isAlimtalkChecked){
				params["is_alimtalk_on"] = true;
				params["alimtalk_selected_id"] = selectedTemplateIdAlimtalk;
			}
			
			const { res } = await Axios.post(`/api_message/auto_send`, params)
			return res
		},
		onSuccess: (res) => {
			navigate(-1)
		},
	});

	const getEmailTemplateData = () => {
		return new Promise((resolve, reject) => {
			if (emailEditorRef.current !== null) {
				emailEditorRef.current.editor.exportHtml((data) => {
					resolve(data);
				});
			}
		});
	}

	return (
		<>
		<div className="w-full">
            <div className="flex flex-col w-full">
                <GreenLabel className="">자동 전송 조건 / 이벤트</GreenLabel>
                <Input className="mt-3 w-[32rem]" value={inputAutoSendEventName} onChange={(event)=>setInputAutoSendEventName(event.target.value)}/>
				<GreenLabel className="mt-6">메시지 타입</GreenLabel>
				<div className='mt-3 flex flex-row gap-3 items-center'>
					<Checkbox checked={isPushChecked} onCheckedChange={()=>setIsPushChecked( (prev) => !prev )}/>
					<Label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">푸쉬</Label>
					<Checkbox checked={isInAppChecked} onCheckedChange={()=>setInAppChecked( (prev) => !prev )}/>
					<Label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">인앱</Label>
					<Checkbox checked={isEmailChecked} onCheckedChange={()=>setIsEmailChecked( (prev) => !prev )}/>
					<Label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">이메일</Label>
					<Checkbox checked={isSMSChecked} onCheckedChange={()=>setIsSMSChecked( (prev) => !prev )}/>
					<Label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">문자</Label>
					<Checkbox checked={isAlimtalkChecked} onCheckedChange={()=>setIsAlimtalkChecked( (prev) => !prev )}/>
					<Label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">알림톡</Label>
				</div>
				<GreenLabel className="mt-6">개발 관련 커뮤니케이션</GreenLabel>
				<Textarea
					className="resize-none mt-3 w-[32rem]"
					rows="4"
					value={inputDevelopCommunication}
					onChange={(event)=>setInputDevelopCommunication(event.target.value)}
                />
            </div>
			{
				isPushChecked
				&& <div className='flex flex-col mt-24'>
						<div className="mb-5 title w-full">푸쉬 설정</div>
						<PushTemplateEditor inputSubject={inputSubjectPush} setInputSubject={setInputSubjectPush} inputContent={inputContentPush}
							setInputContent={setInputContentPush} imageOne={imageOnePush} setImageOne={setImageOnePush}/>
					</div>
			}
			{
				isInAppChecked
				&& <div className='flex flex-col mt-24'>
						<div className="mb-5 title w-full">인앱 설정</div>
						<InAppTemplateEditor inputSubject={inputSubjectInApp} setInputSubject={setInputSubjectInApp} inputContent={inputContentInApp}
							setInputContent={setInputContentInApp} inputLinkedPageUrl={inputLinkedPageUrlInApp} setInputLinkedPageUrl={setInputLinkedPageUrlInApp}/>
					</div>
			}
			{
				isEmailChecked
				&& <div className='flex flex-col mt-24'>
						<div className="mb-5 title w-full">이메일 설정</div>
						<EmailTemplateEditor inputSubject={inputSubjectEmail} setInputSubject={setInputSubjectEmail} inputContent={inputContentEmail}
							setInputContent={setInputContentEmail} emailEditorRef={emailEditorRef} onReady={onReady}/>
					</div>
			}
			{
				isSMSChecked
				&& <div className='flex flex-col mt-24'>
						<div className="mb-5 title w-full">문자 설정</div>
						<SMSTemplateEditor inputSubject={inputSubjectSMS} setInputSubject={setInputSubjectSMS} inputContent={inputContentSMS}
							setInputContent={setInputContentSMS} smsType={smsTypeSMS} setSmsType={setSmsTypeSMS} imageOne={imageOneSMS} setImageOne={setImageOneSMS}
							imageTwo={imageTwoSMS} setImageTwo={setImageTwoSMS} imageThree={imageThreeSMS} setImageThree={setImageThreeSMS}/>
					</div>
			}
			{
				isAlimtalkChecked
				&& <div className='flex flex-col mt-24'>
						<div className="mb-10 title w-full">알림톡 설정</div>
						{
							isAlimtalkChecked &&
							<AlimtalkTemplateTable selectedTemplateId={selectedTemplateIdAlimtalk} setSelectedTemplateId={setSelectedTemplateIdAlimtalk}/>
						}
						{
							isAlimtalkChecked && selectedTemplateIdAlimtalk !== 0 &&
							<AlimtalkTemplateEditor inputSubject={inputSubjectAlimtalk} setInputSubject={setInputSubjectAlimtalk} inputContent={inputContentAlimtalk}
							setInputContent={setInputContentAlimtalk} alimtalkType={alimtalkType} setAlimtalkType={setAlimtalkType} emphasisType={emphasisType}
							setEmphasisType={setEmphasisType} emphasisTextMainTitle={emphasisTextMainTitle} setEmphasisTextMainTitle={setEmphasisTextMainTitle}
							emphasisTextSubTitle={emphasisTextSubTitle} setEmphasisTextSubTitle={setEmphasisTextSubTitle} emphasisImageFileName={emphasisImageFileName}
							setEmphasisImageFileName={setEmphasisImageFileName} emphasisImageFileUrl={emphasisImageFileUrl} setEmphasisImageFileUrl={setEmphasisImageFileUrl}
							alimtalkButtons={alimtalkButtons} setAlimtalkButtons={setAlimtalkButtons} sendSmsWhenFailed={sendSmsWhenFailed}
							setSendSmsWhenFailed={setSendSmsWhenFailed} smsType={smsTypeAlimtalk} setSmsType={setSmsTypeAlimtalk} inputSMSSubject={inputSMSSubjectAlimtalk}
							setInputSMSSubject={setInputSMSSubjectAlimtalk} inputSMSContent={inputSMSContentAlimtalk} setInputSMSContent={setInputSMSContentAlimtalk} imageOne={imageOneSMSAlimtalk}
							setImageOne={setImageOneSMSAlimtalk} imageTwo={imageTwoSMSAlimtalk} setImageTwo={setImageTwoSMSAlimtalk} imageThree={imageThreeSMSAlimtalk} setImageThree={setImageThreeSMSAlimtalk}
							/>
						}
					</div>
			}
			<div className='flex flex-row justify-end w-full mt-10 gap-6'>
				<Button variant="big" size="big" onClick={() => navigate(-1)}>취소</Button>
				<Button variant="big" size="big" onClick={() => insertAutoSend.mutate()}>저장</Button>
			</div>
		</div>
		</>
	)
}

export default AutoSendCreator
