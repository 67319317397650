import {useState, useEffect, useContext, useRef} from 'react'
import { useNavigate } from 'react-router-dom';

import PushTemplateEditor from "@/components/message_template/push"
import InAppTemplateEditor from '@/components/message_template/inapp'
import EmailTemplateEditor from '@/components/message_template/email'
import SMSTemplateEditor from '@/components/message_template/sms'
import AlimtalkTemplateEditor from '@/components/message_template/alimtalk'

import { GreenLabel, Label } from "@/components/ui/label"
import { Button } from '@/components/ui/button'
import { useQuery, useMutation } from "@tanstack/react-query";
import Axios from "@/components/lib/axios";

const TemplateEditor = ({type, templateId}) => {
	const navigate = useNavigate();

	const [inputSubject, setInputSubject] = useState("");
	const [inputContent, setInputContent] = useState("");
	const [inputLinkedPageUrl, setInputLinkedPageUrl] = useState(""); // InApp Only
	const [smsType, setSmsType] = useState("SMS"); // SMS, Alimtalk
	const [imageOne, setImageOne] = useState("");
	const [imageTwo, setImageTwo] = useState("");
	const [imageThree, setImageThree] = useState("");
	// ----------------------------------------- Alimtalk From Here
	const [alimtalkType, setAlimtalkType] = useState("BA");
	const [emphasisType, setEmphasisType] = useState("NONE");
	const [emphasisTextMainTitle, setEmphasisTextMainTitle] = useState("");
	const [emphasisTextSubTitle, setEmphasisTextSubTitle] = useState("");
	const [emphasisImageFileName, setEmphasisImageFileName] = useState("선택된 파일 없음");
	const [emphasisImageFileUrl, setEmphasisImageFileUrl] = useState("");
	const [alimtalkStatus, setAlimtalkStatus] = useState("REG");
	const [alimtalkButtons, setAlimtalkButtons] = useState([]);
	const [sendSmsWhenFailed, setSendSmsWhenFailed] = useState("Y");
	const [inputSMSSubject, setInputSMSSubject] = useState("");
	const [inputSMSContent, setInputSMSContent] = useState("");
	const [emailHTMLContent, setEmailHTMLContent] = useState("");

	const { isLoading, error, data } = useQuery(
		{
		  queryKey: ["message_template", type, templateId], 
		  queryFn: () =>
			Axios.get(`/api_message/template/${type}/${templateId}`).then((res) => {
			  return res.data;
			}),
		  initialData: ""
		}
	  );
	
	// ----------------------------------------- EMAIL From Here
	const emailEditorRef = useRef(null);

	const onReady = () => {
		// editor is ready
		const editorRef = emailEditorRef.current;
		if (editorRef !== null) {
	
			editorRef.editor.registerCallback('image', function(file, done) {
				var data = new FormData()
				data.append('image', file.attachments[0])
				
				fetch('https://siso.live:5010/api_message/common/image', {
					method: 'POST',
					headers: {
					'Accept': 'application/json'
					},
					body: data
				}).then(response => {
					// Make sure the response was valid
					if (response.status >= 200 && response.status < 300) {
						return response
					} else {
						var error = new Error(response.statusText)
						error.response = response
						throw error
					}
				}).then(response => {
					return response.json()
				}).then(data => {
					// Pass the URL back to Unlayer to mark this upload as completed
					done({ progress: 100, url: data.imgUrl })
				})
			})

			if(inputContent !== "") editorRef.editor.loadDesign(inputContent);
		}
	}
	// ----------------------------------------- EMAIL

	useEffect(() => {
		if(data !== ""){
			if(type === "push"){
				setInputSubject(data.subject);
				setInputContent(data.content);
				data.img_url && setImageOne(data.img_url);
			}else if(type === "inapp"){
				setInputSubject(data.subject);
				setInputContent(data.content);
				data.link_url && setInputLinkedPageUrl(data.link_url);
			}else if(type === "email"){
				setInputSubject(data.subject);
				setInputContent(JSON.parse(data.content))
				setEmailHTMLContent(data.html)
			}else if(type === "sms"){
				setSmsType(data.type)
				setInputSubject(data.subject);
				setInputContent(data.content);
				data.img_url1 && setImageOne(data.img_url1);
				data.img_url2 && setImageTwo(data.img_url2);
				data.img_url3 && setImageThree(data.img_url3);
			}else if(type === "alimtalk"){
				setInputSubject(data.alimtalk_subject);
				setInputContent(data.alimtalk_content);
				setAlimtalkType(data.alimtalk_template_type)
				setEmphasisType(data.alimtalk_template_emphasis_type)
				data.alimtalk_template_emphasis_text_main_title && setEmphasisTextMainTitle(data.alimtalk_template_emphasis_text_main_title)
				data.alimtalk_template_emphasis_text_sub_title && setEmphasisTextSubTitle(data.alimtalk_template_emphasis_text_sub_title)
				data.alimtalk_template_emphasis_image_file_name && setEmphasisImageFileName(data.alimtalk_template_emphasis_image_file_name)
				data.alimtalk_template_emphasis_image_file_url && setEmphasisImageFileUrl(data.alimtalk_template_emphasis_image_file_url)
				setAlimtalkStatus(data.alimtalk_template_approval_status)
				data.alimtalk_template_buttons && setAlimtalkButtons(JSON.parse(data.alimtalk_template_buttons))
				setSendSmsWhenFailed(data.send_sms_when_failed)
				data.sms_type && setSmsType(data.sms_type)
				data.sms_subject && setInputSMSSubject(data.sms_subject)
				data.sms_content && setInputSMSContent(data.sms_content)
				data.sms_img_url1 && setImageOne(data.sms_img_url1);
				data.sms_img_url2 && setImageTwo(data.sms_img_url2);
				data.sms_img_url3 && setImageThree(data.sms_img_url3);
			}
		}
	}, [data]);
	// -------------------------------------------

	useEffect(() => {
		if(inputContent !== ""){
			if(type === "email" && emailEditorRef.current !== null && emailEditorRef.current.editor !== null){
				emailEditorRef.current.editor.loadDesign(inputContent);
			}
		}
	}, [inputContent, emailEditorRef]);

	const getEditor = (type) => {
		switch (type) {
			case 'push':
				return <PushTemplateEditor inputSubject={inputSubject} setInputSubject={setInputSubject} inputContent={inputContent}
				setInputContent={setInputContent} imageOne={imageOne} setImageOne={setImageOne}/>
			case 'inapp':
				return <InAppTemplateEditor inputSubject={inputSubject} setInputSubject={setInputSubject} inputContent={inputContent}
				setInputContent={setInputContent} inputLinkedPageUrl={inputLinkedPageUrl} setInputLinkedPageUrl={setInputLinkedPageUrl}/>
			case 'email':
				return <EmailTemplateEditor inputSubject={inputSubject} setInputSubject={setInputSubject} inputContent={inputContent}
				setInputContent={setInputContent} emailEditorRef={emailEditorRef} onReady={onReady}/>
			case 'sms':
				return <SMSTemplateEditor inputSubject={inputSubject} setInputSubject={setInputSubject} inputContent={inputContent}
				setInputContent={setInputContent} smsType={smsType} setSmsType={setSmsType} imageOne={imageOne} setImageOne={setImageOne}
				imageTwo={imageTwo} setImageTwo={setImageTwo} imageThree={imageThree} setImageThree={setImageThree}/>
			case 'alimtalk':
				return <AlimtalkTemplateEditor inputSubject={inputSubject} setInputSubject={setInputSubject} inputContent={inputContent}
				setInputContent={setInputContent} alimtalkType={alimtalkType} setAlimtalkType={setAlimtalkType} emphasisType={emphasisType}
				setEmphasisType={setEmphasisType} emphasisTextMainTitle={emphasisTextMainTitle} setEmphasisTextMainTitle={setEmphasisTextMainTitle}
				emphasisTextSubTitle={emphasisTextSubTitle} setEmphasisTextSubTitle={setEmphasisTextSubTitle} emphasisImageFileName={emphasisImageFileName}
				setEmphasisImageFileName={setEmphasisImageFileName} emphasisImageFileUrl={emphasisImageFileUrl} setEmphasisImageFileUrl={setEmphasisImageFileUrl}
				alimtalkButtons={alimtalkButtons} setAlimtalkButtons={setAlimtalkButtons} sendSmsWhenFailed={sendSmsWhenFailed}
				setSendSmsWhenFailed={setSendSmsWhenFailed} smsType={smsType} setSmsType={setSmsType} inputSMSSubject={inputSMSSubject}
				setInputSMSSubject={setInputSMSSubject} inputSMSContent={inputSMSContent} setInputSMSContent={setInputSMSContent} imageOne={imageOne}
				setImageOne={setImageOne} imageTwo={imageTwo} setImageTwo={setImageTwo} imageThree={imageThree} setImageThree={setImageThree}
				/>
		  }
	}

	const updateTemplate = useMutation({
		mutationFn: async () => {
			var params;
			if(type === "push"){
				params = {
					subject: inputSubject,
					content: inputContent,
					img_url: imageOne !== "" ? imageOne : null
				}
			}else if(type === "inapp"){
				params = {
					subject: inputSubject,
					content: inputContent,
					link_url: inputLinkedPageUrl !== "" ? inputLinkedPageUrl : null
				}
			}else if(type === "email"){
				const { design, html } = await getEmailTemplateData()
				params = {
					subject: inputSubject,
					content: JSON.stringify(design),
					html: html
				}
			}else if(type === "sms"){
				params = {
					type: smsType,
					subject: inputSubject,
					content: inputContent,
					img_url1: imageOne !== "" ? imageOne : null,
					img_url2: imageTwo !== "" ? imageTwo : null,
					img_url3: imageThree !== "" ? imageThree : null
				}
			}else if(type === "alimtalk"){
				params = {
					alimtalk_subject: inputSubject, 
					alimtalk_content: inputContent,
					alimtalk_template_type: alimtalkType,
					alimtalk_template_emphasis_type: emphasisType,
					alimtalk_template_emphasis_text_main_title: emphasisTextMainTitle !== "" ? emphasisTextMainTitle : null,
					alimtalk_template_emphasis_text_sub_title: emphasisTextSubTitle !== "" ? emphasisTextSubTitle : null,
					alimtalk_template_emphasis_image_file_name: emphasisImageFileName !== "" ? emphasisImageFileName : null,
					alimtalk_template_emphasis_image_file_url: emphasisImageFileUrl !== "" ? emphasisImageFileUrl : null,
					alimtalk_template_buttons: alimtalkButtons.length > 0 ? JSON.stringify(alimtalkButtons) : null,
					send_sms_when_failed: sendSmsWhenFailed,
					sms_type: smsType !== "" ? smsType : null,
					sms_subject: inputSMSSubject !== "" ? inputSMSSubject : null,
					sms_content: inputSMSContent !== "" ? inputSMSContent : null,
					sms_img_url1: imageOne !== "" ? imageOne : null,
					sms_img_url2: imageTwo !== "" ? imageTwo : null,
					sms_img_url3: imageThree !== "" ? imageThree : null
				}
			}
			
			const { res } = await Axios.put(`/api_message/template/${type}/${templateId}`, params)
			return res
		},
		onSuccess: (res) => {
			navigate(-1)
		},
	});

	const applyApprovalTemplate = useMutation({
		mutationFn: async () => {
			const res = await Axios.post(`/api_message/template/apply/${type}/${templateId}`)
			return res
		},
		onSuccess: (res) => {
			navigate(-1)
		},
	});

	const getEmailTemplateData = () => {
		return new Promise((resolve, reject) => {
			if (emailEditorRef.current !== null) {
				emailEditorRef.current.editor.exportHtml((data) => {
					resolve(data);
				});
			}
		});
	}
	  
	return (
		<div className='flex flex-col mt-6'>
			{getEditor(type)}
			<div className='flex flex-row justify-end w-full mt-10 gap-6'>
				<Button variant="big" size="big" onClick={() => navigate(-1)}>취소</Button>
				{
					type !== "alimtalk"
					&&
					<Button variant="big" size="big" onClick={() => updateTemplate.mutate()}>업데이트</Button>
				}
				{
					type === "alimtalk" && alimtalkStatus === "REG"
					&&
					<>
						<Button variant="big" size="big" onClick={() => updateTemplate.mutate()}>업데이트</Button>
						<Button variant="big" size="big" onClick={() => applyApprovalTemplate.mutate()}>승인 요청</Button>
					</>
				}
			</div>
		</div>
	)
}

export default TemplateEditor
