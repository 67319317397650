import {React} from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import ManagerHome from './manager/manager_home'

const Manager = () => {
	const queryClient = new QueryClient()

	return (
			<QueryClientProvider client={queryClient}>
				<ManagerHome/>
			</QueryClientProvider>
	)
}

export default Manager
