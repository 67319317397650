import React, {useState, useEffect, useRef} from 'react'
import axios from "axios";
import { useNavigate } from "react-router-dom"
import cn from 'classnames'
import Chart from 'react-apexcharts'
import Pie from '@/components/pie';
import { getToken } from "../../../services/TokenService";

// css
import Style from './index.module.css'
import CntList01 from '@/assets/image/ico/ico-space-cnt01.svg'
import CntList02 from '@/assets/image/ico/ico-space-cnt01.svg'
// import CntList03 from '@/assets/image/ico/ico-space-cnt01.svg'
// import CntList04 from '@/assets/image/ico/ico-space-cnt01.svg'
import CntList03 from '@/assets/image/ico/ico-mn-list05.svg'
import CntList04 from '@/assets/image/ico/ico-mn-list03.svg'
import More from '@/assets/image/ico/ico-arr-more.svg'

import CostList from '@/assets/image/ico/ico-cost-list.svg'

import { Button } from "@/components/ui/button"
import { MySpinner } from '@/components/ui/spinner'
import Axios from "@/components/lib/axios";
import { useQuery, useMutation } from "@tanstack/react-query";
import { getNumberString } from '@/components/lib/number';
import RenteeInfoDialog from './rentee_info_dialog/rentee_info_dialog';
import { getChartOptions } from './chart/options';
import PaymentInfoDialog from './payment_info_dialog/payment_info_dialog';

import Calendar from './calendar'
import { getDateString } from '../../../components/lib/date';

const AdminDashboard = () => {
	const navigate = useNavigate();
	const [ statsInfo, setStatsInfo ] = useState();
  	const [renteeTableTitle, setRenteeTableTitle] = useState();
	const [renteeTableData, setRenteeTableData] = useState();
	const [xAxisArray, setXAxisArray] = useState();
	const [yAxisDataArray, setYAxisDataArray] = useState();
	const [calendarData, setCalendarData] = useState();
	const [isOpenedRenteeDialog, setIsOpenedRenteeDialog] = useState(false);
	const [isDialogUtility, setIsDialogUtility] = useState(false);
	const [paymentTableTitle, setPaymentTableTitle] = useState();
	const [paymentTableData, setPaymentTableData] = useState();
	const [isOpenedPaymentDialog, setIsOpenedPaymentDialog] = useState(false);
	const [renteeExpenseList, setRenteeExpenseList] = useState();
	const [renteeUtilityList, setRenteeUtilityList] = useState();

	var params;
	if(getToken()){
		params = {
			company: JSON.parse(getToken()).company
		}
	}

  const { isLoading, error } = useQuery({queryKey:["admin_dashboard"], queryFn: () =>
		Axios.post(`/api_expense/dashboard`, params).then((res) => {
			if(res.data.statsInfo !== null) setStatsInfo(res.data.statsInfo);
			if(res.data.statsInfo.chartYAxisData !== null) setYAxisDataArray(res.data.statsInfo.chartYAxisData);
			if(res.data.statsInfo.chartXAxisArray !== null) setXAxisArray(res.data.statsInfo.chartXAxisArray)
			if(res.data.calendarData !== null) setCalendarData(res.data.calendarData);
			if(res.data.renteeExpenseList !== null) setRenteeExpenseList(res.data.renteeExpenseList);
			if(res.data.renteeUtilityList !== null) setRenteeUtilityList(res.data.renteeUtilityList);

			return res.data;
		})});

	const onUserInfoButtonClicked = (userId) => {
		navigate(`/admin/userView/${userId}`);
	}

	const openDailyIncomeInfoDialog = async (url) => {
		/*
		setRenteeTableTitle(title);
		const found = statsInfo.rentee_data.find((rentee) => rentee.mem_seq === parseInt(mem_seq));
		setRenteeTableData([found]);
		setIsOpenedDialog(true);
		*/
		const {data} = await Axios.get(url)
		if(data !== undefined){
			console.log(data);
			setPaymentTableTitle(data.paymentTableTitle);
			setPaymentTableData(data.paymentTableData);
			setIsOpenedPaymentDialog(true);
		}
	}

	const houserentfee = () => {
		navigate("/admin/space/admCostList");
	}
  
	return (
		<>
		{ 
		isLoading
		? <MySpinner/>
		:
		<div className={Style.wrapper}>

			<section className={Style.dsTitleWrap} style={{position: 'relative'}}>
				<strong>Dashboard</strong>
				<span>비용관리 대시보드</span>

				<div style={{position:'absolute', right: '0', fontSize: '21px', color: '#fff', fontWeight: '600'}}>
					<button className={Style.btn} type={'button'} onClick={houserentfee}>관리비</button>
				</div>
			</section>

			<section className={Style.cntWrap}>
				<ul>
					<li>
						<div className={Style.ico}><img src={CntList01} /></div>
						<div className={Style.key}>{statsInfo.textForThisMonth} 예상수입</div>
						<div className={Style.value} onClick={()=>{setIsOpenedRenteeDialog(true);setRenteeTableTitle(`${statsInfo.textForThisMonth} 입금 예정 유저`);
							setRenteeTableData(statsInfo.renteeToPayThisMonth);setIsDialogUtility(false);}}>{getNumberString(statsInfo.totalRentFeeForThisMonth)}</div>
						{/* <div className={Style.chart} style={{background: 'none'}}>
							<Pie percentage={85} colour="rgb(104, 142, 38)" />
						</div> */}
					</li>
					<li>
						<div className={Style.ico}><img src={CntList02} /></div>
						<div className={Style.key}>{statsInfo.textForNextMonth} 예상수입</div>
						<div className={Style.value} onClick={()=>{setIsOpenedRenteeDialog(true);setRenteeTableTitle(`${statsInfo.textForNextMonth} 입금 예정 유저`);
							setRenteeTableData(statsInfo.renteeToPayNextMonth);setIsDialogUtility(false);}}>{getNumberString(statsInfo.totalRentFeeForNextMonth)}</div>
						{/* <div className={Style.chart} style={{background: 'none'}}>
							<Pie percentage={55} colour="rgb(104, 112, 250)" />
						</div> */}
					</li>
					<li>
						<div className={Style.ico}><img src={CntList03}/></div>
						<div className={Style.key}>비용 미지급 거주자 통계</div>
						<div className={Style.value}>-</div>
					</li>
					<li>
						<div className={Style.ico}><img src={CntList04} /></div>
						<div className={Style.key}>관리비 통계</div>
						<div className={Style.value} onClick={()=>{setIsOpenedRenteeDialog(true);setRenteeTableTitle(`관리비 (${statsInfo.utility_last_month})`);
							setRenteeTableData(statsInfo.rentee_utility_last_month);setIsDialogUtility(true);}}>{`${statsInfo.utility_last_month} : ${getNumberString(statsInfo.num_utility_last_month)}`}</div>
						<div className={Style.value} onClick={()=>{setIsOpenedRenteeDialog(true);setRenteeTableTitle(`관리비 (${statsInfo.utility_this_month})`);
							setRenteeTableData(statsInfo.rentee_utility_this_month);setIsDialogUtility(true);}}>{`${statsInfo.utility_this_month} : ${getNumberString(statsInfo.num_utility_this_month)}`}</div>
					</li>
				</ul>
			</section>

			<section className={Style.staticsWrap}>
				<div className={Style.chartWrap}>
					<Chart options={getChartOptions(xAxisArray)} series={yAxisDataArray} type="line" height="100%" className="w-full text-accent-foreground p-2"/>	
				</div>
				<div  className={Style.listWrap}>
					<div className={Style.key}>연체자 리스트</div>
					<ul>
						{/* <li>
							<div className={Style.cate}>@장안생활</div>
							<div className={Style.name}>302호 이지혜</div>
							<div className={Style.age}>32/여</div>
						</li> */}
						<li>
							<div className={Style.cate}>-</div>
						</li>
					</ul>
				</div>
			</section>

			<section className={Style.latestWrap}>
				<div  className={Style.headerWrap}>
					<div className={Style.lt}>
						<img src={CntList01} />
						<strong>거주자별 비용리스트</strong>

					</div>
					<div className={Style.rt}>
						<a href="/admin/space/costList">
							<span>더보기</span>
							<img src={More} />
						</a>
					</div>
				</div>
				<div className={Style.scrolls}>
					<table className={cn(Style.webList)}>
						<colgroup>
							<col width="" />
							<col width="" />
							<col width="" />
							<col width="" />
							<col width="" />
							<col width="" />
							<col width="" />
							<col width="" />
							<col width="" />
							<col width="" />
							<col width="180" />
						</colgroup>
						<thead>
						<tr>
							<th colSpan={'4'}>거주공간</th>
							<th colSpan={'5'}>공간비용</th>
							<th rowSpan={'2'}>거주/잔여차수</th>
							<th rowSpan={'2'}>계약기간</th>
						</tr>
						<tr>
							<th>공유주택</th>
							<th>층수</th>
							<th>공간타입</th>
							<th>호수</th>
							<th>보증금</th>
							<th>월세</th>
							<th>관리비</th>
							<th>납부일</th>
							<th>납부상태</th>
						</tr>
						</thead>
						{
							renteeExpenseList &&
							renteeExpenseList.map((item, index) => {
								return (
									<tr>
										<td>{item.sh_name}</td>
										<td>{item.hs_floor}층</td>
										<td>{item.shd_gonggan}</td>
										<td>{item.hs_hosu}호</td>
										<td>{getNumberString(item.hs_deposit)}</td>
										<td>{getNumberString(item.hs_rent_fee)}</td>
										<td>{typeof value === 'number' ? getNumberString(item.maintenance_fee) : item.maintenance_fee}</td>
										<td>{item.hs_paymentdate}일</td>
										<td>{item.payment_status}</td>
										<td>{item.monthsLiveLeft}</td>
										<td>{`${getDateString(item.hs_in_date)} ~`}<br/>{getDateString(item.hs_out_date)}</td>
									</tr>
								)
							})
						}
			
					</table>

					<ul className={cn(Style.mobList, Style.type01)}>
						<li>
							<div className={Style.chk}>
								<input type="checkbox" />
							</div>
							<table>
								<colgroup>
									<col width="40" />
									<col width="" />
									<col width="" />
									<col width="40" />
									<col width="" />
									<col width="" />
								</colgroup>
								<tr>
									<th rowSpan={4} className={Style.ac}>거주<br />공간</th>
									<td className={Style.al}>공유주택</td>
									<td className={Style.al}>장안생활</td>
									<th rowSpan={4} className={Style.ac}>공간<br />비용</th>
									<td className={Style.al}>보증금</td>
									<td className={Style.al}>5천만원</td>
								</tr>
								<tr>
									<td className={Style.al}>층수</td>
									<td className={Style.al}>7층</td>
									<td rowSpan={2} className={Style.al}>월세</td>
									<td rowSpan={2} className={Style.al}>60만원</td>
								</tr>
								<tr>
									<td className={Style.al}>공간타임</td>
									<td className={Style.al}>개별거주공간</td>
								</tr>
								<tr>
									<td className={Style.al}>호수</td>
									<td className={Style.al}>712호</td>
									<td className={Style.al}>관리비</td>
									<td className={Style.al}>2만4천원</td>
								</tr>
								<tr>
									<th colSpan={2} className={Style.ac}>거주/잔여차수</th>
									<td colSpan={4} className={Style.al}>5개월/7개월</td>
								</tr>
								<tr>
									<th colSpan={2} className={Style.ac}>계약기간</th>
									<td colSpan={4} className={Style.al}>2022.9.13~2023.9.12</td>
								</tr>
							</table>
						</li>
						<li>
							<div className={Style.chk}>
								<input type="checkbox" />
							</div>
							<table>
								<colgroup>
									<col width="40" />
									<col width="" />
									<col width="" />
									<col width="40" />
									<col width="" />
									<col width="" />
								</colgroup>
								<tr>
									<th rowSpan={4} className={Style.ac}>거주<br />공간</th>
									<td className={Style.al}>공유주택</td>
									<td className={Style.al}>장안생활</td>
									<th rowSpan={4} className={Style.ac}>공간<br />비용</th>
									<td className={Style.al}>보증금</td>
									<td className={Style.al}>5천만원</td>
								</tr>
								<tr>
									<td className={Style.al}>층수</td>
									<td className={Style.al}>7층</td>
									<td rowSpan={2} className={Style.al}>월세</td>
									<td rowSpan={2} className={Style.al}>60만원</td>
								</tr>
								<tr>
									<td className={Style.al}>공간타임</td>
									<td className={Style.al}>개별거주공간</td>
								</tr>
								<tr>
									<td className={Style.al}>호수</td>
									<td className={Style.al}>712호</td>
									<td className={Style.al}>관리비</td>
									<td className={Style.al}>2만4천원</td>
								</tr>
								<tr>
									<th colSpan={2} className={Style.ac}>거주/잔여차수</th>
									<td colSpan={4} className={Style.al}>5개월/7개월</td>
								</tr>
								<tr>
									<th colSpan={2} className={Style.ac}>입주기간</th>
									<td colSpan={4} className={Style.al}>2022.9.13~2023.9.12</td>
								</tr>
							</table>
						</li>
					</ul>
				</div>
			</section>

		<section className={Style.latestWrap}>
			<div  className={Style.headerWrap}>
			<div className={Style.lt}>
				<img src={CostList} />
				<strong>관리비 리스트</strong>

			</div>
			<div className={Style.rt}>
				<a href="/admin/space/admCostList">
				<span>더보기</span>
				<img src={More} />
				</a>
			</div>
			</div>
			<div className={Style.scrolls}>
			<table className={cn(Style.webList)}>
				<colgroup>
					<col width="" />
					<col width="" />
					<col width="" />
					<col width="" />
					<col width="" />
					<col width="" />
					<col width="" />
					<col width="" />
					<col width="" />
					<col width="180" />
				</colgroup>
				<thead>
				<tr>
					<th>공유주택</th>
					<th>층수</th>
					<th>호수</th>
					<th>거주자</th>
					<th>년/월</th>
					<th>기본관리비</th>
					<th>전기비용</th>
					<th>수도비용</th>
					<th>가스비용</th>
					<th>기타비용</th>
				</tr>
				</thead>
				{
					renteeUtilityList &&
					renteeUtilityList.map((item, index) => {
						return (
							<tr>
								<td>{item.sh_name}</td>
								<td>{item.uc_floor}층</td>
								<td>{item.uc_hosu}호</td>
								<td>{item.mem_name}</td>
								<td>{`${item.uc_datey}년 ${item.uc_datem}월`}</td>
								<td>{getNumberString(item.uc_cost_kibon)}</td>
								<td>{`${getNumberString(item.uc_cost_electric !== null ? item.uc_cost_electric : 0)} / ${getNumberString(item.uc_cost_electric_etc !== null ? item.uc_cost_electric_etc : 0)}`}</td>
								<td>{`${getNumberString(item.uc_cost_sudo !== null ? item.uc_cost_sudo : 0)} / ${getNumberString(item.uc_cost_sudo_etc !== null ? item.uc_cost_sudo_etc : 0)}`}</td>
								<td>{`${getNumberString(item.uc_cost_gas !== null ? item.uc_cost_gas : 0)} / ${getNumberString(item.uc_cost_gas_etc !== null ? item.uc_cost_gas_etc : 0)}`}</td>
								<td>{`${getNumberString(item.uc_cost_etc1 !== null ? item.uc_cost_etc1 : 0)} / ${getNumberString(item.uc_cost_etc1_t !== null ? item.uc_cost_etc1_t : 0)}`}</td>
							</tr>
						)
					})
				}
			</table>

			<ul className={cn(Style.mobList, Style.type01)}>
				<li>
				<div className={Style.chk}>
					<input type="checkbox" />
				</div>
				<table>
					<colgroup>
						<col width="100" />
						<col width="" />
					</colgroup>
					<tr>
						<th className={Style.al}>공유주택</th>
						<td className={Style.al}>장안생활</td>
					</tr>
					<tr>
						<th className={Style.al}>층수</th>
						<td className={Style.al}>7층</td>
					</tr>
					<tr>
					<th className={Style.al}>호수</th>
					<td className={Style.al}>712호</td>
					</tr>
					<tr>
					<th className={Style.al}>거주자</th>
					<td className={Style.al}>이수진</td>
					</tr>
					<tr>
					<th className={Style.al}>년/월</th>
					<td className={Style.al}>23년 11월</td>
					</tr>
					<tr>
					<th className={Style.al}>기본관리비</th>
					<td className={Style.al}>135,000원</td>
					</tr>
					<tr>
					<th className={Style.al}>전기비용</th>
					<td className={Style.al}>0원 / 12,527원</td>
					</tr>
					<tr>
					<th className={Style.al}>수도비용</th>
					<td className={Style.al}>0원 / 12,527원</td>
					</tr>
					<tr>
					<th className={Style.al}>가스비용</th>
					<td className={Style.al}>0원 / 12,527원</td>
					</tr>
					<tr>
					<th className={Style.al}>기타비용</th>
					<td className={Style.al}>0원 / 12,527원</td>
					</tr>
				</table>
				</li>
				<li>
				<div className={Style.chk}>
					<input type="checkbox" />
				</div>
				<table>
					<colgroup>
					<col width="100" />
					<col width="" />
					</colgroup>
					<tr>
					<th className={Style.al}>공유주택</th>
					<td className={Style.al}>장안생활</td>
					</tr>
					<tr>
					<th className={Style.al}>층수</th>
					<td className={Style.al}>7층</td>
					</tr>
					<tr>
					<th className={Style.al}>호수</th>
					<td className={Style.al}>712호</td>
					</tr>
					<tr>
					<th className={Style.al}>거주자</th>
					<td className={Style.al}>이수진</td>
					</tr>
					<tr>
					<th className={Style.al}>년/월</th>
					<td className={Style.al}>23년 11월</td>
					</tr>
					<tr>
					<th className={Style.al}>기본관리비</th>
					<td className={Style.al}>135,000원</td>
					</tr>
					<tr>
					<th className={Style.al}>전기비용</th>
					<td className={Style.al}>0원 / 12,527원</td>
					</tr>
					<tr>
					<th className={Style.al}>수도비용</th>
					<td className={Style.al}>0원 / 12,527원</td>
					</tr>
					<tr>
					<th className={Style.al}>가스비용</th>
					<td className={Style.al}>0원 / 12,527원</td>
					</tr>
					<tr>
					<th className={Style.al}>기타비용</th>
					<td className={Style.al}>0원 / 12,527원</td>
					</tr>
				</table>
				</li>
			</ul>

			</div>
		</section>
		<Calendar style = {{ paddingLeft : 0, marginLeft : 370 }} calendarData={calendarData} openDailyIncomeInfoDialog={openDailyIncomeInfoDialog}/>
		{
			renteeTableTitle && renteeTableData &&
			<RenteeInfoDialog isOpenedDialog={isOpenedRenteeDialog} setIsOpenedDialog={setIsOpenedRenteeDialog}
				renteeTableTitle={renteeTableTitle} renteeTableData={renteeTableData} onUserInfoButtonClicked={onUserInfoButtonClicked} isUtility={isDialogUtility}/>
		}
		{
			paymentTableTitle && paymentTableData &&
			<PaymentInfoDialog isOpenedDialog={isOpenedPaymentDialog} setIsOpenedDialog={setIsOpenedPaymentDialog}
				paymentTableTitle={paymentTableTitle} paymentTableData={paymentTableData} onUserInfoButtonClicked={onUserInfoButtonClicked}/>
		}
	</div>
	}
		
	</>
	)
}

export default AdminDashboard
