import {useState, useEffect, useContext} from 'react'
import { useNavigate } from 'react-router-dom';
import { GroupSettingsContext } from './index'
import { Button } from '@/components/ui/button'
import { Input } from "@/components/ui/input"
import { Label, GreenLabel } from "@/components/ui/label"
import { MySpinner } from '@/components/ui/spinner'

import { cn } from "@/components/lib/utils"
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Textarea } from "@/components/ui/textarea"
import { toast } from "@/components/ui/use-toast"
import { UserSearchTable } from './user_search_table/table'
import { AddedMembersTable } from './added_member_table/table'
import { MemberDirectInputForm } from './member_direct_input_form'
import { Sheet, SheetClose, SheetContent, SheetDescription, SheetFooter, SheetHeader, SheetTitle, SheetTrigger } from "@/components/ui/sheet"
import { X, Bell } from "lucide-react"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import Axios from "@/components/lib/axios";

const GroupEditor = ({groupId}) => {
	const navigate = useNavigate();
	const { addedMembersList, setAddedMembersList } = useContext(GroupSettingsContext);
	const [inputGroupName, setInputGroupName] = useState("")
	const queryClient = useQueryClient()

	const { isLoading, error } = useQuery({queryKey:["group_detail_data", groupId], queryFn: () =>
		Axios.get(`/api_message/group/${groupId}`).then((res) => {
			setInputGroupName(res.data.name);
			setAddedMembersList(JSON.parse(res.data.members));
			return res.data;
	})});

	const updateGroup = useMutation({
		mutationFn: async () => {
			var params;
			params = {
				name: inputGroupName,
				members: JSON.stringify(addedMembersList),
				num_of_members: addedMembersList.length
			}
			
			const { res } = await Axios.put(`/api_message/group/${groupId}`, params)
			return res
		},
		onSuccess: (res) => {
			setAddedMembersList([])
			queryClient.invalidateQueries({ queryKey: ["message_group_list"] })
			navigate(-1)
		},
	});

	return (
		<>
		{
			isLoading
            ? <MySpinner/>
            :
			<div className='flex flex-col'>
				<div className="w-full mt-12 lg:flex lg:flex-row justify-between gap-5">
					<div className="lg:w-1/2  flex flex-col w-full">
						<GreenLabel className="">그룹 이름</GreenLabel>
						<Input className="mt-3 w-[25.25rem]" value={inputGroupName} onChange={(event)=>setInputGroupName(event.target.value)}/>
						<MemberDirectInputForm/>
						<GreenLabel className="mt-6">회원 검색 선택</GreenLabel>
						<UserSearchTable/>
					</div>
					<div className="lg:w-1/2 w-full">
						<AddedMembersTable/>
					</div>
				</div>
				<div className='flex flex-row justify-end w-full mt-10 gap-6'>
					<Button variant="big" size="big" onClick={() => navigate(-1)}>취소</Button>
					<Button variant="big" size="big" onClick={() => addedMembersList.length > 0 ? updateGroup.mutate() : alert("그룹원을 추가 해 주세요")}>업데이트</Button>
				</div>
			</div>
		}
		</>
	)
}

export default GroupEditor
