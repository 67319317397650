import {React, useState, useEffect, useRef} from 'react'
import axios from "axios";
import cn from 'classnames'

// css
import Style from './index.module.css'
import ProductImg from '@/assets/image/etc/etc-product01.png'
import MainImg from '@/assets/image/etc/etc-main-list01.png'
import SpaceHome from '@/assets/image/ico/ico-adm.svg'
import MnList01 from '@/assets/image/ico/ico-mn-list01.svg'
import MnList02 from '@/assets/image/ico/ico-mn-list02.svg'
import MnList03 from '@/assets/image/ico/ico-mn-list03.svg'
import MnList04 from '@/assets/image/ico/ico-mn-list04.svg'
import MnList05 from '@/assets/image/ico/ico-mn-list05.svg'
import MnList06 from '@/assets/image/ico/ico-mn-list06.svg'
import Cmm from '@/assets/image/ico/ico-cmm.svg'
import Notice from '@/assets/image/ico/ico-noti.svg'
import More from '@/assets/image/ico/ico-arr-more.svg'
import Profile from "@/assets/image/etc/etc-product01.png";
import SearchBtn from "@/assets/image/ico/ico-search.svg";
import RemoveIco from "@/assets/image/ico/ico-remove-white.svg";
import popClose from '@/assets/image/ico/ico-pop-close.svg'



const Index = () => {
	const [isPopup, setIsPopup] = useState(true);
	const [list, setList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [houseList, setHouseList] = useState([]);
	const [companyname, setCompanyName] = useState("");
	const [companyemail1, setCompanyEmail1] = useState("");
	const [companyemail2, setCompanyEmail2] = useState("");
	const [companypasswd, setCompanyPasswd] = useState("");
	const [managerhouse, setManagerHouse] = useState("");
	const [companyclass, setCompanyClass] = useState("A");
	const [companydamdangname, setCompanydamdangName] = useState("");
	const [companybuseo, setCompanyBuseo] = useState("");
	const [companypos, setCompanyPos] = useState("");
	const [managerbirthd, setManagerBirthd] = useState("");
	const [companytel1, setCompanyTel1] = useState("010");
	const [companytel2, setCompanyTel2] = useState("");
	const [companytel3, setCompanyTel3] = useState("");

	const now = new Date();
	const namefocusRef = useRef(null);
	const email1focusRef = useRef(null);
	const email2focusRef = useRef(null);
	const passwdfocusRef = useRef(null);
	const housefocusRef = useRef(null);

	const _ = require('lodash');
	const years = _.range(now.getFullYear() - 64, now.getFullYear() + 1, 1);

	const handleClose = () => {
		setIsPopup(false);
	}

	const gethouseList = async (e) => {
		const resp = await (await axios.get(process.env.REACT_APP_API_MANAGERHOUSELIST)).data; 
		setHouseList(resp.list); 
	}

	const onReg = () => {
		
		if(companyname === ""){
			alert("기업명을 입력해주세요.");
			namefocusRef.current.focus();
			return;
		}
		if(companydamdangname === ""){
			alert("담당자명을 입력해주세요.");
			return;
		}
		if(companyemail1 === ""){
			alert("이메일을 입력해주세요.");
			email1focusRef.current.focus();
			return;
		}
		if(companyemail2 === ""){
			alert("이메일을 입력해주세요.");
			email2focusRef.current.focus();
			return;
		}

		if(companypasswd === ""){
			alert("비밀번호를 입력해주세요.");
			passwdfocusRef.current.focus();
			return;
		}
		
		const formData = new FormData();
		//formData.append('manager',usersid)
		formData.append('companyclass',companyclass)
		formData.append('companyname',companyname)
		formData.append('damdangname',companydamdangname)
		formData.append('buseo',companybuseo)
		formData.append('pos',companypos)
		formData.append('tel1',companytel1)
		formData.append('tel2',companytel2)
		formData.append('tel3',companytel3)
		formData.append('email1',companyemail1)
		formData.append('email2',companyemail2)
		formData.append('passwd',companypasswd)

		axios({
			method:'post',
			url:process.env.REACT_APP_API_CPNMEMBERREG,
			data: formData,
		})
			.then((result)=>{
				if(result.data.code == 200){
					alert("등록 되었습니다.");
					window.location.href="/admin/companyList";
				}else if(result.data.code == 300){
					alert("이미 등록된 이메일 주소입니다.");
				}else{
					console.log(result);
				}
		})
			.catch((error)=>{console.log('요청실패')
			console.log(error)  
		})
	}

	/*useEffect(() => {
		console.log(managerclass);
	}, [managerclass]);*/

	return (
		<div className={Style.wrapper}>

			<section className={Style.spacedHeader}>

				<div className={Style.pageTitle}>
					<img src={SpaceHome} alt="매니저등록" />
					<span>기업 및 위탁 계정 생성</span>
				</div>

			</section>

			<section className={Style.registerWrap}>

				<div className={Style.formGroup}>
					
					<div className={Style.formRows}>
						<div className={Style.label}>계정 타입</div>
						<div className={Style.formItem}>
							<label htmlFor={'id'}><input type='radio' id={'id'} checked='checked' name="class_gubun" value="A" onChange={(e) => setCompanyClass(e.target.value)} /><span>기업</span></label> 	
							<label htmlFor={'id2'}><input type='radio' id={'id2'} name="class_gubun" value="B" onChange={(e) => setCompanyClass(e.target.value)} /><span>위탁</span></label>
						</div>
					</div>

					<div className={Style.formRows}>
						<div className={Style.label}>기업명</div>
						<div className={Style.formItem}>
							<input type={'text'} className={cn(Style.inp, Style.email)} ref={namefocusRef} value={companyname} onChange={(e) => setCompanyName(e.target.value)} />	
						</div>
					</div>
					<div className={Style.formRows}>
						<div className={Style.label}>담당자명</div>
						<div className={Style.formItem}>
							<input type={'text'} className={cn(Style.inp, Style.email)} value={companydamdangname} onChange={(e) => setCompanydamdangName(e.target.value)} />	
						</div>
					</div>
                    <div className={Style.formRows}>
						<div className={Style.label}>담당자 부서/직책</div>
						<div className={Style.formItem}>
							<input type={'text'} style={{maxWidth:'180px',marginRight:'20px'}} className={cn(Style.inp, Style.email)} value={companybuseo} onChange={(e) => setCompanyBuseo(e.target.value)} />
                            <input type={'text'} style={{maxWidth:'180px',marginRight:'20px'}} className={cn(Style.inp, Style.email)} value={companypos} onChange={(e) => setCompanyPos(e.target.value)} />	
						</div>
					</div>
					<div className={Style.formRows}>
						<div className={Style.label}>연락처</div>
						<div className={Style.formItem}>
							<select name="hptel1" className={Style.select} value={companytel1} onChange={(e) => setCompanyTel1(e.target.value)}>
								<option value="010" selected>010</option>
								<option value="011">011</option>
								<option value="016">016</option>
								<option value="017">017</option>
								<option value="018">018</option>
								<option value="019">019</option>
								<option value="02">02</option>
								<option value="031">031</option>
								<option value="032">032</option>
							</select>
							<span className={Style.e01}>-</span>
							<input type={'text'} className={cn(Style.inp, Style.tel)} value={companytel2} onChange={(e) => setCompanyTel2(e.target.value)} />
							<span className={Style.e01}>-</span>
							<input type={'text'} className={cn(Style.inp, Style.tel)} value={companytel3} onChange={(e) => setCompanyTel3(e.target.value)} />
						</div>
					</div>

                    <div className={Style.formRows}>
                        <div className={Style.label}>이메일</div>
                        <div className={Style.formItem}>
                        <input type={'text'} name="email1" className={cn(Style.inp, Style.email)} ref={email1focusRef} value={companyemail1} onChange={(e) => setCompanyEmail1(e.target.value)} />
                        <span className={Style.e01}>@</span>
                        <input type={'text'} name="email2" className={cn(Style.inp, Style.email)} ref={email2focusRef} value={companyemail2} onChange={(e) => setCompanyEmail2(e.target.value)} />
                        </div>
                    </div>

                    <div className={Style.formRows}>
                        <div className={Style.label}>비밀번호</div>
                        <div className={Style.formItem}>
                            <input type={'password'} className={cn(Style.inp, Style.email)} ref={passwdfocusRef} value={companypasswd} onChange={(e) => setCompanyPasswd(e.target.value)} />	
                        </div>
                    </div>
				</div>

				<div className={Style.btnGroup}>
					<button type="button" className={Style.btnCancel}>취소</button>
					<button type="button" className={Style.btnConfirm} onClick={onReg}>등록</button>
				</div>
			</section>


		</div>
	)
}

export default Index
