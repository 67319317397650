import React from 'react'
import cn from 'classnames'

// css
import Style from './index.module.css'
import Profile from '@/assets/image/ico/ico-no-profile.svg'
//import mlist01 from '@/assets/image/etc/etc-main-list01.png'
import mlist01 from '@/assets/image/etc/etc-start-list01.png'
import Logo from '@/assets/video/logo.mp4'
//

const Index = () => {
	return (
		<div className={cn(Style.wrapper, Style.whiteType)}>

			<section className={Style.mainList}>
				<div className={Style.startItem}>
					<div className={Style.slogo}>
						<video width="100%" height="100%" id="v_player" playsinline="" allowfullscreen="true" autoPlay loop muted>
							<source src={Logo} type="video/mp4" />
						</video>
					</div>
					<div className={Style.scont}>
						<div className={Style.stit}>공유주택 플랫폼 SISO 입니다. </div>
						<div className={Style.sdesc}>회원가입하고 편리한 공유생활 하세요.</div>
					</div>
				</div>
				<div className={Style.mainHeader}>
					<div className={Style.userInfo}>
						<div className={Style.profile}>
							<div className={Style.upic} style={{background:`url(${Profile})`}}></div>
						</div>
						<div className={Style.umsg}>Guest로 로그인하셨습니다.</div>
					</div>

					<ul>
						<li><a href="/login">로그인</a></li>
						<li><a href="/signup">회원가입</a></li>
					</ul>
				</div>

				<div className={Style.mlist}>
					<ul>
						<li>
							<div className={Style.mpic} style={{background:`url(${mlist01})`}}>&nbsp;</div>
						</li>
					</ul>
				</div>
			</section>

		</div>
	)
}

export default Index
