import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import { Input } from "@/components/ui/input"

import { Send, Pencil, Trash, Circle, X } from "lucide-react"

export const getColumns = (data, numOfVariables) => {
  const fieldsList = [];
  for(let i=1; i <= numOfVariables; i++){
    fieldsList.push(data[0][`var_${i}_name`]);
  }
  
  var columns = [];

  columns = [
    {
      accessorKey: "building_name",
      header: () => <div className="text-left">공유 주택</div>,
      cell: ({ row }) => <div className="text-primary">{row.getValue("building_name")}</div>,
    },
    {
      accessorKey: "room_number",
      header: () => <div className="text-center">호수</div>,
      cell: ({ row }) => <div className="text-center text-primary">{row.getValue("room_number")}</div>,
    },
    {
      accessorKey: "name",
      header: () => <div className="text-center">이름</div>,
      cell: ({ row }) => <div className="text-center text-primary">{row.getValue("name")}</div>,
    },
    {
      accessorKey: "phone_number",
      header: () => <div className="text-center">전화번호</div>,
      cell: ({ row }) => <div className="text-center text-primary">{row.getValue("phone_number")}</div>,
    },
    {
      accessorKey: "email",
      header: () => <div className="text-center">이메일</div>,
      cell: ({ row }) => <div className="text-center text-primary">{row.getValue("email")}</div>,
    },
  ]

  for(let i=1; i <= numOfVariables; i++){
    columns.push(
      {
        accessorKey: `var_${i}_value`,
        header: () => <div className="text-center">{fieldsList[i-1]}</div>,
        cell: ({ row }) => <div className="text-center text-primary">{row.getValue(`var_${i}_value`)}</div>,
      }
    )
  }

  // variableData.forEach( (variable, index) => {
  //   columns.push(
  //     {
  //       accessorKey: variable.field_name,
  //       header: () => <div className="text-center">{variable.header}</div>,
  //       cell: ({ row }) => <div className="text-center text-primary">{row.getValue(variable.field_name)}</div>,
  //     }
  //   )
  // })
  
  

  return columns;
}