import {useState, useEffect, useContext} from 'react'
import { Label, GreenLabel } from "@/components/ui/label"
import { DifferentMessageTable } from './different_message_table/table'
import { useQuery } from "@tanstack/react-query";
import Axios from "@/components/lib/axios";
import { MySpinner } from "@/components/ui/spinner";
import { SameMessageTable } from './same_message_table/table'

const ContentViwer = ({type, id}) => {
	const { isLoading, error, data } = useQuery({queryKey:["send_history", type, id], queryFn: () =>
		Axios.get(`/api_message/send_history/${type}/${id}`).then((res) => {
			console.log(res.data);
			return res.data;
		})
	});

	const getTypeInKorean = () => {
		switch(type) {
			case 'push':
				return "푸쉬";
			case 'inapp':
				return "인앱";
			case 'email':
				return "이메일";
			case 'sms':
				return "문자";
			case 'alimtalk':
				return "알림톡";
		  }
	}

	return (
		<div className='w-full mt-24 flex flex-col'>
		{
			isLoading
			?
			<MySpinner/>
			:
			<>
				<div className="mb-5 title w-full">전송 내역 상세 정보</div>
				<div className='flex flex-row mt-6 gap-2'>
					<GreenLabel>전송 시간 :</GreenLabel>
					<Label>{data.sent_on}</Label>
				</div>
				<div className='flex flex-row mt-6 gap-2'>
					<GreenLabel>메세지 제목 :</GreenLabel>
					<Label>{data.subject}</Label>
				</div>
				<div className='flex flex-row mt-6 gap-2'>
					<GreenLabel>메시지 타입 :</GreenLabel>
					<Label>{getTypeInKorean()}</Label>
				</div>
				<div className='flex flex-row mt-6 gap-2'>
					<GreenLabel>전송 대상 :</GreenLabel>
					<Label>{data.target}</Label>
				</div>
				{
						type === "email"
						?
						<div className='flex flex-col mt-6 gap-2'>
							<GreenLabel>메세지 내용 :</GreenLabel>
							<div dangerouslySetInnerHTML={{ __html: data.content }} />
						</div>
						:
						<div className='flex flex-row mt-5 gap-2 items-start'>
							<GreenLabel className="mt-1">메세지 내용 :</GreenLabel>
							<pre className='text-base text-primary m-0'>{data.content}</pre>
						</div>
				}
				<GreenLabel className='mt-8'>{data.message_content_type === "different" ? "개별 전송 메시지 내용 및 수신인 리스트" : "수신인 리스트"}</GreenLabel>
				<div className='w-full mt-4'>
					{	
						data.message_content_type === "different" 
						?
						<DifferentMessageTable data={JSON.parse(data.different_message_list)} numOfVariables={data.num_of_variables}/>
						:
						<SameMessageTable data={JSON.parse(data.added_member_list)}/>
					}
				</div>
			</>
		}
		</div>
	)
}

export default ContentViwer
