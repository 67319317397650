import React, {useState, useRef} from 'react'
import { useNavigate } from "react-router-dom"
import axios from "axios";
import cn from 'classnames'
import { setToken } from "../../services/TokenService";
import { GoogleOAuthProvider } from '@react-oauth/google'
import GoogleSNSLogin from '../../components/googleSNSLogin'

// css
import Style from './index.module.css'
import Kakao from '@/assets/image/ico/ico-sns-list01.svg'
import Google from '@/assets/image/ico/ico-sns-list02.svg'
import Logo from '@/assets/video/logo.mp4'

const Index = () => {

	const emailfocusRef = useRef(null);
	const passfocusRef = useRef(null);
	const passconfocusRef = useRef(null);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordconfirm, setPasswordconfirm] = useState("");
    const [nickname, setNickname] = useState("");
	const [isLoding, setIsLoding] = useState(false);

	const pathname = window.location.pathname;
	const [referer, setReferer] = useState(pathname);
	
	const navigate = useNavigate();

	const REST_API_KEY = process.env.REACT_APP_KAKAO_RESTAPI_KEY;
  	const REDIRECT_URI = process.env.REACT_APP_KAKAO_LOGIN_REDIRECT_URI;
  	const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code`;
	const GOOGLE_REST_API_KEY = process.env.REACT_APP_GOOGLE_CLIENT_ID;

	const onKeyEnter = (e) => {
		if(e.key === 'Enter'){
			LoginFunc(e);
		}
	}

	const LoginFunc = (e) => {
		e.preventDefault();
		if(email === ""){
			alert("이메일 주소를 입력해주세요.");
			emailfocusRef.current.focus();
			return;
		}

		if(password === ""){
			alert("비밀번호를 입력해주세요.");
			passfocusRef.current.focus();
			return;
		}
		
		axios.post(process.env.REACT_APP_API_LOGIN, {
			useremail: email,
			password: password,
			referer: referer
		})
		.then(function (response) {
			 // response  
			// const accessToken = 'slkdfnsldfn43wl5n345345';
			// API 요청하는 콜마다 헤더에 accessToken 담아 보내도록 설정
			//axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
			
			if(response.data.result === true){
				const token_data = {
					access_token: response.data.access_token, 
					token_type: response.data.token_type,
					exp: response.data.exp
				  }; 
				if(response.data.adchk === 'Y'){
					token_data["adchk"] = "Y";
				} 
				if(response.data.company){
					token_data["company"] = response.data.company;
				}  
				setToken(JSON.stringify(token_data));
				alert("로그인 했습니다.");
				
				if(response.data.adchk === 'Y'){
					window.location.href="/admin/main";
				}else if(response.data.adchk === 'N'){
					window.location.href="/";
				}
			}else{
				if(response.data.msg === 'no member'){
					alert("등록된 회원이 없습니다. 가입후 이용바랍니다.");
					window.location.href="/signup";
				}else if(response.data.msg === 'wrong password'){
					alert("비밀번호가 일치하지 않습니다. 확인후 다시 로그인해주세요.");
					window.location.reload();
				}else{
					alert("로그인이 실패하였습니다.");
					window.location.reload();
				}
			}

		}).catch(function (error) {
			// 오류발생시 실행
			console.log("에러", error);

		});

	}

	const ad_login =() => {
		navigate("/signup", { state: 'Y' });
	}

	const login = () => {
		navigate("/signup", { state: 'N' });
	}

	return (
		 <div className={Style.wrapper}>
			<section className={Style.loginWrap}>
				<div className={Style.slogo}>
					<video width="100%" height="100%" id="v_player" playsinline="" allowfullscreen="true" autoPlay loop muted>
						<source src={Logo} type="video/mp4" />
					</video>
				</div>
				<div className={Style.stit}>
					로그인 하기
					</div>
				<div className={Style.loginForm}>
					<div className={Style.formItem}>
						<input type="text" id="email" value={email} className={Style.inp} ref={emailfocusRef} placeholder="이메일주소" onChange={(e) => setEmail(e.target.value)} />
					</div>
					<div className={Style.formItem}>
						<input type="password" id="password" name="password" value={password} ref={passfocusRef} className={Style.inp} placeholder="비밀번호" onKeyPress={onKeyEnter} onChange={(e) => setPassword(e.target.value)} />
					</div>
					<div className={Style.findLink}>
						<a href="#">비밀번호 찾기</a>
					</div>
					<div className={Style.findLink}>
					{pathname === "/admin" ?
						<a href="#;" onClick={ad_login}>회원가입하기</a>
						:
						<a href="#" onClick={login}>회원가입하기</a>
					}
					</div>
					<button type="button" onClick={LoginFunc}>로그인</button>
					<div className={Style.snsList}>
						<a href={KAKAO_AUTH_URL}><img src={Kakao} /></a>
						<GoogleOAuthProvider clientId={`${GOOGLE_REST_API_KEY}`}>
							<GoogleSNSLogin />
						</GoogleOAuthProvider>
					</div>
				</div>
			</section>
		</div>
	)
}

export default Index
