import {useState, useEffect, useContext, createContext} from 'react'
import { SendHistoryTable } from './table/table'
import { Button } from '@/components/ui/button'
import { useQuery } from "@tanstack/react-query";
import Axios from "@/components/lib/axios";
import { MySpinner } from "@/components/ui/spinner";

const ContentTable = ({type}) => {
	const { isLoading, error, data } = useQuery({queryKey:["send_history", type], queryFn: () =>
		Axios.get(`/api_message/send_history/${type}`).then((res) => {
			return res.data;
		})
	});

	return (
		<div className="w-full mt-24">
			{
				isLoading
				?
				<MySpinner/>
				:
				<SendHistoryTable type={type} data={data}/>
			}
		</div>
	)
}

export default ContentTable
